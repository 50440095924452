import actions from '../../actions';
import Header from '../../atoms/Header';
import MessagesUnreadCount from '../Messages/UnreadCount';

import moment from 'moment';
import { saveAs } from 'file-saver';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NotificationsMenu from './NotificationsMenu';
import { isSmallScreenWidth, isNativeApp } from '../../lib/environment-check';
import { getDefaultLocation } from '../../lib/utils';
import classNames from 'classnames';
import { fetchCsvDownload,fetchNotificationTypes } from '../../lib/api';

const { upsertUserDetail } = actions;

class MainMenu extends Component{
  constructor(props){
    super(props);
    this.state = {
      menu: {},
      isSmallScreenWidth: isSmallScreenWidth(),
      csvLoading: false,
      csvData: null,
      openMenuOption: window.openMenuOption || '',
      notification_types: false
    };
  }
  UNSAFE_componentWillMount(){
    this.on_orientationChange = this.on_orientationChange.bind(this);
    window.addEventListener('orientationchange', this.on_orientationChange);
  }

  componentWillUnmount(){
    window.removeEventListener('orientationchange', this.on_orientationChange);
  }

  on_orientationChange(){
    this.setState({
      isSmallScreenWidth: isSmallScreenWidth()
    });
  }

  getMenuUrl(replacement){
    const { routerLocation } = this.props;
    return routerLocation.pathname.replace(/\/menu(.+)?/, `/menu/${replacement}`);
  }

  onClickLocationLink(locationId){
    return () => {
      const {openMenuOption} = this.state;
      if(openMenuOption === locationId) {
        window.openMenuOption = '';
        this.setState({
          openMenuOption: ''
        });
      } else {
        window.openMenuOption = locationId;
        this.setState({
          openMenuOption: locationId
        });
      }
      const { history } = this.props;
      history.replace(
        this.getMenuUrl(locationId)
      );
    };
  }

  onClickWalkthroughLink(){
    return e => {
      const { history, routerLocation } = this.props;
      e.preventDefault();
      window.scrollTo(0,0);
      this.props.upsertUserDetail(
        {
          userDetail: {
            force_walkthrough: true
          },
          local: true
        }
      );
      history.replace(
        routerLocation.pathname.replace(/\/menu(.+)?/, '')
      );
    };
  }

  generateHistorical(currentLocation){
    var datestring = moment().format('YYYY-MM-DD');
    this.setState({
      csvLoading: true
    });
    fetchCsvDownload(currentLocation.id, 'billing', 'h')
      .then(
        response => {
          const { menu } = this.props;
          if(currentLocation.id === menu.locationId){
            this.setState({
              csvData: response,
              csvFilename: `${currentLocation.label} - Billing thru ${datestring}.csv`
            });
          }
        }
      )
      .catch(console.error)
      .then(
        () => this.setState({
          csvLoading: false
        })
      );
  }

  downloadHistorical(e){
    const { csvData, csvFilename } = this.state;
    e.preventDefault();
    e.stopPropagation();
    if(csvData){
      saveAs(
        new Blob([csvData], { type: 'data:text/plain;base64' }),
        csvFilename
      );
    }
  }

  getMessagesUrl(){
    let { location } = this.props;
    const { isSmallScreenWidth } = this.state;
    if(!location || !location.id){
      location = getDefaultLocation(this.props);
    }
    var baseUrl = '/inbox';
    if(!isSmallScreenWidth){
      return `/dash/${location.id}/menu${baseUrl}`;
    }
    return baseUrl;
  }

  getProfileLink(){
    return (
      <Link
        className="profile-link anchor"
        to={this.getMenuUrl('profile')}>
        <i />
      </Link>
    );
  }

  onClickConfigLink(loc){
    return e => {
      const { history } = this.props;
      e.preventDefault();
      history.push(this.getMenuUrl(`${loc.id}/configurations`));
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    // if we close the menu or switch location, clear out the csv state
    if(!nextProps.menu.open || nextProps.menu.locationId !== this.props.menu.locationId){
      this.setState({
        csvLoading: false,
        csvData: null
      });
    }
  }

  render(){
    const { location, locationsFlat, appConfig, user, inbox, userDetail, basic, closeMenu} = this.props;
    const { csvLoading, csvData, openMenuOption, notification_types } = this.state;
    const support_phone_number = appConfig.support_phone_number || '844-629-2872';
    const support_email_address = appConfig.support_email_address || 'support@energycurb.com';

    if(notification_types===false){
      fetchNotificationTypes(appConfig.fleet_id)
        .then(response => {
          this.setState({
            notification_types: response
          });
        })
        .catch(()=> {
          this.setState({
            notification_types: true
          });
        });
    }
    let locationsList = user.isDemoInstaller
      ? [location]
      : locationsFlat;

    const locationsMenu = () => {
      if(locationsList && locationsList.length){
        return locationsList.map(loc => {
          const isActive = openMenuOption === loc.id;
          const isCurrentLocation = location.id === loc.id;
          const iconClassNames = classNames({
            'fa fa-chevron-circle-up rotate-arrow active-menu': isActive,
            'fa fa-chevron-circle-up rotate-arrow inactive-menu ': !isActive
          });

          return (
            <li key={loc.id} role="navigation">
              <div aria-controls="locations-submenu" onClick={this.onClickLocationLink(loc.id)} className={`fake-link ${isActive ? 'active' : ''}`}>
                <i className={(isCurrentLocation ? 'highlightIcon' : 'lowlightIcon') + ' fa-home fa fa-fw'} />
                { user.isDemoGuest ? 'Demo Home' : loc.label } 
                {
                  locationsList.length > 0 ?
                  (
                    <span className="location-button">
                      <i className={iconClassNames} />
                    </span>
                  ) :
                  null
                }
              </div>
              <div id="locations-submenu" className={isActive ? 'locationHolder opened options-3' : 'locationHolder closed'}>
              {
                isActive && (
                    <ul className="sub-list">
                      {
                        !user.isDemoGuest && (
                          <li>
                            <Link className={"anchor"} to={this.getMenuUrl(`${loc.id}/info`)} role="menuitem">
                              <i className="fa fa-fw fa-info-circle" />
                              Location Information
                            </Link>
                          </li>
                        )
                      }
                      <li>
                        <Link className={"anchor"} to={this.getMenuUrl(`${loc.id}/energy-provider`)} role="menuitem">
                          <i className="fa fa-fw fa-bolt" />
                          Energy Provider
                        </Link>
                      </li>
                      {
                        user.isDemoGuest ?
                        null :
                        (
                          <li>
                            <a className={"anchor"} role="menuitem" onClick={this.onClickConfigLink(loc)}>
                              <i className="fa-gears fa fa-fw" />
                              Panels &amp; Power Hubs
                            </a>
                          </li>
                        )
                      }
                      {
                        !isNativeApp && (
                            <li className="download-data">
                              <a className={"anchor"} onClick={() => this.generateHistorical(loc)} role="menuitem">
                                <i className="fa fa-table fa-fw" />
                                Download Data
                                {
                                  (csvLoading || csvData) && (
                                    <button
                                      onClick={(e) => this.downloadHistorical(e)}
                                      disabled={csvLoading}
                                      className="button red inline small">
                                      {
                                        csvLoading
                                          ? (
                                            <span>Generating <i className="fa fa-spinner" /></span>
                                          )
                                          : (
                                            <span>Download <i className="fa fa-download" /></span>
                                          )
                                      }
                                    </button>
                                  )
                                }
                              </a>
                            </li>
                          )
                      }
                    </ul>
                  )
              }
              </div>
            </li>
          );
        });
      }
    };

    let walkthrough = '';

    if(!basic){
      walkthrough = (
        <li>
          <a onClick={this.onClickWalkthroughLink()} role="menuitem">
            <i className="fa fa-fw fa-hand-o-right" />
            Walkthrough Tutorial
          </a>
        </li>
      );
    }

    return (
      <div className="main-menu full-screen">
        <Header clickTo={closeMenu} title="Menu">
          {
            (!user.isDemoGuest && !user.isDemoInstaller && userDetail.user_id) && this.getProfileLink()
          }
        </Header>
        <ul className="link-list main">
          {
            (!user.isDemoGuest && !user.isDemoInstaller && inbox.messages_count > 0) && (
              <li className="inbox-list-item">
                <Link to={this.getMessagesUrl()} role="menuitem">
                  <i className="fa fa-fw fa-envelope-open-o" />
                  Messages
                  <MessagesUnreadCount
                    count={inbox.messages_unread_count} />
                </Link>
              </li>
            )
          }

          {locationsMenu()}
          {Object.keys(notification_types).length>0 && 
            (<li>
              <div onClick={() => {
                if(openMenuOption!=='notifications') {
                  window.ga && window.ga('send', 'pageview', {title: 'Notifications Menu', page: '/dash/location_id/menu/notifications'});
                }
                this.setState({
                  openMenuOption: openMenuOption==='notifications'?'':'notifications'
                });
              }} className="fake-link support"
              aria-controls="notifications-submenu"
              role="menuitem"
              >
                <i className="fa fa-fw fa-question-circle" />
                  Notification Settings
                <span className="location-button">
                  <i className={openMenuOption === 'notifications' ? 'fa fa-chevron-circle-up rotate-arrow active-menu' : 'fa fa-chevron-circle-up rotate-arrow inactive-menu'} />
                </span> 
              </div>
              <div id="notifications-submenu" className={openMenuOption === 'notifications' ? 'locationHolder opened options-1' : 'locationHolder closed'}>
                {openMenuOption === 'notifications' && (
                  <NotificationsMenu userDetail={userDetail} notification_types={notification_types} />
                )}
              </div>
            </li>)}
          <li>
            <div onClick={() => {
              if(openMenuOption!=='support'){
                window.ga && window.ga('send', 'pageview', {title: 'Support Menu', page: '/menu/location_id/support'});
              }
              this.setState({
                openMenuOption: openMenuOption==='support'?'':'support'
              });
            }} className="fake-link support"
            aria-controls="support-submenu"
            >
              <i className="fa fa-fw fa-question-circle" />
              Support
              <span className="location-button">
                <i className={openMenuOption === 'support' ? 'fa fa-chevron-circle-up rotate-arrow active-menu' : 'fa fa-chevron-circle-up rotate-arrow inactive-menu'} />
              </span> 
            </div>
            <div id="support-submenu" className={openMenuOption === 'support' ? 'locationHolder opened options-2' : 'locationHolder closed'}>
              <ul className="sub-list">
                {walkthrough}
                <li>
                  <a className={"anchor"} href={`mailto:${support_email_address}`} role="menuitem" 
                    target="_new">
                    <i className="fa fa-fw fa-envelope" />
                    Email Support
                  </a>
                </li>
                {/*<li>
                  <a href="https://app.energycurb.com/account/password/reset" target="_blank">
                    <i className="fa fa-fw fa-unlock-alt" />
                    Reset Password
                  </a>
                </li>*/}
              </ul>
            </div>
          </li>
          <li>
            <Link className="logout"to="/logout" role="menuitem">
              <i className="fa fa-fw fa-sign-out" />
               Log Out
            </Link>
          </li>
        </ul>

      </div>
    );
  }
}

export default connect(
  // mapStateToProps
  (state) => {
    const { user, userDetail, billing, inbox, appConfig } = state;
    return {
      user,
      userDetail,
      billing,
      inbox,
      appConfig
    };
  },
  // mapDispatchToProps
  {
    upsertUserDetail
  }
)(MainMenu);
