import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrencyFormatter } from '../../lib/formatters';
import { Link } from 'react-router-dom';
import Loader from '../../atoms/Loader';
import { getBillingCycleStart, getTotalCost } from '../../lib/utils';
import { timeFormatter } from '../../lib/formatters';
import { calculateCurrentBill } from '../../lib/api';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';
import MoreInfoLink from '../../atoms/MoreInfoLink';

class CostBreakdown extends Component {
  static defaultProps = {
    location: {},
    billing: {}
  }

  constructor(props){
    super(props);
    this.state = {
      gridImpact: null,
      loading: false,
      cost: {},
      items: []
    };    
  }

  isLoading(){
    return this.state.loading || this.state.cost === null || isNaN(getTotalCost(this.state.cost));
  }

  UNSAFE_componentWillMount(){
    const { location } = this.props;
    this.utilityTooltip = this.utilityTooltip.bind(this);
    if(location){
      this.fetchCost(this.props);
    }
  }

  componentWillUnmount(){
    clearTooltip();
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.location.id !== this.props.location.id || nextProps.billing !== this.props.billing){
      this.fetchCost(nextProps);
    }
  }

  fetchCost(props=this.props){
    const { location, billing, tariff={} } = props;
    this.setState({
      loading: true
    });
    if (billing.type === 'simple' || !tariff.masterTariffId){
      return calculateCurrentBill(location.id, { tariffId: 'simple' })
        .then(
          cost => {
            this.setState({
              loading: false,
              cost,
              items: cost.items
            });
          }
        );
    }
    return calculateCurrentBill(location.id, { tariffId: tariff.masterTariffId })
      .then(
        cost => {
          this.setState({
            loading: false,
            cost,
            items: cost.items
          });
        }
      );
  }

  utilityTooltip(e){
    const { billing, hideTooltip } = this.props;
    const { cost, items } = this.state;
    if (billing.type === 'utility' && cost && !hideTooltip){
      showTooltip({
        show: true,
        margin: 15,
        className: 'breakdown-tooltip',
        fallbackPlacement: 'left',
        target: e.currentTarget,
        content: this.renderTooltip(items)
      });
    }
  }

  renderTooltip(items){
    const { billing } = this.props;
    const { simple_currency_code } = billing;
    // remove items with falsey charges
    items = items.filter(item => item.cost);
    const credits = items.filter(item => 'solarSystemOutput' === item.quantityKey);
    const fixedCharges = items.filter(item => 'FIXED_PRICE' === item.chargeType);
    const usageCharges = items.filter(item => credits.indexOf(item) === -1 && fixedCharges.indexOf(item) === -1);
    const renderItem = item => (
      <li key={item.tariffRateId} className={item.quantityKey.toLowerCase()}>
        <span className="title">
          {item.rateName}
        </span>
        <span className="value">
          {getCurrencyFormatter(simple_currency_code)(item.cost)}
        </span>
      </li>
    );
    return (
      <div className="breakdown-tooltip">
        {
          credits.length ? (
            <ul className="list credits">
              <li className="header">Credits</li>
              {credits.map(renderItem)}
            </ul>
          ) : null
        }
        {
          fixedCharges.length ? (
            <ul className="list fixed">
              <li className="header">Fixed charges</li>
              {fixedCharges.map(renderItem)}
            </ul>
          ) : null
        }
        {
          usageCharges.length ? (
            <ul className="list consumption">
              <li className="header">Usage charges</li>
              {usageCharges.map(renderItem)}
            </ul>
          ) : null
        }

      </div>
    );
  }

  renderMain(){
    const { location, billing, tariff } = this.props;
    const { loading } = this.state;
    const { timezone } = location;
    const { day_of_month, simple_currency_code } = billing;
    const billingStart_moment = getBillingCycleStart(day_of_month);
    const energyProviderUrl = `/dash/${location.id}/menu/${location.id}/energy-provider`;

    if(loading){
      return <Loader />
    }

    if(!tariff && location.id){
      return (
        <div className="utility-message">
          <Link className="button cta red" to={energyProviderUrl}>Add a utility provider</Link>
        </div>
      );
    }

    return <div>    
      <div className="value">
        {
          getCurrencyFormatter(simple_currency_code)(getTotalCost(this.state.cost))
        }
      </div>
      <div className="label">since {timeFormatter('MMM D')(billingStart_moment._d, { timezone })}</div>
      {
        billing.type === 'utility' && tariff.masterTariffId
        ? (
          <div className="tariff">
            <p><Link to={energyProviderUrl}>{tariff.lseName} - {tariff.tariffName}</Link></p>
          </div>
        )
        : null
      }
      <MoreInfoLink to={`/cost-breakdown/${location.id}`}>
        more info&hellip;
      </MoreInfoLink>
    </div>
  }

  render(){
    const { billing } = this.props;
    return (
      <div className={`cost-breakdown module ${billing.type}`}
        onClick={this.utilityTooltip}
        onMouseOver={this.utilityTooltip}
        onMouseOut={clearTooltip}>
         <p className="module-title"><i className="fa fa-bar-chart" /> Cost Breakdown</p>
         <div className="module-inner">
          {this.renderMain()}
         </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const { billing, tariff } = state;
    return {
      billing,
      tariff,
      hideTooltip: true
    };
  }
)(CostBreakdown);
