import React, { Component } from 'react';
import Header from '../../../atoms/Header';
import Alerts from '../../../atoms/Alerts';
import MessageDetail from '../../../components/Messages/Detail';
import withRouterProps from '../../../components/base/WithRouterProps';

export class MessagesDetailPage extends Component {
  render(){
    const { currentLocation, match, routerLocation } = this.props;
    const { params={} } = match;
    const backLinkUrl = routerLocation.pathname.replace( new RegExp(`/${params.messageId}`), '');
    return (
      <div className="message-details-page full-screen full-screen-page">
        <Header linkTo={backLinkUrl} title="Messages" />
        <Alerts />
        <main>
          <MessageDetail
            location={currentLocation}
            messageId={params.messageId} />
        </main>
      </div>
    );
  }
}
export default withRouterProps(MessagesDetailPage);