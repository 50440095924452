import React, {Component} from 'react';
import withRouterProps from '../base/WithRouterProps';
import Header from '../../atoms/Header';
import config from '../../config';
import auth from '../../lib/auth';
import eventEmitter from '../../event-emitter';

class ConfigurationsMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      iframeDimensions: {
        width: 'auto',
        height: 'auto'
      }
    };
  }
  
  componentWillUnmount(){
    eventEmitter.emit('refresh', this.props.location.id);
  }

  render(){
    const { location, history } = this.props;
    const iframeAuthUrl = `${config.CONFIG_APP_URL}/token?entry=/locations/${location.id}#${auth.getAuthHash()}`;
    return (
      <div className="configurations-menu full-height">
        <Header clickTo={history.goBack} title="Configurations" />
        <iframe
          src={iframeAuthUrl}
          frameBorder={0}
          className="configurations-iframe" />
      </div>
    );
  }
}

export default withRouterProps(ConfigurationsMenu);