import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch, Redirect, Link, withRouter } from 'react-router-dom';
import { addParamsToUrl, getQueryStringParams } from 'shared/dist/utils/url';
import auth, { callbackURL } from './lib/auth';
import { isNativeApp } from './lib/environment-check';
import AppContainer from './containers/App';
import Dashboard from './pages/Dashboard';
import LiveUsagePage from './pages/LiveUsagePage';
import CostBreakdownPage from './pages/CostBreakdownPage';
import DemoPage from './pages/Demo';
import UserDetailPage from './pages/User/Detail';
import MessagesListPage from './pages/Messages/ListPage';
import MessagesDetailPage from './pages/Messages/DetailPage';
import NotFoundPage from './pages/NotFoundPage';
import SmartThingsPage from './pages/SmartThings';
import LoadControlConfigPage from './pages/LoadControl/ConfigPage';
import VirtualLoadControlPage from './pages/VirtualLoadControl';
import ErrorPage from './pages/ErrorPage';
import ClaimPage from './pages/Claim';

const defaultRoute = '/dash';

let Router = isNativeApp
  ? HashRouter
  : BrowserRouter;

let history = null;

const goToLoginPage = function({location}){
  let params = {
    redirect_uri: addParamsToUrl({ entry: location.pathname }, callbackURL())
  };
  if(auth.clientId){
    params.client_id = auth.clientId;
  }
  if(auth.domain){
    params.domain = auth.domain;
  };
  window.location.href = addParamsToUrl(params, callbackURL('/login'));
  return null;
}

const indexRedirect = function(){
  return <Redirect push={true} to={defaultRoute} />;
};

const authenticatedRoutes = (props) => {
  history = props.history;

  if(!auth.loggedIn()){
    return goToLoginPage(props);
  }
  return <Switch>
    <Route path="/dash/:locationId?/:primarySection?/:secondarySection?/:tertiarySection?" render={Dashboard} />
    <Route path="/live-usage/:locationId?" render={LiveUsagePage} />
    <Route path="/cost-breakdown/:locationId?" render={CostBreakdownPage} />
    <Route path="/user-detail/:primarySection?" render={UserDetailPage} />
    <Route path="/smartthings/:locationId?/:primarySection?/:secondarySection?/:tertiarySection?" component={SmartThingsPage} />
    <Route path="/load-control" exact={true} component={LoadControlConfigPage} />
    <Route path="/virtual-load-control/:hubSerial?" component={VirtualLoadControlPage} />
    <Route path="/inbox/:messageId" component={MessagesDetailPage} />
    <Route path="/inbox" component={MessagesListPage} />
    <Route path="/claim/:claimId" component={ClaimPage} />
    <Route path="/error/:primarySection?" component={ErrorPage} />
    <Route path="*" component={NotFoundPage} />
  </Switch>;
};

export default (props) => {
  const receiveToken = function(props){
    auth.processAuthHash(props.location.hash);
    try {
      const { entry } = getQueryStringParams();
      if(entry){
        return /^https?:\/\//ig.test(entry)
          ? window.location = entry
          : <Redirect to={entry} />;
      }
      return indexRedirect();  
    }
    catch(err){
      console.error(err);
      indexRedirect();
    }
  };
    
  return <AppContainer>
    <Router>
      <Switch>
        <Route path='/token' render={receiveToken} />
        <Route path='/logout' render={auth.logout} />
        <Route exact path='/' render={indexRedirect} />
        <Route path="/demo/:provider?" component={DemoPage} />
        <Route path="*" render={withRouter(authenticatedRoutes)} />
      </Switch>
    </Router>
  </AppContainer>;
};

export function getRouter(){
  return Router;
};

export function getHistory(){
  return history;
}