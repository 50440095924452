import React from 'react';
import config from '../config';

export default function Loader(props) {
  return (
    <div className={`Loader ${props.className || ''}`}>
      <img src={(`${config.STATIC_PATH}/images/plug.svg`)} />
    </div>
  );
}
