import { call } from 'redux-saga/effects';
import * as APICalls from '../lib/api';

// WORKER SAGAS
export function* updateTariff({locationId, tariff}) {
  try {
    yield call(APICalls.updateTariff, locationId, tariff.masterTariffId);
  } catch (err) {
    console.error(err);
  }
}
