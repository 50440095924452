import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectToLoadControlStatusStream } from '../../../lib/realtime';
import { snoozeLoadControl, resumeLoadControl, fetchLoadControlStatus } from '../../../lib/api';
import Loader from '../../../atoms/Loader';
import actions from '../../../actions';
import { dispatch } from '../../../store';
import SnoozeModal from './SnoozeModal';

// const numberLabels = [
//   'zero',
//   'one',
//   'two',
//   'three',
//   'four',
//   'five',
//   'six',
//   'seven',
//   'eight',
//   'nine',
//   'ten',
//   'eleven',
//   'twelve'
// ];

class EnergySaver extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      modalIsOpen: false,
      status: {}
    };

    this.initSocket();    
  }

  componentWillUnmount(){
    if(this.statusStream){
      this._blockWebsocketUpdates = true;
      this.statusStream.unsubscribeAll();
    }
    if(this.errorTimeout){
      clearTimeout(this.errorTimeout);
    }
  }

  async initSocket(){
    const { location, registers } = this.props;
    this.statusStream = connectToLoadControlStatusStream();
    this.statusStream.subscribeToLocation(location.id);

    this.errorTimeout = setTimeout(
      () => this.setState({
        error: 'Could not fetch load control status',
        loading: false
      }),
      1000 * 10
    );

    this.statusStream.socket.on(
      'disconnect',
      () => this.initSocket()
    );
    this.statusStream.socket.on(
      'data',
      status => {
        if(this.errorTimeout){
          clearTimeout(this.errorTimeout);
          this.errorTimeout = null;
        }
        status.relays.forEach(
          r => {
            if(r.using_backup && r.circuit){
              let backupRegister = registers.find(r => r.id === r.circuit);
              if(backupRegister && backupRegister.label){
                r.label = backupRegister.label;
              }
            }
          }
        )
        if(!this._blockWebsocketUpdates){
          this.setState({
            loading: false,
            error: null,
            status
          });
        }
      }
    );

    this.statusStream.socket.on(
      'error',
      error => this.setState({
        loading: false,
        error: error
      })
    );
  }

  openModal(){
    this.setState({
      modalIsOpen: true
    });
  }

  closeModal(){
    this.setState({
      modalIsOpen: false
    });
  }

  async onClick_resume(e){
    const { location } = this.props;
    try {
      e.preventDefault();
      this.setState({
        loading: true
      });
      this._blockWebsocketUpdates = true;
      await resumeLoadControl(location.id);
      const status = await fetchLoadControlStatus(location.id);
      this.setState({
        status
      });
    }
    catch(err){
      console.error(err);
    }
    this._blockWebsocketUpdates = false;
    this.setState({
      loading: false
    });
  }

  onClick_snooze(e){
    e.preventDefault();
    this.openModal();
  }

  onClick_confirmSnooze(){
    const { location } = this.props;
    this.closeModal();
    this.setState({
      loading: true
    });
    this._blockWebsocketUpdates = true;
    return snoozeLoadControl(location.id)
      .then(
        () => fetchLoadControlStatus(location.id)
      )
      .then(
        status => {
          this.setState({
            status
          });
          dispatch(actions.fetchInbox());
        }
      )
      .catch(console.error)
      .then(
        () => {
          this._blockWebsocketUpdates = false;
          this.setState({
            loading: false
          });
        }
      );
  }

  renderMain(){
    const { loading, status } = this.state;
    const { state, relays, disable_until } = status;
    if(loading){
      return (
        <Loader />
      );
    }

    switch(state){
      case 'inactive':
        return (
          <div>
            <p>Your energy use is on track.</p>
            <p>Keep up the great savings!</p>
          </div>
        );
      case 'active':
        var controlledRelays = relays
          .filter(r => !r.on);
        return (
          <div>
            <p>
              The following appliance(s) have been temporarily shut off to save money:&nbsp;
              {controlledRelays.map(
                (r, i, arr) => (
                  <span className="circuit-item" key={i}>
                    {
                      i === 0
                        ? ''
                        : (i > 0 && i === arr.length-1)
                          ? ', and '
                          : ', '
                    }<span className="circuit-label">
                      {r.label || '(Unnamed)'}
                      {r.using_backup && ' (backup)'}
                    </span>
                  </span>
                )
              )}
            </p>
            <button className="red action" onClick={e => this.onClick_snooze(e)}>Turn everything back on</button>
          </div>
        );
      case 'not_enforced':
        return (
          <p>Your load control system is not scheduled to run at this time.</p>
        );
      case 'snoozed':
        var minutesRemaining = (new Date(disable_until * 1000) - Date.now()) / 1000 / 60;
        var minutesLabel = Math.max(Math.ceil(minutesRemaining), 1);
        return <div>
          <p>Automatic savings have been temporarily disabled.  Normal checks for saving opportunities will resume in {minutesLabel} minute{minutesLabel > 1 && 's'}.</p>
          <button className="action" onClick={e => this.onClick_resume(e)}>Resume now</button>
        </div>;
      case 'killed':
        return (
          <p>Automatic savings have been disabled.</p>
        );
      case 'overridden':
        return (
          <p>Automatic savings have been disabled.  Normal checks for saving opportunities will resume tomorrow.</p>
        );
      default:
        return (
          <div>
            <p><i className="icon-status-error-triangle" /></p>
            <p>This is an error in your load control system.</p>
          </div>
        );
    }
  }

  render(){
    const { status, modalIsOpen } = this.state;
    const { state } = status;
    const className = ['module', 'energy-saver'];
    if(state){
      className.push(state);
    }
    return (
      <div className={className.join(' ')}>
        <p className="module-title"><i className="fa fa-gear"/>WattWise Automation</p>
        <div className="module-content">
          <div className="module-inner">
            {this.renderMain()}
          </div>
        </div>

        <SnoozeModal
          modalIsOpen={modalIsOpen}
          closeModal={() => this.closeModal()}
          onClickConfirm={() => this.onClick_confirmSnooze()} />

      </div>
    );
  }
}

export default connect(
  state => {
    const { registers } = state;
    return {
      registers
    };
  }
)(EnergySaver);
