import React from 'react';
import DiscreteBarChart from 'shared/dist/ui/viz/discrete-bar';
import { formatWatts } from '../../lib/formatters';
import COLORS from '../../lib/colors';

export default class EnergySourceChart extends DiscreteBarChart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      resize: true,
      autoSize: true,
      showXAxis: false,
      showYAxis: false,
      showValues: true,
      margins: {
        top: 20,
        right: 10,
        bottom: 30,
        left: 10
      }
    };
  }

  initDOM(){
    super.initDOM();
    this.axisGroup = this.g
      .append('g')
      .attr('class', 'axis');
  }

  keyAccessor(d){
    return d.label;
  }

  valueAccessor(d){
    return d.value;
  }

  formatValueLabel(d){
    return `${formatWatts(this.valueAccessor(d))} W`;
  }

  getColor(d){
    let red = '#ff0000';
    if(d.grid){
      return d.value > 0 ? red : '#444488';
    }
    if(d.production){
      return COLORS.solar[0];
    }
    if(d.battery){
      return d.value > 0 ? COLORS.battery[0] : red;
    }

    return '#000';
  }

  update(data){
    super.update(data);
  }

  draw(){
    if(!this.data || !this.data.length){
      return;
    }
    super.draw();
    const { height } = this.dimensions;
    const values = this.data.map(this.valueAccessor);
    const hasNegativeValues = Math.min(...values) < 0;

    const axisLabels = this.axisGroup
      .selectAll('text')
      .data(
        this.data
      );

    this.container.classList.toggle('three-col', this.data.length > 2);

    this.barsEnterUpdate
      .select('rect')
      .attr('fill', d => this.getColor(d));

    this.axisGroup
      .attr('transform', `translate(0, ${height + (hasNegativeValues ? 15 : 0) })`);

    axisLabels
      .enter()
        .append('text')
        .text(d => d.label)
        .attr('text-anchor', 'middle')
        .merge(axisLabels)
        .attr('x', d => this.x(this.keyAccessor(d)) + this.x.bandwidth()/2 )
        .attr('dy', '1em')
      .exit()
        .remove();
  }
}
