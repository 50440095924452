import React from 'react';
import { connect } from 'react-redux';
import { getQueryStringParams } from 'shared/dist/utils/url';
import actions from '../../actions';
import withRouterProps from '../base/WithRouterProps';

const { fetchInbox } = actions;

var hasLoadedMessages = false;

export class MessageDependentComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  static defaultProps = {
    messages: []
  }  

  componentDidMount(){
    this.fetchMessages();
  }

  fetchMessages({force}={}){
    const { messages=[], fetchInbox, last_evaluated_key } = this.props;
    const params = {};

    if(!messages.length || force){
      if(!hasLoadedMessages){
        this.setState({
          loading: true
        });
      }

      if(last_evaluated_key){
        params.last_evaluated_key = encodeURIComponent(JSON.stringify(last_evaluated_key));
      }

      return new Promise(
        (resolve, reject) => fetchInbox({
          params,
          resolve, 
          reject
        })
      )
      .catch(
        error => {
          this.setState({
            error
          });
        }
      )
      .then(
        () => {
          hasLoadedMessages = true;
          this.setState({
            loading: false
          });
        }
      );
    }
  }

}

export function MessageDependentComponentConnector(SubComponent, mapDispatchToProps={}){
  return connect(
    state => {
      const { inbox } = state;
      return {
        ...inbox
      };
    },
    {
      fetchInbox,
      ...mapDispatchToProps
    }
  )(withRouterProps(SubComponent));
}
