import React from 'react';
import d3 from 'shared/dist/lib/d3';
import StackedBarChart from 'shared/dist/ui/viz/stacked-bar';
import RegistersChart from 'shared/dist/ui/viz/registers-chart';
import mergePrototypes from 'shared/dist/utils/merge-prototypes';
import { getConsumptionForSnapshot, getProductionForSnapshot, getGridImpactForSnapshot } from 'shared/dist/utils/snapshot';
import colors from '../../lib/colors';
import { isDollar } from '../../lib/unit-converter';
import { formatDate, kwhRangeFormats, getCurrencyFormatter, formatkWhs, formatWatts, formatPercentage } from '../../lib/formatters';
import { hasTouchEvents } from '../../lib/feature-detection';
import { convertWatts } from '../../lib/unit-converter';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';
import { activateRegister } from './utils';

class KwhrChart extends StackedBarChart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      tickFormats: {
        x: v => v
      }
    };
  }

  initDOM(){
    super.initDOM();
    this.zeroLine = this.g.append('line')
      .attr('class', 'zero');
  }

  updateParams(params){
    super.updateParams(params);
    const { unit, simple_kwh_price, currency } = this.params;
    this.params.tickFormats.y = (d) => {
      if(d === 0){
        return d;
      }
      return formatkWhs(d);
    };
    if(isDollar(unit)){
      this.params.tickFormats.y = v => getCurrencyFormatter(currency)( convertWatts({watts: v, unit, simple_kwh_price}) );
    } else if(unit === 'percentage'){
      this.params.tickFormats.y = formatPercentage;
    }
  }

  isNet(){
    const { energyType } = this.params;
    return energyType === 'net';
  }

  totalForCircuit(d){
    return this.data.reduce(
      (acc, column) => {
        var val = 0;
        switch(d.key){
          case '__CONSUMPTION__':
            val = column[1][0].consumptionKwhr;
            break;
          case '__PRODUCTION__':
            val = column[1][0].productionKwhr;
            break;
          case '__BATTERY__':
            val = column[1][0].batteryKwhr;
            break;
          default:
            val = this.getValue(column[1][d.index]);
        }
        return acc + (val || 0);
      },
      0
    );
  }

  renderTooltip(d, i, startDate, endDate){
    const { timeRange, simple_kwh_price, currency, allow_currencies, timezone } = this.params;
    const isNet = this.isNet();
    const circuit = this.getRegisterForId(d.key);
    const circuitValue = this.getValue(d[0].data[d.index]);
    const formatCurrency = getCurrencyFormatter(currency);
    const { total, avg } = d[0].data.reduce(
      (acc, v) => {
        return {
          total: acc.total + this.getValue(v),
          avg: acc.avg + (v.avg || 0)
        };
      },
      {
        total: 0,
        avg: 0
      }
    );

    return (
      <div className="breakdown-tooltip kwhr">
        <div className="date-time">
          {
            ['3h', '1d'].indexOf(timeRange) > -1 ?
            (
              <span className="date">
                {formatDate(startDate, {timezone})}
              </span>
            ) :
            null
          }
          <span className="time">
            {kwhRangeFormats(timeRange, startDate, endDate, timezone)}
          </span>
        </div>
        <div className="highlight">
          <div className="name">
            <span
              className="key"
              style={{backgroundColor: this.getColor(d)}} />
            <span className="title">
              {circuit.label}
            </span>
          </div>
          <div className="values">
            <div className="watts">
              {formatkWhs(circuitValue)} kWh&nbsp;
              { 
                ((circuit.id === '__BATTERY__' || circuit.battery) && circuitValue !== 0) && <span className="battery-polarity-indicator sub-label">
                  {
                    circuitValue > 0
                      ? '(charging)'
                      : '(discharging)'
                  }
                </span>
              }
            </div>
            <div className="dollars">
              {formatkWhs( this.totalForCircuit(d) )} kWh <span className="sub-label per-hour">(per {timeRange})</span>
            </div>
            {
              this.registers.length > 1 && !isNet ?
              (
                <div className="percentage">
                  {formatPercentage(circuitValue / total)}% of Total
                </div>
              ) :
              null
            }
            {
              allow_currencies ? (
                <div className="dollars">
                  {formatCurrency( circuitValue * simple_kwh_price )}
                </div>
              ) : null
            }
          </div>
        </div>
        {
          this.registers.length > 1 && !isNet && (
            <div className="total">
              <div className="name">
                ALL CIRCUITS
              </div>
              <div className="values">
                <div className="watts">
                  {formatkWhs(total)} kWh
                </div>
                <div className="watts">
                  {formatWatts(avg)} Avg. Watts
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  getColor(d){
    if(typeof this.params.getColor === 'function'){
      return this.params.getColor.call(this, d);
    }
    return (this.getRegisterForId(d.key) || {}).color || '#000';
  }

  preTransformData(data){
    // always store a full pristine copy of the source data
    this.sourceData = data;
    // convert raw aggregate data to simpler structure
    /*
    [
      [
        date,
        [
          {
            id: <circuitUUID>,
            khwr: <kwhr>
          }
          ...
        ]
      ]
    ]
    */

    return this.sourceData.map(agg => {
      return [
        [new Date(agg.timestamp_start * 1000), new Date(agg.timestamp_end * 1000)],
        this.filterForEnergyType(agg.aggregate)
          .filter(d => this.registerIdIsActive(d.id)),
        {
          consumption: getConsumptionForSnapshot(agg.aggregate),
          production: getProductionForSnapshot(agg.aggregate),
          gridImpact: getGridImpactForSnapshot(agg.aggregate)
        }
      ];
    });
  }

  // column[0] is an array of two dates start and end time. take the first one as the primary key
  columnKeyAccessor(column){
    return column[0][0];
  }

  getValue(d){
    return d.kwhr || 0;
  }

  // return a unique list of all circuit ids represented in the data
  keysAccessor(column){
    return column[1].map(c => c.id);
  }

  valueAccessor(d, key){
    const { energyType } = this.params;
    return this.getValue(d.find(c => c.id === key)) * ( ['production', 'battery'].indexOf(energyType) > -1 ? -1 : 1 );
  }

  drawNetRects(){
    const { animationDuration } = this.params;
    this.columnsEnterUpdate
      .each(
        (d, i, nodeList) => {
          const data = d[1][0][0].data[0];

          // add consumption and production to the data object so that the tooltip can be drawn
          const supplementedData = [
            data,
            {
              kwhr: data.consumptionKwhr
            },
            {
              kwhr: data.productionKwhr
            },
            {
              kwhr: data.batteryKwhr
            }
          ];

          const gridPositive = data.kwhr > 0;
          const batteryPositive = data.batteryKwhr > 0;

          // draw battery bar
          d3.select(nodeList[i]).append('rect')
            .attr('class', 'battery')
            .attr('data-id', '__BATTERY__')
            .attr('stroke', colors.battery[0])
            .attr('stroke-width', 2)
            .attr('x', 1)
            .attr('fill', 'none')
            .attr('width', this.x.bandwidth()-2)
            .lower()
            .datum(
              {
                0: {
                  data: supplementedData
                },
                index: 3,
                key: '__BATTERY__'
              }
            )
            .attr('y', Math.max(this.y(data.kwhr, this.y(0))))
            .transition()
            .duration(animationDuration)
            .attr(
              'y',
              batteryPositive
                // place it on top of consumption
                ? this.y(data.consumptionKwhr) - (Math.abs(this.y(data.batteryKwhr) - this.y(0)))
                // place it underneath production
                : this.y(data.productionKwhr) + 2
            )
            .attr(
              'height',
              Math.abs(
                this.y(0) - this.y(data.batteryKwhr)
              )
            );

          // draw consumption bar
          d3.select(nodeList[i]).append('rect')
            .attr('class', 'consumption')
            .attr('data-id', '__CONSUMPTION__')
            .attr('stroke', colors.usage)
            .attr('stroke-width', 2)
            .attr('x', 1)
            .attr('fill', 'none')
            .attr('width', this.x.bandwidth()-2)
            .lower()
            .datum(
              {
                0: {
                  data: supplementedData
                },
                index: 1,
                key: '__CONSUMPTION__'
              }
            )
            .attr('y', Math.min(this.y(data.kwhr, this.y(0))))
            .transition()
            .duration(animationDuration)
            .attr(
              'y',
              this.y(data.consumptionKwhr) + 2
            )
            .attr(
              'height',
              gridPositive
                ? this.y(data.kwhr) - this.y(data.consumptionKwhr)
                : this.y(0) - this.y(data.consumptionKwhr)
            );

          // draw production bar
          d3.select(nodeList[i]).append('rect')
            .attr('class', 'production')
            .attr('data-id', '__PRODUCTION__')
            .attr('stroke', colors.solar[0])
            .attr('stroke-width', 2)
            .attr('x', 1)
            .attr('fill', 'none')
            .attr('width', this.x.bandwidth()-2)
            .lower()
            .datum(
              {
                0: {
                  data: supplementedData
                },
                index: 2,
                key: '__PRODUCTION__'
              }
            )
            .attr('y', Math.max(this.y(data.kwhr, this.y(0))))
            .transition()
            .duration(animationDuration)
            .attr('y', Math.max(this.y(0), this.y(data.kwhr)))
            .attr('height', this.y(data.productionKwhr) - Math.max(this.y(0), this.y(data.kwhr)));

        }
      );
  }

  focusRect(d, i, nodeList){
    const el = nodeList[i];
    const parentData = d3.select(el.parentNode).datum();
    const [startDate, endDate] = parentData[0];

    this.kwhRects.filter(`[data-id='${d.key}']`)
      .classed('active', true);

    showTooltip({
      target: nodeList[i],
      fallbackPlacement: 'left',
      content: this.renderTooltip(d, i, startDate, new Date(endDate.getTime() + 1000) )
    });
  }

  draw(){
    const { width } = this.dimensions;
    const { energyType, offset } = this.params;
    const isNet = this.isNet();

    if(isNet){
      // y domain needs to reflect not just net but consumption and production peaks as well (factoring in battery)
      this.y.domain(
        d3.extent(
          this.data
            // map out production and consumption values
            .map(d => {
              const data = d[1][0];
              return [
                Math.min(
                  data.productionKwhr + Math.min( data.batteryKwhr, 0 ),
                  data.kwhr
                ),
                Math.max(
                  data.consumptionKwhr + Math.max( data.batteryKwhr, 0 ),
                  data.kwhr
                )
              ];
            })
            // flatten
            .reduce(
              (acc,v) => acc.concat(v),
              []
            )
        )
      );
    }

    super.draw();

    if(isNet){
      this.drawNetRects();
    }

    if(offset && this.x.bandwidth() < 40){
      this.xAxisGroup.select('.tick:first-of-type text')
        .style('font-weight', 'bold')
        .attr('text-anchor', 'start')
        .attr('dx', `-${this.x.bandwidth()/2}px`);
    }

    this.kwhRects = this.columnsContainer.selectAll('.column rect');
    this.kwhRects
      .on('mouseover touchstart', (d, i, nodeList) => this.focusRect(d, i, nodeList), { passive: true })
      .on('mouseout touchcancel touchend', () => {
        this.kwhRects.classed('active', false);
        clearTooltip();
      })
      .on('click', d => {
        d3.event.preventDefault();
        d3.event.stopPropagation();
        if(hasTouchEvents){
          clearTooltip();
        }
        if(energyType !== 'net'){
          activateRegister.call(this, d.key, this.params.locationId);
        }
      });

    this.zeroLine
      .attr('stroke', '#000')
      .attr('x1', 0)
      .attr('y1', this.y(0))
      .attr('x2', width)
      .attr('y2', this.y(0));
  }
}

mergePrototypes(KwhrChart, [RegistersChart]);
export default KwhrChart;
