import d3 from 'shared/dist/lib/d3';
// TODO - use d3 or a different library - this one is abandoned
import colors from '../../lib/colors';
import Chart from 'shared/dist/ui/viz/chart';

export default class RadialDialChart extends Chart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      // use the viewBox property instead of recalculating/drawing
      resizable: false,
      intervalsCount: 60,
      levelsCount: 10,
      autoSize: false,
      width: 800,
      height: 800,
      innerRadiusRatio: 0.35,
      margins: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    };
  }

  init(){
    this.currentPosition = this.params.currentPosition || null;
    this.data = [];
    super.init();
  }

  initDOM(){
    const { chartClass, resizable } = this.params;
    const { width, height } = this.dimensions;
    const radius = width/2;

    this.svg = d3.select(this.container).append('svg')
      .attr('class', ['radial-dial', chartClass].join(' '))
      .attr('transform-origin', '0 0 0')
      .attr('viewBox', `0 0 ${width} ${height}`);

    this.g = this.svg
      .append('g');

    this.backgroundCircle = this.g.append('circle')
      .attr('class', 'background-circle')
      .attr('cx', radius)
      .attr('cy', radius)
      .attr('r', radius);

    this.ticksGroup = this.g.append('g')
      .attr('class', 'ticks');

    this.ticks = this.ticksGroup.selectAll('path.tick');

    this.intervalsGroup = this.g.append('g')
      .attr('class', 'intervals');

    this.levelsGroup = this.g.append('g')
      .attr('class', 'levels');

    this.innerCircle = this.g.append('circle')
      .attr('class', 'inner-circle');

    this.averageCircle = this.g.append('circle')
      .attr('class', 'average');

    if(!resizable){
      this.fitToSize();
    }
  }

  initScales(){
    this.radialScale = d3.scaleLinear().clamp(true);

    this.valueScale = d3.scaleLinear()
      .clamp(true)
      .range([-0.25,1.25]);

    this.colorScale = d3.scaleLinear()
      .domain(d3.range(0, 1, 1.0 / (colors.powerband.length - 1)))
      .range(colors.powerband);
  }

  fitToSize(){
    const { intervalsCount, levelsCount, innerRadiusRatio } = this.params;
    const { width } = this.dimensions;
    const radius = width/2;
    const innerRadius = Math.round(radius * innerRadiusRatio);

    this.radialScale
      .range(
        [innerRadius+2, radius]
      );

    this.arcGenerator = d3.arc()
      .innerRadius(innerRadius);

    this.ticksGroup
      .attr('transform', `translate(${radius},${radius})`);

    this.intervals = this.intervalsGroup
      .selectAll('line.interval')
      .data(
        new Array(Math.floor(intervalsCount/2))
      );

    this.intervalsEnter = this.intervals
      .enter()
        .append('line')
        .attr('class', 'interval-line');

    this.intervals.merge(this.intervalsEnter)
      .each(
        (d, i, nodeList) => {
          const angle = (Math.PI*2/intervalsCount) * i;
          d3.select(nodeList[i])
            .attr('x1', Math.round(radius + Math.sin(angle) * radius))
            .attr('y1', Math.round(radius + Math.cos(angle) * radius))
            .attr('x2', Math.round(radius + Math.sin(angle + Math.PI) * radius))
            .attr('y2', Math.round(radius + Math.cos(angle + Math.PI) * radius));
        }
      );

    this.levels = this.levelsGroup
      .selectAll('circle.level')
      .data(
        new Array(levelsCount+1)
      );

    this.levelsEnter = this.levels
      .enter()
        .append('circle')
        .attr('class', 'level')
        .attr('cx', radius)
        .attr('cy', radius);

    this.levels.merge(this.levelsEnter)
      .each(
        (d, i, nodeList) => {
          d3.select(nodeList[i])
            .attr('r', Math.round(innerRadius + (i * (radius - innerRadius)/levelsCount)));
        }
      );

    this.innerCircle
      .attr('cx', radius)
      .attr('cy', radius)
      .attr('r', innerRadius);

    this.averageCircle
      .attr('cx', radius)
      .attr('cy', radius)
      .attr('r', Math.round(innerRadius + (radius - innerRadius)/2));
  }

  updateCurrentPosition(currentPosition){
    this.currentPosition = currentPosition;
    this.draw({
      redrawAll: true
    });
  }

  updateBaseline(baseline){
    this.baseline = baseline;
    const domain = [0, this.baseline * 2];
    this.radialScale.domain(domain);
    this.valueScale.domain(domain);
    this.draw({
      redrawAll: true
    });
  }

  update(data=this.data){
    const { intervalsCount } = this.params;
    this.currentPosition = this.currentPosition === null ? 0 : (data.length < intervalsCount ? data.length - 1 : this.currentPosition + 1) % intervalsCount;
    super.update(data);
  }

  getColor(d){
    return this.colorScale(this.valueScale(d));
  }

  drawTick(d,i){
    const { intervalsCount } = this.params;
    const arcAngle = Math.PI*2/intervalsCount;
    const startAngle = Math.PI/2 + i * arcAngle;
    return this.arcGenerator
      .outerRadius(this.radialScale(d))
      .startAngle(startAngle)
      .endAngle(startAngle + arcAngle)();
  }

  draw({redrawAll}={}){
    const { intervalsCount, resizable } = this.params;
    if(resizable){
      this.fitToSize();
    }

    const ticks = this.ticksGroup
      .selectAll('path.tick')
      .data(
        this.data
      );

    if(!this.data || !this.data.length || typeof this.baseline === 'undefined' || this.baseline === null){
      ticks.exit().remove();
      return;
    }

    if(redrawAll){
      ticks
        .transition(100)
        .attr('fill-opacity', 1)
        .attr('fill', d => this.getColor(d))
        .attr('stroke', d => this.getColor(d))
        .attr('d', (d, i) => this.drawTick(d, i));
    }

    ticks.enter()
      .append('path')
        .attr('class', 'tick')
        .attr('fill-opacity', 1)
        .attr('fill', d => this.getColor(d))
        .attr('stroke', d => this.getColor(d))
        .attr('d', (d, i) => this.drawTick(d, i));

    ticks
      .filter(
        (d, i) => i === this.currentPosition
      )
      .datum(
        this.data[this.currentPosition]
      )
      .transition(100)
      .attr('fill-opacity', 1)
      .attr('fill', d => this.getColor(d))
      .attr('stroke', d => this.getColor(d))
      .attr('d', d => this.drawTick(d, this.currentPosition));

    // apply opacity shift to next 5 ticks to create shadow effect
    const fadedTickCount = 6;
    const fadedOverflowCount = Math.max(-intervalsCount + this.currentPosition + fadedTickCount, 0);
    ticks.filter(
      (d, i) => (i > this.currentPosition && i < this.currentPosition + fadedTickCount) || (fadedOverflowCount && i < fadedOverflowCount)
    )
    .each(
      (d, i, nodeList) => {
        var index = (i + fadedTickCount -fadedOverflowCount) % fadedTickCount;
        d3.select(nodeList[i])
          .transition(100)
          .attr('fill-opacity', 1 - (fadedTickCount - index) * ((1 - 1/fadedTickCount) / fadedTickCount));
      }
    );

    ticks.exit().remove();


  }
}
