export function isDollar(unit) {
  if(typeof unit === 'undefined' || unit === null) {
    return false;
  }
  return !!unit.match(/^\$\/.*/g);
}

/*
"watts" here are not truly watts(J/s).  Here, "watts" refers to watt hours,
which is what is commonly used electrical applications
watts are a measure of power, or energy/time
watt-hours multiply by time, thus are just a measure of energy
*/
export function convertWatts({watts, unit, simple_kwh_price}){
  if(isDollar(unit)){
    switch(unit) {
      case '$/hour':
      case '$/hr':
        return convertWatts({watts, unit: 'kWh'}) * simple_kwh_price;
      case '$/day':
        return convertWatts({watts, unit: 'kWh'}) * 24 * simple_kwh_price;
      case '$/week':
        return convertWatts({watts, unit: 'kWh'}) * 24 * 7 * simple_kwh_price;
      case '$/month':
        return convertWatts({watts, unit: 'kWh'}) * 24 * 30 * simple_kwh_price;
      case '$/year':
        return convertWatts({watts, unit: 'kWh'}) * 24 * 365 * simple_kwh_price;
    }
  }
  switch(unit){
    case 'kWh':
      return watts / 1000;
    default:
      return watts || 0;
  }
}
