import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component{
  render(){
    const { linkTo, title, clickTo, backLinkTitle } = this.props;
    let link = '';
    if(linkTo) {
      link = (
        <Link
          to={linkTo}
          role="button"
          className="backLink">
          <span className="icon" />
          {backLinkTitle}
        </Link>
      );
    } else if(clickTo){
      link = (
        <div
          onClick={clickTo}
          role="button"
          className="backLink">
          <span className="icon" />
          {backLinkTitle}
        </div>
      );
    }
    return (
      <header className={`Header ${this.props.className || ''}`}>
        {link}
        <h2 className="page-title">
          {title}
        </h2>
        <div className="right">
          {this.props.children}
        </div>
      </header>
    );
  }
}
