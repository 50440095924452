import React, { Component } from 'react';
import MessagesUnreadCount from '../../Messages/UnreadCount';
import { Link } from 'react-router-dom';
import withRouterProps from '../../base/WithRouterProps';
import { store } from '../../../store';

class MenuLink extends Component {

  render(){
    const { user={}, routerLocation } = this.props;
    const menuUrl = routerLocation.pathname.split('/');

    // add menu to route if does not exist
    if(menuUrl.indexOf('menu') === -1){
      menuUrl.push('menu');
    }

    // .replace(/(\/menu)+/gi, '/menu');

    return (
      <Link to={menuUrl.join('/')} className="header-menu-link">
        <div role="button" className="bars" />
        {
          !user.isDemoInstaller && <MessagesUnreadCount />
        }        
      </Link>
    );
  }
}

export default withRouterProps(MenuLink);