import React, { Component } from 'react';
import { connect } from 'react-redux';
import { convertWatts } from '../../lib/unit-converter';
import { formatWatts, getCurrencyFormatter } from '../../lib/formatters';
import { getCurrencyConfig } from '../../lib/utils';

class LiveUsage extends Component{

  static defaultProps = {
    location: {},
    billing: {}
  }

  shouldComponentUpdate(nextProps){
    const { data, unit, billing } = nextProps;
    return data !== this.props.data || unit !== this.props.unit || billing.simple_kwh_price !== this.props.billing.simple_kwh_price;
  }

  render() {
    const { unit, billing } = this.props;
    const { simple_currency_code } = billing;
  
    if(typeof this.props.data === 'undefined' || this.props.data === null){
      return (
          <div className="live-usage loading">
            Loading...
          </div>
        );
    }
    const value = convertWatts({
      watts: this.props.data,
      unit: this.props.unit,
      simple_kwh_price: billing.simple_kwh_price
    });

    const displayValue = unit === 'W' ? formatWatts(value) : getCurrencyFormatter(simple_currency_code)(value.toFixed(2));

    return (
      <div className="live-usage" role="status" aria-live="polite">
        <div className="value">{displayValue}</div>
        <div className="scale">{unit === 'W' ? 'Watts' : unit.replace(/^[$]/, getCurrencyConfig(simple_currency_code).symbol)}</div>
      </div>
    );
  }
}

export default connect(
  state => {
    const { billing } = state;
    return {
      billing
    };
  }
)(LiveUsage);
