import actionTypes from '../actions';

export default (state={}, action) => {
  const { type, userDetail, notificationselections } = action;
  switch(type){
    case actionTypes.RECEIVE_USER_DETAIL:
      return userDetail;
    case actionTypes.UPSERT_USER_DETAIL:
      return {
        ...state,
        ...userDetail
      };
    case actionTypes.UPDATE_USER_NOTIFICATION_SETTINGS: {
      const {
        userDetail, 
        userDetail: {notificationsettings}={}
      } = state;
      return {
        ...state,
        ...userDetail,
        notificationsettings: {
          ...notificationsettings,
          notification_types: notificationselections
        }
      };
    }
    default:
      return state;
  }
};
