import React from 'react';
import UserPageWrapper from '../wrappers/UserPageWrapper';
import LocationPageWrapper from '../wrappers/LocationPageWrapper';
import DashboardPageContent from './PageContent';

export default function(props) {
  return (
  <UserPageWrapper {...props}>
    <LocationPageWrapper
      render={p => <DashboardPageContent {...p} /> } />
  </UserPageWrapper>);
}
