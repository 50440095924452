import React, { Component } from 'react';
import UserPageWrapper from '../../wrappers/UserPageWrapper';
import LocationPageWrapper from '../../wrappers/LocationPageWrapper';
import MessagesDetailPageContent from './PageContent';

const overscrollClass = 'overscroll';

export default class MessageDetailPage extends Component {
  componentWillUnmount(){
    document.body.classList.remove(overscrollClass);
    if(screen && screen.orientation && typeof screen.orientation.unlock === 'function'){
      screen.orientation.unlock();
    }
  }
  componentDidMount(){
    window.ga && window.ga('send', 'pageview', {title: 'Message Detail', page: '/inbox/message_id'});
    try {
      document.body.classList.add(overscrollClass);
      if(screen && screen.orientation && typeof screen.orientation.lock === 'function'){
        screen.orientation.lock('portrait');
      }  
    }
    catch(err){
      console.error(err);
    }
  }
  render(){
    return (
      <UserPageWrapper {...this.props}>
        <LocationPageWrapper
          render={p => <MessagesDetailPageContent {...p} />} />
      </UserPageWrapper>
    );
  }
}
