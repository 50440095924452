import React, { Component } from 'react';
import Header from '../../../atoms/Header';
import Alerts from '../../../atoms/Alerts';
import MessagesList from '../../../components/Messages/List';

export default class MessagesListPage extends Component {
  render(){
    const { currentLocation } = this.props;
    const scrollTarget = window;
    return (
      <div className="messages-list-page full-screen full-screen-page">
        <Header
          linkTo={`/dash/${currentLocation.id}/menu`}
          title="Messages"
          backLinkTitle="CURB">
          <nav />
        </Header>
        <Alerts />
        <main>
          <MessagesList location={currentLocation} scrollTarget={scrollTarget} />
        </main>
      </div>
    );
  }
}
