import React, { Component } from 'react';
import CurbInput from '../../atoms/form/input';
import CurbSelect from '../../atoms/form/select';
import { required, positiveInteger } from '../../lib/validators';


export default class Relays extends Component {
  render(){
    const { fields=[], meta, registers=[], change } = this.props;
    const { error } = meta;
    // const existingPorts = fields.getAll().map(r => r.port);

    return (
      <div className="relays-group curb-group curb-group-multi">
        {error && <span className="error">{error}</span>}
        {fields.map(
          (r, index) => {
            return (
              <div key={index} className="relay curb-group-item">
                {
                  fields.length > 1 && (
                    <p className="button-wrapper right">
                      <a className="button remove" onClick={() => fields.remove(index)}><i /></a>
                    </p>
                  )
                }
                <div className="col col-2">
                  <CurbSelect
                    type="number"
                    className="port"
                    label="Relay"
                    name={`${r}.port`}>
                    {
                      [0, 1, 2, 3, 4].map(
                        v => (<option value={v} key={v}>{v+1}</option>)
                      )
                    }
                  </CurbSelect>
                  <CurbSelect
                    type="number"
                    validate={[required, positiveInteger]}
                    className="priority"
                    label="Priority (1 is highest, 5 is lowest)"
                    name={`${r}.priority`}>
                    <option value={0}>0 (disabled)</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </CurbSelect>
                </div>
                <div className="col col-2">
                  <CurbSelect
                    className="relay-circuit"
                    label="Circuit"
                    onChange={
                      (e, newValue) => {
                        const register = registers.find(r => r.id === newValue);
                        if(register){
                          change(
                            `${r}.label`,
                            register.label || `#${register.number}${register.circuit_type !== 'consumption' ? ` - ${register.circuit_type}` : ''}`
                          );
                        }
                      }
                    }
                    name={`${r}.circuit`}>
                    <option value="">None</option>
                    {
                      registers.map(
                        r => (
                          <option key={r.id} value={r.id}>
                            {r.number} - {r.circuit_type}{r.label && ` - ${r.label}`}
                          </option>
                        )
                      )
                    }
                  </CurbSelect>
                  <CurbInput
                    className="relay-label"
                    label="Label"
                    name={`${r}.label`} />
                </div>
              </div>
            );
          }
        )}
        <p className="button-wrapper center">
          {
            fields.length < 5
              ? (
                <a
                  className="button add"
                  onClick={() => fields.push({
                    port: fields.length,
                    priority: fields.length
                  })}><i />
                </a>
              )
              : (
                <p>No more than 5 relays are allowed</p>
              )
          }
        </p>
      </div>
    );
  }
}
