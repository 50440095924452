import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import UserPageWrapper from '../wrappers/UserPageWrapper';
import ThemedPage from '../ThemedPage';
import CurbLogoSrc from 'shared/styles/themes/curb-dark/img/curb-logo-white.png';
import SearchForm from './components/search-form';
import Configure from './components/configure/index';
import VirtualLoadControl from '../../data-containers/virtual-load-control';
import '../../../styles/virtual-load-control/index.less';

export function VirtualLoadControlPageWrapper(props){
  let { children, title='Control', renderHeader, locations, match, appConfig={} } = props;
  let { hubSerial } = match.params;
  const globalContainerClass = 'curb-dark';

  useEffect(
    () => {
      document.documentElement.classList.add(globalContainerClass);
      if(appConfig.provider){
        document.body.classList.remove(appConfig.provider);
      }
      return () => {
        document.documentElement.classList.remove(globalContainerClass);
        if(appConfig.provider){
          document.body.classList.add(appConfig.provider);
        }
      }
    },
    []
  );

  if(typeof renderHeader !== 'function'){
    renderHeader = () => <React.Fragment>
      <h1>
        <img src={CurbLogoSrc} title={`Curb ${title}`} className="curb-logo" />&nbsp;
        {title}
      </h1>
    </React.Fragment>;
  }

  return <VirtualLoadControl.Provider initialState={{locations, hubSerial}}>
    <ThemedPage className="curb-dark-page virtual-load-control-page">
      <header className="app-header primary-section">
        { renderHeader() }
      </header>
      <main className="app-main">
        { children }
      </main>
    </ThemedPage>
  </VirtualLoadControl.Provider>;
}

export default function(props){
  const { match } = props;
  const basePath = match.path.replace(/\?$/g, '');
  return <UserPageWrapper {...props}>
    <VirtualLoadControlPageWrapper>
      {/* virtual load control mini router */}
      <Switch>
        <Route exact={true} path={match.path} component={SearchForm} />
        <Route path={`${basePath}/configure`} component={Configure} />
      </Switch>
    </VirtualLoadControlPageWrapper>
  </UserPageWrapper>;
}