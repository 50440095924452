import React, { Component } from 'react';
import { Field } from 'redux-form';

export default class RadioGroup extends Component {
  field = (props) => {
    const { input, label, meta, options, autoSave } = props;
    const { name, onBlur, onChange } = input;
    const { touched, error } = meta;

    var { className } = this.props;
    if(!Array.isArray(className)){
      className = [className];
    }
    className = className.concat([`radio-group-${name}`, 'curb-radio-group', 'curb-group']).filter(c => c);

    return (
      <div className={className.join(' ')}>
        {label && <label>{label}</label>}
        {touched && error && <span className="error">{error}</span>}
        <div className="options-wrapper">
          {options.map(
            ({label, value, className}, index) => {
              const handleChange = e => {
                onBlur(e);
                const r = onChange(e);
                e.persist();
                if(autoSave){
                  window.requestAnimationFrame(
                    () => {
                      e.target.form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: false}));
                    }
                  );
                }
                return r;
              };
              const id = `${name}_${index}`;
              const key = `${name}[${index}]`;
              const checked = input.value === value;

              if(!className){
                className = `radio-group-${name}`;
              }
              if(!Array.isArray(className)){
                className = [className];
              }
              className = className.concat(['curb-radio', 'curb-group-item']);

              return (
                <div className="curb-radio curb-group-item" key={key}>
                  <input
                    type="radio"
                    id={id}
                    name={key}
                    value={value}
                    checked={checked}
                    onChange={handleChange} />
                  <label htmlFor={id}>
                    <div className="label-content">
                      {label}
                    </div>
                  </label>
                </div>
              );
            }
          )}

        </div>
      </div>
    );
  }

  render(){
    return (
      <Field {...this.props} component={this.field} />
    );
  }
}
