import React, { Component } from 'react';
import { Field } from 'redux-form';

export class BaseSelect extends Component {
  render(){
    const {
      input,
      meta,
      id,
      disabled,
      autoSave,
      placeholder
    } = this.props;
    const standardProps = {
      id,
      disabled
    };
    const { touched, error } = meta;
    const hasValue = input.hasOwnProperty('value');
    if(placeholder && !hasValue){
      input.defaultValue = null;
    }

    return (
      <div className={`select-wrap ${touched && error ? 'error' : ''}`}>
        <select
          {...input}
          {...standardProps}
          className={`${placeholder && input.value === '' ? 'placeholder' : ''}`}
          readOnly={disabled}
          value={hasValue ? input.value : placeholder}
          onChange={
            e => {
            input.onChange(e);
            e.persist();
            window.requestAnimationFrame(
              () => {
                e.target.classList.toggle('placeholder', e.target.value === '');
                if(autoSave){
                  e.target.form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: true}));
                }
              }
            );
          }}>
          {
            placeholder
              ? (
                <option disabled={!disabled}>{placeholder}</option>
              )
              : null
          }
          {this.props.children}
        </select>
        {touched && error && <span className="error">{error}</span>}
        <i />
      </div>
    );
  }
}

export default class CurbSelect extends Component {
  render(){
    const { name, type='text', label, id=this.props.name } = this.props;
    var className = this.props.className;
    if(!className){
      className = `select-${name}`;
    }
    if(!Array.isArray(className)){
      className = [className];
    }
    className = className.concat(['curb-select', 'curb-form-element']);
    return (
      <div className={className.join(' ')}>
        {
          label
            ? <label htmlFor={id}>{label}</label>
            : null
        }
        <Field
          type={type}
          parse={
            value => type === 'number' && value.toString().length
              ? Number(value)
              : value
          }
          id={id}
          {...this.props}
          component={BaseSelect} />
      </div>
    );
  }
}
