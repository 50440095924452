import React, { Component } from 'react';
import Header from '../../atoms/Header';
import MessagesList from '../../components/Messages/List';
import withRouterProps from '../base/WithRouterProps';

class MessagesMenu extends Component{
  on_touchMove(e){
    // keep the rest of the body from scrolling
    // e.preventDefault();
    e.stopPropagation();
  }
  render(){
    const { history } = this.props;
    return (
      <div className="messages-list-menu" onTouchMove={this.on_touchMove}>
        <Header clickTo={history.goBack} title="Messages" />
        <MessagesList {...this.props} />
      </div>
    );
  }
}

export default withRouterProps(MessagesMenu);
