import * as user from './user';
import * as userDetail from './user-detail';
import * as locations from './locations';
import * as live from './live';
import * as messages from './messages';
import * as alerts from './alerts';
import * as appConfig from './app-config';
import * as registers from './registers';
import * as billing from './billing';
import * as tariff from './tariff';
import * as page from './page';

const API_ERROR = 'API_ERROR';
function APIError(err) {
  // TODO - use better error messaging
  var content = '';
  var title = 'Error';  
  try {
    title = typeof err.message === 'string'
      ? err.message
      : (err.message)
        ? JSON.stringify(err.message)
        : 'Error';
    content = err.data.error_description || err.data.message || err.data.error;
  } catch(e) {
    content = JSON.stringify(err);
  }
  return {
    type: API_ERROR,
    id: Date.now(),
    content,
    title
  };
}

export default {
  API_ERROR,
  APIError,
  ...user,
  ...userDetail,
  ...billing,
  ...tariff,
  ...locations,
  ...live,
  ...messages,
  ...alerts,
  ...appConfig,
  ...registers,
  ...page
};
