import React, { Component } from 'react';
import BasicPage from '../../BasicPage/';
import WithRouterProps from '../../../components/base/WithRouterProps';
import UserDetailForm from '../../../components/User/UserDetailForm';
import Loader from '../../../atoms/Loader';
import Alerts from '../../../atoms/Alerts';

class UserDetailPageContent extends Component {
  render(){
    const { userDetail={}, user={}, history } = this.props;

    // demo guest should not be here
    if(user.isDemoGuest || user.isDemoInstaller){
      history.replace('/');
    }

    if(user.isLoading){
      return (
        <Loader className="fill large" />
      );
    }

    return (
      <BasicPage
        className="user-detail-page"
        headerProps={{
          showMenu: true,
          title: 'Account Setup'
        }}
        {...this.props}>
        <section>
          <Alerts />
          <h1>User Account</h1>
          {!userDetail.user_id && (<p>Welcome to Curb!  Let us know a little about yourself so we can better support you</p>)}
          <UserDetailForm
            {...this.props}
            locationsFlat={(userDetail.useraccess_set || []).map(ua => ua.location).filter(l => l.id)}
            onSuccess={this.onSuccess} />
        </section>
      </BasicPage>
    );
  }
}

export default WithRouterProps(UserDetailPageContent);