import { put, call } from 'redux-saga/effects';
import actions from '../actions';
import * as APICalls from '../lib/api';

// WORKER SAGAS
export function* saveBilling({location, billing, done}) {
  try {
    const { type, tariffId, simple_kwh_price } = billing;

    var utilityRate = null;
    if(type === 'utility' && tariffId){
      let cost = yield call(APICalls.fetchAvgKwhPriceForTariff, tariffId);
      utilityRate = cost.rateMean;
    }

    billing = {
      ...billing,
      simple_kwh_price: utilityRate || simple_kwh_price,
      tariffId: tariffId || null
    };

    yield call(APICalls.updateLocationBilling, location.id, billing);

    yield put(
      actions.updateBilling(location.id, billing)
    );

    if(typeof done === 'function'){
      done();
    }
  } catch (err) {
    console.error(err);
  }
}
