import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import Modal from '../../atoms/LegacyModal';
import actions from '../../actions';

const modalThreshold = 600;

class CircuitsList extends Component{
  constructor(props){
    super(props);
    this.state = {
      open: false
    };
  }
  componentDidMount(){
    this.onClickOff = this.onClickOff.bind(this);
    document.addEventListener('click', this.onClickOff);
  }
  componentWillUnmount(){
    document.removeEventListener('click', this.onClickOff);
  }
  onClickOff(e){
    if(
      !this.container.contains(e.target)
      ||
      e.target.classList.contains('modal-backdrop')
    ){
      this.setState({
        open: false
      });
    }
  }
  onClickToggle(){
    this.setState({
      open: !this.state.open
    });
  }
  onClickCircuit(c){
    const { location, updateRegister, onClickCircuit } = this.props;
    if(typeof onClickCircuit === 'function'){
      onClickCircuit(c);
    } else {
      updateRegister(location.id, c.id, {
        inactive: !c.inactive
      });
    }
  }
  onClickSelectAll(){
    const { onClickSelectAll } = this.props;
    if(typeof onClickSelectAll === 'function'){
      return onClickSelectAll();
    }
    this.updateAll({inactive: false});
  }
  onClickDeselectAll(){
    const { onClickDeselectAll } = this.props;
    if(typeof onClickDeselectAll === 'function'){
      return onClickDeselectAll();
    }
    this.updateAll({inactive: true});
  }
  updateAll(params){
    const { circuits, location, updateAllRegisters } = this.props;
    updateAllRegisters(
      location.id,
      params,
      circuits.map(c => c.id).filter(id => id)
    );
  }
  renderContent(isModal){
    const { circuits } = this.props;

    if(!circuits || !circuits.length){
      return null;
    }

    const columnCount = isModal ? 2 : 3;

    const columnLength = Math.ceil(circuits.length/columnCount);
    const circuitColumns = [];
    for(var i=0; i<columnCount; i++){
      circuitColumns.push(
        circuits.slice(columnLength * i, columnLength * (i+1))
      );
    }

    return (
      <div className="circuits">
        <div className="controls">
          <span className="select-all" onClick={() => this.onClickSelectAll()}>
            Select all
          </span>
          <span className="deselect-all" onClick={() => this.onClickDeselectAll()}>
            Deselect all
          </span>
        </div>
        <div className="circuits-wrapper">
        {
          circuitColumns.map(
            (column, columnIndex) => {
              return (
                <ul key={columnIndex}>
                  {
                    column.map((c,i) => {
                      return (
                        <li
                          key={i}
                          data-id={c.id}
                          className={c.inactive ? 'inactive': ''}
                          onClick={() => this.onClickCircuit(c)}>
                          <span className="tile" style={{backgroundColor: c.color, borderColor: c.color}} />
                          <span className="label">{c.label}</span>
                        </li>
                      );
                    })
                  }
                </ul>
              );
            }
          )
        }
        </div>
      </div>
    );
  }
  render() {
    const { open } = this.state;
    var classes = ['circuits-list'];
    if(open){
      classes.push('open');
    }
    return (
      <div
        className={classes.join(' ')}
        ref={c => (this.container = c)}>
        <span
          className="toggle-list"
          onClick={() => this.onClickToggle()}>
          <span className="icon-caret"><i /></span>
          <span className="icon-list"><i /></span>
        </span>
        <MediaQuery minWidth={modalThreshold+1}>
          {this.renderContent()}
        </MediaQuery>
        <MediaQuery maxWidth={modalThreshold}>
          <Modal
            isOpen={this.state.open}
            className="circuits-modal large">
            {this.renderContent(true)}
          </Modal>
        </MediaQuery>
      </div>
    );
  }
}

export default connect(
  null,
  {
    updateRegister: actions.updateRegister,
    updateAllRegisters: actions.updateAllRegisters
  }
)(CircuitsList);
