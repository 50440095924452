import { call, put } from 'redux-saga/effects';
import actions from '../actions';
import * as APICalls from '../lib/api';

// WORKER SAGAS
const pastNotifications = [];

export function* fetchInbox({params, resolve, reject}) {
  try {
    const inbox = yield call( APICalls.getInbox, params );
    inbox.messages = inbox.messages.sort(
      (a, b) => new Date(b.dt_created) - new Date(a.dt_created)
    );
    
    // do not alert if looking at inbox, or if the most recent message has been read
    if(!document.location.href.includes('inbox') && inbox.messages.length && inbox.messages[0].was_read===false){
      // do not send alert if this browser already has
      if(!pastNotifications.includes(inbox.messages[0].id)){
        pastNotifications.push(inbox.messages[0].id);
        window.CURB && window.CURB.newNotification(inbox.messages[0]);
      }
    }
    yield put( actions.updateInbox(inbox) );
    if(typeof resolve === 'function'){
      yield call( resolve, inbox );
    }
  } catch (err) {
    console.error(err);
    if(typeof reject === 'function'){
      yield call( reject, err );
    }
  }
}

export function* deleteMessages({messageIds, done}) {
  try {
    // messages aren't truly deleted, they are archived
    yield messageIds.map(
      id => call( APICalls.updateMessage, id, { is_archived: true })
    );

    if(typeof done === 'function'){
      done();
    }
  } catch (err) {
    console.error(err);
  }
}

export function* updateMessage({messageId, message, done}) {
  try {
    yield call( APICalls.updateMessage, messageId, message);
    // filter for only messages that match our location so that metadata is updated properly
    // const allMessages = (store.getState().messages || []).filter(m => m.context === 'location' && m.context_id === locationId);
    // yield put( actions.updateLocationMetadata(locationId, { unread_count: allMessages.filter(m => !m.was_read).length }) );
    if(typeof done === 'function'){
      done();
    }
  } catch (err) {
    console.error(err);
    if(typeof done === 'function'){
      done();
    }
  }
}

export function* fetchMessage({messageId, resolve, reject, finished}) {
  try {
    const message = yield call( APICalls.getMessage, messageId);
    yield put( actions.addMessages([message]) );
    if(typeof resolve === 'function'){
      resolve(message);
    }
  } catch (err) {
    if(typeof reject === 'function'){
      reject(err);
    }
  }
  if(typeof finished === 'function'){
    finished();
  }
}
