import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getQueryStringParams } from 'shared/dist/utils/url';
import BasicPage from '../BasicPage';
import UserPageWrapper from '../wrappers/UserPageWrapper';

class ErrorPage extends Component {
  UNSAFE_componentWillMount(){
    this.setState(getQueryStringParams());
  }
  render(){
    const {
      error='An unknown error occurred',
      error_description=(<p>Please contact <a href="https://energycurb.com/support" target="_blank">Curb support</a> if this problem persists</p>)
    } = this.state;

    return <UserPageWrapper {...this.props}>
      <BasicPage
        {...this.props}
        headerProps={
          {
            showMenu: true
          }
        }
        className="not-found">
        <div className="status error">
          <h1>{error}</h1>
          <p>{error_description}</p>
          <p>
            <Link className="button" to="/logout">Logout</Link>
          </p>
        </div>
      </BasicPage>
    </UserPageWrapper>;
  }
}

export default connect(
  // mapStateToProps
  null,
  // mapDispatchToProps
  {

  }
)(ErrorPage);
