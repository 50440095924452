import React, { useState } from 'react';
import Modal from './modal-dark';

export default function(props){
  const { render, className, initialShow=false, modalProps } = props;
  const [showModal, setShowModal] = useState(initialShow);
  const classes = [
      'modal-manager',
      showModal
        ? 'modal-visible'
        : 'modal-hidden',
      className
    ]
    .filter(s => s);

  return (
    <div
      className={classes.join(' ')}
      onClick={
        e => {
          e.preventDefault();
          if(!showModal){
            setShowModal(!showModal);
          }
        }
      }>

      {render({showModal, setShowModal})}

      { showModal && (
        <Modal
          onUpdate={
            (modalState) => {
              if(showModal === modalState.closed){
                setShowModal(!modalState.closed);
              }
            }
          }
          {...modalProps}
        />
      )}

    </div>
  )
}
