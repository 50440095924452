import React, { useState } from 'react';
import LoadingSpinner from 'shared/dist/components/atoms/loading-spinner';
import PrintError from 'shared/dist/components/atoms/print-error';
import RelaysModal from '../relays-modal';
import VirtualLoadControl from '../../../../../data-containers/virtual-load-control';
import ModalManager from '../../../../../atoms/modal-manager';
import { getConnectionHealthForHub } from 'shared/dist/utils/hardware';

function VirtualPlatformLink({platform, fetchAuthorizationUrl, allVirtualPlatforms}){
  const { id, label, active, authorizationUrl, authorizationUrlLoading, authorizationUrlError } = platform;
  const [ authorizationInProgress, setAuthorizationInProgress ] = useState();
  const [ authorizationWindow, setAuthorizationWindow ] = useState();
  const [ authorizationWindowError, setAuthorizationWindowError ] = useState();
  const { fetchVirtualDevices } = VirtualLoadControl.useContainer();

  const error = authorizationUrlError || authorizationWindowError;

  const classes = ['platform-item'];
  if(active){
    classes.push('active');
  }

  if(error){
    classes.push('has-error');
  }

  if(authorizationUrlLoading){
    classes.push('loading');
  }

  function onClick(e){
    try {
      e.preventDefault();
      setAuthorizationWindowError(null);
      if(authorizationWindow){
        return authorizationWindow.focus();
      }
      const _authorizationWindow = window.open(authorizationUrl, `Authorize ${label}`);
      setAuthorizationWindow(_authorizationWindow);
      setAuthorizationInProgress(true);
      _authorizationWindow.focus();
      const authorizationWindowMonitor = setInterval(
        () => {
          if(_authorizationWindow.closed){
            clearInterval(authorizationWindowMonitor);
            fetchVirtualDevices();
            setAuthorizationWindow(null);
            setAuthorizationInProgress(false);
            allVirtualPlatforms.forEach(fetchAuthorizationUrl);
          }
        },
        500
      );
    }
    catch(err){
      console.error(err);
      setAuthorizationWindowError(err);
    }
  };
  
  return <div className={classes.join(' ')}>
    <a 
      className="link" 
      href={authorizationUrl || '#'}
      target="_blank"
      onClick={onClick}>
      { 
        authorizationUrlLoading
          ? <LoadingSpinner className="tiny" />
          : <span className="status-indicator" /> 
      }      
      { label }
    </a>

    {
      authorizationInProgress && <div className="status-in-progress">In progress &hellip;</div>
    }

    { 
      error && <div className="status-message error">
        <PrintError error={error} />
      </div> 
    }
  </div>;

}

export default function PlatformsNav(props){
  const { formRenderProps, platforms } = props;
  const { values:{ port_circuit_mappings=[]} } = formRenderProps;
  const { 
    virtualDevices=[],
    registers,
    loadingVirtualDevices,
    hasWattwise
  } = VirtualLoadControl.useContainer();

  const relaysConfigModal = {
    className: 'relays-config-modal',
    title: 'WattWise Relay Configuration',
    render: (modalProps) => <RelaysModal {...formRenderProps} modalProps={modalProps} />
  };

  const classes = ['platform-nav'];
  if(loadingVirtualDevices){
    classes.push('loading');
  }

  return <nav className={classes.join(' ')}>
    <div className="info">
      Log in to enable control of your appliances&nbsp;
      {
        loadingVirtualDevices && <LoadingSpinner className="inline tiny" />
      }
    </div>
    <div className="platforms-list">
      {
        platforms.map(
          (platform, i) => {
            const { id, active, physical } = platform;
            if(physical){
              return <div key={i} className="platform-item">
                {
                  (() => {
                    // if this is not an arcadia hub and there are no pre existing physical port mappings, render nothing
                    if(!hasWattwise && !port_circuit_mappings.length){
                      return null;
                    }
                    let classes = ['launch-relays-config-modal', id];
                    if(active){
                      classes.push('active');
                    }
                    return <ModalManager
                      key={i}
                      className={classes.join(' ')}
                      modalProps={relaysConfigModal}
                      render={
                        () => <div className="link">
                          <span className="status-indicator" />
                          { platform.label }
                        </div>
                      } />;      
                  })()                  
                }
              </div>
            }
            return <VirtualPlatformLink
              key={i} 
              platform={platform}
              allVirtualPlatforms={platforms.filter(({physical}) => !physical)}
              {...props} />;
          }
        )
      }        
    </div>
  </nav>;
}