import React, { Component } from 'react';
import omit from 'lodash.omit';
import { registerIsMain } from 'shared/dist/utils/hardware';
import { FieldArray } from 'redux-form';
import CurbInput from '../../atoms/form/input';
import CurbSelect from '../../atoms/form/select';
import CurbCheckbox from '../../atoms/form/checkbox';
import CollapseGroup from '../../atoms/CollapseGroup';
import CheckboxGroup from '../../atoms/form/checkbox-group';

import EnforcementWindows from './enforcement-windows';
import Relays from './relays';
// import LoadCircuits from './load-circuits';

import { hubSerial, required, positiveInteger, notEmpty } from '../../lib/validators';
import { fetchRegistersForInstallation, fetchLoadControlSettings } from '../../lib/api';

const validControlThreshold = (value, allValues) => {
  return value <= allValues.release_threshold
    ? 'Must be greater than release threshold'
    : undefined;
};

const validReleaseThreshold = (value, allValues) => {
  return value >= allValues.control_threshold || 0
    ? 'Must be less than control threshold'
    : undefined;
};

// const validMonitoringMins = (value, allValues) => {
//   return value <= allValues.monitor_interval_guard_mins
//     ? 'Must be greater than monitoring interval guard'
//     : undefined;
// };

// const validMonitoringGuardMins = (value, allValues) => {
//   return value >= allValues.monitor_interval_mins
//     ? 'Must be less than monitoring interval'
//     : undefined;
// };

class LoadControlConfigFormContent extends Component {

  static formKeys = [
    'is_enabled',
    'installation',
    'monitor_interval_mins',
    // 'monitor_interval_guard_mins',
    // 'control_minimum_off_mins',
    'control_maximum_off_mins',
    // 'control_minimum_delay_mins',
    // 'control_priority_step_mins',
    // 'disable_until',
    // 'utc_offset_hours',
    'enforcement_windows',
    'control_threshold',
    'release_threshold',
    'load_circuits',
    'load_circuits_manual',
    'relays'
  ]

  static initialValues = {
    is_enabled: true,
    control_threshold: 1000,
    release_threshold: 800,
    utc_offset_hours: -6,
    monitor_interval_mins: 30,
    // monitor_interval_guard_mins: 2,
    // control_minimum_off_mins: 5,
    control_maximum_off_mins: 0,
    // control_minimum_delay_mins: 0,
    // control_priority_step_mins: 1,
    // disable_until: 0,
    enforcement_windows: [
      {
        start_hour: 8,
        end_hour: 17,
        days_of_week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
      }
    ],
    relays: [
      {
        port: 0,
        priority: 1
      },
      {
        port: 1,
        priority: 2
      },
      {
        port: 2,
        priority: 3
      },
      {
        port: 3,
        priority: 4
      },
      {
        port: 4,
        priority: 5
      }
    ]
  }

  constructor(props){
    super(props);
    this.state = {
      registers: []
    };
  }

  UNSAFE_componentWillMount(){
    const { initialValues={} } = this.props;
    const { installation } = initialValues;
    this.onInputSerial = this.onInputSerial.bind(this);
    this.normalizeLoadCircuits = this.normalizeLoadCircuits.bind(this);
    this.onInputSerial(installation);
  }

  normalizeRegister(r){
    return JSON.stringify({
      group: r.group,
      channel: r.channel
    });
  }

  normalizeLoadCircuits(circuits){
    return circuits.map(this.normalizeRegister);
  }

  onInputSerial(value){
    const { load_circuits=[] } = this.props.currentValues;
    if(!hubSerial(value)){
      this.fetchExistingConfig(value);
      this.fetchRegisters(value);
    } else {
      //
      if(load_circuits.length){
        this.props.change('load_circuits', []);
      }
    }
  }

  fetchExistingConfig(installation=this.props.currentValues.installation){
    this.setState({ isFetchingStatus: `Fetching load control config for ${installation}` });
    if(!installation){
      return;
    }
    return fetchLoadControlSettings(installation)
      .then(
        config => {
          if(!config){
            throw new Error(`Config not found for ${installation}`);
          }

          if(config.classic){
            config = config.classic;
          }

          if(config.load_circuits && config.load_circuits.length){
            config.load_circuits = (config.load_circuits || [])
              .map(this.normalizeRegister);
          }
          else {
            config = omit(config, 'load_circuits');
          }

          if(config.relays && config.relays.length){
            config.relays = config.relays.map(
              r => omit(r, 'on')
            );
          }

          // apply existing config onto form
          Object.keys(config).forEach(
            key => {
              this.props.change(key, config[key]);
            }
          );
        }
      )
      .catch(console.error)
      .then(
        () => this.setState({ isFetchingStatus: null })
      );
  }

  fetchRegisters(installation=this.props.currentValues.installation){
    if(!installation){
      return;
    }
    this.setState({ loadingRegisters: true });
    return fetchRegistersForInstallation(installation)
      .then(
        registers => {
          if(registers.length && (!this.props.currentValues.load_circuits || !this.props.currentValues.load_circuits.length)){
            this.props.change(
              'load_circuits',
              registers
                .filter(registerIsMain)
                .map(this.normalizeRegister)
            );
          }
          this.setState({ registers });
        }
      )
      .catch(console.error)
      .then(
        () => {
          this.setState({
            loadingRegisters: false
          });
        }
      );
  }

  render(){
    const { currentValues, valid, isSubmitting, status, change } = this.props;
    const { registers, loadingRegisters, isFetchingStatus } = this.state;

    const serialError = hubSerial(currentValues.serial);

    return (
      <section className={`wrapper ${isSubmitting ? 'busy' : ''}`}>
        <div className="alert warning">This is the legacy implemenation of the load control configuration <strong>that should only to be used for legacy hubs running out-of-date firmware</strong>.  Unless you are certain that your hub is a legacy device, you should be using the <a href="/virtual-load-control">new load control configuration interface</a>.</div>

        <h1>Load Control Configuration</h1>

        <CurbCheckbox name="is_enabled" defaultValue={true} label="Enabled" />

        {
          isFetchingStatus && <p className="center">{isFetchingStatus}...</p>
        }

        <CurbInput
          name="installation"
          autoCapitalize={false}
          label="Serial"
          onInput={this.onInputSerial}
          maxLength={8}
          validate={[required, hubSerial]}
          placeholder="Enter your 8 character hub serial" />

        <section className={`configuration${(currentValues.is_enabled && !isFetchingStatus) ? '' : ' disabled'}`}>

          <CollapseGroup title="Timing variables" isOpened={false} collapseProps={{hasNestedCollapse: true}}>
            <CurbSelect
              type="number"
              validate={[required, positiveInteger]}
              name="monitor_interval_mins"
              label="Monitoring interval (minutes)">
              <option value={1}>1 minute</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>60 minutes</option>
            </CurbSelect>

            {/*
              <CurbInput
                type="number"
                validate={[required, positiveInteger, validMonitoringGuardMins]}
                name="monitor_interval_guard_mins"
                label="Monitoring interval guard (minutes)" />
            */}

            {/*
              <CurbInput
                type="number"
                validate={[required, positiveInteger]}
                name="control_minimum_off_mins"
                label="Minimum off time (minutes)" />
            */}

            <CurbInput
              type="number"
              validate={[required, positiveInteger]}
              name="control_maximum_off_mins"
              label="Maximum off time (minutes). 0 is disabled" />

            {/*
              <CurbInput
                type="number"
                validate={[required, positiveInteger]}
                name="control_minimum_delay_mins"
                label="Minimum delay (minutes)" />
            */}

            {/*
              <CurbInput
                type="number"
                validate={[required, positiveInteger]}
                name="control_priority_step_mins"
                label="Priority step (minutes)" />
            */}

            {/*
              <CurbInput
                type="number"
                validate={[required, positiveInteger]}
                name="disable_until"
                label="Disable until" />
            */}

            <CollapseGroup title="Enforcement windows" isOpened={true}>
              <FieldArray
                name="enforcement_windows"
                validate={notEmpty('At least one enforcement window must be specified')}
                component={EnforcementWindows} />
            </CollapseGroup>
          </CollapseGroup>

          <CollapseGroup title="Thresholds" isOpened={false}>
            <CurbInput
              type="number"
              validate={[required, positiveInteger, validControlThreshold]}
              name="control_threshold"
              label="Control threshold (W)" />
            <CurbInput
              type="number"
              validate={[required, positiveInteger, validReleaseThreshold]}
              name="release_threshold"
              label="Release threshold (W)" />
          </CollapseGroup>

          <CollapseGroup title="Load Circuits" isOpened={true} collapseProps={{hasNestedCollapse: true}}>
            {
              !serialError && !registers.length && !loadingRegisters
                ? (<span className="error">No circuits found for hub "{currentValues.installation}" - please check the hub serial above</span>)
                : null
            }
            <CheckboxGroup
              className="load-circuits"
              name="load_circuits"
              normalize={this.normalizeLoadCircuits}
              parse={
                vals => {
                  return vals.map(
                    c => typeof c === 'string' ? JSON.parse(c) : c
                  );
                }
              }
              options={
                registers.map(
                  r => {
                    return {
                      value: this.normalizeRegister(r),
                      label: (
                        <div className="relay-option">
                          <div>Label: {r.label}</div>
                          <div>Type: {r.circuit_type}</div>
                          <div>Group: {r.group}, Channel {r.channel}</div>
                        </div>
                      )
                    };
                  }
                )
              }/>
          </CollapseGroup>
          <CollapseGroup title="Relays" isOpened={false}>
            <FieldArray
              name="relays"
              validate={[notEmpty('At least one relay must be specified')]}
              registers={registers}
              change={change}
              component={Relays} />
          </CollapseGroup>

        </section>

        <input
          type="submit"
          className="button cta red"
          disabled={!valid}
          value={`${isSubmitting ? 'Submitting' : 'Submit'}`} />
        {!valid && <p className="center">There are errors, please see above</p>}
        {status && <p className={`status ${status.type}`}>{status.message}</p>}

      </section>
    );
  }
}

export default LoadControlConfigFormContent;
