import React from 'react';
import countries from 'shared/dist/lib/countries';
import USStates from 'shared/dist/lib/us-states';
import FormContent from '../../atoms/form/content';
import CurbInput from '../../atoms/form/input';
import CurbSelect from '../../atoms/form/select';

export default class LocationForm extends FormContent {
  static formKeys = [
    'label',
    'address',
    'city',
    'state',
    'postcode',
    'country'
  ]
  render(){
    const { currentValues } = this.props;
    const isUSA = (currentValues.country === 'USA' || currentValues.country === 'US');

    return (
      <section className="form-wrapper">
        <section className="form-section compact">
          <h3 className="label section-title">Location Name</h3>
          <CurbInput
            name="label"
            placeholder="Location name" />
        </section>
        <section className="form-section compact">
          <h3 className="label section-title">Address</h3>
          <CurbInput
            name="address"
            placeholder="Address" />
          <CurbInput
            name="city"
            placeholder="City" />
          <CurbSelect
            name="state"
            disabled={!isUSA}
            placeholder="State">
            {
              Object.keys(USStates).map(
                code => (
                  <option key={code} value={code}>{USStates[code]}</option>
                )
              )
            }
          </CurbSelect>
          <CurbInput
            type={isUSA ? 'number' : 'text'}
            name="postcode"
            placeholder={`${isUSA ? 'Zip' : 'Post code'}`} />
          <CurbSelect
            name="country"
            placeholder="Please select your country">
            {countries.map(country => {
              return (
                <option key={country.iso2} value={country.iso2}>{country.name}</option>
              );
            })}
          </CurbSelect>
        </section>
      </section>
    );
  }
}
