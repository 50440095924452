// https://github.com/erikras/redux-form/issues/1037
import React, { Component } from 'react';
import { Field } from 'redux-form';

export default class CheckboxGroup extends Component {
  field = (props) => {
    const { input, meta, options, autoSave } = props;
    const { name, onBlur, onChange } = input;
    const { touched, error } = meta;
    const inputValue = input.value || [];
    var className = props.className;
    if(!Array.isArray(className)){
      className = [className];
    }
    className = className.concat(['curb-checkbox-group', 'curb-group']);
    return (
      <div className={[className.join(' ')]}>
        {touched && error && <span className="error">{error}</span>}
        {options.map(
          ({label, value}, index) => {
            const handleChange = e => {
              e.persist();
              // copy the values array
              const values = [...inputValue];
              if(e.target.checked) {
                values.push(value);
              }
              else {
                values.splice(values.indexOf(value), 1);
              }
              if(autoSave){
                window.requestAnimationFrame(
                  () => {
                    e.target.form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: false}));
                  }
                );
              }
              onBlur(values);
              return onChange(values);
            };
            const className = ['curb-checkbox', 'curb-group-item'];
            const isChecked = inputValue.indexOf(value) > -1;
            const id = `${name}_${index}`;
            const key = `${name}[${index}]`;
            if(isChecked){
              className.push('checked');
            }
            return (
              <div className={className.join(' ')} key={key}>
                <label htmlFor={id}>{label}</label>
                <div className="input-checkbox">
                  <input
                    type="checkbox"
                    id={id}
                    name={key}
                    value={value}
                    checked={isChecked}
                    onChange={handleChange} />
                  <label htmlFor={id} />
                </div>
              </div>
            );
          }
        )
      }
      </div>
    );
  }

  render(){
    return (
      <Field {...this.props} type="checkbox" component={this.field} />
    );
  }
}
