import React, { useEffect, useState, useRef } from 'react';
import { createContainer } from 'unstated-next';
import * as API from '../lib/api';
import { wait } from 'shared/dist/utils/async';

function useVirtualLoadControl(initialState={}){
  const { locations, hubSerial } = initialState;
  const [ installation, setInstallation ] = useState();
  const [ error, setError ] = useState();
  const [ location, setLocation ] = useState();
  const [ settings, setSettings ] = useState();
  const [ version, setVersion ] = useState();
  const [ loadingInstallation, setLoadingInstallation ] = useState();
  const [ formValues, setFormValues ] = useState({});

  const [ loadingRegisters, _setLoadingRegisters ] = useState(null);
  const [ registers, setRegisters ] = useState([]);
  const [ registersError, setRegistersError ] = useState(null);
  const [ hasWattwise, setHasWattwise ] = useState(null);

  const [ loadingVirtualDevices, _setLoadingVirtualDevices ] = useState(null);
  const [ virtualDevices, setVirtualDevices ] = useState([]);
  const [ virtualDevicesError, setVirtualDevicesError ] = useState(null);  

  const instance = useRef({});

  const setLoadingRegisters = v => {
    instance.current.loadingRegisters = v;
    _setLoadingRegisters(v);
  }

  const setLoadingVirtualDevices = v => {
    instance.current.loadingVirtualDevices = v;
    _setLoadingVirtualDevices(v);
  }

  const getStorageKey = () => `load_control_config_${hubSerial}`;
  const getStoredFormValues = () => {
    try {
      return JSON.parse( window.sessionStorage.getItem(getStorageKey()) );
    }
    catch(err){
      return null;
    }
  };
  
  const clearStoredFormValues = () => {
    window.sessionStorage.removeItem(getStorageKey());
  };

  const persistFormValues = (values) => {
    window.sessionStorage.setItem(getStorageKey(), JSON.stringify(values));
  };

  const fetchForSerial = async hubSerial => {
    try {
      setLoadingInstallation(true);
      const [_installation, _settings, { version }, { isInFleet } ] = await Promise.all([
        API.fetchInstallation(hubSerial),
        API.fetchLoadControlSettings(hubSerial, { payloadOnly: true }),
        API.fetchLoadControlSettingsVersion(hubSerial),
        API.installationIsInFleet(hubSerial, 'arcadia')
      ]);

      if(instance.current.hubSerial !== hubSerial){
        return;
      }

      if(_installation.location){
        let location = await API.fetchLocation(_installation.location);
        if(!location){
          throw new Error(`No location found for UUID ${_installation.location}`);
        }
        setLocation(location);
      }
      else {
        throw new Error(`Hub with serial "${instance.current.hubSerial}" has not been installed`)
      }
      setInstallation(_installation);
      setSettings(_settings);
      // hardcode virtual_load_control version
      setVersion('virtual_load_control');
      setHasWattwise(isInFleet);
    }
    catch(err){      
      setError(err);
    }
    window.requestAnimationFrame(
      () => setLoadingInstallation(false)
    );
  };

  const fetchVirtualDevices = async () => {
    try {
      setLoadingVirtualDevices(true);
      setVirtualDevicesError(null);
      const { hub_serial, virtual_devices } = await API.fetchVirtualDevices(hubSerial);
      if(hub_serial === instance.current.hubSerial){
        let startingPort = 100;
        // apply port to virtual devices if doesn't exist
        virtual_devices
          .forEach(
            (virtual_device, i, arr) => {
              if(!virtual_device.port){
                let existingPorts = arr.map(vd => vd.port).filter(n => n);
                if(!existingPorts.length){
                  virtual_device.port = startingPort;
                  return;
                }
                virtual_device.port = Math.max(...existingPorts, startingPort-1) + 1;
              }
            }
          );
        setVirtualDevices(virtual_devices);
      }
    }
    catch(err){
      // don't treat 404s as errors, just return an empty list
      if(err.status === 404){
        // do nothing
        // setVirtualDevices([]);
      }
      else {
        setVirtualDevicesError(err);
      }
    }
    window.requestAnimationFrame(
      () => setLoadingVirtualDevices(false)
    );
  }

  // fetch only if hasn't been fetched et
  function passiveFetchVirtualDevices(){
    if(!instance.current.loadingVirtualDevices){
      return fetchVirtualDevices();
    }
  }

  const fetchRegisters = async () => {
    try {
      setLoadingRegisters(true);
      setRegistersError(null);
      const registers = await API.fetchRegistersForInstallation(hubSerial);
      if(hubSerial === instance.current.hubSerial){
        setRegisters(registers);
      }
    }
    catch(err){
      setRegistersError(err);
    }
    window.requestAnimationFrame(
      () => setLoadingRegisters(false)
    );
  }

  // fetch only if hasn't been fetched yet
  function passiveFetchRegisters(){
    if(!instance.current.loadingRegisters && !registers.length){
      return fetchRegisters();
    }
  }

  const clear = () => {
    setInstallation(null);
    setSettings(null);
    setVersion(null);
    setLocation(null);
    setError(null);

    setLoadingInstallation(null);
    setLoadingVirtualDevices(null);
    setLoadingRegisters(null);
    setRegisters([]);
    setVirtualDevices([]);
  };

  useEffect(
    () => {
      instance.current.hubSerial = hubSerial;
      if(hubSerial){
        clear();
        fetchForSerial(hubSerial);
        setFormValues(getStoredFormValues());
      }
    },
    [hubSerial]
  );

  return {
    hubSerial,
    installation,
    location,
    settings, setSettings,
    version,
    hasWattwise,
    error,
    clear,
    loadingInstallation,
    formValues, 
    setFormValues,
    persistFormValues,
    getStoredFormValues,
    clearStoredFormValues,
    // registers
    fetchRegisters,
    passiveFetchRegisters,
    loadingRegisters,
    registers,
    registersError,
    // virtual devices
    fetchVirtualDevices,
    passiveFetchVirtualDevices,
    loadingVirtualDevices,
    virtualDevices,
    virtualDevicesError
  };
};

export default createContainer(useVirtualLoadControl);
