import React, { Component } from 'react';
import classNames from 'classnames';

export default class Dropdown extends Component{
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.change = this.change.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }
  change(val) {
    return (e) => {
      e.stopPropagation();
      this.props.onChange(val);
      this.close();
    };
  }
  close() {
    this.setState({
      open: false
    });
  }
  toggle(e) {
    e.stopPropagation();
    this.setState({
      open: !this.state.open
    });
  }
  UNSAFE_componentWillMount() {
    window.addEventListener('mousedown', this.close, false);
  }
  componentWillUnmount() {
    window.removeEventListener('mousedown', this.close, false);
  }
  render(){
    const { children, currentValue, className } = this.props;
    const selectedChild = children.find(child => currentValue === child.props.value);
    const selectedPosition = children.indexOf(selectedChild);
    const dropdownClasses = classNames('dropdown', className, {
      open: this.state.open,
      [`selected-item-${selectedPosition}`]: true,
      last: selectedPosition === children.length - 1
    });

    return (
      <div
        className={dropdownClasses}
        style={this.props.style}
        tabIndex="1"
        ref="dropdown"
        onMouseDown={this.toggle}>
        <div className="selected-label">
          {selectedChild ? selectedChild.props.children : 'N/A'}
          <i />
        </div>
        <ul className="dropdown-list">
          {children.map(child => {
            const { value, className, disabled, children } = child.props;
            const isActive = currentValue === value;
            const itemClasses = classNames('dropdown-item', className, {
              'active': isActive
            });
            return (
              <li
                className={itemClasses}
                onMouseDown={e => {
                  if(disabled){
                    return;
                  }
                  return this.change(value)(e);
                }}
                disabled={disabled}
                key={value}>
                {isActive ? <i /> : ''}
                {children}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
