import React from 'react';
import Tabs from '../../atoms/Tabs';
import RefreshableComponent from '../base/RefreshableComponent';
import { connect } from 'react-redux';
import Dropdown from '../../atoms/Dropdown';
import HistoricalCharts from './HistoricalCharts';
import CircuitsList from './CircuitsList';
import { fetchHistorical, fetchKwhrData } from '../../lib/api';
import colors from '../../lib/colors';
import { getCurrencyConfig } from '../../lib/utils';
import {
  registerIsProduction,
  registerIsBattery,
  registerIsConsumption
} from 'shared/dist/utils/hardware';
import {
  getNetSeries,
  getConsumptionSeries,
  getProductionSeries,
  getBatterySeries,
  getRangeMagnitude,
  getRangeUnit,
  parseTimeString
} from 'shared/dist/utils/historical';

class Historical extends RefreshableComponent {
  static defaultProps = {
    location: {},
    billing: {},
    registers: [],
    appConfig: {
      historical_unit: 'w',
      historical_range: '3h',
      historical_offset: '',
      historical_energytype: 'consumption'
    }
  }

  constructor(props){
    super(props);
    const { appConfig, location } = props;
    this.state = {
      loading: true,
      modalIsOpen: false,
      error: null,
      historicalIntervalId: null,
      circuitsTimeSeries: [],
      producers: [],
      energyType: location.metadata.production && appConfig.historical_energytype ? appConfig.historical_energytype : 'consumption',
      timeRange: appConfig.historical_range,
      selectedTimeRange: appConfig.historical_range,
      resolution: this.getResolutionFromTimeRange(appConfig.historical_range),
      unit: appConfig.historical_unit,
      offset: null
    };
  }

  componentDidMount(){
    this.getData(this.state, this.props);
  }

  setAdditionalState(props){
    const { billing, appConfig, location } = props;
    this.setState({
      simple_kwh_price: billing.simple_kwh_price
    });


    if(appConfig.historical_unit !== this.props.appConfig.historical_unit){
      this.setState({
        unit: appConfig.historical_unit || Historical.defaultProps.appConfig.historical_unit
      });
    }

    if(appConfig.historical_range !== this.props.appConfig.historical_range){
      let timeRange = appConfig.historical_range || Historical.defaultProps.appConfig.historical_range;
      this.setState({
        timeRange,
        selectedTimeRange: timeRange,
        resolution: this.getResolutionFromTimeRange(timeRange)
      });
    }

    if(appConfig.historical_energytype !== this.props.appConfig.historical_energytype){
      this.setState({
        energyType: location.metadata.production ? appConfig.historical_energytype : Historical.defaultProps.appConfig.historical_energytype
      });
    }

  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  hasNext(){
    const { offset } = this.state;
    return !!offset;
  }

  paginate(next){
    return e => {
      e.preventDefault();
      const { selectedTimeRange, offset } = this.state;
      const rangeMagnitude = getRangeMagnitude(selectedTimeRange);
      const rangeUnit = getRangeUnit(selectedTimeRange);
      const currentOffsetMagnitude = offset
        ? getRangeMagnitude(offset)
        : 0;

      const newOffsetMagnitude = currentOffsetMagnitude + (
        next
          ? -rangeMagnitude
          : rangeMagnitude
      );

      this.setState({
        offset: newOffsetMagnitude > 0
          ? `${newOffsetMagnitude}${rangeUnit}`
          : null
      });
    };
  }

  onEnergyTabChange(newEnergyType){
    this.setState({
      energyType: newEnergyType
    });
  }

  onTimeRangeChange(newTimeRange){
    this.setState({
      selectedTimeRange: newTimeRange,
      resolution: this.getResolutionFromTimeRange(newTimeRange),
      offset: null
    });
  }

  onUnitChange(newUnit){
    this.setState({
      unit: newUnit
    });
  }

  getResolutionFromTimeRange(range){
    switch(range) {
      case '3h':
        return 'm';
      case '12h':
        return '5m';
      case '1d':
        return '5m';
      case '1w':
        return 'h';
      case '1mo':
        return 'h';
      case '1y':
        return 'd';
      case '5y':
        return 'd';
    }
  }
  getKwhrResolutionFromTimeRange(range){
    switch(range) {
      case '3h':
        return '15m';
      case '12h':
        return '30m';
      case '1d':
        return '1h';
      case '1w':
        return '1d';
      case '1mo':
        return '1w';
      case '1y':
        return '1mo';
      case '5y':
        return '1mo';
    }
  }

  getData(state=this.state, props=this.props, {background}={}){
    const { location } = props;
    const { selectedTimeRange, resolution, unit, offset } = state;
    const params = { };
    if(offset){
      params.offset = offset;
    }

    const hasChanged = () => {
      return selectedTimeRange !== this.state.selectedTimeRange
      ||
      (unit !== this.state.unit && (unit === 'kwhr' || this.state.unit === 'kwhr'))
      ||
      offset !== this.state.offset;
    };

    if(location){
      if(!background){
        this.setState({
          loading: true,
          circuitsTimeSeries: [],
          kwhr: [],
          net: []
        });
      }
      var fetcher;
      if(unit === 'kwhr'){
        this.clearDataFetcher();
        fetcher = fetchKwhrData(location.id, selectedTimeRange, this.getKwhrResolutionFromTimeRange(selectedTimeRange), params)
          .then(
            aggregates => {
              if(!hasChanged()){
                this.setState({
                  selectedTimeRange,
                  timeRange: selectedTimeRange,
                  kwhr: aggregates
                });
              }
            }
          );
      } else {
        params.interpolatePartials = true;
        if(!background){
          this.createDataFetcher(state, props);
        }
        fetcher = fetchHistorical(location.id, selectedTimeRange, resolution, params)
          .then(
            circuitsTimeSeries => {
              const consumptionCircuitsTotal = circuitsTimeSeries.filter(e => !e.main && !e.production).map(e=> { e.values.sort((a,b)=>b.t - a.t); return e;}).reduce((acc,cur) => {
                const sumOfValues = cur.values.reduce((a,c) => a + c.w, 0);
                return[acc[0] + cur.values[0].w,sumOfValues/cur.values.length];
              }, [0,0]);
              if(typeof window.setRadialDial === 'function' ){ 
                window.setRadialDial({unit: 'W', latestConsumption: consumptionCircuitsTotal[0], simple_kwh_price: 0.02801, locationId: location.id, base: consumptionCircuitsTotal[1]});
              } 
              if(!hasChanged()){
                this.setState({
                  loading: false,
                  error: null,
                  circuitsTimeSeries,
                  selectedTimeRange,
                  timeRange: selectedTimeRange,
                  net: this.getNetSeriesData(circuitsTimeSeries)
                });
              }
            }
          );
      }

      if(fetcher){
        fetcher
          .catch(
            err => {
              this.setState({
                circuitsTimeSeries: [],
                error: err.message
              });
            }
          )
          .then(
            () => {
              if(!hasChanged()){
                this.setState({
                  loading: false
                });
              }
            }
          );
      }
      this.setAdditionalState(props);
    }
  }

  getNetSeriesData(circuitsTimeSeries){
    const { location } = this.props;
    const { production, battery } = location.metadata;
    const net = [
      {
        label: 'Use',
        use: true,
        color: colors.usage,
        values: getConsumptionSeries(circuitsTimeSeries).sort((a,b) => a.t - b.t)
      }
    ];
    if(production){
      net.push({
        label: 'Production',
        production: true,
        color: colors.solar[0],
        values: getProductionSeries(circuitsTimeSeries).sort((a,b) => a.t - b.t)
      });
    }
    if(battery){
      net.push({
        label: 'Storage',
        storage: true,
        color: colors.battery[0],
        values: getBatterySeries(circuitsTimeSeries).sort((a,b) => a.t - b.t)
      });
    }
    net.push(
      {
        label: 'Net',
        net: true,
        color: colors.net,
        values: getNetSeries(circuitsTimeSeries).sort((a,b) => a.t - b.t)
      }
    );
    return net;
  }

  clearDataFetcher(){
    if(this.fetchInterval){
      clearInterval(this.fetchInterval);
    }
  }

  createDataFetcher(state=this.state, props=this.props){
    const { resolution, timeRange, offset } = state;
    this.clearDataFetcher();
    const params = { omitPartials: true };
    if(offset){
      params.offset = offset;
    }
    // multiply resolution by 3 to guarantee that we don't miss any data points
    let magnitude = getRangeMagnitude(resolution)*3;
    this.fetchInterval = setInterval(
      () => {
        const { location } = props;
        const { resolution } = state;
        fetchHistorical(location.id, `${magnitude}${getRangeUnit(resolution)}`, resolution, params)
          .then(
            circuitsTimeSeries => {
              if(!circuitsTimeSeries.length){
                magnitude = magnitude + getRangeMagnitude(resolution);
                return;
              }
              magnitude = getRangeMagnitude(resolution)*3;
              const latestSeconds = circuitsTimeSeries.reduce(
                (acc, c) => {
                  acc = Math.max(acc, Math.max(...c.values.map(v => v.t)));
                  return acc;
                },
                0
              );
              const newNetData = this.getNetSeriesData(circuitsTimeSeries);
              const timeRangeStartSeconds = latestSeconds - parseTimeString(timeRange).seconds;
              this.setState({
                circuitsTimeSeries: this.state.circuitsTimeSeries.map(
                  c => {
                    const newCircuit = circuitsTimeSeries.find(circuit => circuit.id === c.id);
                    if(newCircuit && newCircuit.values){
                      // insert new values, but only if those timestamps that are not already present
                      c.values = c.values
                        .concat(
                          newCircuit.values.filter(
                            v => !c.values.find(existingValue => existingValue.t === v.t)
                          )
                        )
                        .filter(
                          v => v.t >= timeRangeStartSeconds
                        );
                    }
                    return c;
                  }
                ),
                net: this.state.net.map(
                  (item, i) => {
                    // insert new values, but only if those timestamps that are not already present
                    item.values = item.values
                      .concat(
                        newNetData[i].values.filter(
                          v => !item.values.find(existingValue => existingValue.t === v.t)
                        )
                      )
                      .filter(
                        v => v.t >= timeRangeStartSeconds
                      );
                    return item;
                  }
                )
              });
            }
          );
      },
      parseTimeString(resolution).seconds * 1000
    );
  }

  onRefresh(){
    this.getData(this.state, this.props, {background: true});
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    this.clearDataFetcher();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState){
    // if(this.state.energyType !== nextState.energyType){
    //   return this.getData(nextState, nextProps);
    // }
    if (this.state.selectedTimeRange !== nextState.selectedTimeRange){
      return this.getData(nextState, nextProps);
    }
    if (this.state.unit !== nextState.unit){
      // if we're switching to or from kwh
      if (nextState.unit === 'kwhr' || this.state.unit === 'kwhr')
      {
        return this.getData(nextState, nextProps);
      }
    }
    if (this.state.offset !== nextState.offset){
      return this.getData(nextState, nextProps);
    }

    // if(nextState.energyType === 'net' && this.state.energyType !== nextState.energyType){
    //   // switch to net
    //
    // }
    // else if(this.state.energyType === 'net' && this.state.energyType !== nextState.energyType){
    //   // switched away fromm net
    // }

  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.location.id !== this.props.location.id){
      this.setState({
        loading: true
      });
      this.getData(this.state, nextProps);
      // if changing to a location without production, force consumption as energyType
      if(!nextProps.location.metadata.production){
        this.onEnergyTabChange('consumption');
      }
    }
    this.setAdditionalState(nextProps);
  }

  getGraphItems(){
    const { registers } = this.props;
    const { energyType, net } = this.state;
    switch(energyType){
      case 'consumption':
        return registers.filter(registerIsConsumption);
      case 'battery':
        return registers.filter(registerIsBattery);
      case 'production':
        return registers.filter(registerIsProduction);
      case 'net':
        return net;
      default:
        return registers;
    }
  }

  onClickNetGroup(c){
    const { net } = this.state;
    this.setState({
      net: net.map(
        group => {
          return {
            ...group,
            inactive: c.label === group.label ? !group.inactive : group.inactive
          };
        }
      )
    });
  }

  changeAllNet(update){
    const { net } = this.state;
    this.setState({
      net: net.map(
        group => {
          return {
            ...group,
            ...update
          };
        }
      )
    });
  }

  render() {
    const { location, registers, appConfig, billing, className } = this.props;
    const currencyConfig = getCurrencyConfig(billing.simple_currency_code);
    const { allow_currencies } = appConfig;
    const { energyType, timeRange, offset, resolution, loading, circuitsTimeSeries, unit, kwhr, simple_kwh_price, net, error, circuitsTimeSeries_add, net_add } = this.state;
    const { production, battery, is_cellular } = location.metadata;

    const chartsProps = {
      registers,
      timeRange,
      offset,
      resolution,
      billing,
      location,
      energyType,
      loading,
      allow_currencies,
      simple_kwh_price,
      unit,
      kwhr,
      error,
      circuitsTimeSeries_add,
      net_add
    };

    const energyTypeTabs = (production || battery) &&
    (
      <Tabs
        currentValue={this.state.energyType}
        onChange={(newEnergyType) => this.onEnergyTabChange(newEnergyType)}
        className="energy-type-selection"
        title="EnergyType:">
        <div value="consumption">Consumption</div>
        {
          production && (
            <div value="production">Production</div>
          )
        }
        {
          battery && (
            <div value="battery">Storage</div>
          )
        }
        <div
          value="net"
          disabled={unit === 'percentage'}>
          Net
        </div>
      </Tabs>
    );

    const classes = ['historical-chart'];
    if(className){
      classes.push(className);
    }

    const timeRanges = [
      ['3h', '3 hr'],
      ['12h', '12 hr'],
      ['1d', '1 day'],
      ['1w', '1 week'],
      ['1mo', '1 month'],
      ['1y', '1 year'],
      ['5y', 'All']
    ];

    // remove 3h for cellular
    if(is_cellular){
      timeRanges.shift();
    }

    return (
      <div className={classes.join(' ')}>

        <div className="historical-options">
          {energyTypeTabs}
          <Tabs
            currentValue={this.state.selectedTimeRange}
            onChange={(newTimeRange) => this.onTimeRangeChange(newTimeRange)}
            className="time-range-selection"
            title="Range:">
            {
              timeRanges.map(
                ([value, label]) => <div key={value} value={value}>{label}</div>
              )
            }
          </Tabs>
        </div>

        {
          (energyType !== 'net' || unit !== 'kwhr') && (
            <CircuitsList
              location={location}
              onClickCircuit={
                energyType === 'net' ? this.onClickNetGroup.bind(this) : null
              }
              onClickSelectAll={
                energyType === 'net' ? () => this.changeAllNet({inactive: false}) : null
              }
              onClickDeselectAll={
                energyType === 'net' ? () => this.changeAllNet({inactive: true}) : null
              }
              circuits={this.getGraphItems()}
            />
          )
        }

        <Dropdown
          currentValue={this.state.unit}
          onChange={(newUnit) => this.onUnitChange(newUnit)}
          className="Dropdown format-selection"
          title="Unit">
          <div value="$/hr" className={`${!allow_currencies ? 'hidden' : ''}`}>
            {currencyConfig.symbol} <span className="full-label">{currencyConfig.code}</span>
          </div>
          <div value="w">
            W <span className="full-label">Watts</span>
          </div>
          <div value="percentage" disabled={energyType === 'net'}>
            % <span className="full-label">Percentage</span>
          </div>
          <div value="kwhr">
            ∑ <span className="full-label">kWattHrs</span>
          </div>
        </Dropdown>

        <HistoricalCharts
          {...chartsProps}
          data={circuitsTimeSeries}
          netData={net}
        />

        {
          this.state.selectedTimeRange !== '5y' && (
            <div className="historical-navigation">
              <a className="prev" onClick={this.paginate(false)}>
                <i className="icon-caret-left" />
              </a>
              <a className="next" onClick={this.paginate(true)} disabled={!this.hasNext()}>
                <i className="icon-caret-right" />
              </a>
            </div>
          )
        }

      </div>
    );
  }

}

export default connect(
  state => {
    const { registers, billing, appConfig } = state;
    return {
      billing,
      appConfig: {
        ...Historical.defaultProps.appConfig,
        ...appConfig
      },
      registers
    };
  }
  // null,
  // null,
  // {
  //   areStatesEqual: (next, prev) => {
  //     return next.billing === prev.billing && next.appConfig === prev.appConfig && next.registers === prev.registers;
  //   }
  // }
)(Historical);
