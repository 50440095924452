import actionTypes from '../actions';

export default (state={}, action) => {
  const { type, appConfig } = action;
  switch(type){
    case actionTypes.RECEIVE_APP_CONFIG:
      return appConfig;
    default:
      return state;
  }
};
