import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../../atoms/LegacyModal';

export default class SnoozeModal extends Component {
  componentDidUpdate(prevProps){
    if(this.props.modalIsOpen && !prevProps.modalIsOpen){
      window.requestAnimationFrame(
        () => {
          this.container.modalBox.querySelector('footer .button-cancel').focus();
        }
      );
    }
  }
  render(){
    const { modalIsOpen, closeModal, onClickConfirm } = this.props;
    return (
      <Modal
        ref={c => (this.container = c)}
        close={closeModal}
        isOpen={modalIsOpen}
        className="snooze-modal"
        header={null}
        closeModal={closeModal}
        footer={(
          <footer>
            <button
              className="button-cancel"
              onClick={closeModal}><strong>Keep saving</strong></button>
            <button
              className="button-confirm"
              onClick={onClickConfirm}>Do it anyway</button>
          </footer>
        )}>
        <div className="content center">
          <h3><i className="icon-status-error-triangle" /></h3>
          <p>
            Overriding your energy saver may result in a higher electricity bill.  It may take up to five minutes to turn on all of your devices.
          </p>
        </div>
      </Modal>
    );
  }
}
