import React, { Component } from 'react';
import eventEmitter from '../../event-emitter';

export default class RefreshableComponent extends Component {
  constructor(props){
    super(props);
    this.onRefresh = this.onRefresh.bind(this);
    eventEmitter.on('refresh', this.onRefresh);
  }

  componentWillUnmount(){
    eventEmitter.off('refresh', this.onRefresh);
  }

  onRefresh(){
    // override this
  }

}
