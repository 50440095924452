import React from 'react';
import { connect } from 'react-redux';
import { getConsumptionForSnapshot } from 'shared/dist/utils/snapshot';
import LiveDataComponent from '../base/LiveDataComponent';
import { fetchAggregate } from '../../lib/api';
import LiveUsage from './LiveUsage';
import RadialDialChart from '../../ui/viz/radial-dial';
import { LIVE_DATA_INTERVALS } from '../../constants';
import actions from '../../actions';

const { updateBaseline } = actions;

class LiveWrapper extends LiveDataComponent {

  setAdditionalState(props){
    const { location, live } = props;
    // location has been updated
    if(location.id){
      this.fetchBaseline(props);
      var consumption = this.getAllSnapshots(props);
      const currentPosition = live[location.id] ? live[location.id].radialPosition : consumption.length ? consumption.length - 1 : null;
      consumption = consumption
        // re-shuffle the redux store data based on our current radial position
        // redux store prepends latest item to front (E, D, C, B, A),
        // and this component adds latest item to a growing index (A, B, C, D, E)
        .slice(0, currentPosition).reverse().concat( consumption.slice(currentPosition).reverse() )
        .map(snapshot => getConsumptionForSnapshot(snapshot));
      this.setState({
        latestConsumption: null,
        baseline: null,
        consumption,
        currentPosition
      });
    }
  }

  fetchBaseline(props=this.props){
    const { location, updateBaseline } = props;
    fetchAggregate(location.id, '1h', '5m')
      .then(
        aggregate => {
          updateBaseline({
            locationId: location.id,
            baseline: getConsumptionForSnapshot(aggregate, { metric: 'avg' })
          });
        }
      );
    this.baselineTimeout = window.setTimeout(
      () => this.fetchBaseline(),
      1000 * 60 * 5
    );
  }

  clearPreviousLocationData(){
    if(this.graph){
      this.graph.update([]);
      this.graph.updateCurrentPosition(this.state.currentPosition);
    }
    this.setState({
      consumption: []
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    // got new live data
    const { location, updateBaseline, live } = nextProps;
    if(!this._isMounted){
      return;
    }
    const currentPosition = (live[location.id] || {}).radialPosition;
    const previousPosition = (this.props.live[location.id] || {}).radialPosition;
    if(location.id !== this.props.location.id){
      // location has changed
      if(this.props.location.id){
        this.clearPreviousLocationData();
      }
      this.setAdditionalState(nextProps);
    }
    else if(currentPosition !== previousPosition) {
      const latestSnapshot = this.getLatestSnapshot(nextProps);
      if(latestSnapshot && latestSnapshot.length){
        const { baseline } = (live[location.id] || {});
        const latestConsumption = getConsumptionForSnapshot(latestSnapshot);
        const consumption = [...this.state.consumption];
        consumption[currentPosition] = latestConsumption;
        this.setState({
          latestConsumption,
          consumption,
          currentPosition
        });

        // add a temporary baseline if it hasn't been fetched yet
        if(!baseline){
          updateBaseline({
            locationId: location.id,
            baseline: latestConsumption
          });
        }
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState){
    // update chart data here
    const { live, location } = nextProps;
    const { consumption, pageHidden, currentPosition } = nextState;
    if(this.graph){
      const { baseline } = (live[location.id] || {});
      // if we were previously hidden, then update the currentPosition
      if(!pageHidden && this.state.pageHidden){
        // apply data that was accumulated while page was hidden
        this.graph.update(consumption);
        // update position, which will re-render all graph ticks
        this.graph.updateCurrentPosition(currentPosition);
      } else if(this.state.currentPosition !== currentPosition) {
        this.graph.update(consumption);
      }

      // update baseline if it has changed
      if(baseline && baseline !== this.graph.baseline){
        this.graph.updateBaseline(baseline);
      }
    }
  }

  UNSAFE_componentWillMount(){
    super.UNSAFE_componentWillMount();
    this.setAdditionalState(this.props);
  }

  initGraph(){
    this.graph = new RadialDialChart(
      this.chartContainer,
      {
        intervalsCount: LIVE_DATA_INTERVALS,
        currentPosition: this.state.currentPosition
      }
    );
  }

  componentDidMount(){
    super.componentDidMount();
    this.initGraph();
    window.setRadialDial = ({latestConsumption, unit, simple_kwh_price, base}) => {
      if(!this.state.consumption || this.state.consumption.length<2){
        this.setState({
          latestConsumption, unit, simple_kwh_price, consumption: [latestConsumption], currentPosition: 0
        });
        if(this.graph){
          this.graph.updateBaseline(base);
          this.graph.update([latestConsumption]);
          this.graph.updateCurrentPosition(0);
        }
      }
    };
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.baselineTimeout){
      window.clearTimeout(this.baselineTimeout);
    }
  }

  render() {
    const { latestConsumption } = this.state;
    return (
      <div className="live-wrapper">
        <LiveUsage
          data={latestConsumption}
          {...this.props }/>
        <div
          className="radial-dial-chart-container"
          ref={node => (this.chartContainer = node)} />
      </div>
    );
  }
}

export default connect(
  state => {
    const { live } = state;
    return {
      live
    };
  },
  {
    updateBaseline
  }
)(LiveWrapper);
