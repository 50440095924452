import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isGUID } from 'shared/dist/utils/string';
import withRouterProps from '../base/WithRouterProps'
import MainMenu from './MainMenu';
import SupportMenu from './SupportMenu';
import ConfigurationsMenu from './ConfigurationsMenu';
import EnergyProviderMenu from './EnergyProviderMenu';
import MessagesListMenu from './MessagesListMenu';
import MessageDetailMenu from './MessageDetailMenu';
import LocationInfoMenu from './LocationInfoMenu';
import ProfileMenu from './ProfileMenu';
import eventEmitter from '../../event-emitter';

class Menu extends Component{
  static defaultProps = {
    menu: {}
  }

  constructor(props){
    super(props);
    this.state = {};
  }

  changeSection(val) {
    return (e) => {
      e.preventDefault();
      this.setState({
        menuSection: val
      });
    };
  }

  on_keyup(e){
    // TODO listen for esc key
    const code = e.which || e.keyCode;
    if(code === 27){
      this.handleGoBack(this.props);
    }
  }

  on_clickOverlay(){
    if(window.CURB && window.CURB.webBrowserNotifications) {
      window.CURB.webBrowserNotifications.askPermission();
    }
    return () => {
      this.handleGoBack(this.props);
    };
  }

  on_touchMoveOverlay(e){
    e.stopPropagation();
  }

  UNSAFE_componentWillMount(){
    this.changeSection = this.changeSection.bind(this);
    this.on_keyup = this.on_keyup.bind(this);
    eventEmitter.on('showDeleteModal', () => this.setState({modalIsOpen: true}));
    eventEmitter.on('hideDeleteModal', () => this.setState({modalIsOpen: false}));
  }

  componentDidMount(){
    window.addEventListener('keyup', this.on_keyup);
  }

  componentWillUnmount(){
    window.removeEventListener('keyup', this.on_keyup);
    eventEmitter.off('showDeleteModal');
    eventEmitter.off('hideDeleteModal');
  }

  handleGoBack(){
    const { routerLocation, history } = this.props;
    history.replace(routerLocation.pathname.replace(/\/menu.*/, ''));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!nextProps.menu.open && this.props.menu.open) {
      this.handleGoBack();
      this.setState({
        menuSection: 'closed'
      });
    } else if(nextProps.menu.open && !this.props.menu.open) {
      this.setState({
        menuSection: 'main'
      });
    }
  }

  renderSection() {
    // Mini-router
    const { menu, locations, history, match, locationsFlat, user, userDetail, params } = this.props;
    const menuLocation = locations[menu.locationId];
    const profileProps = {
      user,
      userDetail,
      locationsFlat,
      profileSubsection: params ? params.profileSubsection : null
    };    
    const historyProps = {
      history,
      match
    };
    switch(menu.section) {
      case 'configurations':
        return <ConfigurationsMenu location={menuLocation} {...historyProps} />;
      case 'support':
        return <SupportMenu />;
      case 'energy-provider':
        return <EnergyProviderMenu location={menuLocation} {...historyProps} />;
      case 'inbox':
        if(menu.messageId){
          return <MessageDetailMenu location={menuLocation} messageId={menu.messageId} {...historyProps} />;
        }
        return <MessagesListMenu location={menuLocation} scrollTarget={this.menuContainer} {...historyProps} />;
      case 'info':
        return <LocationInfoMenu location={menuLocation} {...historyProps} />;
      case 'profile':
        return <ProfileMenu {...profileProps} {...historyProps} />;
      default:
        return <MainMenu {...this.props} closeMenu={this.on_clickOverlay()} />;
    }
  }
  render(){
    const { className, menu } = this.props;
    const { menuSection, modalIsOpen } = this.state;

    const classes = [
      'menu',
      menu.open
        ? 'open'
        : 'closed'
    ];
    if(className){
      classes.push(className);
    }

    if(menuSection){
      classes.push(`${menuSection}-section`);
    }

    if(modalIsOpen){
      classes.push('modal-open');
    }

    return (
      <div className={classes.join(' ')}>
        <div className="menu-overlay" onClick={this.on_clickOverlay()} onTouchMove={this.on_touchMoveOverlay} />
        <div
          className="menu-container"
          ref={c => (this.menuContainer = c)}>
          {React.cloneElement(this.renderSection(), {changeSection: this.changeSection, menu})}
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const { billing, locations, messages } = state;

    return {
      billing,
      locations,
      messages
    };
  }
)(
  withRouterProps(
    (props) => {
      /* determine menu state from URL */
      const { match, location } = props;
      const { params={} } = match;
      const { primarySection, secondarySection, tertiarySection } = params;
      const menuLocation = isGUID(secondarySection)
        ? secondarySection
        : null;

      const section = menuLocation 
        ? tertiarySection
        : secondarySection;

      const menu = {
        open: primarySection === 'menu',
        locationId: menuLocation || location.id,
        section,
        messageId: section === 'inbox'
          ? tertiarySection
          : null
      };      

      return <Menu menu={menu} {...props} />;
    }
  )

);