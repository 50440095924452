import React from 'react';
import { connect } from 'react-redux';
import currencies from 'shared/dist/lib/currencies';
import { reduxForm, formValueSelector } from 'redux-form';
import range from 'lodash.range';
import CurbInput from '../../atoms/form/input';
import CurbSelect from '../../atoms/form/select';
import CurbRadioGroup from '../../atoms/form/radio-group';
import { required } from '../../lib/validators';

const formName = 'energyProviderForm';

const renderers = {
  utilitiesDropdown: (tariffs, lses, currentValues, validateTariffId) => {
    if (currentValues.tariffId && !currentValues.lseId){
      tariffs.map((t) => {if(t.masterTariffId === currentValues.tariffId){currentValues.lseId = t.lseId;}});
    }
    return (
      <div>
        <CurbSelect
          name="lseId"
          label="Utility Provider"
          disabled={!lses.length}
          autoSave={true}
          placeholder={!lses.length ? 'Loading utilities...' : 'Select a utility provider'}>
          {lses.map(lse => {
            return (<option value={lse.lseId} key={lse.lseId}>{lse.lseName}</option>);
          })}
        </CurbSelect>
        <CurbSelect
          name="tariffId"
          label="Utility plan"
          validate={[validateTariffId]}
          disabled={!lses.length}
          placeholder={!tariffs.length ? 'Loading utilities...' : 'Select a plan'}
          autoSave={true}>
          {tariffs.filter(tariff => tariff.lseId === currentValues.lseId).map(tariff => {
            return (<option value={tariff.masterTariffId} key={tariff.masterTariffId}> {tariff.tariffName}</option>);
          })}
        </CurbSelect>
      </div>
    );
  },
  currencyDropdown: (currencies) => {
    return (
      <CurbSelect
        name="simple_currency_code"
        label="Currency"
        autoSave={true}>
        {
          Object.keys(currencies).map(
            currencyId => {
              const currencyGroup = currencies[currencyId];
              return (
                <option value={currencyId} key={currencyId}>{currencyGroup.symbol} ({currencyGroup.name_plural})</option>
              );
            }
          )
        }
      </CurbSelect>
    );
  }
};

renderers.utilitiesDropdown.displayName = 'UtilitiesDropdown';
renderers.currencyDropdown.displayName = 'CurrencyDropdown';

const validateTariffId = (value, values) => {
  if(values.type === 'utility'){
    return required(value);
  }
};

const validateKWHPrice = (value, values) => {
  if(values.type === 'simple'){
    return required(value);
  }
};

let EnergyProviderForm = props => {
  const { handleSubmit, location, validatePostcode, onZipCodeChange, APIError, tariffs, lses, valid, currentValues, submitting } = props;
  const isUSA = location.country === 'USA';
  let classNames = ['curb-form', 'EnergyProvider', 'energy-provider-form'];
  if(submitting){
    classNames.push('busy');
  }

  const onSubmit = e => {
    e.preventDefault();
    if(valid){
      if (currentValues.type === 'utility')
      {
        tariffs.forEach((t) => {
          if(t.masterTariffId === currentValues.tariffId)
          {
            currentValues.currency = t.currency;
          }
        });
      }
      handleSubmit(currentValues, props);
    }
  };

  const onInputPostcode = (postcode, meta={}) => {
    const { valid } = meta;
    if(valid){
      onZipCodeChange(postcode);
    }
  };

  return (
    <form onSubmit={onSubmit} className={classNames.join(' ')}>
      <section>
        <section>

          <CurbSelect
            name="day_of_month"
            label="Billing day of the month"
            autoSave={true}>
            {range(1,32).map(day => {
              return (<option value={day} key={day}>{day}</option>);
            })}
          </CurbSelect>

          <CurbInput
            name="postcode"
            label="Zip code"
            validate={[validatePostcode]}
            onInput={onInputPostcode}
            autoSave={true}
            type={isUSA ? 'number' : 'text'}
            placeholder="Enter your zip code" />

        </section>

        <section>
          <CurbRadioGroup
            name="type"
            label="Billing type:"
            className="radio-block"
            autoSave={true}
            options={[
              {
                value: 'utility',
                label: 'Utility Rate'
              },
              {
                value: 'simple',
                label: 'Simple Rate'
              }
            ]} />
          {
            APIError ?
            (
              <span className="error">{APIError}</span>
            ) :
            null
          }
          <section>
          {
            currentValues.type === 'simple'
              ? (
                  <div>
                    <CurbInput
                      name="simple_kwh_price"
                      label="Price per kWh"
                      type="number"
                      validate={[validateKWHPrice]}
                      autoSave={true}
                      placeholder="eg. 0.1"
                      disabled={currentValues.type !== 'simple'} />
                    { renderers.currencyDropdown(currencies) }
                  </div>
              )
              : renderers.utilitiesDropdown(tariffs, lses, currentValues, validateTariffId)
          }
          </section>

        </section>

      </section>
    </form>
  );
};

EnergyProviderForm = reduxForm({
  form: formName,
  enableReinitialize: true
})(EnergyProviderForm);


export default connect(
  state => {
    // pull profile from state and use it to populate the form's initialValues, as well as the current values
    const selector = formValueSelector(formName);
    const currentValues = selector(state, 'lseId', 'tariffId', 'postcode', 'day_of_month', 'type', 'simple_currency_code', 'simple_kwh_price');
    currentValues.simple_kwh_price = parseFloat(currentValues.simple_kwh_price || 0);
    ['day_of_month', 'lseId', 'tariffId'].forEach(
      key => {
        currentValues[key] = parseInt(currentValues[key], 10);
      }
    );
    return {
      currentValues
    };
  }
)(EnergyProviderForm);
