import React from 'react';
import { Field } from 'react-final-form';
import CurbSelect from 'shared/dist/components/atoms/form/select.jsx';
import { FieldArray } from 'react-final-form-arrays'
import { registerIsConsumption } from 'shared/dist/utils/hardware';
import VirtualLoadControl from '../../../../data-containers/virtual-load-control';

export default function({ values, form, modalProps, initialValues }){
  const { registers } = VirtualLoadControl.useContainer();
  return <section className="curb-form">
    <p className="intro">Specify which circuits are controlled by relays</p>
    <section className="relay-configs-list">
      <FieldArray name="port_circuit_mappings">
        {
          ({fields}) => fields.map(
            (fieldName, fieldIndex) => {
              const value = (fields.value[fieldIndex]);
              return <div className="relay-config-item" key={fieldIndex}>
                <span className="label">Relay {fieldIndex+1}:</span>
                <Field 
                  name={`${fieldName}.circuit`}
                  label="Curb Circuit"
                  component={CurbSelect}>
                    <option value="">None</option>
                  {
                    registers.filter(r => registerIsConsumption(r))
                      .map(
                        r => <option 
                          key={r.id}
                          value={r.id}
                          disabled={fields.value.find(({circuit}) => circuit === r.id) && value.circuit !== r.id}>
                            {r.label || `Group ${r.group} | Channel ${r.channel}`}
                        </option>
                      )
                  }
                </Field>
              </div>
            }
          )
        }
      </FieldArray>
    </section>
    <div className="curb-submit">
      <button className="passive" onClick={
        () => {
          form.change('port_circuit_mappings', initialValues.port_circuit_mappings);
        }
      }>Revert</button>
      <button className="irreversible" onClick={modalProps.close}>Save</button>
    </div>
  </section>;
};