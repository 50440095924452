const analyticsDevice = () => {
  const ducktyping = () => {
    // Opera 8.0+
    if((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0){
      return 'Opera';
    }
    // Firefox 1.0+
    if(typeof InstallTrigger !== 'undefined'){
      return 'Firefox';
    }
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    if( /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))){
      return 'safari';
    }
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE){
      return 'IE';
    }
    // Edge 20+
    if(!isIE && !!window.StyleMedia){
      return 'Edgeprechromium';
    }
    // Chrome 1 - 79
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // Edge (based on chromium) detection
    if(isChrome && (navigator.userAgent.indexOf('Edg') !== -1)){
      return 'Edgechromium';
    }
    if(isChrome) {
      return 'Chrome';
    }
    return 'Unknownbrowser';
  };
  if(window.cordova && window.appContainer){
    return `iOSapp-${window.navigator.platform}-${ducktyping()}`;
  } else if(window.cordova){
    return `Androidapp-${window.navigator.platform}-${ducktyping()}` ;
  }
  return `browser-${window.navigator.platform}-${ducktyping()}`;
};

const slugify = (a) => {
  return a.replace(/[^a-z0-9\s]/gi, '').replace(/[-\s]/g, '_');
};
export {analyticsDevice, slugify};