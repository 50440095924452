import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import throttle from 'lodash.throttle';
import { getGridImpactForSnapshot, getProductionForSnapshot, getBatteryForSnapshot } from 'shared/dist/utils/snapshot';
import { parseTimeString } from 'shared/dist/utils/historical';
import LiveDataComponent from '../base/LiveDataComponent';
import EnergySourceChart from '../../ui/viz/energy-source';
import { fetchAggregate } from '../../lib/api';
import Loader from '../../atoms/Loader';

class EnergySource extends LiveDataComponent {
  static defaultProps = {
    location: {},
    metric: 'w'
  }

  constructor(props){
    super(props);
    this.state = {
      ...(this.state || {}),
      snapshot: []
    };
  }

  async fetchData({background}={}){
    try {
      const { location, useLive, range, resolution, metric } = this.props;
      const { pageHidden } = this.state;
      if(pageHidden){
        return;
      }
      if(!background){
        this.setState({
          error: null,
          loading: true
        });
      }
      let snapshot;
      if(useLive){
        snapshot = this.getLatestSnapshot();
      }
      else {
        snapshot = await fetchAggregate(location.id, range, resolution);
        if(!snapshot || !snapshot.length || !snapshot.find(c => !c.other && c.hasOwnProperty(metric))){
          throw new Error('No data');
        }
      }

      if(snapshot){
        this.setState({
          snapshot,
          error: null,
          loading: false
        });  
      }
    }
    catch(error){
      this.setState({
        error,
        loading: false
      });
    }
  }

  componentDidUpdate(){
    const { location, metric } = this.props;
    const { snapshot=[] } = this.state;
    if(this.graph && snapshot.length){
      let { production, battery } = location.metadata;
      let data = [];

      if(production){
        data.push({
          label: 'Production',
          production: true,
          value: -getProductionForSnapshot(snapshot, { metric })
        });
      }

      if(battery){
        // positive is charging
        // negative is discharging
        data.push({
          label: 'Storage',
          battery: true,
          value: getBatteryForSnapshot(snapshot, { metric })
        });
      }

      data.push({
        label: 'Grid Net',
        grid: true,
        value: getGridImpactForSnapshot(snapshot, { metric })
      });
      this.graph.update(data);
    }
  }

  componentWillReceiveProps(nextProps){
    const { useLive } = nextProps;
    if(!this._isMounted) {
      return;
    }
    if(useLive){
      this.fetchData();
    }
  }

  initGraph(){
    this.graph = new EnergySourceChart(
      this.chartContainer,
      {}
    );
  }

  onResize(){
    const width = this.chartContainer.offsetWidth;
    this.chartContainer.style.height = `${width - 23}px`;
    if(this.graph){
      this.graph.draw();
    }
  }

  componentDidMount(){
    const { range, useLive, refreshRate='5m' } = this.props;
    super.componentDidMount();
    this.initGraph();
    window.addEventListener(
      'resize', 
      throttle(
        () => this.onResize(),
        500
      )
    );
    this.onResize();
    this.fetchData();
    if(!useLive){
      let { seconds } = parseTimeString(refreshRate);      
      this.interval = setInterval(
        () => this.fetchData({background: true}),
        seconds * 1000
      );
    }
  }

    // shouldComponentUpdate(nextProps, nextState){
    //   const { useLive } = nextProps;
    //   // all updates are handled by the graph component via UNSAFE_componentWillReceiveProps, React can stay out of the way
    //   return (useLive || !nextState.loading) && super.shouldComponentUpdate(nextProps, nextState);
    // }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  render(){
    const { className } = this.props;
    const { loading, error } = this.state;
    const classes = ['energy-source', 'module'];
    
    if(className){
      classes.push(className)
    }
    if(loading){
      classes.push('loading');
    }

    return (
      <div className={classes.join(' ')}>
        <p className="module-title"><i className="fa fa-exchange"/>Energy Source</p>
        <div
          className="energy-source-chart"
          ref={node => (this.chartContainer = node)}>
          { loading && <Loader /> }
          { 
            error && <div className="message error">
              <p><i className="icon-status-error-triangle" /></p>
              <p>{error.message}</p>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const { live } = state;
    const { useLive } = ownProps;
    if(useLive){
      return { 
        live
      };
    }
    return {};
  }
)(EnergySource);
