import {
  call,
  put
} from 'redux-saga/effects';
import actions from '../actions';
import * as APICalls from '../lib/api';

export function* upsertUserDetail({
  userDetail,
  local,
  resolve,
  reject
}) {
  if (!local) {
    try {
      const response = yield call(
        APICalls.upsertUserDetail,
        userDetail
      );

      if(userDetail.email_address){
        yield put(
          actions.updateUser({
            user: {
              email: userDetail.email_address
            },
            local: true
          })
        );
      }

      if(typeof resolve === 'function'){
        yield call( resolve, response );
      }

    } catch (err) {
      yield put(actions.APIError(err));
      if(typeof reject === 'function'){
        yield call( reject, err );
      }
    }
  }
}
