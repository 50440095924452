import actionTypes from '../actions';
import colors from '../lib/colors';
import { sortCircuits } from 'shared/dist/utils/snapshot';
import hardwareUtils from 'shared/dist/utils/hardware';

const { registerIsConsumption, registerIsBattery, registerIsProduction } = hardwareUtils;

export default (state=[], action) => {
  const { type, registerId, register, registers, subsetIds=[] } = action;
  switch(type){
    case actionTypes.RECEIVE_REGISTERS: {
      let consumers = registers.filter(registerIsConsumption);
      let producers = registers.filter(registerIsProduction);
      let batteries = registers.filter(registerIsBattery);
      return sortCircuits(registers).map(
        r => {
          if(consumers.concat(producers).concat(batteries).indexOf(r) > -1 || r.other){
            r.color = colors.getForRegister(
              r,
              registerIsProduction(r)
                ? producers.indexOf(r)
                : (registerIsBattery(r))
                  ? batteries.indexOf(r)
                  : consumers.indexOf(r)
            );
          }
          return r;
        }
      );
    }
    case actionTypes.UPDATE_REGISTER:
      return state.map(
        r => {
          return {
            ...r,
            ...(
              register && r.id === registerId
                ? register
                : {}
            )
          };
        }
      );
    case actionTypes.UPDATE_ALL_REGISTERS:
      return state.map(
          r => {
            if(subsetIds.length && subsetIds.indexOf(r.id) === -1){
              return r;
            }
            return {
              ...r,
              ...(register || {})
            };
          }
        );
    case actionTypes.UPDATE_MULTIPLE_REGISTERS:
      return state.map(
        existingCircuit => {
          const matchingCircuit = registers.find(r => r.id === existingCircuit.id) || {};
          return {
            ...existingCircuit,
            ...matchingCircuit
          };
        }
      );
    default:
      return state;
  }
};
