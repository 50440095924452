import React from 'react';
import DiscreteBarChartHorizontal from 'shared/dist/ui/viz/discrete-bar-horizontal';
import RegistersChart from 'shared/dist/ui/viz/registers-chart';
import mergePrototypes from 'shared/dist/utils/merge-prototypes';
import { formatWatts, getCurrencyFormatter, truncateString } from '../../lib/formatters';
import { isDollar, convertWatts } from '../../lib/unit-converter';

class LiveDataChart extends DiscreteBarChartHorizontal {
  get defaultParams(){
    return {
      ...super.defaultParams,
      chartClass: 'live-usage-chart',
      unit: 'w',
      autoSize: true,
      showValues: true,
      showXAxis: true,
      showYAxis: true,
      barPadding: 0.12,
      margins: {
        top: 10,
        right: 60,
        bottom: 50,
        left: 120
      },
      tickFormats: {
        x: d => {
          return this.formatValue(d);
        },
        y: d => {
          return truncateString(this.getRegisterForId(d).label || '');
        }
      }
    };
  }

  keyAccessor(d){
    return d.id;
  }

  valueAccessor(d){
    return d.w;
  }

  formatValueLabel(watts){
    return this.formatValue(watts, true);
  }

  formatValue(watts){
    const { unit, simple_kwh_price, currency } = this.params;
    if(isDollar(unit) && simple_kwh_price){
      let dollars = convertWatts({watts, unit, simple_kwh_price});
      return dollars >= 0.01 ? getCurrencyFormatter(currency)(dollars) : '<';
    }
    return watts !== 0 ? formatWatts(watts) : '<';
  }

  getColor(d){
    const circuit = this.getRegisterForId(d.id);
    return circuit.color;
  }
  draw(){
    // don't draw anything if we don't have our circuits yet
    if(!this.registers || !this.registers.length) {
      return;
    }
    return super.draw();
  }

}
mergePrototypes(LiveDataChart, [RegistersChart]);
export default LiveDataChart;
