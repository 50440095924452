import React, { Component } from 'react';
import classNames from 'classnames';

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      show: false
    };
  }
  componentDidMount(){
    document.addEventListener('keyup', this.onKeyPress);
  }

  componentWillUnmount(){
    document.removeEventListener('keyup', this.onKeyPress);
  }

  onKeyPress(e){
    const code = e.which || e.keyCode;
    if(this.state.show && code === 27){
      e.stopPropagation();
      this.setState({
        show: false
      });
    }
  }

  setAdditionalState(props){
    this.setState({
      show: props.isOpen
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setAdditionalState(nextProps);
  }

  UNSAFE_componentWillMount(){
    this.onKeyPress = this.onKeyPress.bind(this);
    this.setAdditionalState(this.props);
  }

  onClickModalBackdrop(){
    const { persistent, closeModal } = this.props;
    if(!persistent && typeof closeModal === 'function'){
      closeModal();
    }
  }

  render(){
    if(this.state.show) {
      let modalClasses = classNames('Modal', this.props.className);
      let header = '';
      let footer = this.props.footer;
      if(typeof this.props.title !== 'undefined') {
        header = (
          <header>
            <span>{this.props.title}</span>
          </header>
        );
      }

      return (
        <div className={modalClasses}>
          <div
            ref={c => (this.modalBox = c)}
            className="modal-box">
            {header}
            <div className="modal-body">
              {this.props.children}
            </div>
            {footer}
          </div>
          <div
            ref={c => (this.modalBackdrop = c)}
            className="modal-backdrop" onClick={e => this.onClickModalBackdrop(e)} />
        </div>
      );
    }
    return null;
  }
}

module.exports = Modal;
