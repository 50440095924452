import React, { Component } from 'react';
import { addParamsToUrl } from 'shared/js/utils/url';
import Loader from '../../atoms/Loader';
import auth from '../../lib/auth';
import config from '../../config';

/*
  When processing HTTP responses from the backend API, these are possible values
    for the key "application_code".
*/
const API_STATUS_NO_LOCATION = 1;
const API_STATUS_NO_JWT = 2;
const API_STATUS_INVALID_JWT = 3;
const API_STATUS_NO_USER_DATA = 4;
const API_STATUS_NO_POST_DATA = 5;

/*
  Definition of general constants used by class DeviceControl.
*/
const INTERVAL_REFRESH_DEVICES_STATE_SECONDS = 60;

const INDICATOR_DEVICE_ON = '🟢'; // solid green circle
const INDICATOR_DEVICE_OFF = '🔴'; // solid red circle
const INDICATOR_DEVICE_INDETERMINATE = '⚫'; // solid black/gray circle

/*
  Returns string for entire URL to use with fetch API call on the backend
    service.
  Note: `APIURL` must be in the form '/a/b' (include leading slash, and no
    trailing slash).
*/
function getFullURLForAPICall(APIURL, { location }) {
  return addParamsToUrl(
    {
      token: auth.getToken(),
      location: location.id
    },
    `${config.DEVICE_CONTROL.HOST_AND_STAGE}${APIURL}`
  );
};

/*
  Returns a unicode character to display to user to represent a particular
    SmartThings device's status.
*/
function getIndicatorTextForDeviceStatus(deviceStatus) {
  switch (deviceStatus) {
    case 'on':
      return INDICATOR_DEVICE_ON;
    case 'off':
      return INDICATOR_DEVICE_OFF;
    default:
      return INDICATOR_DEVICE_INDETERMINATE;
  };
};


/*
  Class "DeviceControl" displays a list of the authenticated user's switchable
    SmartThings devices. If the user has not performed the authorization and
    setup procedure, a means to initiate the procedure is displayed instead.

  The API used is hosted on AWS API Gateway, whose name is
    "web-app-device-control-backend".

  An instance of `Device Control` will periodically get devices' state and
    re-render accordingly.

  props:
  - (None)
*/
class DeviceControl extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shouldRenderInvitationToDoSetup: false,
      loading: true,
      devicesData: []
    };
  }

  componentDidMount(){
    this.doAPICallToGetDevicesState();
  }

  /*
    Deal with successful (or not) backend service responses.
    return the JSON response body
    throw if error
  */
  async doCommonServerResponseHandling(
    response
  ) {
    let errorMessage;
    // We need to parse the JSON HTTP response body.
    const jsonPayload = await response.json();

    switch (response.status) {
      case 200:
        // success
        return jsonPayload;
      case 400:
        switch (jsonPayload['application_code']) {
          case API_STATUS_NO_USER_DATA:
            // User never did authorization and setup procedure.
            // This should not throw
            this.setState({
              shouldRenderInvitationToDoSetup: true
            });
            return jsonPayload;            
          case API_STATUS_NO_LOCATION:
          case API_STATUS_NO_JWT:
            errorMessage = (
              'Error: made API call which lacks URL parameter "location"'
                + ' or "token".'
            );
            break;
          case API_STATUS_INVALID_JWT:
            errorMessage = (
              'Error: made API call whose URL parameter "token" has an'
                + ' invalid JWT value.'
            );
            break;
          default:
            errorMessage = (
              'Error: made API call which resulted in HTTP response with an'
                + ' expected status code, but value for "application_code" is'
                + ` not recognized (${jsonPayload['application_code']}).`
            );
        };
        break;
      default:
        errorMessage = ('Error: made API call which resulted in unexpected backend service'
        + ` HTTP response status of ${response.status}: `);
    };
    if(errorMessage){
      throw new Error(errorMessage);
    }
  }

  async doAPICallToGetDevicesState({ silent }={}) {
    try {
      if(!silent){
        this.setState({ loading: true });
      }
      const response = await fetch(
        getFullURLForAPICall('/devices', this.props),
        {
          headers: {
            'Content-Type': 'text/plain' // avoid CORS pre-flight HTTP request
          }
        }
      );

      const payload = await this.doCommonServerResponseHandling(response);

      // if what we received is an array, set that to devicesData
      if(Array.isArray(payload)){
        this.setState({
          shouldRenderInvitationToDoSetup: false,
          devicesData: payload
        });

        this.pollDevicesState();
      }
    }
    catch(err){
      console.error(err);
      this.setState()
    }
    if(!silent){
      this.setState({ loading: false });
    }
  }

  pollDevicesState(){
    if(!this.devicesStatePollingInterval){
      this.devicesStatePollingInterval = setInterval(
        () => this.doAPICallToGetDevicesState({ silent: true }),
        INTERVAL_REFRESH_DEVICES_STATE_SECONDS * 1000
      )
    }
  }

  /*
    This method is invoked when a user tries to toggle the switch state of one
      of the listed SmartThings devices.
  */
  async handleDeviceStateToggle(deviceIndex, event) {
    const updateDevice = (update={}) => {
      this.setState({
        devicesData: this.state.devicesData.map(
          (d, i) => {
            if(i === deviceIndex){
              d = {
                ...d,
                ...update
              };
            }
            return d;
          }
        )
      });
    };
    try {
      const deviceData = this.state.devicesData[deviceIndex];

      // exit if loading
      if(deviceData.loading){
        return;
      }

      // set loading to true on device state
      updateDevice({
        loading: true
      });

      if (
        deviceData['status'] !== 'on' &&
        deviceData['status'] !== 'off'
      ) {
        // Device is likely offline, so can't issue a switch command.
        throw new Error('Device is likely offline');
      }
      const newStatus = deviceData['status'] === 'on' ? 'off' : 'on';
      const postPayload = {
        'deviceId': deviceData['deviceId'],
        'component_id': deviceData['component_id'],
        'status': newStatus
      };

      const response = await fetch(
        getFullURLForAPICall('/devices', this.props),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain' // avoid CORS pre-flight HTTP request
          },
          body: JSON.stringify(postPayload)
        }
      );
      await this.doCommonServerResponseHandling(response);
      updateDevice({
        status: newStatus
      });
    }
    catch(err){
      console.error(err);
    }

    // set loading to false on device state
    updateDevice({
      loading: false
    });

  }

  componentWillUnmount() {
    if(this.authorizationWindowMonitor){
      clearInterval(this.authorizationWindowMonitor);
    }
    if(this.devicesStatePollingInterval){
      clearInterval(this.devicesStatePollingInterval);
    }
  }

  onClickInitializationLink(e){
    e.preventDefault();
    if(this.state.authorizationWindow){
      this.state.authorizationWindow.focus();
    }
    else {
      const authorizationWindow = window.open(e.target.href, 'Authorize SmartThings');
      authorizationWindow.focus();
      this.setState({
        authorizationWindow
      });
      this.authorizationWindowMonitor = setInterval(
        () => {
          if(authorizationWindow.closed){
            clearInterval(this.authorizationWindowMonitor);
            this.setState({
              authorizationWindow: null
            });
            this.doAPICallToGetDevicesState();
          }
        },
        500
      );
    }
  }

  renderDevicesState() {
    if (this.state.devicesData.length === 0) {
      return (
        <p className="small">
          (There are no SmartThings devices set up in your location)
        </p>
      );
    }
    else {
      return (
        <table style={{border: 0 + 'px', width: 100 + '%'}}>
          <tbody>
          {
            this.state.devicesData.map(
              (deviceData, index) => {
                const classes = ['device-row'];
                if(deviceData.loading){
                  classes.push('loading');
                }
                return <tr
                  className={classes.join(' ')}
                  key={`device-data-row-${deviceData['deviceId']}`}
                  onClick={event => this.handleDeviceStateToggle(index, event)}
                >
                  <td className="label">
                    {deviceData['label'] || deviceData['name']}
                  </td>
                  <td className="status" width={20}>
                    {getIndicatorTextForDeviceStatus(deviceData['status'])}
                  </td>
                </tr>
              }
            )
          }
          </tbody>
        </table>
      );
    }
  }

  renderMain() {
    const fullURLForAPICallToInitAuthSetup = getFullURLForAPICall(
      '/smartthings/authorization/initiate',
      this.props
    );
    const { loading } = this.state;

    if(loading){
      return <Loader />;
    }

    if (this.state.shouldRenderInvitationToDoSetup) {
      /*
        Show HTML form for SmartThings authorization and setup in a new tab or
          window.
      */
      return (
        <div>
          <a
            href={fullURLForAPICallToInitAuthSetup}
            onClick={e => this.onClickInitializationLink(e)}
            className="auth-link"
            target="_blank"
          >
            Click here to set up control of your SmartThings devices
          </a>
        </div>
      );
    }
    else {
      /*
        Render device list and state, followed by link to allow user to redo
          setup procedure.
      */
      return (
        <div className="devices-state">
          {this.renderDevicesState()}
          <a
            href={fullURLForAPICallToInitAuthSetup}
            onClick={e => this.onClickInitializationLink(e)}
            className="auth-link"
            target="_blank"
          >
            Click here to change your SmartThings devices location or access token
          </a>
        </div>
      );
    }
  }

  render() {
    const { loading, shouldRenderInvitationToDoSetup } = this.state;
    const classes = [
      'device-control',
      'module',
      shouldRenderInvitationToDoSetup
        ? 'setup'
        : 'control'
    ];
    if(loading){
      classes.push('loading');
    }
    return (
      <div className={classes.join(' ')}>
        <p className="module-title"><i className="fa fa-power-off" /> Device Control </p>
        <div className="module-inner">
          {this.renderMain()}
        </div>
      </div>
    );
  }

}; // end `class DeviceControl` definition

export default DeviceControl;