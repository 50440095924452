import React from 'react';
import DiscreteBarChart from 'shared/dist/ui/viz/discrete-bar';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';
import { formatWatts, getCurrencyFormatter, truncateString, formatkWhs } from '../../lib/formatters';

export default class CostBreakdownChart extends DiscreteBarChart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      chartClass: 'cost-breakdown-chart',
      autoSize: true,
      showValues: false,
      showXAxis: true,
      showYAxis: true,
      barPadding: 0.12,
      animationDuration: 500,
      margins: {
        top: 10,
        right: 30,
        bottom: 100,
        left: 60
      },
      tickFormats: {
        x: d => {
          const circuit = this.getRegisterForId(d);
          return circuit ? truncateString(circuit.label) : '';
        },
        y: d => {
          const { currency } = this.params;
          return getCurrencyFormatter(currency)(d);
        }
      }
    };
  }

  updateRegisters(registers){
    this.registers = registers;
    this.update();
  }

  getRegisterForId(id){
    return this.registers.find(c => c.id === id);
  }

  keyAccessor(d){
    return d.id;
  }

  valueAccessor(d){
    return d.cost || 0;
  }

  formatValueLabel(d){
    return `${formatWatts(this.valueAccessor(d))} W`;
  }

  getColor(d){
    const circuit = this.getRegisterForId(d.id);
    return circuit ? circuit.color : '#000';
  }

  renderTooltip(d){
    const { currency } = this.params;
    const circuit = this.getRegisterForId(d.id);
    return (
      <div>
        <div className="name">
          <span className="key" style={{backgroundColor: circuit.color}} />
          <span className="title">{circuit.label}</span>
        </div>
        <div className="value">
          {formatkWhs(Math.abs(d.kwhr))} kWh
        </div>
        <div className="value">
          {getCurrencyFormatter(currency)(this.valueAccessor(d))}
        </div>
      </div>
    );
  }

  preTransformData(data){
    const productionCircuitsCount = data.filter(d => d.production).length;
    if(productionCircuitsCount){
      data.splice(productionCircuitsCount, 0, {
        id: Math.ceil(Math.random() * 1000000),
        placeholder: true
      });
      data.splice(productionCircuitsCount, 0, {
        id: Math.ceil(Math.random() * 1000000),
        placeholder: true
      });

      return data.map(d => {
        return {
          ...d,
          cost: Math.abs(d.cost)
        };
      });
    }
    return data;
  }

  filterDrawnData(data){
    return data.filter(d => !d.placeholder);
  }

  draw(){
    super.draw();

    this.barsEnter
      .select('rect')
      .on('mouseenter touchstart', (d, i, nodeList) => {
        showTooltip({
          target: nodeList[i],
          fallbackPlacement: 'left',
          className: 'cost-breakdown-tooltip breakdown-tooltip border',
          content: this.renderTooltip(d)
        });
      })
      .on('mouseleave touchend touchcancel', () => {
        clearTooltip();
      });

    this.barsEnterUpdate
      .select('rect')
        .attr('fill', d => this.getColor(d));
  }
}
