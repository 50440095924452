import { call, put } from 'redux-saga/effects';
import { decamelizeObject } from 'shared/dist/utils/string';
import actions from '../actions';
import * as APICalls from '../lib/api';

// WORKER SAGAS
export function* updateLocation({locationId, location, resolve, reject, local}) {
  try {
    if(!local){
      const updatedLoc = yield call(APICalls.updateLocation, locationId, decamelizeObject(location));
      yield put(
        actions.updateLocation(locationId, updatedLoc, null, null, true)
      );
      if(typeof resolve === 'function'){
        yield call(resolve, updatedLoc);
      }
    }
  } catch (err) {
    console.error(err);
    if(typeof reject === 'function'){
      yield call(reject, err);
    }
  }
}

export function* fetchLocationState({locationId, user, resolve, reject}) {
  try {
    // mark location as loading
    yield put( actions.updateLocation(locationId, { loading: true }, null, null, true) );
    window.CURB.locationId = locationId;
    const { appConfig, registers, billing, tariff, load_control_enabled, is_cellular, metadata={} } = yield call(
      APICalls.fetchLocationState,
      locationId,
      user && user.fleet
        ? {
          metadata: true
        }
        : null
    );
    // unmark location as loading
    yield put( actions.receiveAppConfig(appConfig) );
    yield put( actions.receiveRegisters(registers) );
    yield put( actions.receiveBilling(billing) );
    yield put( actions.receiveTariff(tariff) );
    yield put(
      actions.updateLocationMetadata(locationId, {
        ...metadata,
        load_control_enabled,
        is_cellular
      })
    );
    yield put( actions.updateLocation(locationId, { loading: false, current: true }, null, null, true) );
    if(typeof resolve === 'function'){
      yield call( resolve );
    }
  } catch (err) {
    console.error(err);
    if(typeof reject === 'function'){
      yield call( reject, err );
    }
  }
}
