import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; 

const toggle = ({label = '', onChange = ()=>null, initialCheckedState = false, className='', name=''}) => {
  return (
    <label className={className} role="menuitem">
        <span>{label}</span>
        <Toggle
            name={name}
            defaultChecked={initialCheckedState}
            icons={false}
            onChange={onChange} />
    </label>
  );
};

export default toggle; 