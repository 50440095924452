import React, { Component } from 'react';
import { MessageDependentComponent, MessageDependentComponentConnector } from './MessageDependentComponent';
class MessagesUnreadCount extends MessageDependentComponent {
  render(){
    const { messages_unread_count=0 } = this.props;
    const { loading } = this.state;
    const isOverflow = messages_unread_count > 99;
    
    return loading
      ? <span className="loader-inline tiny blue" />
      : (messages_unread_count)
        ? (
          <span className={`message-count ${isOverflow && 'overflow'}`}>
            {
              isOverflow
                ? '99+'
                : messages_unread_count
            }
          </span>
        )
        : null;
  }
}

export default MessageDependentComponentConnector(
  MessagesUnreadCount, 
  {}
);
