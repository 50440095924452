// https://fontawesome.com/cheatsheet
import { library } from '@fortawesome/fontawesome-svg-core';
// https://fontawesome.com/icons?d=gallery&m=free
import {
  faSearch,
  faTimes,
  faPlus,
  faMinus,
  faPause,
  faPlay,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
// https://fontawesome.com/icons?d=gallery&s=regular&m=free
// import * as regulars from '@fortawesome/free-regular-svg-icons';

library.add(
  faSearch,
  faTimes,
  faPlus,
  faMinus,
  faPause,
  faPlay,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faExclamationCircle
);