import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import DeleteModal from './DeleteModal';
import actions from '../../actions';

const { deleteMessages } = actions;

const HAS_DELETED_MESSAGE_KEY = 'has_deleted_messages';

const hasUsedMessageDelete = () => window.localStorage.getItem(HAS_DELETED_MESSAGE_KEY) === 'true';

class MessageActionBar extends Component {
  static defaultProps = {

  }

  constructor(props){
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  onClickDeleteIcon(){
    if(hasUsedMessageDelete()){
      this.confirmDelete();
    } else {
      this.setState({
        modalIsOpen: true
      });
    }
  }

  closeModal(e){
    if(e){
      e.preventDefault();
    }
    this.setState({
      modalIsOpen: false
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { triggerDelete } = nextProps;
    if(triggerDelete){
      this.onClickDeleteIcon();
    }
  }

  confirmDelete(e){
    const { handleDelete, message={}, deleteMessages } = this.props;
    if(e){
      e.preventDefault();
    }
    window.localStorage.setItem(HAS_DELETED_MESSAGE_KEY, 'true');
    this.closeModal();
    if(typeof handleDelete === 'function'){
      return handleDelete(message);
    }
    if(message.id) {
      return deleteMessages([message.id]);
    }
  }

  render(){
    const { disabled, selectedIds } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div className={`messages-actions-bar ${disabled ? 'disabled' : ''}`}>
        {/*
        <a className="action-flag" title="Flag">
          <i />
        </a>
        <a className="action-share" title="Share">
          <i />
        </a>
        */}
        <a
          className="action-delete"
          title="Delete"
          onClick={e => this.onClickDeleteIcon(e)}>
          <i />
        </a>
        <DeleteModal
          modalIsOpen={modalIsOpen}
          closeModal={() => this.closeModal()}
          onClickConfirm={() => this.confirmDelete()}
          selectedIds={selectedIds} />
      </div>
    );
  }
}

export default connect(
  null,
  {
    deleteMessages
  }
)(MessageActionBar);
