import React from 'react';
import { Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurbDatePicker from 'shared/dist/components/atoms/form/datepicker';
import CurbInput from 'shared/dist/components/atoms/form/input.jsx';
import CurbInputGroup from 'shared/dist/components/atoms/form/input-group';
import { FieldArray } from 'react-final-form-arrays'
import IconLink from '../../../../atoms/icon-link';
import { required } from '../../../../lib/validators';
import { HOLIDAYS } from './utils';

function HolidayCheckboxSlider(props){
  let { input, id, label } = props;
  const { name, checked } = input;
  const classes = ['curb-input', 'curb-form-element', 'curb-checkbox-slider', 'holiday-checkbox-slider'];

  if(!id){
    id = `checkbox_${name}`;
  }

  if(input.checked){
    classes.push('checked');
  }

  return <div className={classes.join(' ')}>
    { label && <label className="text-label" htmlFor={id}>{label}</label> }    

    <div className="message">
      {
        input.checked
          ? 'Control Savings Anyway'
          : 'Pause Control'
      }
    </div>
    <input id={id} {...input} />
    <label htmlFor={id} className="toggler">      
      <FontAwesomeIcon className="icon pause" icon="pause" />
      <FontAwesomeIcon className="icon play" icon="play" />
      <div className="indicator"></div>
    </label>
  </div>;
}

export default function({ values, form, modalProps, initialValues }){
  const { mutators: { push }} = form;
  const minDate = new Date();
  minDate.setMonth(0,1);
  const maxDate = new Date();
  maxDate.setMonth(11,31);

  const format = ({ month, day } = {}) => {
    if(month && day){
      return new Date( new Date().setMonth(month, day) );
    }
  };
  const parse = d => {
    if(!d){
      return {};
    }
    return {
      month: d.getMonth(),
      day: d.getDate()
    };
  };

  return <section className="curb-form">
    <p className="intro">These observed holidays are off-peak all day long, so automatic device control will not be performed</p>
    <section className="exceptions-list">
      {
        HOLIDAYS.map(
          (holiday, i) => {
            return <div key={`holiday_${i}`} className="exceptions-item holiday-item">
              <label>{holiday.label}</label>
              <Field 
                name="holidays"
                type="checkbox"
                id={`holidays_checkbox_${i}`}
                value={i}
                component={HolidayCheckboxSlider} />
            </div>
          }
        )
      }

      {/* https://codesandbox.io/s/kx8qv67nk5?file=/index.js:1466-1495  */}
      <FieldArray name="holidaysCustom">
        {
          ({ fields }) => fields.map(
            (name, i) => <div key={`custom_${i}`} className="exceptions-item custom-item">
              <span 
                className="round-icon linked-icon irreversible remove-exception"
                onClick={
                  () => fields.remove(i)
                }>
                <FontAwesomeIcon icon="minus" />
              </span>
              <Field
                name={`${name}.date`}
                format={format}
                parse={parse}
                minDate={minDate}
                maxDate={maxDate}
                validate={required}
                dateFormat="MMM d"
                placeholderText="Select a custom holiday"
                component={CurbDatePicker} />
              <Field 
                name={`${name}.unpaused`}
                type="checkbox"
                id={`${name}_unpaused`}
                component={HolidayCheckboxSlider} />
            </div>
          )
        }
      </FieldArray>

      <IconLink 
        icon="plus" 
        className="add-holiday"
        onClick={
          () => {
            push('holidaysCustom', { ...parse(new Date()), unpaused: false });
            setTimeout(
              () => {
                const container = document.querySelector('.exceptions-list');
                container.scroll(0, container.offsetHeight);
              },
              0
            );
          }
        }>Add Holiday</IconLink>

    </section>
    <div className="curb-submit">
      <button className="passive" onClick={
        () => {
          form.change('holidays', initialValues.holidays || []);
          form.change('holidaysCustom', initialValues.holidaysCustom);
        }
      }>Revert</button>
      <button className="irreversible" onClick={modalProps.close}>Save</button>
    </div>
  </section>;
};