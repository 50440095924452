import React, { Component } from 'react';
import { connect } from 'react-redux';
import BasicPage from '../BasicPage';
import CurbFormWrapper from '../../atoms/form';
import LoadControlConfigForm from '../../components/LoadControl/ConfigForm';
import { upsertLoadControlConfig } from '../../lib/api';
import actions from '../../actions';

const { requestUser } = actions;

class ConfigPage extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  onSave(values){
    // merge load_circuits and load_circuits_manual and JSON parse load circuits which are stored as keys
    values.load_circuits = values.load_circuits
      .map(c => typeof c === 'string' ? JSON.parse(c) : c)
      .concat( values.load_circuits_manual || [] )
      // remove nulls
      .filter(c => c)
      // enfoce uniqueness
      .reduce(
        (acc, v) => {
          if(
            !acc.find(c => c.group === v.group && c.channel === v.channel)
          ){
            acc.push(v);
          }
          return acc;
        },
        []
      );

    this.setState({ status: null });
    return upsertLoadControlConfig(values)
      .then(
        () => this.setState({
          status: {
            message: 'Successfully updated',
            type: 'success'
          }
        })
      )
      .catch(
        err => {
          this.setState({
            status: {
              message: err.message,
              type: 'error'
            }
          });
        }
      );
  }
  UNSAFE_componentWillMount(){
    this.onSave = this.onSave.bind(this);
  }
  render(){
    return (
      <BasicPage {...this.props} showMenu={false}>
        <CurbFormWrapper
          skipLocalCache={true}
          formKeys={LoadControlConfigForm.formKeys || []}
          reduxFormOptions={LoadControlConfigForm.reduxFormOptions || {}}
          id="load-control-config-form"
          onSubmit={this.onSave}
          FormContent={LoadControlConfigForm}
          {...this.state} />
      </BasicPage>
    );
  }
}

export default connect(
  // mapStateToProps
  (state) => {
    const { user } = state;
    return {
      user
    };
  },
  // mapDispatchToProps
  {
    requestUser
  }
)(ConfigPage);
