import React from 'react';
import RefreshableComponent from '../base/RefreshableComponent';
import { connect } from 'react-redux';
import { fetchAggregate } from '../../lib/api';
import isEqual from 'lodash.isequal';
import RecentUseChart from '../../ui/viz/recent-use';
import Loader from '../../atoms/Loader';

class RecentUse extends RefreshableComponent {
  static defaultProps = {
    location: {},
    billing: {}
  }

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      registerAggregates: [],
      error: null
    };
  }

  isLoading(){
    return this.state.loading && !this.state.error;
  }

  onRefresh(){
    return this.fetchData(this.props, true);
  }

  fetchData(props=this.props, background){
    const { location } = props;
    if(location){
      if(!background){
        this.setState({
          loading: true,
          registerAggregates: [],
          error: null
        });
      }
      fetchAggregate(location.id, '1d', 'h')
        .then(
          aggregates => {
            this.setState({
              loading: false,
              registerAggregates: aggregates
                .sort(
                  (a, b) => a.kwhr - b.kwhr
                )
            });
          }
        )
        .catch(
          err => this.setState({
            error: err
          })
        )
        .then(
          () => this.setState({
            loading: false
          })
        );
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState){
    const { appConfig, billing, registers } = nextProps;
    const { allow_currencies } = appConfig;
    if(this.graph){
      if(billing.simple_kwh_price !== this.props.billing.simple_kwh_price || !this.graph.params.simple_kwh_price || billing.currency !== this.props.billing.currency || !this.graph.params.currency){
        this.graph.updateParams({
          simple_kwh_price: billing.simple_kwh_price,
          currency: billing.simple_currency_code
        });
      }
      if(allow_currencies !== this.props.appConfig.allow_currencies){
        this.graph.updateParams({
          allow_currencies
        });
      }
      if(this.graph && !isEqual(nextState.registerAggregates, this.state.registerAggregates)){
        this.graph.update(nextState.registerAggregates);
      }
      if(this.graph && !isEqual(registers, this.props.registers)){
        this.graph.updateRegisters(registers);
      }

    }
  }

  initGraph(){
    const width = this.chartContainer.offsetWidth;
    if(this.graph){
      this.graph.destroy();
    }
    this.graph = new RecentUseChart(
      this.chartContainer,
      {
        autoSize: false,
        resizable: false,
        width: width,
        height: width
      }
    );
    this.graph.updateRegisters(this.props.registers);
  }

  componentDidMount(){
    this.fetchData();
    this.initGraph();
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.location.id !== this.props.location.id){
      this.initGraph(nextProps);
      this.fetchData(nextProps);
      this.setState({
        loading: true
      });
    }
  }

  render(){
    const { registerAggregates, loading, error } = this.state;
    const hasData = registerAggregates && registerAggregates.length && registerAggregates.find(a => a.kwhr);
    const showEmptyMessage = !hasData && !loading;

    const renderMain = () => {
      if(loading){
        return <Loader />
      }
      if(error){
        return <div className="message error">
          <p><i className="icon-status-error-triangle" /></p>
          <p>{error.message}</p>
        </div>
      }
      
      if(showEmptyMessage){
        return <div className="error">
          <h3>No data</h3>
        </div>;
      }        
      return null;
    }

    const classes = ['recent-use', 'module'];
    if(loading){
      classes.push('loading');
    }
    if(error || showEmptyMessage){
      classes.push('error');
    }
    return (
      <div className={classes.join(' ')}>
        <p className="module-title"><i className="fa fa-plug" /> Recent Use</p>
        <div
          className="module-inner recent-use-chart"
          ref={node => (this.chartContainer = node)}>
          { renderMain() }
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const { registers, appConfig, billing } = state;
    return {
      registers,
      billing,
      appConfig
    };
  }
)(RecentUse);
