import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IMPERSONATED_KEY } from 'shared/dist/lib/authService';
import Loader from '../../atoms/Loader';
import actions from '../../actions';
import UserDetailPageContent from '../User/Detail/PageContent';
import ErrorModal from '../../atoms/ErrorModal';

const { requestUser } = actions;

class UserPageWrapper extends Component {
  constructor(props){
    super(props);
    const hasUser = this.hasUserDetail(props);
    this.state = {
      loading: !hasUser,
      is_webview_hidden: false
    };
    const setErrorState = (error='Could not fetch user state') => {
      this.setState({
        error,
        loading: false
      });
    };
    const { match={} } = props;
    const { locationId } = match.params;
    if(!hasUser){

      let requestTimeout = setTimeout(
        () => setErrorState('Fetching user state is taking too long to complete.  You can choose to keep waiting, or choose one of the options below.'),
        1000 * 10
      );

      new Promise(
        (resolve, reject) => {
          this.props.requestUser({ 
            resolve, 
            reject,
            query: locationId
              ? {
                locationId
              }
              : null
          });
        }
      )
      .then(
        ({user}) => {
          this.setState({
            error: null
          });
          clearTimeout(requestTimeout);
          if(user.user_id && user.impersonated){
            window.localStorage.setItem(IMPERSONATED_KEY, user.impersonated);
          }
        }
      )
      .catch(
        err => {
          console.error(err);
          setErrorState(err);
        }
      )
      .then(
        () => {
          this.setState({
            loading: false
          });
        }
      );
    }
  }
  hasUserDetail(props=this.props){
    const { userDetail } = props;
    return userDetail && userDetail.user_id && userDetail.email_address;
  }

  render(){
    const { children, render } = this.props;
    if(this.state.loading){
      return (
        <Loader className="large fill" />
      );
    }

    if(this.state.error){
      return (
        <ErrorModal error={this.state.error} />
      );
    }

    if(!this.hasUserDetail()){
      return (
        <UserDetailPageContent {...this.props} />
      );
    }

    if(typeof render === 'function'){
      return render(this.props);
    }

    const onlyChild = React.Children.toArray(children)[0];

    return React.cloneElement(
      onlyChild,
      {
        ...this.props,
        ...onlyChild.props
      }
    );
  }
}

export default connect(
  // mapStateToProps
  (state) => {
    const { user, userDetail, locations, appConfig } = state;
    return {
      user,
      userDetail,
      locations,
      appConfig
    };
  },
  // mapDispatchToProps
  {
    requestUser
  }
)(UserPageWrapper);
