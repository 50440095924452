import React, { Component } from 'react';
import UserPageWrapper from '../wrappers/UserPageWrapper';
import LocationPageWrapper from '../wrappers/LocationPageWrapper';
import LiveUsagePageContent from './PageContent';

export default function LiveUsagePage(props){
  return <UserPageWrapper {...props}>
    <LocationPageWrapper
      render={p => <LiveUsagePageContent {...p} /> } />
  </UserPageWrapper>
}
