import React, { Component } from 'react';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

const triggerSubmit = debounce(
  form => form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: false})),
  1000
);

export default class FormContentWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidUpdate(prevProps){
    window.requestAnimationFrame(
      () => {
        const { autoSave, formElement, valid, pristine, currentValues } = this.props;
        if(
          autoSave &&
          formElement &&
          valid &&
          !pristine &&
          !isEqual(currentValues, prevProps.currentValues)
        ){
          triggerSubmit(formElement);
        }
      }
    );
  }
}
