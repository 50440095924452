import actionTypes from '../actions';

export default (state={
  type: 'simple',
  day_of_month: 1,
  simple_kwh_price: 0.1,
  simple_currency_code: 'USD'
}, action) => {
  const { type, billing } = action;
  switch(type){
    case actionTypes.RECEIVE_BILLING:
      return billing || state;
    case actionTypes.UPDATE_BILLING:
      return {
        ...state,
        ...billing
      };
    default:
      return state;
  }
};
