import eventEmitter from '../event-emitter';
import {analyticsDevice, slugify} from '../lib/analytics';

export const askPermission = () => {
  if(!window.CURB || !window.CURB.webBrowserNotifications || window.CURB.webBrowserNotifications.alreadyAskedPermission) {
    return;
  }
  window.CURB.webBrowserNotifications.alreadyAskedPermission = true;
  if (!('Notification' in window) || Notification.permission === 'granted') {
    return;
  }
  const permissionReqHandler = () => {
    if(!window.safari){
      try {
        Notification.requestPermission().then(function (permission) {
          if(permission==='denied') {
            localStorage.setItem('notificationRequestTimeStamp', Date.now());
          }
        });
      } catch (error) {
        // this browser doesn't handle promises on Notifications
        if (error instanceof TypeError) {
          Notification.requestPermission((permission) => {                                                                                                                                                             
            if(permission==='denied') {
              localStorage.setItem('notificationRequestTimeStamp', Date.now());
            }
          });
        }
      }
    } else {
      Notification.requestPermission((permission) => {                                                                                                                                                             
        if(permission==='denied') {
          localStorage.setItem('notificationRequestTimeStamp', Date.now());
        }
      });
    }
  };
  if (Notification.permission === 'denied') {
    const notificationTimestamp = localStorage.getItem('notificationRequestTimeStamp');
    // in one week ask again
    if((parseInt(notificationTimestamp) + (7*24*60*60*1000) < Date.now())) {
      permissionReqHandler();
    }
  } 
  else if (Notification.permission !== 'denied') { // ie is equal to default, unset
    permissionReqHandler();
  } 
  return;
};

export const notifyWebBrowser = ({subject, message, id}) =>{
  if (!('Notification' in window)) {
    return;
  }
  window.accessibilityAlert(`New Notification, ${subject}: ${message.default}`);
  const sendNotification = () => {
    window.ga && window.ga('send', 'event', `${window.CURB.provider}-notification-${slugify(subject)}-browser-${analyticsDevice()}-posted`, window.CURB.user_id, id);
    const notification = new Notification(subject, {
      body: message.default, 
      icon: '/favicon.ico', 
      data: {messageId: id, subject}
    });
    notification.onclick = (event) => {
      const {messageId, subject} = event.target.data;
      window.ga && window.ga('send', 'event', `${window.CURB.provider}-notification-${slugify(subject)}-browser-${analyticsDevice()}-clicked`, window.CURB.user_id, messageId);
      eventEmitter.emit(
        'navigateToMessageDetailInApp',
        messageId
        );
      window.focus();
    };
  };
  const askPermissionHandler = (permission) => {
    if(permission==='granted') {
      window.ga && window.ga('send', 'event', `${window.CURB.provider}-notification-acceptance-accepted-${analyticsDevice()}`, window.CURB.user_id);
      sendNotification();
    } else if (permission==='denied'){
      window.ga && window.ga('send', 'event', `${window.CURB.provider}-notification-acceptance-denied-${analyticsDevice()}`, window.CURB.user_id);
      localStorage.setItem('notificationRequestTimeStamp',Date.now());
    }
  };
  const requestPermission = () => {
    if(!window.safari) {
      try {
        Notification.requestPermission().then(function (permission) {
          askPermissionHandler(permission);
        });
      }catch (error) {
        if (error instanceof TypeError) {
          Notification.requestPermission((permission) => {                                                                                                                                                             
            askPermissionHandler(permission);
          });
        }
      }
    } else {
      Notification.requestPermission((permission) => {                                                                                                                                                             
        askPermissionHandler(permission);
      });
    }
  };

  if (Notification.permission === 'granted') {
    sendNotification();
  }
  else if (Notification.permission !== 'denied') {
    requestPermission();
  } 
  else if (Notification.permission === 'denied') {
    const notificationTimestamp = localStorage.getItem('notificationRequestTimeStamp');
    // in one week ask again
    if((parseInt(notificationTimestamp) + (86400000 * 7) < Date.now())) {
      requestPermission();
    }
  }
};