import mitt from 'mitt';
import { visibilityChangeEvent, documentHidden } from './lib/feature-detection';
import { dispatch } from './store';
import actions from './actions';

import { getHistory } from './app-router';
import {analyticsDevice, slugify} from './lib/analytics';

const eventEmitter = mitt();

document.addEventListener(
  visibilityChangeEvent,
  () => {
    eventEmitter.emit('visibilityChange', documentHidden());
    eventEmitter.emit('documentVisibiltyStatus', documentHidden());
  },
  false
);

eventEmitter.on(
  'receiveMessage',
  function(){
    dispatch(actions.fetchInbox());
  }
);

// this is called by the iOS and Android app
eventEmitter.on(
  'navigateToMessageDetail',
  function(messageId){
    setTimeout(()=> {
      window.ga && window.ga('send', 'event', `${window.CURB.provider}-notification-${messageId ? slugify(window.CURB.subject[messageId]) : 'noMessageId'}-mobileApp-${analyticsDevice()}-clicked`, window.CURB.user_id, messageId);
    }, 2000);
    const history = getHistory();
    dispatch(actions.fetchInbox());    
    if(history){
      messageId
        ? history.push(`/inbox/${messageId}`)
        : history.push('/inbox/');
    }
  }
);

eventEmitter.on(
  'navigateToMessageDetailInApp',
  function(messageId){
    const history = getHistory();
    dispatch(actions.fetchInbox());    
    if(history){
      messageId
        ? history.push(`/inbox/${messageId}`)
        : history.push('/inbox/');
    } 
  }
);

export default eventEmitter;
