import React from 'react';
import throttle from 'lodash.throttle';
import { MessageDependentComponent, MessageDependentComponentConnector } from './MessageDependentComponent';
import withRouterProps from '../base/WithRouterProps';
import actions from '../../actions';
import { formatMessageTimestamp } from '../../lib/formatters';
import { getMessageIdentifier, getMessageSubject, getMessageHTML } from '../../lib/utils';
import { isSmallScreenWidth } from '../../lib/environment-check';
import ActionsBar from './ActionsBar';
import Loader from '../../atoms/Loader';

const { deleteMessages, updateMessage, updateInbox, fetchMessage } = actions;

class MessageDetail extends MessageDependentComponent {
  static defaultProps = {
    location: {}
  }

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      message: {}
    };    
  }

  getCurrentMessage(props){
    const { messageId, messages, fetchMessage } = props;
    const { loading } = this.state;
    // TODO, clean up this garbage
    return new Promise(
      (resolve, reject) => {
        try {
          // first try to find message by searching through existing
          const message = messages.find(m => m.id.toString() === messageId);
          if(message){
            return resolve(message);
          }

          if(!loading){
            fetchMessage(
              messageId,
              resolve,
              reject
            );
          }
        } catch(err) {
          console.error(err);
          reject(err);
        }
      }
    );
  }

  isLoading(){
    return this.state.loading || !this.props.location.id || (!this.props.messages.length && !this.state.error && !this.props.error);
  }

  onClickToggleEditMode(e){
    e.preventDefault();
    this.setState({
      editMode: !this.state.editMode
    });
  }

  UNSAFE_componentWillMount(){
    const { location, messageId } = this.props;
    this.resizeHandler = throttle(
      () => this.resizeActionsBar(),
      500
    );

    if((!getMessageIdentifier(this.state.message) || getMessageIdentifier(this.state.message) !== messageId) && location.id){

      this.setState({
        loading: true
      });

      return this.getCurrentMessage(this.props)
        .then(
          message => {
            if(message){
              window.CURB.subject = window.CURB.subject || {};
              window.CURB.subject[message.id] = message.subject;
              this.setState({
                message
              });
            }
          }
        )
        .catch(
          error => {
            console.error(error);
            this.setState({
              error
            });
          }
        )
        .then(
          () => this.setState({
            loading: false
          })
        );
    }
  }

  markAsRead(){
    const { updateMessage, updateInbox, messages_unread_count } = this.props;
    const { message={} } = this.state;
    const messageId = getMessageIdentifier(message);
    if(messageId && !message.was_read && !this.hasMarkedAsRead){
      this.hasMarkedAsRead = true;
      updateMessage(
        messageId,
        {
          was_read: true
        }
      );
      updateInbox({
        messages_unread_count: messages_unread_count-1
      });
    }
  }

  componentDidUpdate(){
    this.markAsRead();
    this.resizeActionsBar();
  }

  componentDidMount(){
    super.componentDidMount();
    this.markAsRead();
    this.resizeActionsBar();
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeActionsBar(){
    /*
      the actions bar must be fixed to the bottom of the viewport for mobile screens
      we can't use absolute positioning with vh units because vh units are basically broken on iOS (they don't account for the browser controls)
      so we have to use fixed positioning, which means we have to dynamically set the left offset (percentages on fixed elements don't really work either)
    */
    if(this.container){
      const actionsBar = this.container.querySelector('.messages-actions-bar');
      if(actionsBar){
        const leftOffset = isSmallScreenWidth() ? window.innerWidth - this.container.offsetWidth : 0;
        actionsBar.setAttribute('style', `left: ${leftOffset}px;`);
      }
    }
  }

  confirmDeleteMessage(message){
    const { deleteMessages, history, routerLocation } = this.props;
    const messageId = getMessageIdentifier(message);
    deleteMessages([messageId]);
    history.replace(routerLocation.pathname.replace(`/${messageId}`, ''));
  }

  render(){
    const { location } = this.props;
    const { message={} } = this.state;

    if(this.isLoading()){
      return (
        <Loader className="fill large" />
      );
    }

    if(!getMessageIdentifier(message)){
      return (
        <div className="messages-container">
          <h3 className="empty-message">Message not found</h3>
        </div>
      );
    }

    return (
      <div
        className="messages-container message-detail"
        ref={c => (this.container = c)}>
        <ActionsBar
          location={location}
          message={message}
          handleDelete={message => this.confirmDeleteMessage(message)} />
        <div className="message-content">
          <div className="message-header">
            <h3 className="title">{getMessageSubject(message)}</h3>
            <span className="date">
              {`${formatMessageTimestamp(message.dt_created, location.timezone)}`}
            </span>
          </div>
          <div className="message-body" dangerouslySetInnerHTML={{__html: getMessageHTML(message)}} />
        </div>
      </div>
    );
  }
}

export default MessageDependentComponentConnector(
  withRouterProps(MessageDetail), 
  { deleteMessages, updateMessage, updateInbox, fetchMessage }
);
