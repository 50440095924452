import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import RenderField from '../../atoms/RenderField';
import CurbCheckbox from '../../atoms/form/checkbox';

const formName = 'UpdatePasswordForm';

const validate = function(values){
  var errors = {};

  if(values.new_password !== values.confirm_new_password){
    errors.confirm_new_password = 'Passwords must match';
  }

  if(values.new_password && values.new_password.length < 8){
    errors.new_password = 'New password must be at least 8 characters';
  }

  if(!values.password){
    errors.password = 'Required';
  }
  if(!values.new_password){
    errors.new_password = 'Required';
  }
  if(!values.confirm_new_password){
    errors.confirm_new_password = 'Required';
  }

  return errors;
};


class UpdatePasswordForm extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  
  onSubmit(e){
    const { handleSubmit, pristine, valid } = this.props;
    e.preventDefault();
    if(!pristine && valid && typeof handleSubmit === 'function'){
      handleSubmit(this);
    }
  }

  render(){
    const { pristine, valid, currentValues } = this.props;
    const { busy } = this.state;
    return (
      <form
        className={`curb-form update-password-form ${busy ? 'busy' : ''}`}
        onSubmit={this.onSubmit.bind(this)}>
        <section className="form-fields">
          <h3 className="form-title center">Use at least 8 characters, including upper and lowercase letters, numbers, and symbols</h3>
          <section className="form-section">
            <h3>Create new password</h3>
            <Field
              name="new_password"
              type={currentValues.show_password ? 'text' : 'password'}
              placeholder="New password"
              component={RenderField} />
            <Field
              name="confirm_new_password"
              type={currentValues.show_password ? 'text' : 'password'}
              placeholder="Confirm new password"
              required={true}
              component={RenderField} />
            <CurbCheckbox
              label="Show password"
              name="show_password" />
          </section>
          <section className="form-section">
            <h3>Authorize with current password</h3>
            <Field
              name="password"
              type={currentValues.show_current_password ? 'text' : 'password'}
              placeholder="Current password"
              required={true}
              component={RenderField} />
            <CurbCheckbox
              label="Show password"
              name="show_current_password" />
          </section>
        </section>
        <div className="form-actions">
          <button
            type="submit"
            disabled={!valid || pristine}
            className={`red cta ${!valid || pristine ? 'disabled' : ''}`}>{busy ? 'Sending...' : 'Confirm'}</button>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector(formName);
export default connect(
  state => {
    const initialValues = {
      password: null,
      new_password: null,
      confirm_new_password: null,
      show_password: false,
      show_current_password: false
    };
    const currentValues = selector(state, ...Object.keys(initialValues));
    return {
      initialValues,
      currentValues
    };
  }
)(
  reduxForm({
    form: formName,
    validate
  })(UpdatePasswordForm)
);
