import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import DeviceControl from './DeviceControl';
import RecentUse from './RecentUse';
import CostBreakdown from './CostBreakdown';
import PowerBand from './PowerBand';
import EnergySaver from './EnergySaver';
import EnergySource from './EnergySource';

import { minWidthHistorical } from '../../constants';

export default class Modules extends Component {

  render(){
    const { location, appConfig={}, user } = this.props;
    const { metadata } = location;
    const { is_cellular, production, battery, load_control_enabled } = metadata;
    const { allow_currencies } = appConfig;     

    return (
     <div className="modules">
      {
        (() => {
          const RecentUseContent = <RecentUse location={location} />;
          // non cellular always gets recent use
          if(!is_cellular){
            return RecentUseContent;
          }
          // cellular gets recent use if it has production/battery (because then the energy source will be showing)
          if(production || battery){
            return RecentUseContent;
          }
          // or if in desktop mode
          return <MediaQuery query={`(min-width: ${minWidthHistorical+1}px), (min-aspect-ratio: 1/1)`}>
            {RecentUseContent}
          </MediaQuery>
        })()
      }
      

      <PowerBand location={location} appConfig={appConfig}/>
      {
        allow_currencies && <CostBreakdown location={location} />
      }
      {
        load_control_enabled && <EnergySaver location={location} />
      }

      { 
        (() => {
          const EnergySourceContent = <EnergySource useLive={true} location={location} />;
          if(!is_cellular){
            // non cellular always get this widget if they have production/battery
            if(production || battery){
              return EnergySourceContent;
            }
            return null;
          }
          else if(production || battery) {
            // cellular gets the widget only in desktop mode
            return <MediaQuery query={`(min-width: ${minWidthHistorical+1}px), (min-aspect-ratio: 1/1)`}>
              {EnergySourceContent}
            </MediaQuery>
          }
        })() 
      }
     </div>

     );

  }

}
