export const UPDATE_LIVE = 'UPDATE_LIVE';
export const UPDATE_BASELINE = 'UPDATE_BASELINE';
export const UPDATE_RADIAL_POSITION = 'UPDATE_RADIAL_POSITION';
export const UPDATE_LIVE_SNAPSHOT = 'UPDATE_LIVE_SNAPSHOT';

export function updateLive({locationId, circuits}){
  return {
    type: UPDATE_LIVE,
    locationId,
    circuits
  };
}

export function updateBaseline({locationId, baseline}){
  return {
    type: UPDATE_BASELINE,
    locationId,
    baseline
  };
}

export function updateLiveSnapshot(snapshot){
  return {
    type: UPDATE_LIVE_SNAPSHOT,
    snapshot
  };
}
