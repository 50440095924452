import React, { Component } from 'react';
import { Collapse } from 'react-collapse';

export default class CollapseGroup extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpened: !!props.isOpened
    };
    
  }
  componentDidMount(){
    this.onClickHeader = this.onClickHeader.bind(this);
  }

  onClickHeader(e){
    e.preventDefault();
    this.setState({
      isOpened: !this.state.isOpened
    });
  }

  getDefaultCollapseProps(){
    // return {
    //   onRest: () => {
    //     if(!this.state.isOpened)
    //   }
    // }
  }

  render(){
    const { isOpened } = this.state;
    var { collapseProps={}, children, title } = this.props;
    var className = this.props.className;
    if(!Array.isArray(className)){
      className = [className];
    }
    className.push('collapse-group');
    if(isOpened){
      className.push('open');
    }

    collapseProps = {
      ...collapseProps,
      isOpened
    };

    return (
      <section className={ className.join(' ') }>
        <div className="section-header" onClick={this.onClickHeader}>
          <h3>{title}</h3>
          <i />
        </div>
        <Collapse {...collapseProps}>
            {children}
        </Collapse>
      </section>
    );
  }
}
