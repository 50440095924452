import React, { Component } from 'react';
import FormContent from '../../atoms/form/content';
import CurbFormWrapper from '../../atoms/form';
import CurbInput from '../../atoms/form/input';
import CurbPhoneInput from '../../atoms/form/phone-input';
import CurbSelect from '../../atoms/form/select';
import CurbRadioGroup from '../../atoms/form/radio-group';

import { required, validEmail, validPhone } from '../../lib/validators';

import actions from '../../actions';
import { dispatch } from '../../store';

const { receiveUserDetail, upsertUserDetail } = actions;

// TODO, replace this with cobranding
const providers = [
  {
    label: 'ABC Home and Commercial',
    provider: 'abc'
  },
  {
    label: 'Arcadia Solar',
    provider: 'arcadia'
  },
  {
    label: 'Baker Electric Solar',
    provider: 'baker'
  },
  {
    label: 'Palmetto Solar',
    provider: 'palmetto'
  }
];

const validateProvider = (value, currentValues) => {
  const { provider_manual, user_type } = currentValues;
  if(user_type === 'installer' && !value && !provider_manual){
    return 'Installers must enter their provider';
  }
  return undefined;
};

const validatePhone = (value, currentValues) => {
  return (value || currentValues.user_type === 'installer')
    ? validPhone(value || '')
    : undefined;
};

class UserDetailFormContent extends FormContent {

  static formKeys = [
    'name',
    'email_address',
    'phone_number',
    'phone_number__country',
    'user_type',
    'provider',
    'provider_manual',
    'default_location'
  ]

  render(){
    const { currentValues, hideEmail, autoSave, locationsFlat, valid, isSubmitting } = this.props;

    return (
      <section className="wrapper">
        <CurbInput
          label="Name"
          name="name"
          autoComplete="off"
          validate={[required]}
          placeholder="First and last name" />
        {
          !hideEmail && <CurbInput
            type="email"
            name="email_address"
            label="Email address"
            validate={[required, validEmail]}
            placeholder="name@example.com" />
        }
        <CurbPhoneInput
          country="US"
          name="phone_number"
          validate={[validatePhone]}
          label="Phone number" />
        <CurbRadioGroup
          name="user_type"
          label="User type"
          className="radio-block"
          options={[
            {
              value: 'homeowner',
              label: 'Homeowner'
            },
            {
              value: 'installer',
              label: 'Installer'
            }
          ]} />
        {
          currentValues.user_type === 'installer'
            ? (
              <div className="installer-options curb-group">
                <CurbSelect
                  name="provider"
                  placeholder="Select your company"
                  disabled={!!currentValues.provider_manual}
                  validate={[validateProvider]}
                  label="Select company">
                  {
                    providers.map(
                      (p, i) => (<option key={i} value={p.provider}>{p.label}</option>)
                    )
                  }
                </CurbSelect>
                <CurbInput
                  name="provider_manual"
                  label="Company not found? Enter here.  Otherwise leave empty" />
              </div>
            )
            : (locationsFlat && locationsFlat.length > 1)
              ? (
                <div className="homeowner-options curb-group">
                  <CurbSelect
                    name="default_location"
                    label="Default location">
                    {
                      locationsFlat.map(
                        (loc, i) => (<option value={loc.id} key={i}>{loc.label}</option>)
                      )
                    }
                  </CurbSelect>
                </div>
              )
              : null
        }
        {
          !autoSave && (
            <div className="curb-form-element">
              <button
                type="submit"
                className="button red"
                disabled={!valid}>
                {
                  isSubmitting
                    ? 'Submitting...'
                    : 'Submit'
                }
              </button>
            </div>
          )
        }
      </section>
    );
  }
}

export default class UserDetailForm extends Component {
  onSave(values){
    const { onSuccess, userDetail={} } = this.props;
    const { user_id } = userDetail;
    this.setState({ status: null });
    values = {
      user_id,
      ...values,
      provider: values.user_type === 'homeowner'
        ? 'curb'
        : values.provider_manual
          ? values.provider_manual
          : values.provider
    };


    return new Promise(
      (resolve, reject) => {
        dispatch(
          upsertUserDetail(
            {
              userDetail: values,
              resolve,
              reject
            }
          )
        );
      }
    )
    .then(
      data => {
        this.setState({
          status: {
            message: 'Successfully updated',
            type: 'success'
          }
        });

        // save to redux
        dispatch(
          receiveUserDetail(data)
        );

        // call onSuccess handler if defined
        if(typeof onSuccess === 'function'){
          onSuccess(data);
        }
      }
    )
    .catch(
      err => {
        this.setState({
          status: {
            message: err.message,
            type: 'error'
          }
        });
      }
    );
  }
  UNSAFE_componentWillMount(){
    this.onSave = this.onSave.bind(this);
  }
  render(){
    const { userDetail={}, user={} } = this.props;
    const { provider } = userDetail;

    const matchingProvider = providers.find(p => p.provider === provider);

    const initialValues = {
      provider: matchingProvider
        ? provider
        : null,
      provider_manual: matchingProvider
        ? null
        : provider,
      user_type: 'homeowner',
      ...userDetail,
      email_address: user.email
    };

    return (
      <CurbFormWrapper
        skipLocalCache={true}
        {...this.props}
        {...this.state}
        formKeys={UserDetailFormContent.formKeys || []}
        reduxFormOptions={{
          ...(UserDetailFormContent.reduxFormOptions || {}),
          initialValues
        }}
        id="user-detail-form"
        onSubmit={this.onSave}
        FormContent={UserDetailFormContent} />
    );
  }
}
