import React, { Component } from 'react';
import { Field } from 'redux-form';
import debounce from 'lodash.debounce';

const triggerSubmit = debounce(
  form => form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: false})),
  1000
);

export class BaseInput extends Component {
  render(){
    const {
      input,
      type,
      autoSave,
      autoFocus,
      autoComplete,
      autoCapitalize,
      onInput,
      disabled,
      placeholder,
      readOnly,
      id,
      step,
      pattern,
      maxLength,
      meta: { error, touched }
    } = this.props;

    const standardProps = {
      disabled,
      step,
      pattern,
      maxLength,
      id,
      autoComplete,
      autoCapitalize,
      autoFocus
    };

    var className = ['input-wrap'];

    if(touched && error){
      className.push('error');
    }

    return (
      <div className={className.join(' ')}>
        <input
          {...input}
          {...standardProps}
          type={type}
          readOnly={disabled || readOnly}
          placeholder={placeholder}
          onChange={
            e => {
              input.onChange(e);
              e.persist();
              window.requestAnimationFrame(
                () => {
                  if(typeof onInput === 'function'){
                    onInput(this.props.input.value, this.props.meta);
                  }
                  if(autoSave){
                    triggerSubmit(e.target.form);
                  }
                }
              );
            }
          } />
        {touched && error && <span className="error">{error}</span>}
      </div>
    );
  }
}

export default class CurbInput extends Component {
  render(){
    const { name, label, type='text', id=this.props.name, component=BaseInput } = this.props;
    var { className } = this.props;

    if(!className){
      className = `input-${name}`;
    }
    if(!Array.isArray(className)){
      className = [className];
    }
    className = className.concat(['curb-input', 'curb-form-element']);
    return (
      <div className={className.join(' ')}>
        {
          label
            ? <label htmlFor={id}>{label}</label>
            : null
        }
        <Field
          parse={
            value => type === 'number' && value.toString().length
              ? Number(value)
              : value
          }
          id={id}
          type={type}
          {...this.props}
          component={component} />
      </div>
    );
  }
}
