import actionTypes from '../actions';

export default (state={}, action) => {
  const { type, tariff={} } = action;
  switch(type){
    case actionTypes.RECEIVE_TARIFF:
      return tariff || state;
    case actionTypes.UPDATE_TARIFF:
      return {
        ...state,
        ...tariff
      };
    default:
      return state;
  }
};
