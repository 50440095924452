import React, { Component } from 'react';
import eventEmitter from '../../../event-emitter';
import FleetSearch from '../../Fleet/Search';

export default class LocationSelect extends Component {
  onChangeLocationDropdown(){
    return e => {
      const newLocationId = e.target.value;
      window.CURB.locationId = newLocationId;
      const { locationsFlat } = this.props;
      eventEmitter.emit(
        'receiveLocation',
        locationsFlat.find(l => l.id === newLocationId)
      );
    };
  }
  locationDropdown(){
    const { user={}, location={}, locationsFlat=[] } = this.props;

    const locationsList = user.isDemoInstaller
      ? [location]
      : locationsFlat;

    return (
      <div className="header-location">
        <div className="location-select-wrapper">
          <span className="single-location"> { !user.isDemoGuest ? location.label : 'Demo Home' } </span>
          {
            user.fleet && locationsList
              ? null
              : (locationsList.length > 1)
                ? (
                  <div className="multi-location-selector">
                    <span className="icon-down-arrow">
                      <i />
                    </span>
                    <select defaultValue={location.id} onChange={this.onChangeLocationDropdown()}>
                      {
                        locationsList.map(
                          loc => (
                            <option key={loc.id} value={loc.id}>
                              {loc.label}
                            </option>
                          )
                        )
                      }
                    </select>
                  </div>
                )
                : null
          }
        </div>
      </div>
    );
  }

  render(){
    const { location={}, user={} } = this.props;
    return (
      <div className="provider-location-group">
        {this.props.children}
        {!!location.id && this.locationDropdown()}
        {(!!user.fleet && !user.isDemoInstaller) && <FleetSearch />}
      </div>
    );
  }
}
