var CURB = window.CURB || {};

const CONFIG = {
  staging: {
    AUTH_CLIENT_ID: 'SvDncInJRgZ3w49HD5CdUKitPKsz4n1w',
    AUTH_DOMAIN: 'auth.staging.energycurb.com',
    AUTH_NONCE: 'b306953c38303bd70d960b90caa271ef',
    CONFIG_APP_URL: 'https://connect.staging.energycurb.com',
    SOCKET_HOST: 'https://app.staging.energycurb.com',
    DEVICE_CONTROL: {
      HOST_AND_STAGE: 'https://gajtbdvc60.execute-api.us-west-2.amazonaws.com/main'
    }
  },
  production: {
    AUTH_CLIENT_ID: 'JGTqSXy7aIYiInC4xXcbNCHASOOcFKQS',
    AUTH_DOMAIN: 'auth.energycurb.com',
    AUTH_NONCE: '032d0884922d3fc2bf4dbce6f39ff3c9',
    CONFIG_APP_URL: 'https://connect.prod.energycurb.com',
    SOCKET_HOST: 'https://app.prod.energycurb.com',
    DEVICE_CONTROL: {
      HOST_AND_STAGE: 'https://o3cbz0462f.execute-api.us-west-2.amazonaws.com/main'
    }
  }
};

var envConfig = {
  SOCKET_HOST: window.location.origin
};

// if using file protocol, assume production scenario
if(window.location.protocol === 'file:'){
  envConfig = {
    ...CONFIG[CURB.env.CURB_ENV || 'production']
  };
} else {
  switch(window.location.hostname){
    case 'app.energycurb.com':
    case 'app.prod.energycurb.com':
      envConfig = {
        ...envConfig,
        ...CONFIG.production
      };
      break;
    case 'app.staging.energycurb.com':
      envConfig = {
        ...envConfig,
        ...CONFIG.staging
      };
      break;
    default:
      envConfig = {
        ...envConfig,
        ...CONFIG.staging
      };
      envConfig.local = true;
      envConfig.SOCKET_HOST = 'https://app.energycurb.com';
      // let environment variables at build time override url, but only for local scenario
      if(process.env.CURB_ENV && CONFIG.hasOwnProperty(process.env.CURB_ENV)){
        Object.assign(envConfig, CONFIG[process.env.CURB_ENV]);
      }
      // when developing against a local backend
      if(process.env.LOCAL_BACKEND){
        Object.assign(envConfig, {
          SOCKET_HOST: `http://${window.location.hostname}:3000`,
          CONFIG_APP_URL: `http://${window.location.hostname}:5555`
        });
      }
  }
}

export default {
  STATIC_PATH: `${window.location.origin}/static`,
  ...envConfig,
  ...CURB.env
};
