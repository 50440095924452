import React, { useEffect } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import CurbInput from 'shared/dist/components/atoms/form/input.jsx';
import LoadingSpinner from 'shared/dist/components/atoms/loading-spinner';
import PrintError from 'shared/dist/components/atoms/print-error';
import Address from 'shared/dist/components/atoms/address';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withRouterProps from '../../../components/base/WithRouterProps';
import VirtualLoadControl from '../../../data-containers/virtual-load-control';
import { hubSerial as hubSerialValidator } from '../../../lib/validators';
import debounce from 'lodash.debounce';

const SEARCH_FIELD_NAME = 'q';

const validateHubSerial = v => {
  if(!v){
    return;
  }
  return hubSerialValidator(v);
};

function VirtualLoadControlSearchForm({match, routerLocation, history}){
  const { 
    hubSerial,
    clear,
    loadingInstallation,
    error,
    location
   } = VirtualLoadControl.useContainer();

  const onSearchSubmit = values => {
    // console.log(values);
    // search for
  }

  useEffect(
    () => {
      if(!hubSerial){
        clear();
      }
    },
    []
  );

  const onChange = debounce(
    ({valid, values}) => {
      const { q } = values;
      if(!q){        
        history.replace('/virtual-load-control/');
        clear();
      }
      else if(valid){
        history.replace(`/virtual-load-control/${q.toLowerCase()}`);
      }
    },
    100
  );

  return <Form
    onSubmit={onSearchSubmit}
    initialValues={{
      [SEARCH_FIELD_NAME]: hubSerial
    }}
    render={
      (renderProps) => {
        const { handleSubmit, form, values, valid } = renderProps;
        const q = values[SEARCH_FIELD_NAME];

        const reset = () => {
          form.reset({
            [SEARCH_FIELD_NAME]: ''
          });
        };

        return <form 
          onSubmit={handleSubmit}
          className="curb-form virtual-load-control-search-form">
            <section className="narrow">
              <div className="search-input-wrap">
                <FontAwesomeIcon
                  className="icon search-icon"
                  htmlFor={SEARCH_FIELD_NAME}
                  icon="search" />
                <Field
                  autoFocus={true}
                  name={SEARCH_FIELD_NAME}
                  type="text"
                  id="search-input"
                  label="Search by CURB serial number"
                  className="search"
                  placeholder="Serial Number"
                  autoComplete="off"
                  render={CurbInput}
                  validate={validateHubSerial} />
                  { values[SEARCH_FIELD_NAME] && (
                  <FontAwesomeIcon
                    icon="times-circle"
                    className="icon search-clear-icon"
                    onClick={reset} />
                )}
              </div>
            </section>

            <section>
              {
                loadingInstallation && <LoadingSpinner />
              }

              { 
                error && <div className="status-message error">
                  <PrintError error={error} />
                </div> 
              }

              {
                location && <div className="location">
                  <div className="info">A location has been found for this serial number.</div>
                  <Address {...location} />
                </div>
              }
            </section>


            <section>
              <div className="curb-form-element curb-submit">
                <button type="button" className="passive" onClick={reset}>Cancel</button>
                <button type="button" disabled={!location} onClick={
                  () => {
                    history.push(`/virtual-load-control/${hubSerial}/configure`);
                  }
                }>Configure</button>
              </div>
            </section>

            {/* provides onChange listener */}
            <FormSpy
              subscription={{
                values: true,
                valid: true
              }}
              onChange={onChange} />
          </form>;
      }
    }
  />
}

export default withRouterProps(VirtualLoadControlSearchForm);