import { dispatch } from '../../store';
import { updateMultipleRegisters } from '../../actions/registers';

export function activateRegister(registerId, locationId){
  const graphRegisters = this.filterForEnergyType(this.registers);
  const currentlyActiveRegisters = graphRegisters.filter(c => !c.inactive);
  const allOtherRegisters = graphRegisters
    .filter(
      r => r.id !== registerId
    )
    .map(
      r => {
        return {
          ...r,
          // if we have only one register, activate everything, otherwise deactivate everything
          inactive: currentlyActiveRegisters.length !== 1
        };
      }
    );

  dispatch(
    updateMultipleRegisters(
      locationId,
      allOtherRegisters
    )
  );
}
