import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function IconLink(props){
  const { className, children, icon='plus' } = props;
  const classes = ['icon-link'];
  if(className){
    classes.push(className);
  }
  return <a {...props} className={classes.join(' ')}>
    <span className="round-icon">
      <FontAwesomeIcon icon={icon} />
    </span>
    {children}
  </a>;
};