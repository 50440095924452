import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';

const { removeAlert, clearAllAlerts } = actions;

class Alerts extends Component {
  onclickCloseAlert(id){
    return e => {
      e.preventDefault();
      this.props.removeAlert(id);
    };
  }

  renderAlert(alert, i){
    return (
      <li key={i} className={`alert ${alert.category}`}>
        <span className="title" dangerouslySetInnerHTML={{__html: alert.title}} />
        <div className="content" dangerouslySetInnerHTML={{__html: alert.content}} />
        { !alert.hideCloseLink && <a href="#" className="close" onClick={this.onClickClose(alert.id)}><i /></a> }
      </li>
    );
  }

  onClickClose(id){
    return (e) => {
      e.preventDefault();
      this.props.removeAlert(id);
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.alerts.length && !nextProps.alerts.length){
      if(typeof this.props.onClearAllAlerts === 'function'){
        this.props.onClearAllAlerts();
      }
    }
  }

  componentWillUnmount(){
    this.props.clearAllAlerts();
  }

  render(){
    const { alerts } = this.props;
    if(!alerts || !alerts.length) {
      return null;
    }

    return (
      <ul className="alerts-list">
        { alerts.map((alert, i) => this.renderAlert(alert, i)) }
      </ul>
    );
  }
}

export default connect(
  state => {
    const { alerts } = state;
    return {
      alerts
    };
  },
  {
    removeAlert,
    clearAllAlerts
  }
)(Alerts);
