import React, { useState, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Switch, Route } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'shared/dist/components/atoms/loading-spinner';
import PrintError from 'shared/dist/components/atoms/print-error';
import withRouterProps from '../../../../components/base/WithRouterProps';
import VirtualLoadControl from '../../../../data-containers/virtual-load-control';
import TimingMetrics from './timing-metrics';
import Priorities from './priorities/index';
import { HOLIDAYS, MONTHS, DAYS, defaultSettings, transformFormValues, parseLoadControlSettingsForForm } from './utils';

function FormWrapper(props){
  const { match, history, routerLocation } = props;
  const { 
    settings,
    persistFormValues,
    getStoredFormValues,
    setFormValues,
    version
   } = VirtualLoadControl.useContainer();
  const [ initialValues, setInitialValues ] = useState();

  useEffect(
    () => {
      setInitialValues({
        ...parseLoadControlSettingsForForm(
          {
            ...defaultSettings,
            ...settings
          },
          {
            version
          }
        ),
        ...getStoredFormValues()
      });
    },
    [settings]
  );

  const onSubmit = values => {
    // do something on submit, or not
  }

  const onChange = debounce(
    ({values, pristine}) => {
      if(!pristine){
        persistFormValues(values);
      }
    },
    1000
  );  
  
  return <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    mutators={{
      ...arrayMutators
    }}    
    render={
      (formRenderProps) => {
        const { form, handleSubmit } = formRenderProps;
        return <form 
          onSubmit={handleSubmit}
          className="curb-form virtual-load-control-config-form">

          {
            getStoredFormValues() && <div className="status-inline error unsaved-changes-message">
              <FontAwesomeIcon icon="exclamation-circle" />&nbsp; 
              <span>There are unsaved changes for this hub</span>
            </div>
          }  

          <Switch>
            <Route path={match.path} exact={true} render={() => history.replace(`${routerLocation.pathname.replace(/\/$/, '')}/timing-metrics`) } />
            <Route path={`${match.path}/timing-metrics`} render={(routeRenderProps) => <TimingMetrics {...props} {...routeRenderProps} formRenderProps={formRenderProps} />} />
            <Route path={`${match.path}/priorities`} render={(routeRenderProps) => <Priorities {...props} {...routeRenderProps} formRenderProps={formRenderProps} />} />
          </Switch>
          {/* onchange listener to save form values to session */}
          <FormSpy
            subscription={{
              values: true,
              pristine: true
            }}
            onChange={onChange} />          
        </form>
      }
    } />
}

export default withRouterProps(
  function(props){
    const { 
      loadingInstallation,
      error
    } = VirtualLoadControl.useContainer();
     
    if(loadingInstallation){
      return <LoadingSpinner />;
    }
    if(error){
      return <div className="status-message error">
        <PrintError error={error} />
      </div>;
    }

    return <FormWrapper {...props} />;
  }
);