import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import eventEmitter from '../../event-emitter';
import { searchLocations, fetchLocationMetadata } from '../../lib/api';
import actions from '../../actions';
import {analyticsDevice} from '../../lib/analytics';

const { receiveAllLocations, updateLocation } = actions;

class FleetSearch extends Component {

  static defaultProps = {
    location: {}
  }

  constructor(props){
    super(props);
    this.state = {
      locationQuery: '',
      queriedSearchTerm: '',
      matchingLocations: [],
      loading: false,
      open: false,
      hideResults: false
    };

    this.throttledFetchLocations = debounce(
      this.fetchLocations.bind(this),
      500
    );

  }

  onBlurLocationSearch(){
    setTimeout(
      () => this.setState({ matchingLocations: [], hideResults: true }),
      300
    );
  }

  fetchLocations(){
    const { searchTerm } = this.state;
    this.setState({
      loading: true,
      queriedSearchTerm: searchTerm
    });
    searchLocations({
      searchTerm: searchTerm,
      matchAllWords: true
    })
    .then(
      matchingLocations => {
        if(searchTerm === this.state.searchTerm){
          this.setState({
            matchingLocations
          });
        }
      }
    )
    .catch(
      err => {
        if(searchTerm === this.state.searchTerm){
          console.error(err);
          this.setState({
            matchingLocations: [],
            searchTerm: null,
            nextPage: null
          });
        }
      }
    )
    .then(
      () => {
        if(searchTerm === this.state.searchTerm || !searchTerm){
          this.setState({
            loading: false
          });
        }
      }
    );
  }

  onFocusLocationSearch(){
    this.setState({
      hideResults: false
    });
    if(this.state.searchTerm){
      this.throttledFetchLocations();
    }
  }

  onInputLocationSearch(e){
    e.persist();
    this.setState({
      hideResults: false
    });
    if(e.target.value && e.target.value.length){
      this.setState({
        searchTerm: e.target.value
      });
    }
    else if(!e.target.value || !e.target.value.length){
      this.setState({
        loading: false,
        searchTerm: '',
        queriedSearchTerm: '',
        matchingLocations: []
      });
    }
  }

  onClickIcon(){
    this.setState({
      open: !this.state.open
    });
    setTimeout(
      () => {
        this.container.querySelector('input').focus();
      },
      0
    );
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.searchTerm && this.state.searchTerm.length >= 3 && this.state.searchTerm !== prevState.searchTerm){
      this.throttledFetchLocations();
    }
  }

  getOnClickLocationLinkHandler(location){
    const { receiveAllLocations } = this.props;
    return e => {
      e.stopPropagation();

      this.setState({
        matchingLocations: []
      });

      receiveAllLocations({
        [location.id]: location
      });
      
      window.ga && window.ga('send', 'event', 'locationLinkHandler-search-' + analyticsDevice(), 'clicked',window.CURB.provider);
      eventEmitter.emit(
        'receiveLocation',
        location
      );

      fetchLocationMetadata(location.id)
        .then(
          metadata => {
            // const locationWithMetadata = {
            //   metadata,
            //   ...location
            // };

            updateLocation(
              location.id,
              {
                metadata,
                ...location
              }
            );
          }
        );
    };
  }

  render(){
    const { matchingLocations, loading, open, queriedSearchTerm, hideResults } = this.state;

    return (
      <div className="location-search-autocomplete" ref={c => (this.container = c)}>
        <i className={open ? 'icon-close' : 'icon-search'} onClick={() => this.onClickIcon()} />
        <div className={`location-search-content ${open ? 'open' : 'hidden'}`}>
          <div className="input-wrap">
            <input
              type="text"
              className="autocomplete"
              placeholder="Search for a location..."
              onBlur={() => this.onBlurLocationSearch()}
              onFocus={() => this.onFocusLocationSearch()}
              onInput={e => this.onInputLocationSearch(e)} />
            <span className="input-icon" />
          </div>
          <ul className={`locations-autocomplete ${hideResults ? 'hidden' : ''}`}>
            {
              loading
                ? (
                  <li className="loading"><span className="loader-inline blue" /></li>
                )
                : queriedSearchTerm && !matchingLocations.length
                  ? (
                    <li className="empty">
                      No results found for "{queriedSearchTerm}"
                    </li>
                  )
                  : null
            }
            {
              matchingLocations.map(
                (loc, i) => (
                  <li key={i} onClick={this.getOnClickLocationLinkHandler(loc)} className={`${location.id === loc.id ? 'active' : ''}`}>{loc.label}</li>
                )
              )
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    receiveAllLocations,
    updateLocation
  }
)(FleetSearch);
