import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouterProps from '../base/WithRouterProps';
import Header from '../../atoms/Header';
import Alerts from '../../atoms/Alerts';
import actions from '../../actions';
import UpdateProfileForm from '../User/UpdateProfileForm';

const { updateUser, addAlert, clearAllAlerts } = actions;

class UpdateProfileMenu extends Component {
  handleSubmit(form){
    const { updateUser, addAlert, clearAllAlerts } = this.props;
    const { currentValues } = form.props;
    form.setState({
      busy: true
    });
    clearAllAlerts();
    return new Promise(
      (resolve, reject) => updateUser({
        user: {
          password: currentValues.password,
          email: currentValues.email
        },
        resolve,
        reject
      })
    )
    .then(
      () => {
        addAlert({
          category: 'success',
          title: 'Success',
          content: `Your email address has been changed to <strong>${currentValues.email}</strong>`
        });
      }
    )
    .catch(
      () => null
    )
    .then(
      () => form.setState({
        busy: false
      })
    );
  }
  render(){
    const { history } = this.props;
    return <div className="full-height menu-section-dark">
      <Header clickTo={history.goBack} title="Update Email" />
      <div className="menu-content">
        <Alerts />
        <UpdateProfileForm handleSubmit={this.handleSubmit.bind(this)} />
      </div>
    </div>
  }
}

export default connect(
  null,
  {
    addAlert,
    updateUser,
    clearAllAlerts
  }
)(withRouterProps(UpdateProfileMenu));
