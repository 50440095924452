import actionTypes from '../actions';
import { getMessageIdentifier } from '../lib/utils';

const sortByDateDescending = (a, b) => new Date(b.dt_created) - new Date(a.dt_created);

// location
export default (state={ messages: [], messages_count: 0, messages_unread_count: 0 }, action) => {
  const { type, inbox, message, messages, messageId, messageIds } = action;
  switch(type){
    case actionTypes.RECEIVE_INBOX:
      return {
        ...state,
        messages: (messages || state.messages).sort(sortByDateDescending)
      };
    case actionTypes.UPDATE_INBOX:
      return {
        ...state,
        ...inbox,
        messages: state.messages.concat(
          (inbox.messages || []).filter(message => !state.messages.find(m => m.id === message.id))
        ).sort(sortByDateDescending)
      };
    case actionTypes.ADD_MESSAGES:
      return {
        ...state,
        messages: state.messages.concat(
          messages.filter(m => !state.messages.find(m_ => m_.id === m.id))
        )
        .sort(sortByDateDescending)
      };
    case actionTypes.UPDATE_MESSAGE:
      return {
        ...state,
        messages: state.messages.map(
          m => {
            if(getMessageIdentifier(m) === messageId){
              m = {
                ...m,
                ...message
              };
            }
            return m;
          }
        )
      };
    case actionTypes.DELETE_MESSAGES:
      // delete messages and update counts
      const [ messagesToDelete, remainingMessages ] = state.messages.reduce(
        (acc, m) => {
          acc[
            messageIds.find(id => id === m.id)
              ? 0
              : 1
          ].push(m);
          return acc;
        },
        [
          [],
          []
        ]
      );
      return {
        ...state,
        messages_count: Math.max(state.messages_count - messagesToDelete.length, 0),
        messages_unread_count: Math.max(state.messages_unread_count - messagesToDelete.filter(m => !m.was_read).length, 0),
        messages: remainingMessages
      };
    default:
      return state;
  }
};
