import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BasicPage from '../BasicPage';
import UserPageWrapper from '../wrappers/UserPageWrapper';

class NotFoundPage extends Component {
  render(){
    return <UserPageWrapper {...this.props}>
      <BasicPage
        className="not-found">
        <main>
          <h1>Page Not Found</h1>
          <h3>You have landed on a page that does not exist</h3>
          <p>
            <Link className="button" to={'/dash'}>Take me to the dashboard &raquo;</Link>
          </p>
        </main>
      </BasicPage>
    </UserPageWrapper>;
  }
}

export default connect(
  // mapStateToProps
  null,
  // mapDispatchToProps
  {

  }
)(NotFoundPage);
