import React from 'react';
import { withRouter } from 'react-router';

// does exactly what React Router's `withRouter()` does, but renames `location` to `routerLocation`
// to prevent prop name collisions with other props called `location`
export default function withRoutingProps(C){
  const R = withRouter(
    (props) => {
      const { match, location, history } = props;
      const onlyChild = React.Children.toArray(props.children)[0];

      return React.cloneElement(
        onlyChild,
        {
          match,
          history,
          routerLocation: location,
          ...onlyChild.props
        }
      );      
    }
  );

  return (props) => <R>
    <C {...props}>
      {props.children}
    </C>
  </R>;
};