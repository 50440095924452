export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_METADATA = 'UPDATE_LOCATION_METADATA';
export const FETCH_LOCATION_STATE = 'FETCH_LOCATION_STATE';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const RECEIVE_ALL_LOCATIONS = 'RECEIVE_ALL_LOCATIONS';
export const EXPIRE_LOCATION_CONFIG = 'EXPIRE_LOCATION_CONFIG';

export function updateLocation(locationId, location, resolve, reject, local) {
  return {
    type: UPDATE_LOCATION,
    locationId,
    location,
    resolve,
    reject,
    local
  };
}

export function updateLocationMetadata(locationId, metadata) {
  return {
    type: UPDATE_LOCATION_METADATA,
    locationId,
    metadata
  };
}

export function setCurrentLocation(locationId) {
  return {
    type: SET_CURRENT_LOCATION,
    locationId
  };
}

export function receiveAllLocations(allLocations) {
  // location defaults here
  return {
    type: RECEIVE_ALL_LOCATIONS,
    allLocations
  };
}

export function fetchLocationState(locationId, user, resolve, reject){
  return {
    type: FETCH_LOCATION_STATE,
    locationId,
    user,
    resolve,
    reject
  };
}
