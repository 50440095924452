import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import user from './user';
import userDetail from './user-detail';
import billing from './billing';
import tariff from './tariff';
import alerts from './alerts';
import appConfig from './app-config';
import inbox from './inbox';
import locations from './locations';
import registers from './registers';
import live from './live';
import page from './page';

const rootReducer = combineReducers({
  user,
  userDetail,
  billing,
  tariff,
  alerts,
  appConfig,
  inbox,
  locations,
  registers,
  live,
  page,
  form: reduxFormReducer
});

export default rootReducer;
