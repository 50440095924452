import omit from 'lodash.omit';

export const HOLIDAYS = [
  {
    month: 1,
    day: 1,
    label: 'New Year\'s Day (observed)'
  },
  {
    month: 5,
    day: 25,
    label: 'Memorial Day'
  },
  {
    month: 7,
    day: 4,
    label: 'Independence Day (observed)'
  },
  {
    month: 9,
    day: 7,
    label: 'Labor Day'
  },
  {
    month: 11,
    day: 26,
    label: 'Thanksgiving Day'
  },
  {
    month: 12,
    day: 25,
    label: 'Christmas Day (observed)'
  }
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const UTILITY_TEMPLATES = {
  "bandera-tou": {
    enforcement_windows: [
      {
        months: MONTHS.slice(5, 9),
        days: DAYS.slice(0),
        ranges: [
          { start_hour: 14, end_hour: 18 }
        ]
      },
      {
        months: MONTHS.slice(-3).concat(MONTHS.slice(0, 5)),
        days: DAYS.slice(0),
        ranges: [
          { start_hour: 17, end_hour: 19 },
        ]
      }
    ],
    holidays: [0, 1, 2, 3, 4, 5]
  },
  "salt-river-project-e27": {
    enforcement_windows: [
      {
        months: MONTHS.slice(4, 10),
        days: DAYS.slice(1,-1),
        ranges: [
          { start_hour: 14, end_hour: 20 }
        ]
      },
      {
        months: MONTHS.slice(-2).concat(MONTHS.slice(0, 4)),
        days: DAYS.slice(1,-1),
        ranges: [
          { start_hour: 5, end_hour: 9 },
          { start_hour: 17, end_hour: 21 },
        ]
      }
    ],
    holidays: []
  }
};

export const defaultSettings = {
  enforcement_windows: [
    {
      days_of_week: DAYS.slice(1, -1),
      start_hour: 8,
      end_hour: 17
    }
  ],
  monitor_interval_mins: 30,
  relays: []
};

export const NUM_RELAYS = [];

export function transformFormValuesToSettings(formValues, { hubSerial, virtualDevices=[], registers=[], version }={}){
  const { holidays=[], holidaysCustom, priorities, port_circuit_mappings } = formValues;
  let enforcement_window_exceptions;

  enforcement_window_exceptions = HOLIDAYS.filter(
    (_v, i) => holidays.indexOf(i) === -1
  )
  .concat(
    holidaysCustom.filter(({unpaused, date}) => date && !unpaused)
      .map(
        ({date}) => {
          const { month, day } = date;
          // load control expects non-zero-indexed form months
          return {
            month: month + 1,
            day
          };
        }
      )
  );  

  // split out enforcement windows
  formValues.enforcement_windows = formValues.enforcement_windows.reduce(
    (acc, ew) => {
      const { ranges=[] } = ew;
      acc = acc.concat(
        ranges.map(
          r => {
            return {
              ...omit(ew, 'ranges'),
              ...r
            };
          }
        )
      );
      return acc;
    },
    []
  );

  // transform `priorities` to `relays` object, looking up ports on `port_circuit_mappings` or virtualDevices
  const relays = priorities.map(
    ({platform, device, backupPlatform, backupDevice, label}, i) => {
      let priority = i+1;
      let port;
      let backup_port;
      let circuit;

      if(device){
        if(platform === 'wattwise'){
          let matchingRelay = port_circuit_mappings.find(pcm => pcm.circuit === device);
          if(matchingRelay){
            port = matchingRelay.port;
            circuit = device;
          }
        }
        else if(platform){
          let matchingVirtualDevice = virtualDevices.find(vd => vd.id === device);
          if(matchingVirtualDevice){
            port = matchingVirtualDevice.port;          
          }
        }  
      }

      if(backupDevice){
        if(backupPlatform === 'wattwise'){
          let matchingRelay = port_circuit_mappings.find(pcm => pcm.circuit === backupDevice);
          if(matchingRelay){
            backup_port = matchingRelay.port;
            circuit = matchingRelay.circuit;
          }
        }
        else if(backupPlatform){
          let matchingVirtualDevice = virtualDevices.find(vd => vd.id === backupDevice);
          if(matchingVirtualDevice){
            backup_port = matchingVirtualDevice.port;
          }
        }
      }

      return{
        priority,
        port,
        backup_port,
        circuit,
        label
      };
    }
  );

  // all other `port_circuit_mappings` entries that are not part of a priority should get a relay object with priority 0
  port_circuit_mappings.forEach(
    (pcm, i) => {
      if(!relays.find(r => r.port === pcm.port || r.backup_port === pcm.port)){
        relays.push({
          ...pcm,
          port: i,
          priority: 0
        })
      }
    }
  );
  
  // load_circuits
  let load_circuits = registers.filter(r => r.grid);
  if(!load_circuits.length){
    load_circuits = registers;
  }

  return {
    // strip out UI-generated values
    ...omit(formValues, 'holidays', 'holidaysCustom', 'unpausedHolidays', 'port_circuit_mappings', 'priorities', 'template'),
    installation: hubSerial,
    is_enabled: true,
    enforcement_window_exceptions,
    relays,
    load_circuits: load_circuits
      .map(
        ({group, channel}) => {
          return {
            group,
            channel
          };
        }
      ),      
    // these are required keys on the load control config
    monitor_average_mins: 0,
    control_maximum_off_mins: 0

  };

}

export function parseLoadControlSettingsForForm(settings={}, { version }={}){
  let { enforcement_window_exceptions, enforcement_windows } = settings;

  // use default holidays if not defined
  if(!enforcement_window_exceptions){
    settings.enforcement_window_exceptions = HOLIDAYS;
  }

  // derive `holidays` and `holidaysCustom` from `enforcement_window_exceptions`
  settings.holidays = HOLIDAYS.reduce(
    (acc, holiday, i) => {
      if(!settings.enforcement_window_exceptions.find(({month, day}) => holiday.month === month && holiday.day === day)){
        acc.push(i);
      }
      return acc;
    },
    []
  );

  settings.holidaysCustom = settings.enforcement_window_exceptions.reduce(
    (acc, v, i) => {
      let { month, day } = v;
      // settings are non-zero-indexed, subtract one to make them zero indexed to work in the UI
      if(!HOLIDAYS.find(holiday => holiday.month === month && holiday.day === day)){
        acc.push({
          date: {
            month: month-1,
            day
          }
        });
      }
      return acc;
    },
    []
  );

  settings.enforcement_windows = enforcement_windows.reduce(
    (acc, v) => {
      let { days_of_week, days, months=MONTHS, start_hour, end_hour } = v;
      if(days_of_week && !days){
        days = days_of_week;
      }

      let ranges = [{start_hour, end_hour}];

      // merge enforcement windows that have the same months and days
      const matching = acc.find(
        existing => {
          return existing.days.join('') === days.join('') && existing.months.join('') === months.join('')
        }
      );

      if(matching){
        matching.ranges = matching.ranges.concat(ranges);
      }
      else {
        acc.push({
          ...v,
          days,
          months,
          ranges
        });
      }

      return acc;
    },
    []
  );


  // sort relays by port
  settings.relays = settings.relays
    .sort(
      (a, b) => a.priority - b.priority
    );

  // create an array of objects to pair port numbers to circuits
  settings.port_circuit_mappings = [];
  for(let i=0; i<5; i++){
    let port = i;
    let { circuit } = settings.relays.find(r => r.port === port || r.backup_port === port) || {};
    settings.port_circuit_mappings.push({
      port,
      circuit
    });
  }

  // for each relay with a priority > 0, create an empty object. ports and devices will be assigned when they are loaded, in <PrioritiesList>
  settings.priorities = settings.priorities || [];
  settings.relays.forEach(
    ({priority}, i) => {
      if(priority > 0){
        settings.priorities.push({})
      }
    }
  )
  // also ensure that at least one priority exists
  if(settings.priorities.length === 0){
    settings.priorities.push({});
  }
  
  return settings;
}