import React from 'react';
import d3 from 'shared/dist/lib/d3';
import StackedAreaChart from 'shared/dist/ui/viz/stacked-area';
import RegistersChart from 'shared/dist/ui/viz/registers-chart';
import mergePrototypes from 'shared/dist/utils/merge-prototypes';
import arrayUtils from 'shared/dist/utils/array';
import { parseTimeString } from 'shared/dist/utils/historical';
import { isDollar } from '../../lib/unit-converter';
import { formatDate, formatTime, getCurrencyFormatter, formatWatts, formatPercentage } from '../../lib/formatters';
import { convertWatts } from '../../lib/unit-converter';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';
import { GRAPH_CONTIGUITY_UNITS } from '../../constants';
import { activateRegister } from './utils';

class RegistersStackedAreaChart extends StackedAreaChart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      simple_kwh_price: 0.1,
      contiguityThreshold: Math.Infinity
    };
  }

  initDOM(){
    super.initDOM();

    this.interactionLine = this.pathGroup
      .append('line')
      .attr('class', 'interaction-line');

    this.pathGroup
      .on(
        'mousemove touchmove',
        (d, i, nodeList) => {
          this.focusChart(nodeList[i]);
          if(d3.event.type === 'touchmove' && d3.event.srcElement.tagName.toLowerCase() !== 'rect'){
            d3.event.preventDefault();
          }
        },
        {
          passive: true
        }
      )
      .on(
        'mouseover touchstart',
        (d, i, nodeList) => {
          this.interactionLine
            .classed('active', true);
          this.focusChart(nodeList[i]);
        },
        {
          passive: true
        }
      )
      .on(
        'mouseout touchend touchcancel',
        () => {
          this.interactionLine
            .classed('active', false);
          clearTooltip();
        }
      );

    this.zeroLine = this.g.append('line')
      .attr('class', 'zero');

  }

  valueAccessor(v){
    return v.w || 0;
  }

  focusChart(el){
    const mouse = d3.mouse(el);
    const { height } = this.dimensions;
    const nearestTimegroup = this.rolloverPoints[mouse[0]] || this.getPointForX(mouse[0]);
    const nearestX = this.x(nearestTimegroup[0]);
    this.focusedTimeGroup = nearestTimegroup;

    this.interactionLine
      .attr('y1', 0)
      .attr('y2', height)
      .attr('x1', nearestX)
      .attr('x2', nearestX);
  }

  generateRolloverPoints(){
    const { width } = this.dimensions;
    this.rolloverPoints = [];
    for(var i=width; i>=0; i--){
      this.rolloverPoints[i] = this.getPointForX(i);
    }
  }

  getPointForX(x){
    return arrayUtils.getNearestValue(
      [this.x.invert(x)],
      this.dataGroupedByTimestamp,
      {
        accessor: v => v[0].getTime()
      }
    );
  }

  updateParams(params){
    super.updateParams(params);
    const { unit, simple_kwh_price, resolution, currency } = this.params;

    if(!this.params.tickFormats){
      this.params.tickFormats = {};
    }

    try {
      this.params.contiguityThreshold = parseTimeString(resolution).seconds * 1000 * GRAPH_CONTIGUITY_UNITS;
    } catch(err){
      console.error(err);
    }

    if(isDollar(unit)){
      this.params.tickFormats.y = v => getCurrencyFormatter(currency)( convertWatts({watts: v, unit, simple_kwh_price}) );
    } else if(unit === 'percentage'){
      this.params.tickFormats.y = formatPercentage;
    }
  }

  transformDateFormatString({s, d}){
    if(d.getFullYear() !== new Date().getFullYear() && !/YY$/ig.test(s)){
      s = `${s} \`YY`;
    }
    return s;
  }

  renderTooltip({timeGroup, i, circuit}){
    const { simple_kwh_price, currency, allow_currencies, timezone, resolution, energyType } = this.params;
    const isBattery = energyType === 'battery';
    const [date, values, total] = timeGroup;
    const formatCurrency = getCurrencyFormatter(currency);
    const circuitValue = this.params.unit === 'percentage' ? values[i] * total : values[i];
    if(!circuit || !circuitValue){
      return null;
    }
    return (
      <div className="breakdown-tooltip">
        <div className="date-time">
          <span className="date">
            {formatDate(date, {timezone, transformString: this.transformDateFormatString})}
          </span>
          { resolution !== 'd' && (
            <span className="time">
              {formatTime(date, {timezone})}
            </span>
          )}
        </div>
        <div className="highlight">
          <div className="name">
            <span
              className="key"
              style={{backgroundColor: circuit.color}} />
            <span className="title">
              {circuit.label}
            </span>
          </div>
          <div className="values">
            <div className="watts">
              {formatWatts(circuitValue)} Watts&nbsp;
              { 
                (isBattery && circuitValue !== 0) && <span className="battery-polarity-indicator sub-label">
                  {
                    circuitValue > 0
                      ? '(charging)'
                      : '(discharging)'
                  }
                </span>
              }
            </div>
            {
              allow_currencies ? (
                <div className="dollars">
                  {formatCurrency( convertWatts({watts: circuitValue, unit: '$/hr', simple_kwh_price: simple_kwh_price}) )} <span className="sub-label per-hour">(per hour)</span>
                </div>
              ) : null
            }
            <div className="percentage">
              {formatPercentage(circuitValue / total)}% of Total
            </div>
          </div>
        </div>
        <div className="total">
          <div className="name">
            ALL CIRCUITS
          </div>
          <div className="values">
            <div className="watts">
              {formatWatts(total)} Watts
            </div>
            {
              allow_currencies ? (
                <div className="dollars">
                  {formatCurrency( convertWatts({watts: total, unit: '$/hr', simple_kwh_price: simple_kwh_price}) )} <span className="sub-label per-hour">(per hour)</span>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }

  getColor(d){
    return (this.getRegisterForId(d.key) || {}).color || '#000';
  }

  onClickCircuitLayer(d){
    activateRegister.call(this, d.key, this.params.locationId);
  }

  showCircuitFocus(d){
    this.focusedCircuit = this.getRegisterForId(d.key);
    this.allLayers.filter(`[data-id='${d.key}']`)
      .classed('active', true);
    if(this.focusedTimeGroup){
      const dataTimeSlot = d.find(item => item.data[0].getTime() === this.focusedTimeGroup[0].getTime());
      const offset = this.pathGroup._groups[0][0].getBoundingClientRect();
      if(dataTimeSlot){
        showTooltip({
          show: true,
          className: 'breakdown-tooltip',
          fallbackPlacement: 'left',
          target: [
            offset.left + this.x(this.focusedTimeGroup[0]),
            offset.top + this.y(dataTimeSlot[1])
          ],
          content: this.renderTooltip({
            timeGroup: this.focusedTimeGroup,
            i: d.index,
            circuit: this.focusedCircuit
          })
        });
      }
    }
  }

  beginFocusCircuit(d){
    this.focusedCircuit = this.getRegisterForId(d.key);
    this.showCircuitFocus(d);
    if(d3.event && d3.event.type.match(/^touch/)){
      d3.event.preventDefault();
    }
  }

  unfocusCircuit(){
    this.focusedCircuit = null;
    this.allLayers.classed('active', false);
    clearTooltip();
  }

  preTransformData(data){
    const { energyType } = this.params;
    const isProduction = energyType === 'production';
    const isBattery = energyType === 'battery';

    // flip solar and battery
    return this.filterForEnergyType(data)
      .map(
        c => {
          if(isProduction){
            return {
              ...c,
              values: c.values.map(
                v => {
                  return {
                    ...v,
                    w: -this.valueAccessor(v)
                  };
                }
              )
            };
          }
          return c;
        }
      )
      .filter(
        d => this.registerIdIsActive(d.id)
      )
      // stacking sort order baased on deviation
      .sort(
        (a,b) => d3.deviation(a.values.map(this.valueAccessor)) - d3.deviation(b.values.map(this.valueAccessor))
      );
  }

  postTransformData(dataGroupedByTimestamp){
    dataGroupedByTimestamp = dataGroupedByTimestamp.map(
      d => {
        const total = d[1].reduce((a,b) => a + (b || 0), 0);
        d[1] = d[1]
          .map(
            v => {
              if (this.params.unit === 'percentage'){
                if (total === 0){
                  return 0;
                }
                return v/total;
              }
              return v;
            }
          );
        d[2] = total;
        return d;
      }
    );
    return dataGroupedByTimestamp;
  }

  draw(){
    const { width } = this.dimensions;
    const { energyType } = this.params;
    super.draw();
    this.allLayers = this.pathGroup.selectAll('.layer');
    if(this.layersEnter && this.layersEnter.length){
      this.layersEnter.forEach(
        layersEnter => {
          layersEnter.attr('data-id', d => d.key)
          // apply color based on key (circuit id)
          .on(
            'mouseover touchstart',
            d => {
              if(d3.event.type === 'touchstart'){
                this.isTapping = true;
                this.touchStartX = d3.event.pageX ? d3.event.pageX : d3.event.touches[0].pageX;
              } else {
                this.beginFocusCircuit(d);
              }
            },
            {
              passive: true
            }
          )
          // establish focused circuit
          .on(
            'mousemove touchmove',
            d => {
              if(typeof d3.event.movementX === 'undefined' || d3.event.movementX){

                if(d3.event.type === 'touchmove' && !isNaN(d3.event.pageX)){
                  this.movementX = (d3.event.pageX ? d3.event.pageX : d3.event.touches[0].pageX) - this.touchStartX;
                  // prevents page scrolling on touchmove
                  let hoveredElement = document.elementFromPoint(d3.event.pageX - window.scrollX, d3.event.pageY - window.scrollY);
                  let hoveredData = d3.select(hoveredElement).datum();
                  if(hoveredElement !== d3.event.srcElement){
                    if(hoveredData && hoveredData.key){
                      this.unfocusCircuit(d);
                      this.beginFocusCircuit(d3.select(hoveredElement).datum());
                    }
                    return;
                  }
                }
                this.showCircuitFocus(d);
              }
            },
            {
              passive: true
            }
          )
          .on(
            'mouseout touchend touchcancel',
            (d, i, nodeList) => {
              if(d3.event.type === 'touchend'){
                this.hasTapped = true;
                if(Math.abs(this.movementX) < 10){
                  this.onClickCircuitLayer(d);
                }
              }
              this.focusChart(nodeList[i]);
              this.touchStartX = 0;
              this.movementX = 0;
              this.unfocusCircuit(d);
            }
          )
          .on('click', d => {
            if(!this.hasTapped){
              this.onClickCircuitLayer(d);
            }
            this.unfocusCircuit(d);
            this.hasTapped = false;
          });
        }
      );

    }

    // bring interaction line to top
    this.interactionLine
      .raise();

    this.generateRolloverPoints();

    if(energyType === 'battery'){
      this.zeroLine
        .attr('stroke', '#000')
        .attr('x1', 0)
        .attr('y1', this.y(0))
        .attr('x2', width)
        .attr('y2', this.y(0));
    }

  }

}
mergePrototypes(RegistersStackedAreaChart, [RegistersChart]);
export default RegistersStackedAreaChart;
