import React, { Component } from 'react';
import config from '../../../config';
import MenuLink from './MenuLink';
import LocationSelect from './LocationSelect';

export default class Header extends Component {

  static defaultProps = {
    provider: 'curb',
    logoPath: '/images/header.png',
    curbLogoPath: '/images/header.png',
    location: {
      metadata: {}
    }
  }

  constructor(props){
    super(props);
    this.state = {
      locationQuery: '',
      matchingLocations: []
    };        
  }

  onChangeLocationDropdown(){
    const { selectLocation } = this.props;
    return (e) => {
      e.preventDefault();
      window.CURB.locationId = e.target.value;
      selectLocation(
        this.props.locationsFlat.find(loc => loc.id === e.target.value)
      );
    };
  }

  onBlurLocationSearch(){
    setTimeout(
      () => this.setState({ matchingLocations: [] }),
      300
    );
  }

  onInputLocationSearch(e){
    const { locationsFlat } = this.props;
    this.setState({
      matchingLocations: e.target.value.length < 3
        ? []
        : locationsFlat.filter(
          loc => {
            return new RegExp(e.target.value, 'ig').test(loc.label);
          }
        )
    });
  }

  UNSAFE_componentWillMount(){
    this.onInputLocationSearch = this.onInputLocationSearch.bind(this);
    this.onBlurLocationSearch = this.onBlurLocationSearch.bind(this);
  }

  getOnClickLocationLinkHandler(location){
    return e => {
      e.stopPropagation();
      this.props.selectLocation(location);
      this.setState({
        matchingLocations: []
      });
    };
  }
  
  render(){
    const { logoPath, user, provider, curbLogoPath, showMenu=true } = this.props;    
    const logoPathFull = /^https*:/.test(logoPath) ? logoPath : `${config.STATIC_PATH}${logoPath}`;
    const curbLogoPathFull = /^https*:/.test(curbLogoPath) ? curbLogoPath : `${config.STATIC_PATH}${curbLogoPath}`;

    const linkHandler = () => {
      if(window.cordova && window.cordova.InAppBrowser) {
        window.cordova.InAppBrowser.open('https://www.elevationsolar.com/customer-referrals/', '_system');
      } else {
        window.open('https://www.elevationsolar.com/customer-referrals/', '_new');
      }
      return false;
    };

    const logo = () => {
      if (provider.toLowerCase()==='elevation') {
        return (
          <a 
            onClick={linkHandler}
            style={{border: '0'}}
          >
            <img
              src={logoPathFull}
              alt={`${provider.charAt(0).toUpperCase()}${provider.slice(1)}`}
              className="header-logo" />
          </a>
        );
      }
      return (<img
        src={logoPathFull}
        alt={`${provider.charAt(0).toUpperCase()}${provider.slice(1)}`}
        className="header-logo" />);
    };

    return (
      <header className="app-header">
        <LocationSelect {...this.props}>
          {logo()}
        </LocationSelect>

        {showMenu && <MenuLink user={user} />}
        {
          provider === 'curb'
          ? null
          : (
            <div className="powered-by-curb">
              <span> Powered by &nbsp;
                <img src={curbLogoPathFull} />
              </span>
            </div>
          )
        }
      </header>

    );
  }
}
