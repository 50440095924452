import React, { Component } from 'react';
import UserPageWrapper from '../wrappers/UserPageWrapper';
import SmartThingsPageContent from './PageContent';

export default class SmartThingsPage extends Component {
  render(){
    return (
      <UserPageWrapper {...this.props}>
        <SmartThingsPageContent />
      </UserPageWrapper>
    );
  }
}
