import React from 'react';
import d3 from 'shared/dist/lib/d3';
import LineChart from 'shared/dist/ui/viz/line';
import RegistersChart from 'shared/dist/ui/viz/registers-chart';
import mergePrototypes from 'shared/dist/utils/merge-prototypes';
import arrayUtils from 'shared/dist/utils/array';
import { parseTimeString } from 'shared/dist/utils/historical';
import { isDollar } from '../../lib/unit-converter';
import { formatDate, formatTime, getCurrencyFormatter, formatWatts, formatPercentage } from '../../lib/formatters';
import { convertWatts } from '../../lib/unit-converter';
import { GRAPH_CONTIGUITY_UNITS } from '../../constants';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';

class NetChart extends LineChart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      tickFormats: {
        x: v => v
      }
    };
  }

  initDOM(){
    super.initDOM();

    this.interactionLine = this.lineGroup
      .append('line')
      .attr('class', 'interaction-line');

    this.lineGroup
      .on('mousemove touchmove',
        (d, i, nodeList) => {
          d3.event.preventDefault();
          if(typeof d3.event.movementX === 'undefined' || d3.event.movementX){
            const { height } = this.dimensions;
            const mouse = d3.mouse(nodeList[i]);
            const nearestTimegroup = this.rolloverPoints[mouse[0]] || this.getPointForX(mouse[0]);
            const nearestX = this.x(nearestTimegroup[0]);
            const offset = nodeList[i].getBoundingClientRect();
            const netGroup = this.data.find(g => g.label === 'Net');
            const netIndex = this.data.indexOf(netGroup);
            // try to target the net line, otherwise just take the first line
            const targetIndex = netIndex > -1 ? netIndex : 0;

            this.interactionLine
              .attr('y1', 0)
              .attr('y2', height)
              .attr('x1', nearestX)
              .attr('x2', nearestX);

            showTooltip({
              show: true,
              className: 'breakdown-tooltip',
              fallbackPlacement: 'bottom',
              target: [
                offset.left + window.scrollX + nearestX,
                offset.top + window.scrollY + this.y(nearestTimegroup[1][targetIndex])
              ],
              content: this.renderTooltip(nearestTimegroup)
            });
          }
        }
      )
      .on(
        'mouseover touchstart',
        () => {
          this.interactionLine
            .classed('active', true);
          d3.event.preventDefault();
        }
      )
      .on(
        'mouseout',
        () => {
          this.interactionLine
            .classed('active', false);
          clearTooltip();
        }
      );
  }

  updateParams(params){
    super.updateParams(params);
    const { unit, simple_kwh_price, currency, resolution } = this.params;
    try {
      this.params.contiguityThreshold = parseTimeString(resolution).seconds * 1000 * GRAPH_CONTIGUITY_UNITS;
    } catch(err){
      console.error(err);
    }

    if(isDollar(unit)){
      this.params.tickFormats.y = v => getCurrencyFormatter(currency)( convertWatts({watts: v, unit, simple_kwh_price}) );
    } else if(unit === 'percentage'){
      this.params.tickFormats.y = formatPercentage;
    }
    this.update();
  }

  generateRolloverPoints(){
    const { width } = this.dimensions;
    this.rolloverPoints = [];
    for(var i=width; i>=0; i--){
      this.rolloverPoints[i] = this.getPointForX(i);
    }
  }

  getPointForX(x){
    return arrayUtils.getNearestValue(
      [this.x.invert(x)],
      this.dataGroupedByTimestamp,
      {
        accessor: v => v[0].getTime()
      }
    );
  }

  preTransformData(data){
    return data
      .filter(group => !group.inactive)
      .map(group => {
        return {
          ...group,
          values: group.values.map(
            v => {
              return [
                new Date(v.t * 1000),
                v.w
              ];
            }
          )
        };
      });
  }

  transformDateFormatString({s, d}){
    if(d.getFullYear() !== new Date().getFullYear() && !/YY$/ig.test(s)){
      s = `${s} \`YY`;
    }
    return s;
  }

  renderTooltip(d){
    const { simple_kwh_price, currency, allow_currencies, timezone, resolution } = this.params;
    const [date, values] = d;
    return (
      <div className="totals-tooltip">
        <div className="date-time">
          <span className="date">
            {formatDate(date, {timezone, transformString: this.transformDateFormatString })}
          </span>
          {
            resolution !== 'd' && (
              <span className="time">
                {formatTime(date, {timezone})}
              </span>
            )
          }
        </div>
        {
          values.map(
            (v, i) => {
              const group = this.data[i];
              return (
                <div className="datum" key={i}>
                  <div className="name">
                    <span className="key" style={{backgroundColor: group.color}} />
                    <span className="title">{group.label}</span>
                  </div>
                  <div className="values">
                    <div className="watts">
                      {formatWatts(v)} Watts&nbsp;
                        { 
                          (group.storage && v !== 0) && <span className="battery-polarity-indicator sub-label">
                            {
                              v > 0
                                ? '(charging)'
                                : '(discharging)'
                            }
                          </span>
                        }
                    </div>
                    {
                      allow_currencies ? (
                        <div className="dollars">
                          {getCurrencyFormatter(currency)( convertWatts({watts: values[i], unit: '$/hr', simple_kwh_price}) )} <span className="per-hour sub-label">(per hour)</span>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              );
            }
          )
        }
      </div>
    );
  }

  draw(){
    super.draw();

    // bring interaction line to top
    this.interactionLine
      .raise();

    this.generateRolloverPoints();
  }
}
mergePrototypes(NetChart, [RegistersChart]);
export default NetChart;
