import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconLink from '../../../../atoms/icon-link';
import CurbDatePicker from 'shared/dist/components/atoms/form/datepicker';
import CurbSelect from 'shared/dist/components/atoms/form/select';
import CurbInput from 'shared/dist/components/atoms/form/input';
import { MONTHS, DAYS } from './utils';
import VirtualLoadControl from '../../../../data-containers/virtual-load-control';

function StylizedCheckbox(props){
  let { input, id, label, className, children } = props;
  const { name, checked } = input;
  const classes = ['curb-input', 'curb-form-element', 'curb-styled-checkbox'];

  if(!id){
    id = `checkbox_${name}`;
  }

  if(className){
    classes.push(className);
  }

  if(input.checked){
    classes.push('checked');
  }

  return <div className={classes.join(' ')}>
    <input id={id} {...input} />
    <label htmlFor={id} className="stylized-label">
      {
        children.length
          ? children
          : label
      }
    </label>
  </div>;
};

export default function EnforcementWindows({ form, values, errors }){
  const { mutators: { push, remove }} = form;
  const { version } = VirtualLoadControl.useContainer();
  const isClassic = version === 'classic';
  const { monitor_interval_mins } = values;
  
  return <FieldArray name="enforcement_windows">
    {
      ({fields}) => fields.map(
        (name, fieldIndex) => {
          return <section key={fieldIndex} className="inset enforcement-window">
            <section className="inner">
              <div className="section-title">
                <h3>Enforcement Window {fieldIndex+1}</h3>
                {
                  fields.length > 1 && <div 
                    className="close"
                    onClick={
                      () => fields.remove(fieldIndex)
                    }>
                    <FontAwesomeIcon icon="times" />
                  </div>
                }
              </div>

              <React.Fragment>
                <h3>Enforce during these months:</h3>
                <div className="time-checkbox-list months-list">
                  {
                    MONTHS.map(
                      (m, i) => <div key={`month_${fieldIndex}_${i}`} className="time-checkbox-item">
                        <Field 
                          name={`${name}.months`}
                          type="checkbox"
                          className="date-styled-checkbox"
                          id={`month_checkbox_${fieldIndex}_${i}`}
                          value={m}
                          component={StylizedCheckbox}>
                            {m.substr(0, 3)}
                        </Field>
                      </div>
                    )
                  }
                </div>                  
              </React.Fragment>

              <h3>Enforce on these days:</h3>
              <div className="time-checkbox-list days-list">
                {
                  DAYS.map(
                    (d, i) => <div key={`day_${fieldIndex}_${i}`} className="time-checkbox-item">
                      <Field 
                        name={`${name}.days`}
                        type="checkbox"
                        className="date-styled-checkbox"
                        id={`day_checkbox_${fieldIndex}_${i}`}
                        value={d}
                        component={StylizedCheckbox}>
                          {d.substr(0, 3)}
                      </Field>
                    </div>
                  )
                }
              </div>

              <h3>Enforce during these hours</h3>
                <FieldArray name={`${name}.ranges`}
                  validate={
                    (ranges, values) => {
                      const currentWindow = values.enforcement_windows[fieldIndex];
                      if(!currentWindow){
                        return;
                      }
                      for(let i=0; i<ranges.length; i++){
                        let { start_hour, end_hour } = ranges[i];
                        let overlappingWindows = values.enforcement_windows
                          // remove current window
                          .filter((v, i) => i !== fieldIndex)
                          // select windows that have month and day overlaps with the current window
                          .filter(
                            ({days, months}) => currentWindow.days.some(d => !!days.find(_d => _d === d)) && currentWindow.months.some(m => !!months.find(_m => _m === m))
                          );

                        let allOtherRanges = overlappingWindows
                          .reduce(
                            (acc, { ranges }) => {
                              acc = acc.concat(ranges);
                              return acc;
                            },
                            []
                          )
                          .concat(
                            ranges.filter((r, _i) => _i !== i)
                          );

                        if(
                          allOtherRanges.find(
                            (w) => {
                              if(start_hour >= w.start_hour && start_hour < w.end_hour){
                                return true;
                              }
                              if(start_hour < w.start_hour && end_hour > w.start_hour){
                                return true;
                              }
                            }
                          )
                        ){
                          return 'This enforcement window overlaps with another';
                        }
                      }
                    }
                  }>
                  {
                    ({ fields, meta }) => {
                      const format = v => new Date( new Date().setHours(v) );
                      const parse = v => v.getHours();
                      const classes = ['time-ranges-list'];                      

                      if(meta.error){
                        classes.push('has-error');
                      }

                      return <div className={classes.join(' ')}>
                        {
                          fields.map(
                            (timeRangeName, timeRangeIndex) => {
                              const currentRange = fields.value[timeRangeIndex];
                              const { start_hour, end_hour } = currentRange;
                              const lowerLimit = new Date( new Date().setHours(0, 0, 0, 1) );
                              const upperLimit = new Date( new Date().setHours(23, 0, 0, 0) );
                              const minTime = new Date( new Date().setHours(start_hour+1, 0, 0, 0) );
                              const maxTime = new Date( new Date().setHours(end_hour-1, 0, 0, 0) );
                              return <div key={timeRangeIndex} className="time-range">
                                {
                                  fields.length > 1 && <span 
                                    className="round-icon linked-icon irreversible remove-range"
                                    onClick={
                                      () => fields.remove(timeRangeIndex)
                                    }>
                                    <FontAwesomeIcon icon="minus" />
                                  </span>
                                }
                                <label>From:</label>                                
                                <Field
                                  name={`${timeRangeName}.start_hour`}
                                  key={`${timeRangeName}.start_hour`}
                                  label="Start Time"
                                  format={format}
                                  parse={parse}
                                  timeIntervals={60}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  dateFormat="h aa"
                                  component={CurbDatePicker}
                                  minTime={lowerLimit}
                                  maxTime={maxTime} />
                                <label>To:</label> 
                                <Field
                                  name={`${timeRangeName}.end_hour`}
                                  key={`${timeRangeName}.end_hour`}
                                  label="End Time"
                                  format={format}
                                  parse={parse}
                                  timeIntervals={60}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  dateFormat="h aa"
                                  minTime={minTime}
                                  maxTime={upperLimit}
                                  component={CurbDatePicker} />
                              </div>
                            }
                          )
                        }
                        <IconLink 
                          icon="plus" 
                          className="add-time-range"
                          onClick={
                            () => fields.push({
                              start_hour: 8,
                              end_hour: 17
                            })
                          }>
                          Add Time Range
                        </IconLink>
                        {
                          meta.error && <div className="status-message error"><FontAwesomeIcon icon="exclamation-circle" />&nbsp;{meta.error}</div>
                        }
                      </div>;
                    }
                  }
                </FieldArray>

                <React.Fragment>
                    <section className="curb-form-element threshold-group">

                      <div className="curb-form-element">
                        <div className="status-message warning">
                          <p>Units for thresholds below are <strong>Watt-hours per demand interval</strong> ({monitor_interval_mins} minutes)</p>
                        </div>
                      </div>

                      <h3>Shut off devices or circuits when consumption exceeds:</h3>
                      <div className="form-row">
                        <Field
                          name={`${name}.control_threshold`}
                          placeholder="Number"
                          type="number"
                          parse={
                            v => {
                              if(!v) return undefined;
                              return Number(v);
                            }
                          }
                          validate={
                            (v, values) => {
                              const currentWindow = values.enforcement_windows[fieldIndex];
                              if(!currentWindow){
                                return;
                              }
                              const { release_threshold } = currentWindow;
                              if(!v){
                                return 'Required';
                              }
                              if(v <= release_threshold){
                                return "Control threshold must be greater than the release threshold below"
                              }
                            }
                          }
                          component={CurbInput} />
                        {
                          /* 
                          <Field
                            name={`${name}.control_threshold_unit`}
                            placeholder="Number"
                            type="number"
                            component={CurbSelect}>
                            <option value="avg-watts">Avg Watts</option>
                          </Field>
                          */
                        }
                      </div>
                    </section>

                    <section className="curb-form-element threshold-group">
                      <h3>Release devices or circuits when consumption drops below:</h3>
                      <div className="form-row">
                        <Field
                          name={`${name}.release_threshold`}
                          placeholder="Number"
                          type="number"
                          parse={
                            v => {
                              if(!v) return undefined;
                              return Number(v);
                            }
                          }
                          validate={
                            (v, values) => {
                              const currentWindow = values.enforcement_windows[fieldIndex];
                              if(!currentWindow){
                                return;
                              }
                              const { control_threshold } = currentWindow;
                              if(!v){
                                return 'Required';
                              }
                              if(v >= control_threshold){
                                return "Release threshold must be less than the control threshold above"
                              }
                            }
                          }
                          component={CurbInput} />
                        {
                          /* 
                            <Field
                              name={`${name}.release_threshold_unit`}
                              placeholder="Number"
                              type="number"
                              component={CurbSelect}>
                              <option value="avg-watts">Avg Watts</option>
                            </Field>                          
                            <div className="curb-form-element">
                              <div className="status-message warning">
                                <p>Units are Watt-hours per demand interval ({monitor_interval_mins} minutes)</p>
                              </div>
                            </div>
                          */
                        }
                      </div>
                    </section>
                </React.Fragment>

            </section>
          </section>          
        }
      )
    }
  </FieldArray>;
}