import actionTypes from '../actions';

// location
export default (state=[], action) => {
  const { type, category, hideCloseLink, title, content, id } = action;
  switch(type){
    case actionTypes.ADD_ALERT:
      return [{
        category,
        title,
        content,
        hideCloseLink,
        id
      }].concat(state || []);
    case actionTypes.REMOVE_ALERT:
      if(typeof id !== 'undefined'){
        return (state || []).filter(alert => alert.id !== id);
      }
      break;
    case actionTypes.CLEAR_ALL_ALERTS:
      return [];
    case actionTypes.API_ERROR:
      return [{
        category: category || 'error',
        title: title || 'Error',
        id,
        content
      }].concat(state || []);
    default:
      return state;
  }
};
