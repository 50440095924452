import React from 'react';

export default function ThemedPage(props){
  const { className='curb-dark', children } = props;
  const classes = ['app-inner themed-page'];
  if(className){
    classes.push(className);
  }
  return <div className={classes.join(' ')}>
    {children}
  </div>;
}