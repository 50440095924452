import getAPI from 'shared/dist/lib/api';
import auth from './auth';
import config from '../config';

const { APP_URL } = config;

export const APICall = getAPI(auth, APP_URL);

export function fetchUser(query) {
  return APICall('/api-curb/state', 'GET', null, query);
}

export function fetchNotificationTypes(provider) {
  return APICall(`/api/v3/notification-types/${provider}`);
}

export function fetchLocationMetadata(id) {
  return APICall(`/api-curb/location/${id}/metadata`);
}

export function fetchLocation(id) {
  return APICall(`/api-curb/location/${id}`);
}

export function fetchLocationState(id, query={}) {
  return APICall(`/api-curb/location/${id}/state`, 'GET', null, query);
}

export function fetchAppConfig(locationId) {
  return APICall(locationId ? `/api-curb/app-config/${locationId}` : '/api-curb/app-config');
}

export function updateLocation(locationId, data) {
  return APICall(`/api-curb/locations/${locationId}`, 'PUT', data);
}

export function fetchRegistersForInstallation(serial) {
  return APICall(`/api-curb/registers/${serial}`);
}

export function searchLocations(query) {
  return APICall('/api-curb/location_search', 'GET', null, query);
}

export function fetchLocationBilling(locationId) {
  return APICall(`/api-curb/location/${locationId}/billing`);
}

export function updateLocationBilling(locationId, data) {
  return APICall(`/api-curb/locations/${locationId}/billing`, 'PATCH', data);
}

export function updateUser(data) {
  return APICall('/api-curb/protected/user', 'PATCH', data);
}

export function updateUserMetadata(metadata) {
  return APICall('/api-curb/user', 'PATCH', metadata);
}

export function upsertUserDetail(userDetail) {
  return APICall('/api-curb/user-detail', 'PUT', userDetail);
}

export function updateNotificationSettings(data){
  return APICall('/api/v3/user-notification-settings', 'PATCH', data);
}

// claim
export function processClaim(claimId){
  return APICall('/api-curb/claim', 'POST', { claimId });
}

// fleet
export function fleetSearch(params) {
  return APICall('/api-curb/locations/search', 'GET', null, params);
}

export function installationIsInFleet(hubSerial, fleetId){
  return APICall(`/api-curb/installation/${hubSerial}/is_in_fleet/${fleetId}`);
}

// historical
export function fetchHistorical(locationId, range, resolution, query) {
  return APICall(`/api-curb/historical/${locationId}/${range}/${resolution}`, 'GET', null, query);
}

export function fetchHistoricalCost(locationId, range, resolution, interpolate) {
  const query = interpolate ? { interpolate: true } : null;
  return APICall(`/api-curb/historical_cost/${locationId}/${range}/${resolution}`, 'GET', null, query);
}

export function fetchKwhrData(locationId, range, resolution, query) {
  return APICall(`/api-curb/kwhr/${locationId}/${range}/${resolution}`, 'GET', null, query);
}

export function fetchAggregate(locationId, range, resolution) {
  return APICall(`/api-curb/aggregate/${locationId}/${range}/${resolution}`);
}

export function fetchCsvDownload(locationId, range, resolution) {
  return APICall(`/api-curb/csvdownload/${locationId}/${range}/${resolution}`, 'GET', null, { fill_gaps: true });
}

// utilities
export function fetchUtilities(zipCode) {
  return APICall(`/api-curb/utilities?zip_code=${zipCode}`);
}

export function fetchAvgKwhPriceForTariff(tariffId){
  return APICall(`/api-curb/utilities/${tariffId}/avg_kwh_price`);
}

export function calculateCurrentBill(locationId, params={}) {
  // don't send null tariffId
  if(params.tariffId === null){
    delete params.tariffId;
  }
  return APICall(`/api-curb/utilities/${locationId}/current_bill`, 'GET', null, params);
}

export function updateTariff(locationId, masterTariffId){
  return APICall(`/api-curb/utilities/${locationId}/tariff`, 'PUT', { masterTariffId });
}

export function fetchTariffForLocation(locationId){
  return APICall(`/api-curb/utilities/${locationId}/tariff`);
}

// messages
export function getInbox(query) {
  return APICall('/api-curb/notification/inbox', 'GET', null, query);
}

export function getMessage(messageId) {
  return APICall(`/api-curb/notification/${messageId}`);
}

export function updateMessage(messageId, message) {
  return APICall(`/api-curb/notification/${messageId}`, 'PATCH', message);
}

// smarthings
export function getSmartthingsUrl(callback_url, clientId, clientSecret){
  return APICall('/api-curb/smartthings/link', 'POST', { callback_url: callback_url, client_id: clientId, client_secret: clientSecret});
}

export function getAccessTokenWithCode(code, state, callback_url) {
  return APICall(`/api-curb/smartthings/access_token/${code}/${state}`, 'POST', { callback_url: callback_url});
}

export function getSwitchState(uri, access_token) {
  return APICall('/api-curb/smartthings/switchstate', 'POST', { access_token: access_token, uri: uri});
}

export function toggleSwitch(uri, command, access_token) {
  return APICall('/api-curb/smartthings/toggleswitch', 'POST', { access_token: access_token, uri: uri, command: command});
}


// load control (legacy)
export function upsertLoadControlConfig(data) {
  return APICall('/api-curb/installation_load_control_settings', 'POST', data);
}

export function fetchLoadControlStatus(id){
  return APICall(`/api-curb/load_control/${id}`);
}

export function snoozeLoadControl(id){
  return APICall(`/api-curb/load_control/${id}/snooze`, 'POST');
}

export function resumeLoadControl(id){
  return APICall(`/api-curb/load_control/${id}/resume`, 'PUT');
}

// virtual load control
export function fetchInstallation(hubSerial) {
  return APICall(`/api-curb/installation/${hubSerial}`);
}

export function fetchLoadControlSettings(hubSerial, query={}) {
  return APICall(`/api-curb/installation_load_control_settings/${hubSerial}`, 'GET', null, query);
}

export function fetchLoadControlSettingsVersion(hubSerial) {
  return APICall(`/api-curb/installation_load_control_settings_version/${hubSerial}`);
}

export function fetchVirtualDevices(hubSerial) {
  return APICall(`/api-curb/virtual-load-control/${hubSerial}/virtual_devices`);
}

export function upsertVirtualDevices(hubSerial, virtual_devices) {
  return APICall(`/api-curb/virtual-load-control/${hubSerial}/virtual_devices`, 'POST', { virtual_devices } );
}

export function fetchAuthorizationUrl(hubSerial, platform){
  return APICall(`/api-curb/virtual-load-control/${hubSerial}/${platform}/authorization_url`);
}

// PUBLIC
export function fetchDemoURL(callbackURL, provider){
  return APICall('/demo_url', 'POST', {callbackURL, provider}, null, { bypassToken: true });
}
