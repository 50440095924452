import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import Modules from '../../components/Modules';
import Historical from '../../components/Historical';
import Menu from '../../components/Menu';
import AppHeader from '../../components/Structure/AppHeader';
import Walkthrough from '../../components/Dashboard/Walkthrough';
import DashboardMainAside from '../../components/Dashboard/MainAside';
import Eula from '../../components/Dashboard/Eula';
import Alerts from '../../atoms/Alerts';
import { HAS_SEEN_WALKTHROUGH_KEY, minWidthHistorical } from '../../constants';

class DashboardPageContent extends Component {
  constructor(props){
    super(props);
    this.state = {
      is_webview_hidden: false
    };
  }

  shouldRunWalkthrough(){
    const { userDetail } = this.props;  
    return (
      ( !userDetail.walkthrough_complete && !sessionStorage.getItem(HAS_SEEN_WALKTHROUGH_KEY) )
      ||
      userDetail.force_walkthrough
    );
  }
  render(){
    const {
      user,
      userDetail,
      currentLocation,
      locationsFlat=[],
      match,
      appConfig
    } = this.props;
    const { params } = match;
    const { primarySection } = params;
    const location = currentLocation;
    const { metadata={} } = location;
    const { is_cellular } = metadata;
    const menuOpen = primarySection === 'menu';

    const headerProps = {
      user,
      locationsFlat,
      logoPath: appConfig.logo_path,
      provider: appConfig.provider,
      location
    };

    const menuProps = {
      user,
      userDetail,
      location,
      locationsFlat,
      params
    };

    const classes = ['dashboard', 'full-screen-page'];

    if(is_cellular){
      classes.push('cellular');
    }

    return (
      <div className={classes.join(' ')}>
        <AppHeader {...headerProps} />
        <main className="dashboard-main">
          { !menuOpen && <Alerts /> }
          <DashboardMainAside
            className="dashboard-main-aside"
            location={location}
            user={user} 
            /> 

          {/* if landscape mode OR wide enough for historical */}
          <MediaQuery query={`(min-aspect-ratio: 1/1), (min-width: ${minWidthHistorical+1}px)`}>
            <span id={'unhide-sibling'} />
          </MediaQuery>  
          <Historical
            id="hidden-sibling"
            className="dashboard-main-feature hidden-sibling"
            location={location} />
        </main>

        {/* if wide enough for historical OR in portrait mode mode */}
        <MediaQuery query={`(min-width: ${minWidthHistorical+1}px), (max-aspect-ratio: 1/1)`}>
          <div className="dashboard-modules">
            <Modules
              location={location}
              user={user}
              appConfig={appConfig}/>
          </div>
        </MediaQuery>

        <Menu {...menuProps} />

        <Walkthrough run={this.shouldRunWalkthrough()} />

        <Eula
          userDetail={userDetail}
          run={!userDetail.accepted_eula && !user.isDemoGuest} />
      </div>
    );
  }
}

export default connect(
  state => {
    const { appConfig } = state;
    return {
      appConfig
    };
  }
)(DashboardPageContent);
