import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

class CurbFormWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  buildReduxFormComponent(){
    const { id, reduxFormOptions } = this.props;
    this.FormContent = reduxForm({
      form: id,
      ...reduxFormOptions
    })(this.props.FormContent);
  }

  getStoredValues(props=this.props){
    try {
      return JSON.parse(window.sessionStorage.getItem(this.getStoredValuesKey(props)));
    } catch(err) {
      return null;
    }
  }

  getStoredValuesKey(props=this.props){
    return `${props.id}_storedValues`;
  }

  componentDidUpdate(){
    this.storeCurrentValues();
  }

  storeCurrentValues(){
    if(!this.props.skipLocalCache && !isEqual(this.props.currentValues, this.getStoredValues())){
      window.sessionStorage.setItem(this.getStoredValuesKey(), JSON.stringify(this.props.currentValues));
    }
  }

  UNSAFE_componentWillMount(){
    const handler = typeof this.props.onSubmit === 'function'
      ? this.props.onSubmit
      : () => {};

    this.submitHandler = e => {
      e.preventDefault();
      this.setState({ isSubmitting: true });
      var p = handler(this.props.currentValues, this);
      if(p instanceof Promise){
        p
        .then(
          () => window.sessionStorage.removeItem(this.getStoredValuesKey())
        )
        .catch(console.error)
        .then(
          () => this.setState({ isSubmitting: false })
        );
      }
      return p;
    };

    this.storeCurrentValues = debounce(this.storeCurrentValues, 500);
    this.buildReduxFormComponent();
  }

  render(){
    var { className, id, reduxFormOptions, autoSave, FormContent, skipLocalCache } = this.props;
    const { isSubmitting } = this.state;

    if(!Array.isArray(className)){
      className = [className];
    }
    className.push('curb-form');

    if(isSubmitting){
      className.push('busy');
    }
    if(autoSave){
      className.push('autosave');
    }

    const initialValues = {
      ...FormContent.initialValues,
      ...reduxFormOptions.initialValues,
      ...(
        !skipLocalCache
          ? this.getStoredValues()
          : {}
      )
    };

    return (
      <form
        ref={c => (this.formElement = c)}
        id={id}
        className={ className.join(' ') }
        onSubmit={this.submitHandler}>
        <this.FormContent
          formElement={this.formElement}
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          {...this.props} />
      </form>
    );
  }
}

export default connect(
  function(state, ownProps){
    const {
      id,
      formKeys=[]
    } = ownProps;
    const selector = formValueSelector(id);
    const currentValues = selector(state, ...formKeys);
    return {
      currentValues,
      id,
      formKeys
    };
  }
)(CurbFormWrapper);
