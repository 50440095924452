export const FETCH_INBOX = 'FETCH_INBOX';
export const UPDATE_INBOX = 'UPDATE_INBOX';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SAVE_MESSAGE = 'SAVE_MESSAGE';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const RECEIVE_INBOX = 'RECEIVE_INBOX';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const DELETE_MESSAGES = 'DELETE_MESSAGES';

export function fetchInbox({params={}, resolve, reject} = {}){
  return {
    type: FETCH_INBOX,
    params,
    resolve,
    reject
  };
}

export function updateInbox(inbox){
  return {
    type: UPDATE_INBOX,
    inbox
  };
}

export function saveMessage(locationId, message) {
  return {
    type: SAVE_MESSAGE,
    locationId,
    message
  };
}

export function receiveInbox(inbox) {
  return {
    type: RECEIVE_INBOX,
    ...inbox
  };
}

export function fetchMessage(messageId, resolve, reject, finished) {
  return {
    type: FETCH_MESSAGE,
    messageId,
    resolve,
    reject,
    finished
  };
}

export function addMessages(messages) {
  return {
    type: ADD_MESSAGES,
    messages
  };
}

export function deleteMessages(messageIds) {
  return {
    type: DELETE_MESSAGES,
    messageIds
  };
}

export function updateMessage(messageId, message) {
  return {
    type: UPDATE_MESSAGE,
    messageId,
    message
  };
}
