import React, { Component } from 'react';
import { Field } from 'redux-form';

class BaseCheckbox extends Component {
  render(){
    const { input, id, disabled, autoSave } = this.props;

    return (
      <input type="checkbox"
        id={id}
        disabled={disabled}
        checked={input.value}
        onChange={
          e => {
            e.persist();
            if(autoSave){
              window.requestAnimationFrame(
                () => {
                  e.target.form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: false}));
                }
              );
            }
            return input.onChange(e.target.checked);
          }
        } />
    );
  }
}

export default class CurbCheckbox extends Component {
  render(){
    const {
      name,
      label,
      id=this.props.name,
      meta={}
    } = this.props;
    const { error, touched } = meta;
    var className = this.props.className;
    if(!className){
      className = `checkbox-${name}`;
    }
    if(!Array.isArray(className)){
      className = [className];
    }
    className = className.concat(['curb-checkbox', 'curb-form-element']);

    return (
      <div className={className.join(' ')}>
        {
          label
            ? <label htmlFor={id}>{label}</label>
            : null
        }
        <div className="input-checkbox">
          <Field
            id={id}
            {...this.props}
            component={BaseCheckbox} />
          <label htmlFor={id} />
        </div>
        {touched && error && <span className="error">{error}</span>}
      </div>
    );
  }
}
