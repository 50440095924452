import React from 'react';
import { connect } from 'react-redux';
import stringUtils from 'shared/dist/utils/string';
import RefreshableComponent from '../../components/base/RefreshableComponent';
import ErrorModal from '../../atoms/ErrorModal';
import BasicPage from '../BasicPage';
import Alerts from '../../atoms/Alerts';
import Loader from '../../atoms/Loader';
import { dispatch } from '../../store';
import actions from '../../actions';
import eventEmitter from '../../event-emitter';
import config from '../../config';
import auth from '../../lib/auth';
import { fetchLocation } from '../../lib/api';
import { passiveGetCircuitDataStream, getCircuitDataStream } from '../../lib/realtime';
import { getDefaultLocation } from '../../lib/utils';

class LocationPageWrapper extends RefreshableComponent {
  constructor(props){
    super(props);
    this.state = {};

    this.initLocation = this.initLocation.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    const circuitDataStream = passiveGetCircuitDataStream();
    // stop listening
    eventEmitter.off('receiveLocation', this.initLocation);
    // unsubscribe from live data
    if(circuitDataStream){
      circuitDataStream.unsubscribeAll();
    }
  }

  onRefresh(locationId){
    const { user } = this.props;
    const { currentLocation={} } = this.state;

    if(locationId === currentLocation.id){
      dispatch(
        actions.fetchLocationState(locationId, user)
      );
    }
  }

  noLocations(){
    const installationURL = auth.getToken()
      ? `${config.CONFIG_APP_URL}/token#${auth.getAuthHash()}`
      : config.CONFIG_APP_URL;

    dispatch(
      actions.addAlert({
        title: 'You do not have CURB yet <i class="icon-frown" />',
        content: `
          <ul>
            <li>If an installer has already installed your CURB, please check for an email with the subject "Claim your new CURB installation."  Follow the link the email to claim your CURB.</li>
            <li>If you have a CURB that you would like to install yourself, please visit <a href="${installationURL}" target="_blank">the configuration app</a> to get started.</li>
            <li>Need help? Contact CURB Support at <a href="mailto:support@energycurb.com">support@energycurb.com</a>.</li>
          </ul>
        `,
        hideCloseLink: true
      })
    );

    this.setState({
      noLocations: true
    });
  }

  getLocationForId(locationId){
    const { locationsFlat } = this.props;
    return locationsFlat.find(l => l.id === locationId);
  }

  initLocation(location){
    const { match, history, routes=[] } = this.props;
    const { params } = match;

    if(!location){
      return;
    }

    if(location.id && params.hasOwnProperty('locationId') && params.locationId !== location.id){
      let newUrl = null;
      if(!stringUtils.isGUID(params.locationId)){
        // what we have in the second place of the url path is not a GUID
        // so instead just insert the new locationId at the second position
        let urlFragments = this.props.location.pathname
          .split('/')
          .filter(f => f);
        urlFragments.splice(1, 0, location.id);
        newUrl = `/${urlFragments.join('/')}`;
      }
      else if(params.locationId){
        // replace prev location id with new id
        newUrl = this.props.location.pathname.replace(params.locationId, location.id);
      }
      // else if(matchingRoute) {
      //   // smart route replacement
      //   let bareRoute = matchingRoute.path.replace(/[()]/g, '');
      //   newUrl = `/${bareRoute.replace(':locationId', location.id)}`;
      // }
      else {
        // if all else fails, append it to the current url
        let urlFragments = this.props.location.pathname
          .split('/')
          .filter(f => f)
          .concat([location.id]);
        newUrl = `/${urlFragments.join('/')}`;
      }
      history.push(newUrl);
    }
    this.selectLocation(location);
  }

  componentDidUpdate(prevProps){
    const { match } = this.props;
    const { locationId } = match.params;
    if(locationId && prevProps.match.params.locationId !== locationId){
      this.initForLocationId(locationId);
    }
  }

  async initForLocationId(locationId){
    const { locationsFlat } = this.props;
    let matchingLocation = locationsFlat.find(l => l.id === locationId);
    if(!matchingLocation && locationId){
      matchingLocation = await fetchLocation(locationId);
      dispatch(
        actions.receiveAllLocations({
          [locationId]: matchingLocation
        }) 
      );
      this.selectLocation(matchingLocation);
      return;
    }
    this.initLocation(matchingLocation);
  }

  componentDidMount(){
    const { match } = this.props;
    const { params } = match;
    const { locationId } = params;
    // this.initForLocationId(locationId);
    eventEmitter.on('receiveLocation', this.initLocation);    
    eventEmitter.on(
    'documentVisibiltyStatus',
    function(hidden){
      let circuitDataStream = getCircuitDataStream();
      if(hidden){
        if(locationId){
          circuitDataStream.socket.emit('unsubscribe', locationId);
        }
      } else {
        circuitDataStream.subscribeToLocation(locationId);
      }
    });

    // load location based on url or user's default location
    const startingLocation = this.getLocationForId(params.locationId) || getDefaultLocation(this.props);    
    if(startingLocation){
      this.initLocation(startingLocation);
    }
    else {
      this.noLocations();
    }
  }

  getCurrentLocationFromStore(){
    const { currentLocation={} } = this.state;
    const { locations={} } = this.props;
    return currentLocation.id
      ? locations[currentLocation.id]
      : null;
  }

  selectLocation(location){
    const { user } = this.props;
    const { currentLocation={}, loading } = this.state;
    if(location && location.id){
      let circuitDataStream = getCircuitDataStream();
      // subscribe to new location, and unsubscribe from previous location
      if(circuitDataStream){
        if(currentLocation.id){
          circuitDataStream.socket.emit('unsubscribe', currentLocation.id);
        }
        circuitDataStream.subscribeToLocation(location.id);
      }

      let setErrorState = (error='Could not fetch location state') => {
        if(this.state.loading){
          this.setState({
            error,
            loading: false
          });
        }
      };


      // if the location being received is already current, just set state here and return out
      if(location.current){
        this.setState({
          currentLocation: location
        });
        return;
      }

      // poll for messages
      this.pollForMessages(location.id);

      if(!loading){
        let requestTimeout = setTimeout(
          () => setErrorState('Fetching location state is taking too long to complete.  You can choose to keep waiting, or select one of the options below'),
          1000 * 10
        );

        this.setState({  
          currentLocation: location,        
          loading: true
        });
        return new Promise(
          (resolve, reject) => dispatch(
            actions.fetchLocationState(location.id, user, resolve, reject)
          )
        )
        .then(
          () => {
            this.setState({
              error: null
            });
            clearTimeout(requestTimeout);
          }
        )
        .catch(
          err => {
            console.error(err);
            setErrorState(err);
          }
        )
        .then(
          () => this.setState({
            loading: false,
            currentLocation: location
          })
        );
      }
    }
  }

  pollForMessages(){
    if(this.messagesPollingInterval){
      clearInterval(this.messagesPollingInterval);
    }
    this.messagesPollingInterval = setInterval(
      () => {
        dispatch(actions.fetchInbox());
      },
      1000 * 60 * 2
    );
  }

  render(){
    const { render } = this.props;
    const { noLocations, loading, error } = this.state;
    const currentLocation = this.getCurrentLocationFromStore();

    if(noLocations){
      return (
        <BasicPage
          headerProps={{
            showMenu: true,
            title: 'Account Setup'
          }}
          {...this.props}>
          <Alerts />
        </BasicPage>
      );
    }

    if(!currentLocation || loading){
      return (
        <Loader className="large fill" />
      );
    }

    if(error){
      return (
        <ErrorModal error={error} />
      );
    }

    return render({
      ...this.props,
      ...this.state,
      // ensure currentLocation is always coming from locations in store
      currentLocation
    });
  }
}

export default connect(
  state => {
    var { locations } = state;
    const locationsFlat = Object.keys(locations)
      .map(id => locations[id])
      .sort(
        (a, b) => {
          const aName = a && a.label ? a.label.toLowerCase().trim() : '';
          const bName = b && b.label ? b.label.toLowerCase().trim() : '';
          return aName === bName
            ? 0
            : (aName > bName)
              ? 1
              : -1;
        }
      );
    return { locationsFlat };
  }
)(LocationPageWrapper);
