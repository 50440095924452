export const LOGIN_USER = 'LOGIN_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const CHANGE_PASSWORD_USER = 'CHANGE_PASSWORD_USER';
export const FETCH_USER = 'FETCH_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ERROR_REQUESTING_USER = 'ERROR_REQUESTING_USER';
export const CHANGE_PRIMARY_UNIT = 'CHANGE_PRIMARY_UNIT';
export const UPDATE_APP_USER_METADATA = 'UPDATE_APP_USER_METADATA';

export function loginUser(auth, {email, password}) {
  return {
    type: LOGIN_USER,
    auth,
    params: {
      email,
      password
    }
  };
}

export function signupUser(auth, {email, password}) {
  return {
    type: SIGNUP_USER,
    auth,
    params: {
      email,
      password
    }
  };
}

export function changePasswordUser(auth, {email}) {
  return {
    type: CHANGE_PASSWORD_USER,
    auth,
    params: {
      email
    }
  };
}

export function requestUser({ resolve, reject, query }) {
  return {
    type: FETCH_USER,
    resolve,
    reject,
    query
  };
}

export function updateUser({user, local, resolve, reject}) {
  return {
    type: UPDATE_USER,
    user,
    local,
    resolve,
    reject
  };
}

export function receiveUser(user) {
  return {
    type: RECEIVE_USER,
    user: {
      ...user,
      isDemoGuest: (user && user.impersonated && /^(demo).*(@energycurb\.com)$/.test(user.email)),
      isDemoInstaller: (user && user.impersonated && /installers@energycurb\.com$/.test(user.email))
    }
  };
}

export function errorRequestingUser(err) {
  return {
    type: ERROR_REQUESTING_USER,
    err: err
  };
}

export function changePrimaryUnit(newUnit) {
  return {
    type: CHANGE_PRIMARY_UNIT,
    newUnit
  };
}

export function updateAppUserMetadata(data, local){
  return {
    type: UPDATE_APP_USER_METADATA,
    data: data,
    local
  };
}

// export function updateAppUserMetadata() {
//   return {
//     type: UPDATE_APP_USER_METADATA,
//     joyrideCompleted: true
//   };
// }
