import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UnitSelection from './UnitSelection';
import LiveWrapper from './LiveWrapper';
import MoreInfoLink from '../../atoms/MoreInfoLink';
import actions from '../../actions';

const { upsertUserDetail } = actions;

class Radial extends Component {
  static defaultProps = {
    location: {}
  }

  unitDropdownOnChange(unit){
    const { userDetail } = this.props;
    const { user_id } = userDetail;
    this.props.upsertUserDetail(
      {
        userDetail: {
          live_data_unit: unit,
          user_id
        },
        local: true
      }
    );
  }

  getLiveDataUnit(){
    const { userDetail, appConfig } = this.props;
    const fallback = 'W';
    return appConfig.allow_currencies
      ? userDetail.live_data_unit || fallback
      : fallback;
  }

  render(){
    const { location, billing, appConfig, className } = this.props;
    const unit = this.getLiveDataUnit();
    const linkUrl = `/live-usage/${location.id}`;
    const classes = ['radial-chart'];
    if(className){
      classes.push(className);
    }

    return (
    <div className={classes.join(' ')}>
      <div className="radial-dial-wrap">
      { 
        appConfig.allow_currencies && <UnitSelection
          unit={unit}
          direction="down"
          billing={billing}
          appConfig={appConfig}
          onChange={(unit)=>{this.unitDropdownOnChange(unit);}}
        />
      }

        <Link to={linkUrl} className="live-usage-link-radial">
          <div className="radial-background" />
          <LiveWrapper
            unit={unit}
            location={location}
            billing={billing}
           />
        </Link>
        <MoreInfoLink to={linkUrl}>
          more info&hellip;
        </MoreInfoLink>
      </div>
    </div>
    );
  }
}

export default connect(
  state => {
    const { user, userDetail, billing, appConfig } = state;
    return {
      user,
      userDetail,
      appConfig,
      billing
    };
  },
    // mapDispatchToProps
  {
    upsertUserDetail
  }
)(Radial);
