import AuthService from 'shared/dist/lib/authService';
import config from '../config';
import { HAS_SEEN_WALKTHROUGH_KEY } from '../constants';

class AppAuthService extends AuthService {
  clearStoredValues(){
    super.clearStoredAuthValues();
    [HAS_SEEN_WALKTHROUGH_KEY].forEach(localStorage.removeItem);
  }
}

const CURB = window.CURB;
const auth = CURB.auth || new AppAuthService({
  clientId: config.AUTH_CLIENT_ID,
  domain: config.AUTH_DOMAIN,
  nonce: config.AUTH_NONCE
});

// TODO - eventually remove this
// this is a patch for older versions of the iOS app
// which do not have a method called `renewAuth`
if(typeof auth.refreshExpired === 'function'){
  auth.renewAuth = auth.renewAuth || function(){
    return this.refreshExpired.apply(this, arguments);
  };
  auth.domain = CURB.env.CURB_ENV === 'staging'
    ? 'staging-energycurb.auth0.com'
    : 'energycurb.auth0.com';
}
if(typeof auth.logoutWithReentry !== 'function'){
  auth.logoutWithReentry = auth.logout;
}

export const callbackURL = AuthService.callbackURL;

export default auth;
