import React, { Component } from 'react';
import PhoneInput from 'react-phone-number-input';
import { validPhone } from '../../lib/validators';
import CurbInput from './input';
import 'react-phone-number-input/bundle/style.css'

const normalize = value => value ? value : null;

class PhoneInputComponent extends Component {
  render(){
    const {
      input,
      country,
      meta: { error, touched }
    } = this.props;

    const showError = (error && touched);

    var className = ['phone-input'];
    if(showError){
      className.push('error');
    }

    return (
      <div className={className.join(' ')}>
        <PhoneInput
          country={country}
          {...input} />
        { showError && <span className="error">{error}</span>}
      </div>
    );
  }
}

const CurbPhoneInput = props => {
  return (
    <CurbInput
      type="tel"
      validate={[validPhone]}
      normalize={normalize}
      {...props}
      component={PhoneInputComponent} />
  );
};

export default CurbPhoneInput;
