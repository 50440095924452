import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import actions from '../../actions';
import { HAS_SEEN_WALKTHROUGH_KEY } from '../../constants';

const { upsertUserDetail } = actions;

function Walkthrough({ userDetail: { user_id }, upsertUserDetail, ...props }){
  const moduleContainer = document.querySelector('.dashboard-modules > .modules');
  let _steps = [
    // Radial
    {
      title: 'Live feedback',
      disableBeacon: true,
      content: 'CURB’s radial graph shows total consumption in real time. View in watts or dollars per minute, hour, week or month.',
      target: '.radial-dial-wrap',
      placement: 'bottom'
    },
    // Live usage page
    {
      title: 'Real-time Details ',
      content: 'Tap the radial graph or "more info" to examine live usage on a circuit-by-circuit basis.  Understanding what\'s going on right now is the first step to energy intelligence.',
      target: '.radial-dial-wrap > .more-info',
      placement: 'top'
    },
    // Historical
    {
      title: 'Historical Graph',
      content: 'The historical graph shows circuit-by-circuit usage data through time.',
      target: '.historical-chart-inner',
      placement: 'bottom'
    },
    // Historical tabs
    {
      title: 'Production Details',
      content: 'If you have solar, wind or another energy source, tap the “Production” tab to discover detailed production patterns.  The “Net” tab shows how your usage and production totals interact.',
      target: '.energy-type-selection',
      placement: 'bottom'
    },
    // Recent use
    {
      title: 'Recent Use',
      content: 'Instantly see a summary of the past 24 hours.  Visualize major energy hogs and drill-down for more detail.',
      target: '.modules .recent-use',
      placement: 'top'
    },
    // Power band
    {
      title: 'Power Band',
      content: "Get a glanceable view of your home's base load, average load and peak usage; with a 24 hour visualization of whole home activity. See if you can cut your always-on appliance draw in half.",
      target: '.modules .power-band',
      placement: 'top'
    },
    // Cost breakdown
    {
      title: 'Cost Breakdown',
      content: 'Based on your recent usage, CURB provides an estimate of billable energy costs.  Select your specific rate plan in the configuration menu to improve accuracy.',
      target: '.modules .cost-breakdown',
      placement: 'top'
    },
    // Device control
    {
      title: 'Device Control',
      content: 'Link CURB to SmartThings so you can control lights and appliances directly from this dashboard.',
      target: '.modules .device-control',
      placement: 'top'
    },
    // Energy source
    {
      title: 'Energy Source',
      content: 'Know how much energy you’re pulling from the grid right now.  And if you have solar, wind or another energy source, see how much you’re producing and selling back to the grid.',
      target: '.modules .energy-source',
      placement: 'top'
    },
    // Menu
    {
      title: 'Settings Menu',
      content: <React.Fragment>
        <p>In the slide-out menu, you’ll find support information and options to configure your breaker panels and power hubs.</p>
        <p>Specify your rate plan and enter details about your home to improve the quality of your personalized reports and recommendations.</p>
      </React.Fragment>,
      target: '.header-menu-link',
      placement: 'bottom'
    }
  ];  
  const [ run, setRun ] = useState(props.run);
  const [ steps, setSteps ] = useState(_steps);
  const [ stepIndex, setStepIndex ] = useState(0);

  // the walkthrough can only be started from above, but it can't be stopped from above
  useEffect(
    () => {
      if(props.run){
        setRun(props.run);
      }
    },
    [props.run]
  );

  const on_stateChange = (e) => {
    switch(e.action){
      case 'start':
        sessionStorage.setItem(HAS_SEEN_WALKTHROUGH_KEY, 1);
        return upsertUserDetail(
          {
            userDetail: {
              force_walkthrough: false,
              walkthrough_complete: true
            },
            local: true
          }
        );
      case 'close':
      case 'reset':
        if(moduleContainer){
          moduleContainer.scrollLeft = 0;
        }
        // set run to false on close, which will cause Joyride to unmount
        setRun(false);        
        return upsertUserDetail(
          {
            userDetail: {
              force_walkthrough: false,
              walkthrough_complete: true
            },
            local: true
          }
        );
      
    }

    // scroll to appropriate module
    if(e.type === 'step:before'){
      if(e.step && e.step.target && e.step.target.match(/^\.modules /)){
        let target = document.querySelector(e.step.target);
        moduleContainer.scrollLeft = target.offsetLeft - moduleContainer.offsetWidth/2 + target.offsetWidth/2;
        window.dispatchEvent(new Event('resize'));
      }
    }

    // when successfully finished, update user detail
    if(e.type === 'tour:end'){
      moduleContainer.scrollLeft = 0;
      return upsertUserDetail(
        {
          userDetail: {
            walkthrough_complete: true,
            user_id
          }
        }
      );
    }
  };

  // unmount Joyride when closed
  if(!run){
    return null;
  }

  return <Joyride
    callback={on_stateChange}
    steps={
      steps
        .filter(({target}) => document.querySelector(target))
        .map(
          (s, i) => {
            if(i === 0){
              s.disableBeacon = true;
            }
            return s;
          }
        )
      // if we are about to run, then filter out any steps that are not part of the DOM
      // steps.filter(step => document.querySelector(step.target))
    }
    // debug={true}
    disableScrolling={true}
    disableScrollParentFix={true}
    run={run}
    continuous={true}
    scrollToFirstStep={true}
    styles={{
      options: {
        primaryColor: '#448'
      },
      tooltip: {
        maxWidth: '300px',
      },
      tooltipFooter: {
        marginTop: 0
      },
      tooltipContent: {
        padding: '0.5rem 0',
        textAlign: 'left'
      },
      buttonClose: {
        marginTop: 0
      }
    }}
    locale={{
      back: 'Previous',
      close: 'Close',
      last: 'Done',
      next: 'Next',
      skip: 'Skip'          
    }}/>;
}

export default connect(
  state => {
    const { userDetail } = state;
    return {
      userDetail
    };
  },
  {
    upsertUserDetail
  }
)(Walkthrough);
