import React, { useEffect } from 'react';
import { fetchDemoURL } from '../../lib/api';
import AuthService from 'shared/dist/lib/authService';

export default function DemoPage({ match }){
  const { params={} } = match;
  const fetch = async () => {
    const demoURL = await fetchDemoURL(AuthService.callbackURL(), params.provider);
    window.location.href = demoURL;
  }

  useEffect(
    () => fetch(),
    []
  );

  return null;
};