import React from 'react';
import d3 from 'shared/dist/lib/d3';
import Chart from 'shared/dist/ui/viz/chart';
import colors from '../../lib/colors';
import { getCurrencyFormatter, formatWatts } from '../../lib/formatters';
import { convertWatts } from '../../lib/unit-converter';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';

export default class PowerBandChart extends Chart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      resizable: true,
      autoSize: true,
      lineHeight: 0.3,
      thresholds: 25,
      margins: {
        top: 10,
        right: 0,
        bottom: 10,
        left: 0
      }
    };
  }

  init(){
    super.init();
    this.circuits = [];
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  initScales(){
    this.x = d3.scaleLinear();
    this.y = d3.scaleLinear();
  }

  fitToSize(){
    const { margins } = this.params;
    const { width, height } = this.dimensions;
    this.svg
      .attr('width', width + margins.left + margins.right)
      .attr('height', height + margins.top + margins.bottom);

    this.boxLineMin
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', height/2 - height*this.params.lineHeight/2)
      .attr('y2', height/2 + height*this.params.lineHeight/2);

    this.boxLineMax
      .attr('x1', width)
      .attr('x2', width)
      .attr('y1', height/2 - height*this.params.lineHeight/2)
      .attr('y2', height/2 + height*this.params.lineHeight/2);

    this.baseline
      .attr('x1', 0)
      .attr('y1', height/2)
      .attr('x2', width)
      .attr('y2', height/2);

    this.band = this.g
      .append('path')
      .attr('class', 'band');

    this.meanMarker = this.g
      .append('circle')
      .attr('class', 'mean-marker');

    this.interactionLine = this.g
      .append('line')
      .attr('class', 'interaction-line')
      .attr('stroke', '#fff');

    this.eventCapture
      .attr('width', width)
      .attr('height', height);

    this.clipPathRect
      .attr('width', width)
      .attr('height', height)
      .attr('transform', 'translate(1,0)');

    if(this.x && this.y){
      this.x.range([1, width-1]);
      this.y.range([height / 2, 0]);
    }

  }

  onTouchEnd(){
    this.prevTouch = null;
    this.interactionLine
      .style('visibility', 'hidden');
    clearTooltip();
  }

  initDOM(){
    const { height } = this.dimensions;
    const scrollHitboxProportion = 0.6;
    const hitboxMargins = height * (1 - scrollHitboxProportion);
    const { margins, chartClass } = this.params;

    this.svg = d3.select(this.container).append('svg')
      .attr('class', ['powerband-chart', chartClass].join(' '))
      .attr('transform-origin', '0 0 0');

    this.g = this.svg
      .append('g')
      .attr('transform', `translate(${margins.left}, ${margins.top})`)
      .on(
        'mousemove',
        (d, i, nodeList) => {
          const el = nodeList[i];
          const [x, y] = d3.mouse(el);
          if(y < hitboxMargins/2 || y > height - hitboxMargins/2){
            this.onTouchEnd();
            return;
          }
          d3.event.preventDefault();
          if(!this.prevTouch || typeof d3.event.movementX === 'undefined' || d3.event.movementX){
            const el = nodeList[i];
            const boundingBox = el.getBoundingClientRect();
            this.prevToucn = d3.event;
            this.interactionLine
              .style('visibility', 'visible')
              .attr('y1', 0)
              .attr('y2', height)
              .attr('x1', x)
              .attr('x2', x);
              if (boundingBox.width <= 184){
                showTooltip({
                  target: [x + boundingBox.left, this.y(0) + boundingBox.top],
                  content: this.renderTooltip(this.x.invert(x))
                });
              }
          }
        }
      )
      .on(
        'mouseout touchcancel', () => this.onTouchEnd(),
        { passive: true }
      );

    this.svg
      .append('defs')
      .append('linearGradient')
        .attr('id', 'area-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .selectAll('stop')
        .data([
            {offset: '0%', color: colors.powerband[0]},
            {offset: '25%', color: colors.powerband[0]},
            {offset: '50%', color: colors.powerband[1]},
            {offset: '75%', color: colors.powerband[2]},
            {offset: '100%', color: colors.powerband[2]}
        ])
      .enter().append('stop')
        .attr('offset', d => d.offset)
        .attr('stop-color', d => d.color);

    // build boxplot lines
    this.baseline = this.g
      .append('line')
      .attr('class', 'powerband-baseline')
      .style('stroke', 'url("#area-gradient")');

    this.boxLineMin = this.g
      .append('line')
      .attr('class', 'powerband-bounds powerband-min');

    this.boxLineMax = this.g
      .append('line')
      .attr('class', 'powerband-bounds powerband-max');

    this.eventCapture = this.g
      .append('rect')
      .style('visibility', 'hidden')
      .attr('x', 0)
      .attr('y', 0);

    this.clipPathRect = this.g
      .append('clipPath')
      .attr('id', 'power-band-clip-path')
        .append('rect');

    this.areaGenerator = d3.area()
      .curve(d3.curveBasis)
      .x(d => this.x(d.x0))
      .y0(d => this.y(-d.length))
      .y1(d => this.y(d.length));

    this.fitToSize();
  }

  update(data){
    const { thresholds } = this.params;
    if(data && data.length){
      this.data = data;
    }
    if(this.data && this.data.length){
      // structure the data into bins
      this.layoutData = d3.histogram()
        .thresholds(thresholds)(this.data);

      // since the data is draw from the left edge of each histogram bin
      // the right-most data point will be lost if and the graph will cut off prematurely
      // so we add an extra bin that closes the data out nicely
      const dataCap = [];
      dataCap.x0 = this.layoutData[this.layoutData.length-1].x1;
      dataCap.x1 = dataCap.x0;
      this.layoutData.push(dataCap);

      this.y
        .domain([0, d3.max(this.layoutData.map(bin => bin.length))]);

      this.x
        .domain([this.layoutData[0].x0, this.layoutData[this.layoutData.length - 1].x1]);

      this.draw();
    }
  }

  valueAccessor(d){
    return d.kwhr;
  }

  renderTooltip(watts){
    const { simple_kwh_price, currency, allow_currencies } = this.params;
    // const [date, values] = d;
    return (
      <div className="power-band-tooltip">
        <div className="power-band-header">24 Hour Snapshot</div>
        <div className="label">ALL CIRCUITS</div>
        <div className="body">{formatWatts(watts)} watts</div>
        {
          allow_currencies ? (
            <div className="price">
              {
                getCurrencyFormatter(currency)(
                  convertWatts({
                    watts,
                    unit: '$/hour',
                    simple_kwh_price: simple_kwh_price
                  })
                )
              }
              <span className="time"> (per hour)</span>
            </div>
          ) : <div className="price" />
        }
      </div>
    );
  }

  getCircuitById(id){
    return this.circuits.find(c => c.id === id) || {};
  }

  draw(){
    this.band
      .datum(this.layoutData)
      .attr('d', this.areaGenerator)
      .style('fill', 'url(#area-gradient)');

    this.meanMarker
      .attr('cx', this.x(d3.mean(this.data)))
      .attr('cy', this.y(0))
      .attr('r', 2)
      .attr('stroke', '#000')
      .attr('stroke-width', 2)
      .attr('fill', 'none');


    // this.append('path')
    //   .datum(data)
    //   .attr('class', 'violin-plot-area')
    //   .attr('d', area)
    //   .style('fill', 'url(#area-gradient)');

    // this.arcsEnter
    //   .attr('fill', d => {
    //     return this.getCircuitById(d.data.id).color || '#000';
    //   })
    //   .on('mouseover', (d, i, nodeList) => {
    //     showTooltip({
    //       target: nodeList[i],
    //       margin: 10,
    //       className: 'recent-use-tooltip',
    //       content: this.renderTooltip(d)
    //     })
    //   })
    //   .on('mouseout', (d, i, nodeList) => {
    //     clearTooltip();
    //   })
  }
}
