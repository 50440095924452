export const RECEIVE_USER_DETAIL = 'RECEIVE_USER_DETAIL';
export const UPSERT_USER_DETAIL = 'UPSERT_USER_DETAIL';
export const UPDATE_USER_NOTIFICATION_SETTINGS = 'UPDATE_USER_NOTIFICATION_SETTINGS';

export function receiveUserDetail(userDetail) {
  return {
    type: RECEIVE_USER_DETAIL,
    userDetail
  };
}

export function upsertUserDetail({userDetail, local, resolve, reject}) {
  return {
    type: UPSERT_USER_DETAIL,
    userDetail,
    local,
    resolve,
    reject
  };
}

export function updateUserNotificationSettings(notificationselections) {
  return {
    type: UPDATE_USER_NOTIFICATION_SETTINGS,
    notificationselections
  };
}