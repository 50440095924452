import React, { Component } from 'react';
import AppHeader from '../../components/Structure/AppHeader';
import Menu from '../../components/Menu';

export default class BasicPage extends Component {
  render(){
    const { headerProps={} } = this.props;
    var className = this.props.className;
    if(!Array.isArray(className)){
      className = [className];
    }
    className.push('basic-page');

    const menuProps = {
      ...this.props,
      basic: true
    };

    return (
      <div className={className.join(' ')}>
        <AppHeader {...headerProps} {...this.props} />
        <main>
          {
            React.Children.map(
              this.props.children,
              Child => {
                if(typeof Child.type === 'function'){
                  return React.cloneElement(
                    Child,
                    {
                      ...this.props,
                      ...Child.props
                    }
                  );
                }
                return Child;
              }
            )
          }
        </main>
        <Menu {...menuProps} />
      </div>
    );
  }
}
