export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV !== 'production' || process.env.NODE_ENV === 'development';
export const hasTouch = (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
export const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0;
export const isSmallScreenWidth = function(){
  return window.innerWidth < 800;
};
export const getPlatform = function(){
  const platform = localStorage.getItem('platform');
  return platform ? platform.toLowerCase() : 'browser';
};
export const isFromNativeApp = function(){
  const platform = getPlatform();
  return platform && platform !== 'browser';
};

export const isNativeApp = window.location.protocol === 'file:' && !!window.cordova;

export const isAndroid = function(){
  return /(android)/i.test(navigator.userAgent);
};
