import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import d3 from 'shared/dist/lib/d3';
import Loader from '../../atoms/Loader';
import PowerBandChart from '../../ui/viz/powerband';
import { getCurrencyFormatter, formatWatts } from '../../lib/formatters';
import { convertWatts } from '../../lib/unit-converter';
import { fetchHistorical } from '../../lib/api';
import { getConsumptionSeries } from 'shared/dist/utils/historical';

class PowerBand extends Component {

  static defaultProps = {
    location: {},
    billing: {},
    appConfig: {}
  }

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      consumptionValues: []
    };
  }

  componentDidMount(){
    this.fetchData();
  }

  initGraph(){
    if(this.graph){
      this.graph.destroy();
    }
    if(this.chartContainer){
      this.graph = new PowerBandChart(
        this.chartContainer,
        {}
      );
      this.updateChartData();  
    }
  }

  updateChartData(props=this.props, consumptionValues=this.state.consumptionValues){
    const { appConfig, billing } = props;
    const { allow_currencies } = appConfig;
    const { simple_kwh_price, simple_currency_code } = billing;
    if(this.graph){
      this.graph.updateParams({
        simple_kwh_price,
        simple_currency_code,
        allow_currencies
      });
      this.graph.update(consumptionValues);
    }
  }

  fetchData(props=this.props){
    const { location } = props;
    if(location.id || location.id !== this.props.location.id){
      this.setState({
        loading: true
      });
      fetchHistorical(location.id, '1d', '5m', { omitPartials: false })
        .then(timeSeries => {
          let values = getConsumptionSeries(timeSeries, { removeEmpties: true }).map(s => s.w);
          if(values.length < 2){
            throw new Error('No data');
          }
          this.setState({
            consumptionValues: values.map(
              v => !isNaN(v) ? v : 0
            )
          });
          this.updateChartData(this.props, values);
        })
        .catch(
          err => {
            console.error(err);
            this.setState({
              error: err.message
            });
          }
        )
        .then(
          () => this.setState({
            loading: false
          })
        );
    }
  }

  componentDidUpdate(prevProps){
    const { billing } = this.props;
    const prevBilling = prevProps.billing || {};
    if(this.graph && (billing.simple_kwh_price !== prevBilling.simple_kwh_price || billing.simple_currency_code !== prevBilling.simple_currency_code)){
      this.updateChartData();
    }
  }

  getMeanStyle() {
    const { consumptionValues } = this.state;
    if(consumptionValues && this.graph){
      let labelWidth = 100;

      let width = this.graph.dimensions.width;
      let mean = d3.mean(consumptionValues);
      let min = Math.min(...consumptionValues);
      let max = Math.max(...consumptionValues);
      let meanPosition = (mean-min)/(max-min) * width;

      let getPlacement = () => {
        if(min === max){
          return 'center';
        }
        const quantWidth = (max - min)/4;
        var i = 1;
        while(i * quantWidth < mean){
          i++;
        }
        if(i < 2){
          return 'left';
        }
        if(i >= 2 && i <= 3){
          return 'center';
        }
        return 'right';
      };

      const placementStyle = {
        left: {
          transform: `translate(${Math.max(meanPosition, 0)}px,0)`,
          textAlign: 'left'
        },
        'center': {
          transform: `translate(${meanPosition - labelWidth/2}px,0)`,
          textAlign: 'center'
        },
        'right': {
          transform: `translate(${Math.min(meanPosition - labelWidth, width)}px,0)`,
          textAlign: 'right'
        }
      };

      return {
        ...placementStyle[getPlacement()],
        width: `${labelWidth}px`
      };
    }
    return {
      display: 'none'
    };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState){
    const { consumptionValues } = nextState;
    if(consumptionValues && consumptionValues.length){
      this.initGraph();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { appConfig, location} = nextProps;
    const { allow_currencies } = appConfig;

    if(this.graph && allow_currencies !== this.props.appConfig.allow_currencies){
      this.graph.updateParams({
        allow_currencies
      });
    }

    if(location.id !== this.props.location.id){
      this.setState({
        consumptionValues: []
      });
      this.fetchData(nextProps);
    }
  }

  render() {
    const { appConfig , billing} = this.props;
    const { consumptionValues, loading } = this.state;
    const { simple_kwh_price, simple_currency_code } = billing;

    const classes = ['power-band', 'module'];
    if(loading){
      classes.push('loading');
    }

    return (
      <div className={classes.join(' ')} aria-live="polite">
        <p className="module-title"><i className="fa fa-tachometer" /> Power Band</p>

          <div
            className="module-inner chart-container"
            ref={c => (this.chartContainer = c)}>

            {
              (loading)
                ? <Loader />
                : (!consumptionValues || !consumptionValues.length)
                  ? (
                      <div className="error">
                        <h3>No data</h3>
                      </div>
                    )
                  : (
                    <div className="violin-labels">
                      <div className="mean" style={this.getMeanStyle()}>
                        <span className="title" >Average </span>
                        <span className="watts" aria-live="polite">{formatWatts(d3.mean(consumptionValues))} w</span>
                      </div>
                      <div className="min-max">
                        <div className="min">
                          <span className="title">Base Load </span>
                          <span className="watts" aria-live="polite">{formatWatts(Math.min(...consumptionValues))}  w</span>
                          {
                            appConfig.allow_currencies ? (
                              <span className="dollars" aria-live="polite">
                                {
                                  getCurrencyFormatter(simple_currency_code)(
                                    convertWatts({
                                      watts: Math.min(...consumptionValues),
                                      unit: '$/month',
                                      simple_kwh_price
                                    })
                                  )
                                }
                                <span className="monthly">(monthly)</span>
                              </span>
                            ) : null
                          }
                        </div>
                        <div className="max">
                            <span className="title">Peak Use </span>
                            <span className="watts">{formatWatts(Math.max(...consumptionValues))} w</span>
                        </div>
                      </div>
                    </div>
                  )
            }
          </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const { appConfig, billing } = state;
    return {
      appConfig,
      billing
    };
  }
)(PowerBand);
