import actionTypes from '../actions';

// location
export default (state={}, action) => {
  const { type, allLocations, location={}, locationId, metadata } = action;
  switch(type){
    case actionTypes.UPDATE_LOCATION:
      // if we are setting a location to current, set all others to current: false
      if(location.current){
        Object.keys(state).forEach(
          id => {
            if(id !== locationId){
              state[id].current = false;
            }
          }
        );
      }
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          ...location
        }
      };
    case actionTypes.UPDATE_LOCATION_METADATA:
      return {
        ...state,
        [locationId]: {
          ...(state[locationId] || {}),
          metadata: {
            ...(state[locationId].metadata || {}),
            ...metadata
          }
        }
      };
    case actionTypes.SET_CURRENT_LOCATION:
      Object.keys(state).forEach(id => {
        state[id].current = id === locationId;
      });
      return state;
    case actionTypes.RECEIVE_ALL_LOCATIONS:
      Object.keys(allLocations).forEach(
        key => {
          allLocations[key] = {
            metadata: {},
            ...allLocations[key]
          };
        }
      );
      return allLocations;
    default:
      return state;
  }
};
