import React, {useState} from 'react';
import Toggle from '../../atoms/Toggle';
import * as APICalls from '../../lib/api';
import {analyticsDevice} from '../../lib/analytics';
import actions from '../../actions';
import { dispatch } from '../../store';

const { updateUserNotificationSettings } = actions;
const defaultSettings = ['solar_assurance', 'hub_status', 'usage_tier_warning', 'usage_tier_exceeded', 'usage_tier', 'load_control_status', 'load_control_error'];

const NotificationsMenu = ({userDetail, notification_types}) => {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [notificationTypesResponse, updateNotificationTypesResponse] = useState(false);
  // hiddenNotificationTypes will track types that the user has from other fleets but not the current fleet
  const [hiddenNotificationTypes, setHiddenNotificationTypes] = useState([]);
  let notificationsettings = [];
  if(userDetail.notificationsettings && userDetail.notificationsettings.notification_types) {
    notificationsettings = [...userDetail.notificationsettings.notification_types];
  } else {
    notificationsettings = [...defaultSettings];
  }

  const handleChange = async () => {
    window.ga && window.ga('send', 'event', 'notificationsMenu-'+analyticsDevice(), 'toggle changed', window.CURB.provider);
    const notification_settings = [].slice.call(document.querySelectorAll('.notificationsMenuToggle input:checked')).map((el) => el.name);
    notification_settings.push(...hiddenNotificationTypes);
    const { user_id } = userDetail;
    await APICalls.updateNotificationSettings({
      notification_settings,
      user_id
    })
    .then(() => {
      dispatch(updateUserNotificationSettings(notification_settings));
    })
    .catch(()=> false);
  };

  if(!notificationTypesResponse){
    (async () => {
      try {
        const notifications_transformed = Object.keys(notification_types).map(k=> {
          return {
            key: k,
            label: notification_types[k].user_friendly_name || k    
          };
        });
        let holder = [];
        notificationsettings.forEach(k => {
          if(!Object.keys(notification_types).includes(k)){
            holder.push[k];
          }
        });
        if(holder.length) {
          setHiddenNotificationTypes([...holder]);
        }
        updateNotificationTypesResponse(true);
        setNotificationTypes(notifications_transformed);            
      } catch(e){
        updateNotificationTypesResponse(true);
        console.error(e && e.name);
      }
    })();
  }

  let userNotificationSettings = [];
  if(notificationTypes && notificationTypes.length){
    userNotificationSettings = notificationTypes.reduce((a,{key='', label=''})=>{
      return [...a, {
        key,
        value: notificationsettings && notificationsettings.includes(key),
        label: label ? label.replace(/_/g, ' ') : key.replace(/_/g, ' ')
      }];
    }, []);
  }

  const notificationList = (notifications, handleChange) => {
    if(notifications.length) {
      return notifications.map((notification) => {
        return (
          <li key={notification.key}>
            <div className={"anchor white fake-link"} >
              <i className="fa fa-fw fa-info-circle" />
              <Toggle 
                name={notification.key}
                className={'notificationsMenuToggle'} 
                label={notification.label} 
                initialCheckedState={notification.value} 
                onChange={handleChange} />
            </div>
          </li>
        );
      });
    }
    return (
      <li>
        <div className={"anchor white fake-link"} >
          <li className="loading"><span className="loader-inline blue" /></li>
        </div>
      </li>);
  };

  return (
    <ul className="sub-list">
      {notificationList(userNotificationSettings, handleChange)}
    </ul>
  );
};

export default NotificationsMenu;
