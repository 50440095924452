import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from './LegacyModal';

export default class ErrorModal extends Component {
  render(){
    const { error } = this.props;
    const errorMessage = this.props.error.hasOwnProperty('message')
      ? error.message
      : (error)
        ? error
        : 'An unknown error occurred';

    return (
      <Modal
        isOpen={true}
        footer={
          (
            <footer>
              <button className="cancel"><Link to="/logout"><strong>Logout</strong></Link></button>
              <button className="agree" onClick={() => window.location.reload()}><strong>Try again</strong></button>
            </footer>
          )
        }
        className="error-modal">
        <div className="error-modal-content center">
          <h3 className="error">
            There was a problem loading the application
          </h3>
          <div className="modal-content">
            <p>
              {errorMessage}
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
