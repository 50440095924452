import { PLATFORM_KEY } from 'shared/dist/lib/authService';
import { CLICKED_EXTERNAL_URL_KEY } from './constants';
import eventEmitter from './event-emitter';
import auth from './lib/auth';
import { getPlatform } from './lib/environment-check';

window.CURB = {
  ...(window.CURB || {}),
  newNotification: function(message){
    eventEmitter.emit(
      'newNotification',
      message
    );
  },
  receiveMessage: function({messageId}){
    // fetch inbox
    eventEmitter.emit(
      'receiveMessage',
      messageId
    );
  },
  // called by android
  navigateToMessageDetail: function({messageId}){
    eventEmitter.emit(
      'navigateToMessageDetail',
      messageId
    );
  },
  setPlatform: function(platform){
    window.localStorage.setItem(PLATFORM_KEY, platform);
    if(platform && platform !== getPlatform()){
      document.body.classList.add(platform.toLowerCase());
    }
    if(platform && platform !== 'browser'){
      document.documentElement.classList.add('native');
    }
  },
  getUrl: function(){
    return window.location.href;
  },
  getToken: function(){
    return auth.getToken();
  },
  getClickedExternalURL: function(){
    return window.localStorage.getItem( CLICKED_EXTERNAL_URL_KEY );
  },
  clearClickedExternalURL: function(){
    return window.localStorage.removeItem( CLICKED_EXTERNAL_URL_KEY );
  }
};
