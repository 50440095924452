import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../atoms/LegacyModal';
import eventEmitter from '../../event-emitter';

export default class DeleteModal extends Component {
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.modalIsOpen !== this.props.modalIsOpen){
      eventEmitter.emit(nextProps.modalIsOpen ? 'showDeleteModal' : 'hideDeleteModal');
    }
  }
  render(){
    const { modalIsOpen, closeModal, onClickConfirm, selectedIds } = this.props;
    const isMultiple = selectedIds && selectedIds.length > 1;
    return (
      <Modal
        close={closeModal}
        isOpen={modalIsOpen}
        className="messages-delete-modal"
        header={null}
        footer={(
          <footer>
            <button
              className="button-cancel"
              onClick={closeModal}>Cancel</button>
            <button
              className="button-confirm"
              onClick={onClickConfirm}><strong>Delete</strong></button>
          </footer>
        )}>
        <div className="eula-content">
          <h3>Delete {isMultiple ? 'Messages' : 'Message'}</h3>
          <p>
            This will permanently delete {
              isMultiple ?
              'the selected messages' :
              'this message'
            }.
          </p>
        </div>
      </Modal>
    );
  }
}
