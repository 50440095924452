import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import UserDetailForm from '../User/UserDetailForm';
import UpdateProfileMenu from './UpdateProfileMenu';
import UpdatePasswordMenu from './UpdatePasswordMenu';
import Header from '../../atoms/Header';

class ProfileMenu extends Component{
  static defaultProps = {
    location: {}
  }

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      APIError: null,
      utilities: []
    };
  }

  renderSection(){
    const { user, history, match, profileSubsection } = this.props;
    switch(profileSubsection){
      case 'update_profile':
        return (
          <UpdateProfileMenu {...this.props} />
        );
      case 'update_password':
        return (
          <UpdatePasswordMenu {...this.props} />
        );
      default:
        return (
          <div className="full-height menu-section-dark">
            <Header clickTo={history.goBack} title="Profile" />
            <div className="menu-content">
              <section className="subnav">
                <ul className="link-list">
                  <li>
                    <Link to={`${match.url}/update_profile`}>
                      <div className="value">
                        <label>Email</label>
                        <span>{user.email}</span>
                      </div>
                      <i className="fa fa-fw fa-pencil" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${match.url}/update_password`}>
                      <div className="value">
                        <label>Password</label>
                        <span>********</span>
                      </div>
                      <i className="fa fa-fw fa-pencil" />
                    </Link>
                  </li>
                </ul>
              </section>

              <section className="user-details">
                <UserDetailForm
                  {...this.props}
                  hideEmail={true}
                  autoSave={true} />
              </section>
            </div>
          </div>
        );
    }
  }

  render(){
    const { user, history, match } = this.props;
    const basePath = match.path.replace(/\?$/g, '');
    return <Switch>
      <Route path={`${basePath}/update_profile`} render={p => <UpdateProfileMenu {...this.props} />} />
      <Route path={`${basePath}/update_password`} render={p => <UpdatePasswordMenu {...this.props} />} />
      <Route>
        <div className="full-height menu-section-dark">
          <Header clickTo={history.goBack} title="Profile" />
          <div className="menu-content">
            <section className="subnav">
              <ul className="link-list">
                <li>
                  <Link to={`${match.url}/update_profile`}>
                    <div className="value">
                      <label>Email</label>
                      <span>{user.email}</span>
                    </div>
                    <i className="fa fa-fw fa-pencil" />
                  </Link>
                </li>
                <li>
                  <Link to={`${match.url}/update_password`}>
                    <div className="value">
                      <label>Password</label>
                      <span>********</span>
                    </div>
                    <i className="fa fa-fw fa-pencil" />
                  </Link>
                </li>
              </ul>
            </section>

            <section className="user-details">
              <UserDetailForm
                {...this.props}
                hideEmail={true}
                autoSave={true} />
            </section>
          </div>
        </div>
      </Route>
    </Switch>
  }
}

export default connect(
  state => {
    const { user } = state;
    return {
      user
    };
  }
)(ProfileMenu);
