import React from 'react';
import PieChart from 'shared/dist/ui/viz/pie';
import { registerIsConsumption } from 'shared/dist/utils/hardware';
import { getCurrencyFormatter, formatPercentage, formatkWhs } from '../../lib/formatters';
import { showTooltip, clearTooltip } from '../../atoms/tooltips/Tooltip';

export default class RecentUseChart extends PieChart {
  get defaultParams(){
    return {
      ...super.defaultParams,
      resize: true,
      margins: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    };
  }

  init(){
    super.init();
    this.circuits = [];
  }

  // to make room for the arcs to grow a bit on rollover without overflowing, we shrink the whole chart down by 5%
  innerRadius(radius){
    return radius * .95 / 2.5;
  }

  outerRadius(radius){
    return radius * .95;
  }

  updateRegisters(circuits){
    this.circuits = circuits;
    if(this.data && this.data.length){
      this.draw();
    }
  }

  update(data){
    if(data && data.length){
      super.update(data);
      this.total = this.data.reduce((acc, v) => acc + (v.kwhr || 0), 0);
    }
  }

  valueAccessor(d){
    return d.kwhr;
  }

  preTransformData(data){
    return data.filter(registerIsConsumption);
  }

  renderTooltip(d){
    const { simple_kwh_price, currency, allow_currencies } = this.params;
    const circuit = this.getCircuitById(d.data.id);
    // const [date, values] = d;
    return (
      <div>
        <div className="date-time">
          Last 24 hours
        </div>
        <div className="highlight">
          <div className="name">
            <span className="key" style={{backgroundColor: circuit.color}} />
            <span className="title">{circuit.label}</span>
          </div>
          <div className="values">
            <div className="watts">
              {formatkWhs( d.data.kwhr )} kWh
            </div>
            {
              allow_currencies ? (
                <div className="dollars">
                  {getCurrencyFormatter(currency)( d.data.kwhr * simple_kwh_price )}<span className="daily">(per day)</span>
                </div>
              ) : null
            }
            <div className="totals">
              {formatPercentage(d.data.kwhr / this.total)}% of total
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCircuitById(id){
    return this.circuits.find(c => c.id === id) || {};
  }

  draw(){
    super.draw();
    this.arcsEnter
      .attr('fill', d => {
        return this.getCircuitById(d.data.id).color || '#000';
      })
      .on('mouseover', (d, i, nodeList) => {
        showTooltip({
          target: nodeList[i],
          margin: 10,
          sticky: true,
          className: 'recent-use-tooltip',
          content: this.renderTooltip(d)
        });
      })
      .on('mouseout', () => {
        clearTooltip();
      });
  }
}
