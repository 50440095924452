import React, { Component } from 'react';
import { connect } from 'react-redux';
import BasicPage from '../BasicPage';
import Alerts from '../../atoms/Alerts';
import { getSmartthingsUrl, getAccessTokenWithCode } from '../../lib/api';
import withRouterProps from '../../components/base/WithRouterProps';
import { parseQueryString } from 'shared/dist/utils/url';

class SmartThingsPageContent extends Component {
  checkForAuthCode(){
    var params = parseQueryString(routerLocation.search);
    if (params.state === 'smartthings_auth_code'){
      var refreshuri = window.location.href.split('?')[0];
      this.setState({authorized: true});
      getAccessTokenWithCode(params.code, params.state, refreshuri)
        .then(
          config => {
            this.setState({
              STConfig: config,
              authorized: true
            });
            window.location = window.location.href.split('?')[0].replace('smartthings','dash');
          }
        );
    }
  }
  handleSubmit(){

    getSmartthingsUrl(window.location.href,this.refs.smartthingsclientid.value,this.refs.smartthingsclientsecret.value)
     .then(arg => {
       this.setState({
         smartthingsURL: arg.url,
         clientId: this.refs.smartthingsclientid.value,
         clientSecret: this.refs.smartthingsclientsecret.value
       });
       window.location.href = arg.url;
     });
  }

  renderSmartAppCode(){
    return `definition(
    name: "Curb Control",
    namespace: "curb",
    author: "Savanni D&#39;Gerinel",
    description: "Control point for Curb/SmartThings integration",
    category: "Green Living",
    iconUrl: "https://s3.amazonaws.com/smartapp-icons/Convenience/Cat-Convenience.png",
    iconX2Url: "https://s3.amazonaws.com/smartapp-icons/Convenience/Cat-Convenience@2x.png",
    iconX3Url: "https://s3.amazonaws.com/smartapp-icons/Convenience/Cat-Convenience@2x.png",
    oauth: true)

preferences {
	section("Allow Endpoint to Control These Things...") {
		input "switches", "capability.switch", title: "Which Switches?", multiple: true, required: false
	}
}

mappings {

	path("/switches") {
		action: [
			GET: "listSwitches"
		]
	}
	path("/switches/:id") {
		action: [
			GET: "showSwitch"
		]
	}
	path("/switches/:id/:command") {
		action: [
			PUT: "updateSwitch"
		]
	}
}

def installed() {}

def updated() {}

def listSwitches() {
	switches.collect{device(it,"switch")}
}

def showSwitch() {
	show(switches, "switch")
}

void updateSwitch() {
	update(switches)
}

def deviceHandler(evt) {}

private void update(devices) {
    def command = params.command
	if (command)
    {
		def device = devices.find { it.id == params.id }
		if (!device) {
			httpError(404, "Device not found")
		} else {
        	if(command == "toggle")
       		{
            	if(device.currentValue('switch') == "on")
                  device.off();
                else
                  device.on();
       		}
		}
	}
}

private show(devices, type) {
	def device = devices.find { it.id == params.id }
	if (!device) {
		httpError(404, "Device not found")
	}
	else {
		def attributeName = type == "motionSensor" ? "motion" : type
		def s = device.currentState(attributeName)
		[id: device.id, label: device.displayName, value: s?.value, unitTime: s?.date?.time, type: type]
	}
}

private device(it, type) {
	it ? [id: it.id, label: it.label, type: type] : null
}
    `;
  }

  UNSAFE_componentWillMount(){
    this.checkForAuthCode();
  }

  render(){

    return (
      <BasicPage
        className="smartthings-page"
        headerProps={{
          showMenu: true,
          title: 'Account Setup'
        }}
        {...this.props}>
        <div className="smartthings-page-wrapper">
          <Alerts/>
          <div className="smartthings-installation-instructions">
            <p className="smartthings-disclaimer">
              Due to cybersecurity updates at SmartThings, we need to take some extra steps to allow SmartThings control from the Curb dashboard
            </p>
            <h3> Install The Curb SmartApp</h3>
            <p>Log into your <a href="https://graph.api.smartthings.com/" target="_blank"> SmartThings account </a> and click <b>My SmartApps</b></p>
            <p>Click the green <b>+ New SmartApp </b> button in the top right corner</p>
            <p>Select the <b>From Code</b> tab, and copy the code below into the text editor</p>
            <pre className="smartapp-code">
              {this.renderSmartAppCode()}
            </pre>
            <p> Click the <b>Create</b> button</p>
            <h3> Authorize your SmartThings Account</h3>
            <p>Click <b>App Settings</b>, then <b>OAuth</b>, then <b>Enable OAuth in Smart App</b>, then <b>Update</b></p>
            <p>Copy your OAuth Client Id and Oauth Client Secret into the input fields below, and click <b>Generate Authorization Link</b></p>
            </div>
          <form id="smartthings-auth-creds curb-form" >
          <div className="smartthings-auth-creds-authorize">
            <button type="button" onClick={this.handleSubmit.bind(this)}> Authorize </button>
          </div>
            <div className="smartthings-auth-creds-left">
              <div className="smartthings-auth-creds-input">
                <span className="smartthings-auth-creds-input-label">Client ID : </span>
                <input ref="smartthingsclientid" placeholder="Client ID" />
              </div>
              <div className="smartthings-auth-creds-input">
                <label className="smartthings-auth-creds-input-label">Client Secret : </label>
                <input ref="smartthingsclientsecret" placeholder="Client Secret" />
              </div>
            </div>
          </form>
        </div>
      </BasicPage>
    );
  }
}

export default connect(
  // mapStateToProps
  (state) => {
    const { locations, alerts } = state;
    return {
      alerts,
      locations
    };
  },
)(withRouterProps(SmartThingsPageContent));
