import moment from 'moment';
import currencies from 'shared/dist/lib/currencies';

export function wait(ms){
  return new Promise(
    resolve => {
      setTimeout(
        resolve,
        ms
      );
    }
  );
}

export function APIErrorToMessage(APIError){
  if(typeof APIError === 'object'){
    const { status, message, body} = APIError;
    return {
      category: 'error',
      title: [status, message].filter(i => i).join(' - '),
      content: body && body.message ? body.message : null
    };
  }
}

export function getBillingCycleStart(billingday_of_month=1){
  const nowMoment = moment();
  var month = nowMoment.month();
  var year = nowMoment.year();
  if(nowMoment.date() < billingday_of_month){
    month -= 1;
    if(month < 0){
      year -= 1;
      month = 11;
    }
  }
  return moment().year(year).month(month).date(billingday_of_month).startOf('day');
}

export function getTotalCost(cost={}){
  return cost && cost.summary
    ? cost.summary.adjustedTotalCost
    : cost.totalCost;
}

export function getHistoricalParamsSince(startDateMoment){
  // hours since the start of the billing cycle
  const hours = moment().diff(startDateMoment, 'hours');
  return {
    range: `${hours}h`,
    resolution: hours > 24 ? 'd' : 'h'
  };
}

export function getMessageIdentifier(message={}){
  return message.id;
}

export function getMessageSubject(message={}){
  return message.message.subject
    ? message.message.subject
    : (message.subject)
      ? message.subject
      : 'No subject';
}

export function getMessageHTML(message={}){
  try {
    if(!message.message.html){
      throw new Error('No html key found, defaulting to message');
    }
    return message.message.html;
  } catch(err) {
    const fallback = 'No content';
    return (message && message.message)
      ? (message.message.default)
        ? message.message.default
        : fallback
      : fallback;
  }
}

export function getCurrencyConfig(currency='USD'){
  return currencies[currency];
}

export function getAppUserMetadata(user){
  return user && user.user_metadata && user.user_metadata.app
    ? user.user_metadata.app
    : {};
}

export function flattenLocations(locations){
  return Object.keys(locations)
    .map(id => locations[id])
    .sort(
      (a, b) => {
        const aName = a && a.label ? a.label.toLowerCase().trim() : '';
        const bName = b && b.label ? b.label.toLowerCase().trim() : '';
        return aName === bName
          ? 0
          : (aName > bName)
            ? 1
            : -1;
      }
    );
}

export function getDefaultLocation({userDetail, locations={}, locationsFlat=[]}){
  let { default_location } = userDetail;
  if(!locationsFlat.length){
    locationsFlat = Object.keys(locations).map(
      key => locations[key]
    );
  }
  if(default_location){
    let location = locationsFlat.find(l => l.id === default_location);
    if(location){
      return location;
    }    
    console.warn(`User ${userDetail.email_address} has a default location ${default_location}, but they do not have access to a location with that id`);
  }
  return locationsFlat[0];
}

// export function ignoreStateKeys(...stateKeys){
//   return {
//     areStatesEqual: function(next, prev){
//       return
//     }
//   }
// }
//
// export function bindToStateKeys(...stateKeys){
//   return {
//     pure: true,
//     areStatesEqual: function(next, prev){
//       for(var i=0; i<stateKeys.length; i++){
//         let key = stateKeys[i];
//         if(next[key] !== prev[key]){
//           return false;
//         }
//       }
//       return true;
//     }
//   }
// }
