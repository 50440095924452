import { isValidHubSerial } from 'shared/dist/utils/hardware';
import { emailRegex } from 'shared/dist/utils/validators';
import { isValidNumber } from 'libphonenumber-js';
import postCodeRegexs from 'shared/dist/lib/countries/regexs';

export const required = value => (value || typeof value === 'number' ? undefined : 'Required');
export const hubSerial = value => isValidHubSerial(value) ? undefined : 'Invalid hub serial';
export const integer = value => /[^0-9-]/ig.test(value) ? 'Must be an integer' : undefined;
export const positiveInteger = value => /[^0-9]/ig.test(value) ? 'Must be a positive integer' : undefined;
export const notEmpty = (message='Required') => values => !values || !values.length ? message : undefined;
export const validPhone = value => !isValidNumber(value || '') ? 'Invalid phone number' : undefined;
export const validEmail = value => !value.match(emailRegex) ? 'Invalid email address' : undefined;
export const validPostcode = (value, countryCode='US') => {
  // value = value.toString();
  const regex = postCodeRegexs[countryCode];
  if(!regex){
    return undefined;
  }
  return !new RegExp(`^${regex}$`).test(value)
    ? 'Invalid postcode'
    : undefined;
};
