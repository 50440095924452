import {
  call,
  // fork,
  put
} from 'redux-saga/effects';
import actions from '../actions';
import * as APICalls from '../lib/api';

// WORKER SAGAS
export function* loginUser({
  auth,
  params
}) {
  try {
    yield call([auth, auth.login], params);
  } catch (err) {
    yield put(actions.clearAllAlerts());
    yield put(actions.addAlert({
      category: 'error',
      title: 'Login Failed',
      content: 'Incorrect Username or Password'
    }));
  }
}

export function* signupUser({
  auth,
  params
}) {
  try {
    yield call([auth, auth.signup], params);
  } catch (err) {
    yield put(actions.clearAllAlerts());
    yield put(actions.addAlert({
      category: 'error',
      title: 'Signup Failed',
      content: err.message
    }));
  }
}

export function* changePasswordUser({
  auth,
  params
}) {
  try {
    const message = yield call([auth, auth.changePassword], params);
    yield put(actions.addAlert({
      category: 'success',
      title: 'Password change email sent',
      content: message
    }));
  } catch (err) {
    yield put(actions.clearAllAlerts());
    yield put(actions.addAlert({
      category: 'error',
      title: 'Password change Failed',
      content: err.message
    }));
  }
}

export function* updateUser({
  user,
  resolve,
  local,
  reject
}) {
  try {
    if(!local){
      const updatedUser = yield call(APICalls.updateUser, user);
      if(user.email){
        yield put(
          actions.upsertUserDetail({
            userDetail: {
              email_address: user.email
            },
            local: true
          })
        );
      }
      if(typeof resolve === 'function'){
        yield call(resolve, updatedUser);
      }
    }
  } catch(err) {
    console.error(err);
    yield put(actions.APIError(err));
    yield call(reject, err);
  }
}

export function* updateAppUserMetadata({
  data,
  local
}) {
  if (!local) {
    try {
      yield call(
        APICalls.updateUserMetadata, {
          app: data
        }
      );
    } catch (err) {
      yield put(actions.APIError(err));
    }
  }
}

export function* fetchUser({
  resolve,
  reject,
  query
}) {
  try {
    const response = yield call(APICalls.fetchUser, query);
    const { locations=[], user={}, userDetail={} } = response || {};

    let locationsById = {};
    // group locations into an object keyed by id
    locations
      .forEach(
        loc => {
          locationsById[loc.id] = loc;
        }
      );
    yield put(actions.receiveAllLocations(locationsById));
    yield put(actions.receiveUserDetail(userDetail));
    yield put(actions.receiveUser(user));
    if(typeof resolve === 'function'){
      yield call( resolve, { locations, user, userDetail });
    }
  } catch (err) {
    yield put(actions.addAlert({
      category: 'error',
      title: [err.status, err.message].filter(i => i).join(' - '),
      content: 'There was an error fetching user state from the server'
    }));
    if(typeof reject === 'function'){
      yield call( reject, err );
    }
  }
}
