import actionTypes from '../actions';
import omit from 'lodash.omit';

export default (state={unit: 'W'}, action) => {
  const { type, user, newUnit, data } = action;
  switch(type){
    case actionTypes.FETCH_USER:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.RECEIVE_USER:
      return {
        ...state,
        ...user,
        isLoading: false
      };
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        // TODO, maybe rethink this. try not to send this kind of stuff down to the reducer
        ...omit(user, 'password', 'new_password', 'confirm_new_password')
      };
    case actionTypes.UPDATE_APP_USER_METADATA:
      return {
        ...state,
        user_metadata: {
          ...state.user_metadata,
          app: {
            ...(state.user_metadata || {}).app,
            ...data
          }
        }
      };
    case actionTypes.CHANGE_PRIMARY_UNIT:
      return {
        ...state,
        unit: newUnit
      };
    default:
      return state;
  }
};
