import React, { Component } from 'react';
import throttle from 'lodash.throttle';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { TooltipComponent } from '../atoms/tooltips/Tooltip';
import { isSmallScreenWidth, isFromNativeApp, getPlatform } from '../lib/environment-check';
import { hasTouchEvents, isMobileLandscape } from '../lib/feature-detection';
import {askPermission, notifyWebBrowser} from '../lib/notifications';
import eventEmitter from '../event-emitter';
import actions from '../actions';
import { CLICKED_EXTERNAL_URL_KEY } from '../constants';

const { addMessages, fetchMessage, updatePage } = actions;

var queuedMessages = [];
if(window.CURB) { 
  window.CURB.webBrowserNotifications = {askPermission, notifyWebBrowser};
}
// listen for this event here because it is possible that it happens right after page load before the app container could be initialized
eventEmitter.on(
  'receiveMessage',
  data => {
    queuedMessages.push(data);
  }
);

eventEmitter.on(
  'newNotification',
  data => {
    notifyWebBrowser(data);
  }
);

class AppContainer extends Component {
  UNSAFE_componentWillMount(){
    this.on_resize = throttle(
      this.on_resize.bind(this),
      500
    );
    window.addEventListener('resize', this.on_resize);
    this.on_resize();
    this.checkForQueuedMessages();
    eventEmitter.on(
      'receiveMessage',
      () => this.checkForQueuedMessages()
    );
    document.addEventListener(
      'click',
      e => {
        // external links that were clicked inside the native app are saved to localStorage
        if( isFromNativeApp() && e.target.matches('a[href^="https://"],a[href^="http://"]') ){
          window.localStorage.setItem(CLICKED_EXTERNAL_URL_KEY, e.target.getAttribute('href'));
          e.preventDefault();
          return false;
        }
      }
    );
  }

  checkForQueuedMessages(){
    const { fetchMessage } = this.props;
    queuedMessages.forEach(({messageId}) => { fetchMessage(messageId);});
    queuedMessages = [];
  }

  componentDidMount(){
    this.addBodyClasses();
  }

  addBodyClasses(){
    document.body.classList.toggle('small-screen-width', isSmallScreenWidth());
    document.body.classList.toggle('touch-enabled', hasTouchEvents);
    document.body.classList.toggle('mobile-landscape', isMobileLandscape());
    document.body.classList.add(getPlatform());
    document.documentElement.classList.toggle('native', isFromNativeApp());
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.on_resize);
  }

  on_resize(){
    this.addBodyClasses();
    this.props.updatePage({
      width: window.innerWidth,
      height: window.innerHeight,
      smallScreenWidth: isSmallScreenWidth(),
      mobileLandscape: isMobileLandscape()
    });
    const parent = this;
    setTimeout(()=>{
      parent.addBodyClasses();
      parent.props.updatePage({
        width: window.innerWidth,
        height: window.innerHeight,
        smallScreenWidth: isSmallScreenWidth(),
        mobileLandscape: isMobileLandscape()
      });
    }, 200);
    setTimeout(()=>{
      parent.addBodyClasses();
      parent.props.updatePage({
        width: window.innerWidth,
        height: window.innerHeight,
        smallScreenWidth: isSmallScreenWidth(),
        mobileLandscape: isMobileLandscape()
      });
    }, 500);
    setTimeout(()=>{
      parent.addBodyClasses();
      parent.props.updatePage({
        width: window.innerWidth,
        height: window.innerHeight,
        smallScreenWidth: isSmallScreenWidth(),
        mobileLandscape: isMobileLandscape()
      });
    }, 1000);
  }

  isLoggedIn(props=this.props){
    const { user } = props;
    if(!isEmpty(user) && user.user_id) {
      window.CURB.user_id = user.user_id.replace(/auth0\|/gi, '');
      return true;
    } 
    return false;
  }

  hasNewLocations(nextProps){
    return !isEqual(
      Object.keys(nextProps.locations),
      Object.keys(this.props.locations)
    );
  }

  receivedAppConfig(appConfig, prevAppConfig){
    const { provider, fleet_id } = appConfig;

    // add provider as body class
    if(prevAppConfig.provider){
      document.body.classList.remove(prevAppConfig.provider);
    }
    document.body.classList.add(provider);
    window.CURB.provider = fleet_id;
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { user, appConfig } = nextProps;
    user.isLoading ? document.body.classList.add('loading') : document.body.classList.remove('loading');
    if(appConfig.provider !== this.props.appConfig.provider){
      // received new app config
      this.receivedAppConfig(appConfig, this.props.appConfig);
    }
  }

  render(){
    const { appConfig={} } = this.props;
    const { app_css={} } = appConfig;
    let children = null;
    let classNames = ['app'];

    if (this.props.children) {
      // children are the individual routes, so pass them auth instance directly
      // as well as the circuit data stream
      children = React.cloneElement(this.props.children, {
        circuitDataStream: this.circuitDataStream
      });
    }

    if(this.isLoggedIn()){
      classNames.push('logged-in');
    }

    return (
      <div className={classNames.join(' ')}>
        { app_css && app_css.global && (
          <style type="text/css" dangerouslySetInnerHTML={{
            __html: app_css.global
          }} />
        ) }
        {children}
        <TooltipComponent />
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { user={}, locations={}, appConfig={} } = state;
    return {
      user,
      locations,
      appConfig
    };
  },
  {
    addMessages,
    fetchMessage,
    updatePage
  }
)(AppContainer);
