import React, { Component } from 'react';
import Dropdown from '../../atoms/Dropdown';
import { getCurrencyConfig } from '../../lib/utils';
import { isDollar } from '../../lib/unit-converter';
import { isPhoneGap } from '../../lib/environment-check';

const options = [
  {
    value: 'W',
    short: 'Watts',
    long: 'Watts'
  },
  {
    value: '$/hour',
    short: '/hr',
    long: '/hour'
  },
  {
    value: '$/day',
    short: '/day',
    long: '/day'
  },
  {
    value: '$/week',
    short: '/wk',
    long: '/week'
  },
  {
    value: '$/month',
    short: '/mo',
    long: '/month'
  }
];
export default class UnitSelection extends Component {
  static defaultProps={
    unit: 'W',
    direction: 'up',
    billing: {}
  }

  constructor(props){
    super(props);
    this.state = {};
  }

  renderOptions(){
    const { appConfig, billing } = this.props;
    const { symbol } = getCurrencyConfig(billing.simple_currency_code);
    return options.filter(
      o => appConfig.allow_currencies || !isDollar(o.value)
    )
    .map(
      o => {
        const prefix = isDollar(o.value) ? symbol : '';
        return (
          <div key={o.value} value={o.value}>
            <span className="long">{prefix}{o.long}</span>
            <span className="short">{prefix}{o.short}</span>
          </div>
        );
      }
    );
  }

  render() {
    const { unit, onChange, direction } = this.props;
    return (
      <Dropdown
        className={`unit-selection ${direction}${isPhoneGap ? ' static' : ''}`}
        currentValue={unit}
        onChange={onChange}>
        {this.renderOptions()}
      </Dropdown>
    );
  }
}
