import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import RenderField from '../../atoms/RenderField';
import CurbCheckbox from '../../atoms/form/checkbox';
import { email } from 'shared/dist/utils/validators';

const formName = 'UpdateProfileForm';

const validate = function(values){
  var errors = {};
  const emailResult = email(values.email, { required: true });
  if(!emailResult.valid){
    errors.email = emailResult.error;
  }
  if(values.email !== values.confirm_email){
    errors.confirm_email = 'Emails must match';
  }
  if(!values.password){
    errors.password = 'Required';
  }
  if(!values.confirm_email){
    errors.confirm_email = 'Required';
  }
  return errors;
};


class UpdateProfileForm extends Component {
  UNSAFE_componentWillMount(){
    this.state = {};
  }
  onSubmit(e){
    const { handleSubmit, pristine, valid } = this.props;
    e.preventDefault();
    if(!pristine && valid && typeof handleSubmit === 'function'){
      handleSubmit(this);
    }
  }

  render(){
    const { pristine, valid, currentValues } = this.props;
    const { busy } = this.state;
    return (
      <form
        className={`curb-form update-profile-form ${busy ? 'busy' : ''}`}
        onSubmit={this.onSubmit.bind(this)}>
        <section className="form-fields">
          <section className="form-section">
            <h3 className="label">Current email address</h3>
            <Field
              name="current_email"
              type="text"
              readOnly={true}
              component={RenderField} />
          </section>
          <section className="form-section">
            <h3 className="label">New email address</h3>
            <Field
              name="email"
              type="email"
              placeholder="Enter new email"
              component={RenderField} />
            <Field
              name="confirm_email"
              type="email"
              placeholder="Confirm new email"
              component={RenderField} />
          </section>
          <section className="form-section">
            <h3 className="label">Authorize with current password</h3>
            <Field
              name="password"
              type={currentValues.show_password ? 'text' : 'password'}
              placeholder="Current password"
              required={true}
              component={RenderField} />

            <CurbCheckbox
              label="Show password"
              name="show_password"
            />
          </section>
        </section>
        <div className="form-actions">
          <button
            type="submit"
            disabled={!valid || pristine}
            className={`red cta ${!valid || pristine ? 'disabled' : ''}`}>{busy ? 'Sending...' : 'Confirm'}</button>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector(formName);
export default connect(
  state => {
    const { user } = state;
    const initialValues = {
      current_email: user.email,
      password: null,
      email: null,
      confirm_email: null,
      show_password: false
    };
    const currentValues = selector(state, ...Object.keys(initialValues));
    return {
      initialValues,
      currentValues
    };
  }
)(
  reduxForm({
    form: formName,
    validate
  })(UpdateProfileForm)
);
