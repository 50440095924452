export const RECEIVE_TARIFF = 'RECEIVE_TARIFF';
export const UPDATE_TARIFF = 'UPDATE_TARIFF';
export const SAVE_TARIFF = 'SAVE_TARIFF';

export function receiveTariff(tariff) {
  return {
    type: RECEIVE_TARIFF,
    tariff
  };
}

export function updateTariff(locationId, tariff){
  return {
    type: UPDATE_TARIFF,
    locationId,
    tariff
  };
}

export function saveTariff(tariff, location, done){
  return {
    type: SAVE_TARIFF,
    tariff,
    location,
    done
  };
}
