import React, { Component } from 'react';
import Header from '../../atoms/Header';
import { isPhoneGap } from '../../lib/environment-check';

export default class SupportMenu extends Component{
  render(){
    return (
      <div className="SupportMenu">
        <Header clickTo={this.props.changeSection('main')} title="Information" />
        <section className="info">
          <ul>
            {(<li><a className="walkthrough-restart" onClick={this.props.resetJoyride}>
              Restart Walkthrough Tutorial
            </a></li>)}
            {isPhoneGap && (<li>
              <strong>Build number:</strong> {process.env.GIT_SHA && process.env.GIT_SHA.slice(0,7)}
            </li>)}
          </ul>
          <p>
            <a className="contact" href="mailto:support@energycurb.com">
              Contact Support
            </a>
          </p>
        </section>
      </div>
    );
  }
}
