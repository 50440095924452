import '@babel/polyfill';
require('viewport-units-buggyfill').init();
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './lib/icons';
import { isAndroid } from './lib/environment-check';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { 
      hasError: true, 
      error 
    };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    console.error(error);
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      let { className } = this.props;
      let classes = ['error-boundary'];
      if(className){
        classes.push(className);
      }

      // You can render any custom fallback UI
      return <div className={classes.join(' ')}>
        <div className="error-title">An error occurred</div>
        <div className="error-content">
          { JSON.stringify(error) }<br /><br />
          { typeof error.message === 'string' && error.message }
          { typeof error.stack === 'string' && <div className="stack">{error.stack}</div> }
        </div>
        <p className="support-message">Please contact Curb support at <a className="support-email" href="mailto:support@energycurb.com">support@energycurb.com</a> if this problem persists.  Screenshots of the above error can help us resolve the issue.</p>
      </div>
    }

    return this.props.children; 
  }
}

function loadCSS(href){
  return new Promise(
    (resolve, reject) => {
      try {
        const l = document.createElement('link');
        l.setAttribute('type', 'text/css');
        l.setAttribute('rel', 'stylesheet');
        l.setAttribute('media', 'all');
        l.setAttribute('href', href);
        l.addEventListener('load', resolve);
        document.getElementsByTagName('head')[0].appendChild(l);
      } catch(err) {
        reject(err);
      }
    }
  );
}

class Lovelace {
  constructor(params){
    window.CURB = params;
    this.params = params;
    this.init();
  }
  viewportFixes(){
    if(isAndroid()){
      const fixMetaViewportWidths = function(){
        setTimeout(
          function(){
            let viewport = document.querySelector('meta[name=viewport]');
            viewport.setAttribute(
              'content',
              `height=${window.innerHeight}px, width=${window.innerWidth}px, initial-scale=1.0, viewport-fit=cover, user-scalable=0`
            );
          },
          300
        );
      };

      window.addEventListener(
        'orientationchange',
        fixMetaViewportWidths
      );

      fixMetaViewportWidths();
    }
  }
  async loadAdditionalAssets(){
    try {
      const { isNativeApp } = require('./lib/environment-check');
      if(isNativeApp){
        await loadCSS(`${this.params.env.STATIC_PATH}/app.hash.css`)
      }  
    }
    catch(err){
      console.error(err);
    }
  }
  init(){
    const { store } = require('./store');
    const ApplicationRouter = require('./app-router').default;
    const eventEmitter = require('./event-emitter').default;
    const auth = require('./lib/auth').default;
    require('./global');
    // attach objects to app instance so that they can be used by the native app
    this.store = store;
    this.eventEmitter = eventEmitter;
    this.loadAdditionalAssets();
    this.viewportFixes();
    ReactDOM.render(
      (
        <ErrorBoundary>
          <Provider store={store}>
            <ApplicationRouter auth={auth} />
          </Provider>
        </ErrorBoundary>
      ),
      this.params.container || document.body
    );
  }
}

window.Lovelace = Lovelace;
setTimeout(()=>{
  if(!(window.ga && window.ga.loaded)){
    window.location_host = window.location.host ? window.location.host : localStorage.getItem('auth_audience');
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script',`${window.CURB.env.APP_URL}/analytics.js`,'ga');
    const GA_LOCAL_STORAGE_KEY = 'ga:clientId';
    window.ga('create', 'UA-34953013-2', {
      'storage': 'none',
      'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY)||''
    });
    // allow capacitor:// as protocol
    window.ga('set', 'checkProtocolTask', function () { /* nothing */ });
    window.ga(function(tracker) {
      localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
      tracker.set('sendHitTask', function(model) {
        var payLoad = model.get('hitPayload');
        const url = `${window.CURB.env.APP_URL}/gacollect/collect?${payLoad}`;
        var img = document.createElement('img');
        img.src = url;
      });
    });

    window.ga && window.ga('send', 'pageview', {title: 'Curb Dashboard', page: '/dash'});
  }
}, 3000);
