import React, { Component } from 'react';
import classNames from 'classnames';

export default class Tabs extends Component{
  change(val) {
    return () => {
      this.props.onChange(val);
    };
  }

  render (){
    return (
      <div className={'tabs ' + this.props.className}>
        <h4 className="title">{this.props.title}</h4>
        {this.props.children.filter(x=>x).map(tab => {
          const tabClasses = classNames('tab', tab.props.className, {
            'active': this.props.currentValue === tab.props.value
          });
          return (
            <div className={tabClasses}
                 onClick={!tab.props.disabled && this.change(tab.props.value)}
                 disabled={tab.props.disabled}
                 key={tab.props.value}>
              {tab.props.children}
            </div>
          );
        })}
      </div>
    );
  }

}
