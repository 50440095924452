import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { OnChange, OnFocus, OnBlur } from 'react-final-form-listeners'
import CurbSelect from 'shared/dist/components/atoms/form/select';
import CurbInput from 'shared/dist/components/atoms/form/input';
import ModalManager from '../../../../atoms/modal-manager';
import HolidayModal from './holiday-modal';
import EnforcementWindows from './enforcement-windows';
import { MONTHS, DAYS, UTILITY_TEMPLATES } from './utils';
import VirtualLoadControl from '../../../../data-containers/virtual-load-control';

export default function TimingMetrics({match, routerLocation, history, formRenderProps}){
  const { form, values, valid } = formRenderProps;
  const { mutators } = form;
  const { enforcement_windows=[], monitor_interval_mins } = values;
  const [ templateFieldFocused, setTemplateFieldFocused ] = useState();
  const { version } = VirtualLoadControl.useContainer();
    
  const holidayModalProps = {
    className: "holidays-modal",
    title: 'Holiday Schedule',
    render: (modalProps) => <HolidayModal {...formRenderProps} modalProps={modalProps} />
  };

  return <section className="timing-metrics">
    <Field 
      name="template"
      label="Select a utility rate template"
      id="rate-template"
      component={CurbSelect}>
      <option value="">None</option>
      <option value="bandera-tou">Bandera Electric Cooperative: Time of Use</option>
      <option value="salt-river-project-e27">Salt River Project: E-27</option>
    </Field>

    <OnBlur name="template">
      { () => setTemplateFieldFocused(false) }
    </OnBlur>

    <OnFocus name="template">
      { () => setTemplateFieldFocused(true) }
    </OnFocus>

    <OnChange name="template">
      {
        v => {
          if(templateFieldFocused){
            const template = UTILITY_TEMPLATES[v];
            if(template){
              Object.keys(template).forEach(
                (key) => {
                  let value = template[key]
                  if(key === 'enforcement_windows'){
                    value = value.map(
                      (v, i) => {
                        const existing = values.enforcement_windows[i] || {};
                        return {
                          ...existing,
                          ...v
                        };
                      }
                    )
                  }
                  form.change(key, value);
                }
              )
            }  
          }
        }
      }
    </OnChange>

    <div className="monitor-interval curb-form-element">
      <span className="label">Demand Interval:</span>
      <Field 
        name="monitor_interval_mins"
        id="monitor_interval_mins"
        parse={
          value => value.toString().length
            ? Number(value)
            : value
        }
        component={CurbSelect}>
        <option value={15}>15</option>
        <option value={30}>30</option>
        <option value={60}>60</option>
      </Field>
      <span className="label">minutes</span>
    </div>

    <ModalManager
      className="launch-holiday-modal overflow-visible"
      modalProps={holidayModalProps}
      render={
        () => <button type="button">
          Edit Holiday Schedule &hellip;
        </button>
      } />

    { /*
      version === 'classic' && <div className="curb-form-element">
        <div className="curb-form-element">
          <div className="status-message warning">
            <p>Units for thresholds below are <strong>Watt-hours per demand interval</strong> ({monitor_interval_mins} minutes)</p>
          </div>
        </div>
        <h3 style={{marginBottom: '0.2rem'}}>Shut off devices or circuits when consumption exceeds:</h3>
        <Field
          name="control_threshold"
          placeholder="Number"
          type="number"
          parse={
            v => {
              if(!v) return undefined;
              return Number(v);
            }
          }
          validate={
            (v, values) => {
              const { release_threshold } = values;
              if(!v){
                return 'Required';
              }
              if(v <= release_threshold){
                return "Control threshold must be greater than the release threshold below"
              }
            }
          }
          component={CurbInput} />

        <h3 style={{marginBottom: '0.2rem'}}>Release devices or circuits when consumption drops below:</h3>
        <Field
          name="release_threshold"
          placeholder="Number"
          type="number"
          parse={
            v => {
              if(!v) return undefined;
              return Number(v);
            }
          }
          validate={
            (v, values) => {
              const { control_threshold } = values;
              if(!v){
                return 'Required';
              }
              if(v >= control_threshold){
                return "Release threshold must be less than the control threshold above"
              }
            }
          }
          component={CurbInput} />
      </div>
    */ }

    <section className="enforcement-window-list">
      <EnforcementWindows {...formRenderProps} />
    </section>

    <div className="curb-submit">
      <a className="passive button" onClick={history.goBack}>&lt; Prev</a>
      <a
        className="button"
        onClick={
          () => {
            mutators.push('enforcement_windows', {
              months: MONTHS,
              days: DAYS.slice(1, 6),
              ranges: [
                {start_hour: 8, end_hour: 17}
              ]
            });
            
            setTimeout(
              () => window.scroll(0, document.body.offsetHeight),
              0
            );
          }
        }>
        Add Enforcement Window { enforcement_windows.length + 1 }
      </a>

      {
        valid 
          ? <Link className="irreversible button" to={routerLocation.pathname.replace(/timing-metrics*/ig, 'priorities')}>Next &gt;</Link>
          : <button className="irreversible button" type="button" disabled={true}>Next &gt;</button>
      }
      
    </div>
  </section>;
}