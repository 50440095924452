export const RECEIVE_REGISTERS = 'RECEIVE_REGISTERS';
export const UPDATE_MULTIPLE_REGISTERS = 'UPDATE_MULTIPLE_REGISTERS';
export const UPDATE_ALL_REGISTERS = 'UPDATE_ALL_REGISTERS';
export const UPDATE_REGISTER = 'UPDATE_REGISTER';

export function receiveRegisters(registers) {
  return {
    type: RECEIVE_REGISTERS,
    registers
  };
}

export function updateRegister(locationId, registerId, register){
  return {
    type: UPDATE_REGISTER,
    locationId,
    registerId,
    register
  };
}

export function updateMultipleRegisters(locationId, registers){
  return {
    type: UPDATE_MULTIPLE_REGISTERS,
    locationId,
    registers
  };
}

export function updateAllRegisters(locationId, register, subsetIds){
  return {
    type: UPDATE_ALL_REGISTERS,
    locationId,
    register,
    subsetIds
  };
}
