// https://medium.com/@david.gilbertson/modals-in-react-f6c3ff9f4701
import React, { Component } from 'react';
import throttle from 'lodash.throttle';
import eventEmitter from '../../event-emitter';
import { hasTouchEvents } from '../../lib/feature-detection';

const possiblePlacements = [
  'top',
  'right',
  'bottom',
  'left'
];

export class TooltipComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      content: null,
      placement: 'top',
      className: '',
      margin: 10
    };
  }
  UNSAFE_componentWillMount(){

    this.attemptedPlacements = [];

    eventEmitter.on(
      'updateTooltip',
      data => this.setState(data)
    );
    eventEmitter.on(
      'clearTooltip',
      () => this.clearTooltip()
    );
    this.onWindowResize = throttle(this.onWindowResize.bind(this), 500);
    window.addEventListener('resize', this.onWindowResize);
    document.addEventListener('click', () => {
      if(!this.state.sticky){
        this.clearTooltip();
      }
    });
    if(hasTouchEvents){
      document.addEventListener('touchstart', () => {
        this.documentTap = true;
      });
      document.addEventListener('touchmove', () => {
        this.documentTap = true;
      });
      document.addEventListener('touchend', () => {
        if(this.documentTap){
          this.clearTooltip();
        }
      });
    }
    document.addEventListener('orientationchange', () => this.clearTooltip());
  }
  clearTooltip(){
    this.setState({
      show: false,
      content: null,
      margin: 10,
      className: ''
    });
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.onWindowResize);
  }
  componentDidMount(){
    this.arrow = this.container.querySelector('.tooltip-arrow');
  }
  onWindowResize(){
    if(this.state.show){
      this.position();
    }
  }
  getTargetCoords(target, placement){
    const { margin } = this.state;
    if(Array.isArray(target) && target.length === 2){
      switch(placement){
        case 'top':
          return [
            target[0],
            target[1] - margin
          ];
        case 'right':
          return [
            target[0] + margin,
            target[1]
          ];
        case 'bottom':
          return [
            target[0],
            target[1] + margin
          ];
        case 'left':
          return [
            target[0] - margin,
            target[1]
          ];
      }
    }
    if(target instanceof HTMLElement || target instanceof SVGElement){
      let { top, right, bottom, left, width, height} = target.getBoundingClientRect();
      switch(placement){
        case 'top':
          return [
            left + width/2,
            top - margin
          ];
        case 'right':
          return [
            right + margin,
            top + height/2
          ];
        case 'bottom':
          return [
            left + width/2,
            bottom + margin
          ];
        case 'left':
          return [
            left - margin,
            top + height/2
          ];
      }
    }
  }

  position(placement=this.state.placement){
    const { target } = this.state;
    var { x, y, arrowOffsetX, arrowOffsetY } = this.getTooltipCoords(target, placement);

    if(this.isOutsideViewport(x, y)){
      this.attemptedPlacements.push(placement);
      // try to get a fallback placement
      var fallbackPlacement = this.getFallbackPlacement(placement);
      // if the fallback placement has already been tried, then just cycle through the possible placements
      if(this.attemptedPlacements.indexOf(fallbackPlacement) > -1){
        for(var i=0; i<possiblePlacements.length; i++){
          let p = possiblePlacements[i];
          if(this.attemptedPlacements.indexOf(p) === -1){
            return this.position(p);
          }
        }
      }
      else {
        return this.position(fallbackPlacement);
      }
    }

    this.attemptedPlacements = [];

    this.container.classList.remove(...possiblePlacements);
    this.container.classList.add(placement);
    this.container.style.transform = `translate(${Math.round(x)}px, ${Math.round(y)}px)`;
    this.arrow.style.transform = `translate(${Math.round(arrowOffsetX)}px, ${Math.round(arrowOffsetY)}px)`;
  }

  getFallbackPlacement(placement){
    const { fallbackPlacement } = this.state;
    if(fallbackPlacement){
      return fallbackPlacement;
    }
    switch(placement){
      case 'top':
        return 'bottom';
      case 'right':
        return 'left';
      case 'bottom':
        return 'top';
      case 'left':
        return 'right';
    }
  }

  isOutsideViewport(x, y){
    const width = this.container.offsetWidth;
    const height = this.container.offsetHeight;
    return (
      x < window.scrollX
      ||
      y < window.scrollY
      ||
      x + width > window.scrollX + window.innerWidth
      ||
      y + height > window.scrollY + window.innerHeight
    );
  }

  getTooltipCoords(target, placement){
    var x = null,
        y = null,
        arrowOffsetX = 0,
        arrowOffsetY = 0;

    const width = this.container.offsetWidth;
    const height = this.container.offsetHeight;

    const [targetX, targetY] = this.getTargetCoords(target, placement);

    const checkForHorizontalOffset = () => {
      var offset = Math.min(x, window.scrollX);
      offset = offset ? offset : Math.max(x + width - (window.innerWidth + window.scrollX), 0);
      x -= offset;
      arrowOffsetX = offset;
    };

    const checkForVerticalOffset = () => {
      var offset = Math.min(y, window.scrollY);
      offset = offset ? offset : Math.max(y + height - (window.innerHeight + window.scrollY), 0);
      y -= offset;
      arrowOffsetY = offset;
    };

    switch(placement){
      case 'top':
        x = targetX - width / 2;
        y = targetY - height;
        checkForHorizontalOffset();
        break;
      case 'bottom':
        x = targetX - width / 2;
        y = targetY;
        checkForHorizontalOffset();
        break;
      case 'right':
        x = targetX;
        y = targetY - height/2;
        checkForVerticalOffset();
        break;
      case 'left':
        x = targetX - width;
        y = targetY - height/2;
        checkForVerticalOffset();
        break;
    }

    return {
      x: x + window.scrollX,
      y: y + window.scrollY,
      arrowOffsetX,
      arrowOffsetY
    };

  }
  componentDidUpdate(){
    const { show } = this.state;
    if(show){
      this.position();
    }
  }
  render(){
    const { content, className, show } = this.state;
    return (
      <div
        ref={c => (this.container = c)}
        className={`${className} tooltip`}
        style={
          {
            display: show && content ? 'block' : 'none'
          }
        }>
        <div className="tooltip-content">
          {content}
        </div>
        <div className="tooltip-arrow" />
      </div>
    );
  }
}

export function showTooltip(state){
  eventEmitter.emit(
    'updateTooltip',
    {
      ...state,
      show: true
    }
  );
}

export function clearTooltip(){
  eventEmitter.emit(
    'clearTooltip'
  );
}
