import CircuitDataStream from './circuit-data-stream';
import LoadControlStatusStream from './load-control-status-stream';
import config from '../../config';
import auth from '../auth';

export const connectToLoadControlStatusStream = () => {
  return new LoadControlStatusStream({
    url: `${config.SOCKET_HOST}/api-curb/load-control-status`,
    auth
  });
};

let _circuitDataStream = null;

export const passiveGetCircuitDataStream = () => _circuitDataStream;
export const getCircuitDataStream = () => {
  if(_circuitDataStream){
    return _circuitDataStream;
  }
  return _circuitDataStream = new CircuitDataStream({
    url: `${config.SOCKET_HOST}/api-curb/circuit-data`,
    auth
  });
};