import hardwareUtils from 'shared/dist/utils/hardware';

const { registerIsProduction, registerIsBattery } = hardwareUtils;

const colors = {
    get: function(i, grouping='registers'){
      const group = this[grouping];
      return group[i % (group.length-1)];
    },
    getForRegister(register, i){
      if(register.id === '582e276b-f5d7-469e-99ff-bb2690ec55f1'){
        // console.log(register);
      }
      return register.other
        ? this.other
        : this.get(
          i,
          registerIsProduction(register)
            ? 'solar'
            : (registerIsBattery(register))
              ? 'battery'
              : 'registers'
        );
    },
    registers: [
      '#fbab18',
      '#c0d72f',
      '#21b366',
      '#27479d',
      '#9e3995',
      '#ee3b23',
      '#21f1cb',
      '#fde200',
      '#4bb748',
      '#087eba',
      '#3a2f90',
      '#e31a4c',
      '#f68e1e',
      '#f6eb16',
      '#17ac4a',
      '#1760ad',
      '#602c90',
      '#ed2024',
      '#f16321',
      '#ffc906',
      '#8ac53f',
      '#019fc3',
      '#293592',
      '#c21a7b',
      '#21f1cb',
      '#fde200',
      '#4bb748',
      '#087eba',
      '#3a2f90',
      '#e31a4c',
      '#f68e1e',
      '#f6eb16',
      '#17ac4a',
      '#1760ad',
      '#602c90',
      '#ed2024'
    ],
    powerband: [
      '#A3D93D',
      '#FA8D00',
      '#FF2D56'
    ],
    net: '#444488',
    usage: '#ed2024',
    other: '#7d7c7c',
    solar: [
      '#4aad00',
      '#a3d93d',
      '#81c623',
      '#00ff5b',
      '#00ff6c',
      '#00b200',
      '#007c00'
    ],
    battery: [
      '#0ce6ef',
      '#77f4f8',
      '#0debf2',
      '#4dffe7',
      '#70ffec',
      '#00ffdd'
    ]
};

Object.keys(colors).forEach(key => {
  if(typeof colors[key] === 'function'){
    colors[key] = colors[key].bind(colors);
  }
});

export default colors;
