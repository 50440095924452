import actionTypes from '../actions';

export default (state={}, action) => {
  const { type, payload } = action;
  switch(type){
    case actionTypes.UPDATE_PAGE:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
