import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import EnergySource from '../Modules/EnergySource';
import RecentUse from '../Modules/RecentUse';
import Radial from '../Radial';
import { minWidthHistorical } from '../../constants';

function MainAside({location, user}){
  const { metadata={} } = location;
  const { is_cellular, production, battery } = metadata;

  const transferProps = {
    location
  };

  if(is_cellular){
    return (<MediaQuery query={`(max-width: ${minWidthHistorical+1}px) and (max-aspect-ratio: 1/1)`}>
      { 
        (production || battery)
          ? <EnergySource 
            range="10m" 
            refreshRate="5m" 
            resolution="5m" 
            metric="avg" 
            {...transferProps} />
          : <RecentUse {...transferProps} />
      }
    </MediaQuery>);
  }


  // if not mobile landscape and not cellular
  return (<>
  <Radial {...transferProps}
    unit={user.unit} />
    </>);
}

function MainAsideWrapper(props){
  const { className, page, location } = props;
  const { metadata={} } = location;
  const { is_cellular } = metadata;
  const { mobileLandscape, smallScreenWidth } = page;

  if(mobileLandscape || (!smallScreenWidth && is_cellular)){
    return (<div className={'hidden-element'}>
    <MainAside {...props} />
  </div>);
  }

  return (<div className={className}>
    <MainAside {...props} />
  </div>);
}

export default connect(
  state => {
    const { page } = state;
    return {
      page
    };
  }
)(MainAsideWrapper);