import React, { useEffect, useState, useReducer, useRef } from 'react';
import LoadingSpinner from 'shared/dist/components/atoms/loading-spinner';
import { Link } from 'react-router-dom';
import Modal from '../../../../../atoms/modal-dark';
import PrintError from 'shared/dist/components/atoms/print-error';
import VirtualLoadControl from '../../../../../data-containers/virtual-load-control';
import { transformFormValuesToSettings } from '../utils';
import * as API from '../../../../../lib/api';
import PlatformsNav from './platforms-nav';
import PrioritiesList from './priorities-list';
import { UPDATE_APP_USER_METADATA } from '../../../../../actions/user';

const integrations = [
  {
    label: 'Ecobee',
    id: 'ecobee',
  },
  {
    label: 'SmartThings',
    id: 'smartthings',
  },
];

function platformReducer(state=[], {type, id, payload}){
  switch(type){
    case 'set':
      return payload;
    case 'update':
      return state.map(
        p => {
          if(p.id === id){
            p = {
              ...p,
              ...payload
            };
          }
          return p;
        }
      );
    default:
      throw new Error('Reducer type is required');
  }
};

export default function(props){
  const { history, formRenderProps } = props;
  const { values:{ priorities=[], port_circuit_mappings=[] }, form, valid } = formRenderProps;
  const { mutators } = form;
  const { 
    hubSerial,
    installation,
    hasWattwise,
    virtualDevices=[],
    registers,
    loadingVirtualDevices,
    loadingRegisters,
    fetchVirtualDevices,
    passiveFetchRegisters,
    setSettings,
    clearStoredFormValues,
    version
  } = VirtualLoadControl.useContainer();
  // const [ configuredRegisters, setConfiguredRegisters ] = useState([]);
  const [ platforms, platformsDispatch ] = useReducer(platformReducer, []);
  
  // save state
  const [ savePending, setSavePending ] = useState()
  const [ saveSuccess, setSaveSuccess ] = useState();
  const [ saveError, setSaveError ] = useState();

  const [ forceModalClose, setForceModalClose ] = useState();

  const save = async () => {
    try {
      setForceModalClose(false);
      setSavePending(true);
      setSaveError(null);
      setSaveSuccess(null);
      const saveSettings = async () => {
        let settings = await API.upsertLoadControlConfig(transformFormValuesToSettings(formRenderProps.values, { hubSerial, virtualDevices, registers, version }));
        setSettings(settings);
      };
      const updateVirtualDevices = async () => {
        await API.upsertVirtualDevices(hubSerial, virtualDevices);
      };
      
      const APICalls = [saveSettings()];

      if(virtualDevices.length){
        APICalls.push(updateVirtualDevices());
      }

      await Promise.all(APICalls);

      setSaveSuccess(true);
      clearStoredFormValues();
    }
    catch(err){
      console.error(err);
      setSaveError(err);
    }
    setSavePending(false);
  }

  // get configured registers from port_circuit_mappings
  const configuredRegisters = port_circuit_mappings
    .filter(r => r.circuit)
    .reduce(
      (acc, { circuit }) => {
        let matchingRegister = registers.find(r => r.id === circuit);
        if(matchingRegister && !acc.find(r => r.id === circuit)){
          acc.push(matchingRegister);
        }
        return acc;
      },
      []
    );

  // load virtual and physical devices on mount
  useEffect(
    () => {
      fetchVirtualDevices();
      passiveFetchRegisters();
    },
    []
  );

  // when virtualDevices, registers, or configuredRegisters changes,
  // recompute platforms state
  useEffect(
    () => {
      let _platforms = integrations.map(
        (integration) => {
          return {
            ...integration,
            active: !!virtualDevices.find(({brand}) => brand === integration.id)
          };
        } 
      );

      // classic gets no third-party integrations
      // if(isClassic){
      //   _platforms = [];
      // }

      _platforms.push({
        physical: true,
        id: 'wattwise',
        label: 'WattWise Relays',
        active: !!configuredRegisters.length
      });
      platformsDispatch({ type: 'set', payload: _platforms });
    },
    [virtualDevices.length, registers.length, configuredRegisters.length]
  );

  const fetchAuthorizationUrl = async ({id}) => {
    let retryCount = 0;
    let maxRetries = 3;
    const fetch = async () => {
      try {
        platformsDispatch({
          type: 'update',
          id,
          payload: {
            authorizationUrlLoading: true,
            authorizationUrlError: null
          }
        });              
        const { url } = await API.fetchAuthorizationUrl(hubSerial, id);
        platformsDispatch({
          type: 'update',
          id,
          payload: {
            authorizationUrl: url
          }
        });
      }
      catch(err){
        if(err.status === 500){
          retryCount += 1;
          if(retryCount < maxRetries){
            return fetch();
          }
        }
        platformsDispatch({
          type: 'update',
          id,
          payload: {
            authorizationUrlError: err
          }
        });
      }
      platformsDispatch({
        type: 'update',
        id,
        payload: {
          authorizationUrlLoading: false
        }
      });      
    };

    return await fetch();

  };

  useEffect(
    () => {
      platforms.filter(({physical}) => !physical)
        .map(
          async (platform) => {
            fetchAuthorizationUrl(platform);
          }
        )
    },
    [platforms.length]
  );

  const additionalProps = {
    configuredRegisters,
    platforms,
    fetchAuthorizationUrl
  };

  return <section className="priorities">
    <PlatformsNav {...props} {...additionalProps} />

    <section className="priorities-list">
      {/* it's important that PrioritiesList not render until */}
      {
        (loadingVirtualDevices || loadingRegisters)
          ? <LoadingSpinner />
          : <PrioritiesList {...props} {...additionalProps} />
      }      
    </section>

    <div className="curb-submit">
      <a className="passive button" onClick={history.goBack}>&lt; Prev</a>
      <a
        className="button"
        disabled={!valid || loadingVirtualDevices || loadingRegisters}
        onClick={
          () => {
            mutators.push('priorities', {});
            setTimeout(
              () => window.scroll(0, document.body.offsetHeight),
              0
            );
          }
        }>
        Add Appliance Priority { priorities.length + 1 }
      </a>
      <button disabled={!valid} className="irreversible button" onClick={ e => { e.preventDefault(); save(); } }>Save</button>
    </div>

    {/* submit modal */}
    { 
      ((savePending || saveSuccess || saveError) && !forceModalClose) && <Modal 
        className="virtual-load-control-submit"
        sticky={savePending || saveSuccess}
        omitClose={savePending || saveSuccess}
        onUpdate={
          ({closed}) => {
            if(closed){
              setForceModalClose(true);
            }
          }
        }
        render={
          () => <section className="save">
              { 
                savePending && <div className="status-message pending">
                  <h3>Saving load control settings for <code>{hubSerial}</code></h3>
                  <div style={{textAlign: 'center', margin: '1rem'}}><LoadingSpinner /></div>
                </div>
              }
              { 
                saveSuccess && <React.Fragment>
                  <div className="status-message success">
                    Successfully updated load control settings for {hubSerial}
                  </div>
                  <div className="curb-submit">
                    <Link className="button" to={`/dash/${installation.location}`}>Visit dashboard</Link>
                    <Link className="button" to="/virtual-load-control">Configure another hub</Link>
                  </div>
                </React.Fragment>
              }
              { 
                saveError && <React.Fragment>
                  <div className="status-message error">
                    Error saving load control settings for {hubSerial}
                    <PrintError error={saveError} />
                  </div>
                  <div className="curb-submit">
                    <button onClick={save}>Try again</button>
                  </div>
                </React.Fragment>
              }
            </section>
        } /> 
    }
  </section>
}