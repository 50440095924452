export const RECEIVE_APP_CONFIG = 'RECEIVE_APP_CONFIG';
export const FETCH_APP_CONFIG = 'FETCH_APP_CONFIG';

export function receiveAppConfig(appConfig){
  return {
    type: RECEIVE_APP_CONFIG,
    appConfig
  };
}

export function fetchAppConfig(locationId){
  return {
    type: FETCH_APP_CONFIG,
    locationId
  };
}
