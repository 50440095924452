import throttle from 'lodash.throttle';
import SocketStream from 'shared/dist/lib/socket-stream';
import { ID_TOKEN_KEY } from 'shared/dist/lib/authService';
import { dispatch, store } from '../../store';
import actions from '../../actions';

class LoadControlStatusStream extends SocketStream {
  on_connect(isReinitializing){
    return () => {
      this.authenticate();
      // if reinitializing, try to auto subscribe to current location if found
      if(isReinitializing){
        let locations = store.getState().locations;
        const currentLocation = Object.keys(locations).map(id => locations[id]).find(location => location.current);
        if(currentLocation){
          this.subscribeToLocation(currentLocation.id);
        }
      }
    };
  }

  authenticate(){
    const token = this.auth.getToken(ID_TOKEN_KEY) || this.auth.getToken();
    super.authenticate(token);
  }


  subscribeToLocation(locationId){
    this.isAuthorized()
      .then(
        () => this.socket.emit('subscribe', locationId)
      );
  }

  unsubscribeAll(){
    if(this.subscriptions && this.subscriptions.length){
      return this.subscriptions.map(
        room => this.socket.emit('unsubscribe', room)
      );
    }
  }

  on_data(){
    return throttle(
      payload => dispatch( actions.updateLive(payload) ),
      1000,
      {
        leading: true
      }
    );
  }

}

export default LoadControlStatusStream;
