import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange, OnFocus, OnBlur } from 'react-final-form-listeners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'shared/dist/components/atoms/loading-spinner';
import CurbInput from 'shared/dist/components/atoms/form/input';
import CurbSelect from 'shared/dist/components/atoms/form/select';
import VirtualLoadControl from '../../../../../data-containers/virtual-load-control';
import { required } from '../../../../../lib/validators';

export default function PrioritiesList({formRenderProps: { form, values }, platforms, configuredRegisters}){
  const { priorities=[], port_circuit_mappings, relays=[] } = values;
  const { 
    virtualDevices=[],
    loadingRegisters,
    loadingVirtualDevices
  } = VirtualLoadControl.useContainer();

  const [ focusedIndex, setFocusedIndex ] = useState();

  useEffect(
    () => {
      if(loadingRegisters === false && loadingVirtualDevices === false){
        // set default values here now that we have configuredRegisters and virtualDevices
        form.change(
          'priorities',
          priorities.map(
            (p, i) => {
              const priority = i+1;
              let platform;
              let device;
              let backupPlatform;
              let backupDevice;
              // grab the `port`and `backup_port` from the `relays` object
              const { port, backup_port, label } = relays.find((r) => r.priority === priority) || {};
              if(port !== undefined){
                let physical = port_circuit_mappings.find(pcm => pcm.port === port);
                let virtual = virtualDevices.find(device => device.port === port);
                if(physical && physical.circuit){
                  device = physical.circuit;
                  platform = 'wattwise';
                }              
                else if(virtual) {
                  device = virtual.id;
                  platform = virtual.brand;
                }
              }
              if(backup_port !== undefined){
                let physical = port_circuit_mappings.find(pcm => pcm.port === backup_port);
                let virtual = virtualDevices.find(device => device.port === backup_port);
                if(physical && physical.circuit){
                  backupDevice = physical.circuit;
                  backupPlatform = 'wattwise';
                }              
                else if(virtual) {
                  backupDevice = virtual.id;
                  backupPlatform = virtual.brand;
                }
              }
              return {
                platform,
                device,
                backupPlatform,
                backupDevice,
                label,
                ...p
              };
            }
          )
        );        
      }
    },
    [loadingRegisters, loadingVirtualDevices]
  );

  // on first render, if still loading, don't render form stuff
  // it needs registers and virtual devices to generate initialValues
  if(loadingRegisters !== false || loadingVirtualDevices !== false){
    return <LoadingSpinner />
  }  

  const activePlatforms = platforms.filter(({active}) => active);
  const wattWisePlatform = activePlatforms.find(p => p.id === 'wattwise');

  const usedDeviceIds = priorities.reduce(
    (acc, {device, backupDevice}) => {
      acc = acc.concat(
        [device, backupDevice].filter(v => v)
      );
      return acc;
    },
    []
  );

  const devices = virtualDevices
    .concat(
      configuredRegisters.map(
        r => {
          return {
            ...r,
            physical: true
          };
        }
      )
    )
    .map(
      device => {
        return {
          ...device,
          disabled: !!usedDeviceIds.find(id => id === device.id)
        };
      },
    );

  const renderDeviceOptions = (platformId) => {
    let options = platformId === 'wattwise'
      ? devices.filter(d => d.physical)
      : devices.filter(d => d.brand === platformId);
    return options.map(
      ({id, name_or_label, label, disabled, group, channel }, i) => <option key={i} value={id} disabled={disabled}>{ name_or_label || label || `Group ${group} | Channel ${channel}` }</option>
    );
  };

  return <FieldArray name="priorities">
    {
      ({fields}) => fields.map(
        (fieldName, fieldIndex) => {          
          const value = (fields.value[fieldIndex]);          
          const priority = fieldIndex+1;     

          return <section key={fieldIndex} className="inset priorities-item">
            <section className="inner">
              <div className="section-title">
                <nav className="order-controls">
                  <button 
                    disabled={fieldIndex===0}
                    onClick={
                      () => fields.swap(fieldIndex, fieldIndex-1)
                    }>
                      <FontAwesomeIcon icon="chevron-up" />
                  </button>
                  <button 
                    disabled={fieldIndex >= fields.length-1}
                    onClick={
                      () => fields.swap(fieldIndex, fieldIndex+1)
                    }>
                      <FontAwesomeIcon icon="chevron-down" />
                  </button>
                </nav>
                <h3>Appliance Priority {priority}</h3>
                <button 
                  className="close" 
                  disabled={fields.length < 2}
                  onClick={() => fields.remove(fieldIndex)}>
                  <FontAwesomeIcon icon="times" />
                </button>
              </div>

              <section>
                <h3>Manage the following device or circuit</h3>
                <div className="form-row">
                  <Field
                    name={`${fieldName}.platform`}
                    label="Via Platform"
                    validate={required}
                    component={CurbSelect}>
                      <option value="" disabled={!!value.platform}>Select a platform:</option>
                    {
                      activePlatforms.map(
                        (platform, i) => <option key={i} value={platform.id}>{platform.label}</option>
                      )
                    }
                  </Field>

                  {/* when switching from virtual to wattwise, clear out any previously selected backup */}
                  <OnChange
                    name={`${fieldName}.platform`}>
                    {
                      (platform, prevPlatform) => {
                        if(prevPlatform && platform === 'wattwise'){
                          form.change(`${fieldName}.backupDevice`, null);
                        }
                      }
                    }
                  </OnChange>

                  <Field
                    name={`${fieldName}.device`}
                    label="Appliance / Device / Circuit"
                    disabled={!value.platform}
                    validate={required}
                    component={CurbSelect}>
                      <option value="">None</option>
                    {
                      renderDeviceOptions(value.platform)
                    }
                  </Field>

                  {/* set label when primary device field changes */}
                  <OnChange 
                    name={`${fieldName}.device`}>
                    {
                      (id) => {
                        if(focusedIndex === fieldIndex){
                          const device = devices.find(d => d.id === id) || {};
                          const label = device.label || device.name_or_label;
                          if(label){
                            form.change(`${fieldName}.label`, label);
                          }  
                        }
                      }
                    }
                  </OnChange>
                  <OnFocus name={`${fieldName}.device`}>
                    {
                      () => setFocusedIndex(fieldIndex)
                    }
                  </OnFocus>
                  <OnBlur name={`${fieldName}.device`}>
                    {
                      () => setFocusedIndex(null)
                    }
                  </OnBlur>

                </div>
              </section>

              { 
                (!!value.platform && value.platform !== 'wattwise' && !!wattWisePlatform)
                &&
                <section>
                  <h3>Does this device or circuit have fallback control?</h3>
                  <div className="form-row">
                    <Field
                      name={`${fieldName}.backupPlatform`}
                      label="Via Platform"
                      component={CurbSelect}>
                        <option value="">None</option>
                        <option value={wattWisePlatform.id}>{wattWisePlatform.label}</option>
                    </Field>

                    <Field
                      name={`${fieldName}.backupDevice`}
                      label="Appliance / Device / Circuit"
                      disabled={!value.backupPlatform}
                      component={CurbSelect}>
                        <option value="">None</option>
                      {
                        renderDeviceOptions(value.backupPlatform)
                      }
                    </Field>
                  </div>                  
                </section>
              }

              <section>
                <h3>Label (for home-owner notifications)</h3>
                <div className="form-row">
                  <Field
                    name={`${fieldName}.label`}
                    component={CurbInput} />
                </div>
              </section>

              

            </section>
          </section>
        }
      )
    }
  </FieldArray>;
}