import React, { Component } from 'react';
import Header from '../../atoms/Header';
import CurbFormWrapper from '../../atoms/form';
import LocationForm from '../form/LocationForm';
import withRouterProps from '../base/WithRouterProps';
import actions from '../../actions';
import { dispatch } from '../../store';

const { updateLocation } = actions;

class LocationInfoMenu extends Component{
  static defaultProps = {
    location: {}
  }

  handleSubmit(data){
    const { location } = this.props;
    return new Promise(
      (resolve, reject) => dispatch(
        updateLocation(location.id, data, resolve, reject)
      )
    );
  }

  render(){
    const { location, history } = this.props;
    return (
      <div className="location-info-menu menu-section-dark">
        <Header clickTo={history.goBack} title="Location Information" />
        <div className="menu-content">
          <CurbFormWrapper
            {...this.state}
            autoSave={true}
            skipLocalCache={true}
            formKeys={LocationForm.formKeys || []}
            reduxFormOptions={{
              initialValues: location
            }}
            id="location-detail-form"
            onSubmit={this.handleSubmit.bind(this)}
            FormContent={LocationForm} />
        </div>
      </div>
    );
  }
}

export default withRouterProps(LocationInfoMenu);