import actionTypes from '../actions';
import { LIVE_DATA_INTERVALS } from '../constants';

export default (state={}, action) => {
  const { type, circuits, locationId, baseline } = action;
  switch(type){
    case actionTypes.UPDATE_LIVE:
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          snapshots: [circuits].concat((state[locationId] || {}).snapshots || []).slice(0, LIVE_DATA_INTERVALS),
          radialPosition: (state[locationId] && state[locationId].hasOwnProperty('radialPosition') ? state[locationId].radialPosition + 1 : 0) % LIVE_DATA_INTERVALS
        }
      };
    case actionTypes.UPDATE_BASELINE:
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          baseline
        }
      };
    default:
      return state;
  }
};
