import d3 from 'shared/dist/lib/d3';
import moment from 'moment-timezone';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getCurrencyConfig } from './utils';
import { isDollar } from './unit-converter';

moment.tz.setDefault('America/Chicago');

// default to CST if no timezone is provided
export function localizeDateToTimezone(d, timezone){
  // d can either be a Date or a timestamp in ms
  if(!(d instanceof Date)){
    d = new Date(d);
  }
  const timestamp = d.getTime();
  const zone = moment.tz.zone(timezone);
  if(zone){
    // moment defined offset and system offset are in minutes
    // so convert to ms
    let offset = (zone.utcOffset(timestamp) - d.getTimezoneOffset()) * 60 * 1000;
    return new Date(
      timestamp - offset // timezone offsets (timezone - UTC in seconds)
    );
  }
  return d;
}

export function timeFormatter(string) {
  // copy string so that mutations don't persist
  return function(d, {timezone, transformString}={}){
    // d can either be a Date or a timestamp in ms
    if(!(d instanceof Date)){
      d = new Date(d);
    }

    var s = string.slice();

    if(typeof transformString === 'function'){
      s = transformString({s, d});
    }

    // format and remove leading zeros
    return moment.tz(d, timezone).format(s);
  };
}

export const formatDollars = d3.format('$,.2f');
export const formatWatts = d3.format(',.0f');
export const formatkWhs = d3.format(',.2f');
export const formatNumber = d3.format(',');
export const formatPercentage = v => d3.format('.0f')(v * 100);
export const formatDate = timeFormatter('ddd, MMM D');
export const formatTime = timeFormatter('h:mmA');
export const monthDateFormat = timeFormatter('MMMM D');
export const simpleTimeFormat = timeFormatter('h:mmA');

export const rangeFormats = {
  '3h': simpleTimeFormat,
  '12h': simpleTimeFormat,
  '1d': simpleTimeFormat,
  '1w': timeFormatter('ddd h:mmA'),
  '30d': timeFormatter('ddd, MMM D'),
  '365d': timeFormatter('ddd, MMM D'),
  'all': timeFormatter('MMM D `YY'),
  '1mo': timeFormatter('ddd, MMM D'),
  '1y': timeFormatter('ddd, MMM D'),
  '5y': timeFormatter('MMM D `YY')
};

export const kwhRangeTickFormats = {
  '3h': simpleTimeFormat,
  '12h': simpleTimeFormat,
  '1d': simpleTimeFormat,
  '1w': timeFormatter('ddd, MMM D'),
  '30d': function(){
    return `Week of ${timeFormatter('MMM D').apply(this, arguments)}`;
  },
  '1mo': function(){
    return `Week of ${timeFormatter('MMM D').apply(this, arguments)}`;
  },
  '365d': timeFormatter('MMM'),
  '1y': timeFormatter('MMM'),
  '5y': timeFormatter('MMM `YY'),
  'all': timeFormatter('MMM `YY')
};

export const kwhRangeFormats = function(timeRange, startDate, endDate, timezone) {
  switch(timeRange){
    case '3h':
    case '12h':
    case '1d':
      return `${timeFormatter('h:mm')(startDate, {timezone})}-${timeFormatter(startDate.getHours() !== endDate.getHours() ? 'h:mmA' : 'mmA')(endDate, {timezone})}`;
    // case '1d':
    //   return `${timeFormatter('hA')(startDate, {timezone})} - ${timeFormatter('hA')(endDate, {timezone})}`;
    case '1w':
      return `${timeFormatter('ddd MMM D')(startDate, {timezone})}`;
    case '30d':
    case '1mo':
      return `${timeFormatter('MMM D')(startDate, {timezone})} - ${timeFormatter('MMM D')(endDate, {timezone})}`;
    case '365d':
    case '1y':
    case '5y':
    case 'all':
      return `${timeFormatter('MMM `YY')(startDate, {timezone})}`;
    default:
      return `${simpleTimeFormat(startDate, {timezone})}-${simpleTimeFormat(endDate, {timezone})}`;
  }
};

export const truncateString = (string, length = 20) => {
  if(string.length <= length) {
    return string;
  }
  return string.slice(0, length - 3) + '…';
};

export const unitFormats = {
  'w': d3.format(',.0f'),
  '$/hr': d3.format('$,.2f')
};

export const getCurrencyFormatter = (currency='USD') => {
  const currencyConfig = getCurrencyConfig(currency);
  return s => `${currencyConfig.symbol}${d3.format(`.${currencyConfig.decimal_digits}f`)(s)}`;
};

export const getFormatter = unit => {
  if(isDollar(unit)){
    return formatDollars;
  }
  switch(unit){
    case 'percentage':
      return formatPercentage;
    case 'kWh':
    case 'kwhr':
      return formatkWhs;
  }
  return formatWatts;
};

export const formatMessageDate = (d, timezone='America/Chicago') => {
  const now = new Date();
  const startOfToday = new Date( now.getTime() - now.getHours() * 1000 * 60 * 60 - now.getMinutes() * 1000 * 60 - now.getSeconds() * 1000 - now.getMilliseconds() );
  const startOfYesterday = new Date( startOfToday - 1000 * 60 * 60 * 24 );
  if(d > startOfToday){
    return timeFormatter('[Today] h:mm a z')(d, {timezone});
  }
  return timeFormatter('MMM D h:mm a z')(d, {timezone});
};

export const formatMessageTimestamp = (d, timezone) => formatMessageDate(
  new Date(d),
  timezone
);

export const stripTags = s => {
  return s.replace(/<(?:[^>=]|='[^']*'|="[^"]*"|=[^'"][^\s>]*)*>/gi, '');
};

export const numericOnly = str => str.replace(/\D/g, '');

export const formatPhoneNumber = (phone, options={}) => {
  const { country='US' } = options;
  const phoneNumber = parsePhoneNumberFromString(phone, country);
  return phoneNumber.formatNational();
};
