import React, { Component } from 'react';
import d3 from 'shared/dist/lib/d3';
import { sortCircuits } from 'shared/dist/utils/snapshot';
import { registerIsConsumption } from 'shared/dist/utils/hardware';
import { connect } from 'react-redux';
import LiveUsageChart from '../../ui/viz/live-usage';
import Header from '../../atoms/Header';
import Alerts from '../../atoms/Alerts';
import Loader from '../../atoms/Loader';
import UnitSelection from '../../components/Radial/UnitSelection';
import actions from '../../actions';
import { documentHidden } from '../../lib/feature-detection';
import eventEmitter from '../../event-emitter';

const { upsertUserDetail, clearAllAlerts } = actions;

class LiveUsagePageContent extends Component {
  constructor(props){
    super(props);
    this.state = {
      location: {},
      unit: 'W',
      consumers: [],
      pageHidden: documentHidden()
    };
  }

  getAllSnapshots(props=this.props){
    const { live, currentLocation } = props;
    try {
      return live[currentLocation.id].snapshots || [];
    } catch(err) {
      return [];
    }
  }

  getLatestSnapshot(props=this.props){
    const snapshots = this.getAllSnapshots(props);
    return snapshots[0];
  }

  isLoading(state=this.state){
    return !state.consumers.length;
  }

  onVisibilityChange(hidden){
    if(this._isMounted){
      window.ga && window.ga('send', 'pageview', {title: 'Curb Dashboard', page: '/dash'});
      this.setState({
        pageHidden: hidden
      });
    }
  }

  UNSAFE_componentWillMount(){
    const { clearAllAlerts } = this.props;
    this.otherValues = [];
    clearAllAlerts();
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    eventEmitter.on('visibilityChange', this.onVisibilityChange);
    this.checkForLiveData(this.props);
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    eventEmitter.off('visibilityChange', this.onVisibilityChange);
  }

  componentDidMount(){
    const { registers } = this.props;
    this.initGraph();
    this.graph.updateRegisters(registers);
    this._isMounted = true;
    window.ga && window.ga('send', 'pageview', {title: 'Live Usage View', page: '/live-usage/'});
  }

  checkForLiveData(props=this.props){
    const { currentLocation, billing } = props;
    const { pageHidden } = this.state;
    if(currentLocation && currentLocation.id && !pageHidden){
      const snapshot = this.getLatestSnapshot(props);
      const { simple_kwh_price, simple_currency_code } = billing;

      if(snapshot && snapshot.length){
        const consumers = sortCircuits(snapshot.filter(circuit => circuit.label && registerIsConsumption(circuit))).reverse();
        const otherCircuit = consumers.find(c => c.other);

        // have other be an average of the last 5 readings
        if(otherCircuit){
          this.otherValues = [consumers.find(c => c.other).w].concat(this.otherValues).filter(v => typeof v !== 'undefined').slice(0, 5);
          otherCircuit.w = Math.round(d3.mean(this.otherValues));
        }

        this.setState({
          consumers,
          simple_kwh_price,
          simple_currency_code
        });
        this.updateChartData(consumers);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.checkForLiveData(nextProps);
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   // the only re-render is when we go from loading to not loading
  //   // all other updates are handled by d3
  //   return this.isLoading() && !this.isLoading(nextState);
  // }

  initGraph(){
    if(this.graph){
      this.graph.destroy();
    }
    this.graph = new LiveUsageChart(
      this.chartContainer
    );
  }

  updateChartData(data = this.state.consumers){
    const { registers } = this.props;
    const { simple_kwh_price, simple_currency_code } = this.state;
    if(this.graph){
      this.graph.updateParams({
        simple_kwh_price,
        simple_currency_code,
        unit: this.getLiveDataUnit()
      });
      this.graph.updateRegisters(registers);
      this.graph.update(data);
    }
  }

  unitDropdownOnChange(unit){
    this.props.upsertUserDetail(
      {
        userDetail: { live_data_unit: unit }
      }
    );
    this.forceUpdate();
  }
  getLiveDataUnit(){
    const { userDetail, appConfig } = this.props;
    const fallback = 'W';
    return appConfig.allow_currencies
      ? userDetail.live_data_unit || fallback
      : fallback;
  }
  render(){
    const isLoading = this.isLoading();
    const { appConfig, billing, currentLocation } = this.props;

    return (
      <div className="live-usage-page full-screen full-screen-page">
        <Header linkTo={`/dash/${currentLocation.id}`} title="Live Usage by Circuit" />
        <main>
          <Alerts />
          <div className={`chart-title-area ${isLoading ? 'hidden' : ''}`}>
            {
              appConfig.allow_currencies ?
              (
                <UnitSelection
                  unit={this.getLiveDataUnit()}
                  direction="down"
                  billing={billing}
                  appConfig={appConfig}
                  onChange={(unit)=>{this.unitDropdownOnChange(unit);}} />
              ) :
              null
            }
          </div>
          {
            isLoading ?
            (
              <Loader className="fill large" />
            ) :
            null
          }
          <div
            className="chart-wrap"
            ref={c => (this.chartContainer = c)} />
        </main>
      </div>
    );
  }
}

export default connect(
  // mapStateToProps
  state => {
    const { appConfig, billing, live, registers } = state;
    return {
      appConfig,
      live,
      billing,
      registers
    };
  },
  // mapDispatchToProps
  {
    upsertUserDetail,
    clearAllAlerts
  }
)(LiveUsagePageContent);
