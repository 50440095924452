import React, { Component } from 'react';
import WithRouterProps from '../../components/base/WithRouterProps';
import { Link } from 'react-router-dom';
import BasicPage from '../BasicPage/';
import Alerts from '../../atoms/Alerts';
import Loader from '../../atoms/Loader';
import { processClaim } from '../../lib/api';
import actions from '../../actions';
import { dispatch } from '../../store';

class ClaimPage extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  UNSAFE_componentWillMount(){    
    const { match, history } = this.props;
    const { params } = match;
    const { claimId } = params;
    if(claimId){
      let location = null;
      this.setState({
        loading: true
      });
      processClaim(claimId)
        .then(
          userAccess => {
            location = userAccess.location;
            history.replace(
              location
                ? `/dash/${location}`
                : '/dash'
            );
          }
        )
        .catch(
          err => {
            console.error(err);
            dispatch(
              actions.APIError(err)
            );
          }
        )
        .then(
          () => {
            this.setState({
              loading: false
            });
          }
        );
    } else {
      history.replace('/');
    }
  }

  render(){
    if(this.state.loading){
      return (
        <Loader className="large fill" />
      );
    }
    return (
      <BasicPage
        className="claim-page"
        headerProps={{
          showMenu: false
        }}
        {...this.props}>
        <section>
          <Alerts />
          <div className="status center">
            <h3 className="center">Contact CURB support at <a href="mailto:support@energycurb.com">support@energycurb.com</a>.</h3>
            <p>In your support request, please provide the full URL of this page along with either a screenshot or the error shown above to help us troubleshoot the issue.</p>
            <p>
              <Link className="button large center" to="/">Continue to App &raquo;</Link>
            </p>
          </div>
        </section>
      </BasicPage>
    );
  }
}

export default WithRouterProps(ClaimPage);