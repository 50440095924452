import { takeEvery } from 'redux-saga';
import actions from '../actions';
import { loginUser, signupUser, changePasswordUser, fetchUser, updateUser, updateAppUserMetadata } from './user';
import { upsertUserDetail } from './user-detail';
import { saveBilling } from './billing';
import { updateTariff } from './tariff';
import { deleteMessages, updateMessage, fetchMessage, fetchInbox } from './messages';
import { fetchLocationState, updateLocation } from './location';

const createWatcherSaga = (actionType, worker) => {
  return function* () {
    yield takeEvery(actions[actionType], worker);
  };
};

const workerSagas = {
  FETCH_LOCATION_STATE: fetchLocationState,
  LOGIN_USER: loginUser,
  SIGNUP_USER: signupUser,
  CHANGE_PASSWORD_USER: changePasswordUser,
  FETCH_USER: fetchUser,
  UPDATE_USER: updateUser,
  UPDATE_APP_USER_METADATA: updateAppUserMetadata,
  SAVE_BILLING: saveBilling,
  UPDATE_LOCATION: updateLocation,
  DELETE_MESSAGES: deleteMessages,
  UPDATE_MESSAGE: updateMessage,
  FETCH_INBOX: fetchInbox,
  // ADD_MESSAGES: addMessages,
  FETCH_MESSAGE: fetchMessage,
  UPDATE_TARIFF: updateTariff,
  UPSERT_USER_DETAIL: upsertUserDetail
};

// ROOT SAGA WILL GET RUN WHEN THE APP STARTS, RUN ALL WATCHER SAGAS
export default function* rootSaga() {
  yield Object.keys(workerSagas).map(actionType => createWatcherSaga(actionType, workerSagas[actionType])());
}
