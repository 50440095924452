import React from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import actions from '../../actions';
import { MessageDependentComponent, MessageDependentComponentConnector } from './MessageDependentComponent';
import { getInbox } from '../../lib/api';
import { formatMessageTimestamp, stripTags } from '../../lib/formatters';
import { getMessageIdentifier, getMessageSubject, getMessageHTML } from '../../lib/utils';
import Loader from '../../atoms/Loader';
import ActionsBar from './ActionsBar';
import eventEmitter from '../../event-emitter';

const { deleteMessages, updateInbox } = actions;

const SLIDE_IN_WIDTH = 96;
const highlightClass = 'highlight';

class MessagesList extends MessageDependentComponent {
  static defaultProps = {
    location: {},
    messages: []
  }

  constructor(props){
    super(props);
    this.state = {
      editMode: false,
      selectedIds: [],
      forceModalOpen: false
    };    
  }

  UNSAFE_componentWillMount(){
    this.on_scroll = debounce(
      this.on_scroll.bind(this),
      500
    );
    this.on_touchStart = this.on_touchStart.bind(this);
    this.on_touchEnd = this.on_touchEnd.bind(this);
    this.on_touchMove = this.on_touchMove.bind(this);
    this.on_clickDeleteSlideInButton = this.on_clickDeleteSlideInButton.bind(this);

    eventEmitter.on('hideDeleteModal', () => {
      this.setState({
        triggerDelete: false
      });
    });
  }

  componentDidMount(){
    super.componentDidMount();
    this.initScroll();
  }

  componentWillUnmount(){
    const { scrollTarget } = this.props;
    if(this._isScrollListening){
      scrollTarget.removeEventListener('scroll', this.on_scroll);
      this._isScrollListening = false;
    }
  }

  hasAdditionalPages(){
    const { last_evaluated_key } = this.props;
    return !!last_evaluated_key;
  }

  componentDidUpdate(prevProps, prevState){
    const { scrollTarget } = this.props;
    if(this.hasAdditionalPages() && scrollTarget && !this._isScrollListening){
      this.initScroll();
    }
  }

  async loadMoreMessages(){
    this.setState({
      isLoadingAdditionalMessages: true
    });

    try {
      await this.fetchMessages({ force: true });
    }
    catch(err){
      console.error(err);
    }
    this.setState({
      isLoadingAdditionalMessages: false
    });
  }

  on_scroll(){
    const { scrollTarget } = this.props;
    const buffer = 20;

    const scrollDistance = scrollTarget === window
      ? scrollTarget.scrollY
      : scrollTarget.scrollTop;

    if(
      !this.state.isLoadingAdditionalMessages
      &&
      this.hasAdditionalPages()
      &&
      scrollDistance + window.innerHeight >= this.container.offsetHeight - buffer
    ){
      // load more
      this.loadMoreMessages();
    }
  }

  initScroll(){
    const { scrollTarget } = this.props;
    if(scrollTarget && !this._isScrollListening){
      scrollTarget.addEventListener('scroll', this.on_scroll);
      this._isScrollListening = true;
    }
  }

  isLoading(){
    return !this.props.location.id || this.props.loading || (!this.props.messages.length && !this.state.error);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState){
    if(
      (nextState.editMode && !this.state.editMode) || (!nextState.triggerDelete && this.state.triggerDelete)
    ){
      const messageLinks = this.container.querySelectorAll('.message-link');
      for (var i=0; i<messageLinks.length; ++i){
        this.applySwipeStyles(messageLinks[i], 0);
      }
    }
  }

  onClickToggleEditMode(e){
    e.preventDefault();
    this.setState({
      editMode: !this.state.editMode
    });
  }

  onClickMessage(message){
    return e => {
      const { editMode } = this.state;
      if(editMode){
        e.preventDefault();
        if(this.state.selectedIds.indexOf(message.id) > -1){
          this.setState({
            selectedIds: this.state.selectedIds.filter(id => id !== message.id)
          });
        } else {
          this.setState({
            selectedIds: this.state.selectedIds.concat([message.id])
          });
        }
      }
    };
  }

  confirmedDeleteMessages(){
    const { deleteMessages } = this.props;
    deleteMessages(this.state.selectedIds);
    this.setState({
      selectedIds: [],
      triggerDelete: false,
      editMode: false
    });
  }

  applySwipeStyles(el, diff){
    const styleTarget = el.parentElement;
    const overswipeDistance = Math.abs(diff) - SLIDE_IN_WIDTH;
    // if we are swiping beyond our slide in element
    if(overswipeDistance > 0){
      let baseDistance = SLIDE_IN_WIDTH;
      let additionalOverswipe = Math.round( Math.sqrt(overswipeDistance) * 4 );
      if(diff < 0){
        additionalOverswipe = -additionalOverswipe;
        baseDistance = -SLIDE_IN_WIDTH;
      }
      diff = baseDistance + additionalOverswipe;
    }
    el.swipeOffset = diff;
    styleTarget.style.transform = `translateX(${diff}px)`;
  }

  on_touchMove(e){
    var velocity = 0;
    const el = e.currentTarget;
    const touchStart = el.touchStart;
    const touchMove = e.touches[0];
    const prevTouchMove = el.touchMove;
    const xDiff = touchMove.clientX - touchStart.clientX + el.startPosition;
    const yDiff = touchMove.clientY - touchStart.clientY;

    touchMove.time = Date.now();

    if(prevTouchMove){
      velocity = (touchMove.clientX - prevTouchMove.clientX) / (touchMove.time - prevTouchMove.time);
    }
    el.touchMove = touchMove;
    // if we are swiping more horizontal than vertical
    if(Math.abs(xDiff) > Math.abs(yDiff)){
      e.preventDefault();
      e.stopPropagation();
      // only allow swiping left for now
      if(xDiff < 0){
        this.applySwipeStyles(el, xDiff, velocity);
      }
    }
    // else we are probably just scrolling
    else {
      this.applySwipeStyles(el, 0, 0);
    }
  }

  on_touchStart(e){
    if(!this.state.editMode){
      const el = e.currentTarget;
      el.touchStart = e.touches[0];
      el.parentElement.classList.remove('snap');
      el.classList.add(highlightClass);
      el.startPosition = el.startPosition || 0;
      el.addEventListener('touchmove', this.on_touchMove);
    }
  }

  on_touchEnd(e){
    const el = e.currentTarget;
    el.touchStart = el.touchMove = null;
    el.removeEventListener('touchmove', this.on_touchMove);
    el.parentElement.classList.add('snap');
    el.classList.remove(highlightClass);

    var snapToPosition = 0;
    if(el.swipeOffset && Math.abs(el.swipeOffset) > SLIDE_IN_WIDTH){
      snapToPosition = el.swipeOffset > 0 ? SLIDE_IN_WIDTH : -SLIDE_IN_WIDTH;
    }
    el.startPosition = snapToPosition;
    this.applySwipeStyles(el, snapToPosition, 0);
  }

  on_clickDeleteSlideInButton(e, message){
    e.preventDefault();
    this.setState(
      {
        triggerDelete: true,
        selectedIds: [getMessageIdentifier(message)]
      }
    );
  }

  renderMessage(message){
    const { location, routerLocation } = this.props;
    const id = getMessageIdentifier(message);
    const isSelected = this.state.selectedIds.indexOf(id) > -1;
    const url = routerLocation.pathname.replace(/inbox.*$/, `inbox/${id}`);
    return (
      <li
        key={id}
        className={`message ${message.was_read ? 'read' : 'unread'} ${isSelected ? 'selected' : ''}`}>
        <div className="message-inner-wrapper">
          <Link
            className="message-link"
            to={url}
            onClick={this.onClickMessage(message)}
            title="Read more"
            onTouchStart={this.on_touchStart}
            onTouchEnd={this.on_touchEnd}
            onTouchCancel={this.on_touchEnd}>
            <div className="message-header">
              <h3 className="title">{getMessageSubject(message)}</h3>
              <span className="date">
                {`${formatMessageTimestamp(message.dt_created, location.timezone)}`}
              </span>
            </div>
            <div className="excerpt" dangerouslySetInnerHTML={{__html: stripTags(getMessageHTML(message)).substr(0, 200)}} />
          </Link>
          <a className="message-flag slide-in">
            <span className="icon" />
            <span className="label">Flag</span>
          </a>
          <a
          className="message-delete slide-in"
          onClick={e => this.on_clickDeleteSlideInButton(e, message)}>
            <span className="icon" />
            <span className="label">Delete</span>
          </a>
        </div>
      </li>
    );
  }

  render(){
    const { messages, messages_count, location } = this.props;
    const { editMode, selectedIds, triggerDelete, isLoadingAdditionalMessages } = this.state;

    if(this.isLoading()){
      return (
        <Loader className="fill large" />
      );
    }

    if(!messages.length){
      return (
        <div className="messages-container messages-list">
          <h3 className="empty-message">There are no messages for this location</h3>
        </div>
      );
    }

    return (
      <div
        className={`messages-container main messages-list ${editMode ? 'edit-mode' : ''}`}
        ref={c => (this.container = c)}>
        <div className="messages-list-header">
          <div className="flex-container">
            <span className="count">
              All Messages ({messages_count})
            </span>

            <ActionsBar
              triggerDelete={triggerDelete}
              location={location}
              disabled={!selectedIds.length}
              handleDelete={() => this.confirmedDeleteMessages()}
              selectedIds={selectedIds} />

            <a className="toggle-edit-mode"
              onClick={e => this.onClickToggleEditMode(e)}>
              {editMode ? 'Cancel' : 'Select'}
            </a>
          </div>
        </div>
        <ul>
          {messages.map(m => this.renderMessage(m))}
        </ul>

        <div className={`center messages-loading-indicator ${isLoadingAdditionalMessages && 'visible'}`}><span className="loader-inline blue" /></div>

      </div>
    );
  }
}

export default MessageDependentComponentConnector(MessagesList, { deleteMessages, updateInbox });
