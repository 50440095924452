import React from 'react';
import Header from '../../atoms/Header';
import withRouterProps from '../base/WithRouterProps';
import MessagesDetail from '../../components/Messages/Detail';

function MessagesMenu(props){
  const { history } = props;
  return (
    <div className="messages-list-menu">
      <Header clickTo={history.goBack} title="Messages" />
      <MessagesDetail {...props} />
    </div>
  );
}

export default withRouterProps(MessagesMenu);
