import React, { PureComponent } from 'react';
import { getGridImpactForSnapshot, getConsumptionForSnapshot, getProductionForSnapshot, getBatteryForSnapshot } from 'shared/dist/utils/snapshot';
import { parseTimeString } from 'shared/dist/utils/historical';
import NetChart from '../../ui/viz/net';
import RegistersStackedAreaChart from '../../ui/viz/registers-stacked-area';
import Loader from '../../atoms/Loader';
import KwhrChart from '../../ui/viz/kwhr';
import { rangeFormats, kwhRangeTickFormats, getFormatter, timeFormatter } from '../../lib/formatters';
import colors from '../../lib/colors';

export default class HistoricalCharts extends PureComponent {
  static defaultProps = {
    location: {},
    billing: {},
    data: [],
    kwhr: [],
    loading: false
  }

  UNSAFE_componentWillMount(){
    this.setAdditionalState(this.props);
  }

  componentDidMount(){
    this.initGraph(this.props);
    this.updateGraph(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setAdditionalState(nextProps);
  }

  UNSAFE_componentWillUpdate(nextProps){
    this.updateGraph(nextProps);
  }

  componentWillUnmount(){
    this.destroyGraph();
  }

  setAdditionalState(props){
    const { unit, location, energyType, loading } = props;
    if(location.id){

      if(this.props.location.id && this.props.location.id !== props.location.id){
        this.destroyGraph();
      }

      // re-init graph if energy type changes or we're switching to or from kwhr
      if(
        energyType !== this.props.energyType
        ||
        (unit !== this.props.unit && (unit === 'kwhr' || this.props.unit === 'kwhr'))
      ){
        this.initGraph(props);
      }

    }

    if(!loading && !this.hasChartData(props)){
      this.destroyGraph();
    }

  }

  hasChartData(props=this.props){
    const { data, kwhr } = props;
    return data.length || kwhr.length;
  }

  initGraph(props=this.props){
    const { energyType, timeRange, offset, location, unit, resolution } = props;
    var graph;
    if(unit === 'kwhr'){
      graph = new KwhrChart(
        this.container,
        {
          margins: {
            left: 50,
            bottom: 20,
            right: 40
          },
          x: {
            minTickThreshold: 70
          },
          energyType,
          timeRange,
          offset,
          unit,
          resolution,
          chartClass: 'historical-kwhr'
        }
      );
    }
    else if(energyType === 'net'){
      graph = new NetChart(
        this.container,
        {
          margins: {
            left: 50,
            bottom: 20,
            right: 40
          },
          x: {
            tickSeparation: 180,
            minTickThreshold: 100
          },
          energyType,
          timeRange,
          unit,
          resolution,
          chartClass: 'historical-net-chart'
        }
      );
    }
    else {
      graph = new RegistersStackedAreaChart(
        this.container,
        {
          margins: {
            left: 50,
            bottom: 20,
            right: 40
          },
          x: {
            tickSeparation: 160,
            minTickThreshold: 100
          },
          energyType,
          timeRange,
          unit,
          resolution,
          chartClass: 'historical-circuits-chart',
          locationId: location.id
        }
      );

    }
    if(graph){
      // destroy pre-existing graph
      this.destroyGraph();
      this.graph = graph;
      // update graph
      this.updateGraph(props);
    }
  }

  getDateFormatter(props=this.props){
    const { unit, offset, timeRange, location } = props;
    const { timezone } = location;
    const rangeUnit = parseTimeString(timeRange).unit;
    const transformString = ({s, d}) => {
      if(d.getFullYear() !== new Date().getFullYear() && !/YY$/ig.test(s)){
        s = `${s} \`YY`;
      }
      return s;
    };
    return unit === 'kwhr'
      ? function(d, i){
        const { width } = this.dimensions;
        // target one tick per 60 pixels
        const targetCount = Math.round(width / 60);
        const interval = Math.ceil(this.data.length / targetCount);
        if(
          i === 0 ||
          i === this.data.length-1 ||
          (i >= interval && i < this.data.length - interval && i % interval === 0)
        ){
          // offset, and is an hour or day unit
          // then add the day of week and date to first tick
          if(offset && i === 0 && ['h', 'd'].indexOf(rangeUnit) > -1){
            return timeFormatter('ddd M/D')(d, {timezone, transformString});
          }
          return kwhRangeTickFormats[timeRange](d, {timezone, transformString});
        }
      }
      : function(d, i){
        // if offset and the first item, use a different formatter to provide broader context
        if(offset && i === 0){
          // default is month date (eg. Jan 28)
          let formatString = 'MMM D';
          // if going back hours or days, use day of week plus dd/mm (eg. Tues 1/28)
          if(['h', 'd'].indexOf(rangeUnit) > -1){
            formatString = 'ddd M/D';
          }
          return timeFormatter(formatString)(d, { timezone, transformString });
        }

        return rangeFormats[timeRange](d, { timezone, transformString });
      };
  }

  updateGraph(props=this.props){
    const { registers, location, energyType, billing, netData=[], data=[], kwhr=[], timeRange, offset, unit, resolution, allow_currencies } = props;
    if(location.id){
      const { timezone } = location || {};
      const graphData = (function(){
        if(energyType === 'net'){
          return unit === 'kwhr' ?
            kwhr.map(
              agg => {
                // transform raw aggregate data to only have a single item with consumptionKwhr and productionKwhr keys added for the graph to use
                if(!agg.aggregate.find(a => a.id === '__NET__')){
                  return {
                    ...agg,
                    aggregate: [
                      {
                        id: '__NET__',
                        kwhr: getGridImpactForSnapshot(agg.aggregate, { metric: 'kwhr'}),
                        consumptionKwhr: getConsumptionForSnapshot(agg.aggregate, { metric: 'kwhr' }),
                        productionKwhr: getProductionForSnapshot(agg.aggregate, { metric: 'kwhr' }),
                        batteryKwhr: getBatteryForSnapshot(agg.aggregate, { metric: 'kwhr' })
                      }
                    ]
                  };
                }
                return agg;
              }
            ) :
            netData;
        }
        return unit === 'kwhr' ? kwhr : data;
      })();

      const graphRegisters = (function(){
        if(energyType === 'net' && unit === 'kwhr'){
          return [
            {
              id: '__NET__',
              color: colors.net,
              label: 'Net grid impact'
            },
            {
              id: '__CONSUMPTION__',
              color: colors.usage,
              label: 'Consumption'
            },
            {
              id: '__PRODUCTION__',
              color: colors.solar[0],
              label: 'Production'
            },
            {
              id: '__BATTERY__',
              color: colors.battery[0],
              label: 'Storage'
            }
          ];
        }
        return registers;
      })();

      const graphParams = {
        unit,
        energyType,
        locationId: location.id,
        simple_kwh_price: billing.simple_kwh_price,
        currency: billing.simple_currency_code,
        timeRange,
        offset,
        timezone,
        resolution,
        allow_currencies,
        // getColor: unit === 'kwhr' && energyType === 'net' ? getNetKwhrColor : null,
        tickFormats: {
          // custom x tick formatting for kwhr
          x: this.getDateFormatter(props),
          y: unit === 'kwhr'
            ? function(d, i){
              if(i === 0){
                return 0;
              }
              return getFormatter(unit);
            }
            : getFormatter(unit)
        }
      };

      if(!this.graph){
        this.initGraph(props, graphParams);
      }

      if(this.graph && graphData.length && graphRegisters.length){
        this.graph.updateParams(graphParams);
        this.graph.updateRegisters(
          graphRegisters,
          true
        );
        this.graph.update(graphData);
        setTimeout(
          () => {
            if(this.graph && unit !== 'kwhr' && energyType !== 'net'){
              this.graph.draw();
            }
          },
          1000
        );
      }
    }
  }

  destroyGraph(){
    if(this.graph){
      this.graph.destroy();
      this.graph = null;
    }
  }

  render() {
    const { loading } = this.props;

    const renderMain = () => {
      if(loading){
        return (
          <div className="graph-overlay loading">
            <Loader className="fill" />
          </div>
        );
      } else if(!this.hasChartData()) {
        return (
          <div className="graph-overlay error">
            <h2>There is no data for the selected time window</h2>
          </div>
        );
      }
    };

    return (
      <div
        className="historical-chart-inner"
        style={{opacity: loading ? 0.5 : 1}}
        ref={node => (this.container = node)}>
        {renderMain()}
      </div>
    );

  }
}
