export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALL_ALERTS = 'CLEAR_ALL_ALERTS';

export function addAlert({category, title, hideCloseLink, content, id=Date.now()}) {
  return {
    type: ADD_ALERT,
    category,
    title,
    hideCloseLink,
    content,
    id
  };
}

export function removeAlert(id) {
  return {
    type: REMOVE_ALERT,
    id
  };
}

export function clearAllAlerts() {
  return {
    type: CLEAR_ALL_ALERTS
  };
}
