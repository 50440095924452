import React, { Component } from 'react';
import { documentHidden } from '../../lib/feature-detection';
import eventEmitter from '../../event-emitter';

export default class LiveDataComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageHidden: documentHidden()
    };
  }
  getAllSnapshots(props=this.props){
    const { live, location } = props;
    try {
      return live[location.id].snapshots || [];
    } catch(err) {
      return [];
    }
  }

  getLatestSnapshot(props=this.props){
    const snapshots = this.getAllSnapshots(props);
    return snapshots[0];
  }

  onVisibilityChange(hidden){
    if(this._isMounted){
      this.setState({
        pageHidden: hidden
      });
    }
  }

  UNSAFE_componentWillMount(){
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    eventEmitter.on('visibilityChange', this.onVisibilityChange);
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
    eventEmitter.off('visibilityChange', this.onVisibilityChange);
  }

  shouldComponentUpdate(nextProps, nextState){
    return !nextState.pageHidden || this.state.pageHidden !== nextState.pageHidden;
  }

}
