import React, { Component } from 'react';
import d3 from 'shared/dist/lib/d3';
import CheckboxGroup from '../../atoms/form/checkbox-group';
import CurbSelect from '../../atoms/form/select';

const hourFormatter = d3.timeFormat('%I%p');

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const defaultEnforcementWindow = {
  start_hour: 8,
  end_hour: 17,
  days_of_week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
};

const hours = d3.range(0, 24)
  .map(
    h => {
      const d = new Date(0);
      d.setHours(h);
      return {
        value: h,
        label: hourFormatter(d).replace(/^0/ig, '').toLowerCase()
      };
    }
  );

export default class EnforcementWindows extends Component {
  render(){
    const { fields=[], meta } = this.props;
    const { error } = meta;
    return (
      <div className="enforcements-windows-group curb-group-multi">
        {error && <span className="error">{error}</span>}
        {fields.map(
          (ew, index) => {
            return (
              <div key={index} className="enforcement-window curb-group-item">
                <p className="button-wrapper right">
                  <a className="button remove" onClick={() => fields.remove(index)}><i /></a>
                </p>
                <div className="col col-2">
                  <CurbSelect
                    className={['time', 'start_time']}
                    parse={
                      value => value.toString().length
                        ? Number(value)
                        : value
                    }
                    label="Start time"
                    name={`${ew}.start_hour`}>
                    {
                      hours.map(
                        (h, i) => (<option value={h.value} key={i}>{h.label}</option>)
                      )
                    }
                  </CurbSelect>
                  <CurbSelect
                    className={['time', 'end_time']}
                    parse={
                      value => value.toString().length
                        ? Number(value)
                        : value
                    }
                    label="End time"
                    name={`${ew}.end_hour`}>
                    {
                      hours.map(
                        (h, i) => (<option value={h.value} key={i}>{h.label}</option>)
                      )
                    }
                  </CurbSelect>
                </div>
                <CheckboxGroup
                  name={`${ew}.days_of_week`}
                  className="enforcement-window-day-of-week"
                  label="Day of week"
                  options={daysOfWeek.map(
                    d => {
                      return {
                        label: d,
                        value: d
                      };
                    }
                  )} />
              </div>
            );
          }
        )}
        <p className="button-wrapper center">
          <a className="button add" onClick={() => fields.push(defaultEnforcementWindow)}><i /></a>
        </p>
      </div>
    );
  }
}
