import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import actions from '../../actions';
import Modal from '../../atoms/LegacyModal';
import { dispatch } from '../../store';

const { upsertUserDetail } = actions;

export default class Eula extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalIsOpen: props.run
    };    
  }
  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  acceptEula() {
    const { userDetail={} } = this.props;
    const { user_id } = userDetail;
    this.closeModal();
    try {
      dispatch(
        upsertUserDetail(
          {
            userDetail: {
              accepted_eula: true,
              user_id
            }
          }
        )
      );
    } catch(err){
      console.error(err);
    }
  }

  render(){
    var footer =(
        <footer>
          <button className="cancel"><Link to="/logout">Cancel</Link></button>
          <button className="agree" onClick={()=> this.acceptEula()}><strong>Agree</strong></button>
        </footer>
      );
    return (
      <Modal
        close={() => this.closeModal()}
        isOpen={this.state.modalIsOpen}
        className="eula-modal"
        footer={footer}>
        <div className="eula-content">
          I agree to the <strong>CURB’s Terms &amp; Conditions</strong> (<a target="_blank" href="http://energycurb.com/terms">energycurb.com/terms</a>) <br/> and <strong>Privacy Policy</strong> (<a target="_blank" href="https://energycurb.com/privacy">energycurb.com/privacy</a>).
        </div>
      </Modal>
    );
  }
}
