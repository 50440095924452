import React, { Component } from 'react';
import Header from '../../atoms/Header';
import CostBreakdownChart from '../../ui/viz/cost-breakdown';
import { connect } from 'react-redux';
import actions from '../../actions';
import { monthDateFormat, getCurrencyFormatter, formatkWhs } from '../../lib/formatters';
import { getBillingCycleStart, getHistoricalParamsSince } from '../../lib/utils';
import { fetchAggregate, calculateCurrentBill } from '../../lib/api';
import Alerts from '../../atoms/Alerts';
import Loader from '../../atoms/Loader';
import { APIErrorToMessage, getTotalCost } from '../../lib/utils';
import { sortCircuits, getConsumptionForSnapshot, getProductionForSnapshot } from 'shared/dist/utils/snapshot';
import { registerIsBattery, registerIsMain } from 'shared/dist/utils/hardware';
// import { convertWatts } from '../../lib/unit-converter';

const { addAlert, clearAllAlerts } = actions;

class CostBreakdownPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      location: {},
      cost: {},
      circuitsData: [],
      consumptionKwhs: 0,
      productionKwhs: 0,
      hasErrors: false,
      loading: false
    };
  }
  initGraph(){
    if(this.graph){
      this.graph.destroy();
    }
    this.graph = new CostBreakdownChart(
      this.chartContainer
    );
    this.updateChartData();
  }

  updateChartData(data=this.state.circuitsData){
    const { registers, billing } = this.props;
    if(this.graph && billing){
      const { currency } = billing;
      this.graph.updateParams({
        currency
      });
      this.graph.updateRegisters(registers);
      if(data){
        this.graph.update(data);
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState){
    const { registers } = nextProps;
    const { consumptionKwhs, consumptionCost, productionKwhs, productionSavings, circuitsData } = nextState;
    if(this.graph){
      this.graph.updateRegisters(registers);
      this.graph.update(
        circuitsData.map(
          r => {
            return {
              ...r,
              cost: r.production
                ? r.kwhr / productionKwhs * productionSavings
                : r.kwhr / consumptionKwhs * consumptionCost
            };
          }
        )
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { currentLocation } = nextProps;
    const prevLocation = this.props.currentLocation || {};
    if(currentLocation.id !== prevLocation.id){
      this.initForLocation();
    }
  }

  initForLocation(props=this.props){
    const location = this.props.currentLocation;
    const { circuitsData, loading } = this.state;
    const { addAlert, billing, tariff } = props;
    if(location && location.id){
      const billingCycleStart = getBillingCycleStart(billing.day_of_month);
      const { resolution } = getHistoricalParamsSince(billingCycleStart);

      this.setState({
        billingCycleStart
      });

      if(!loading && !circuitsData.length){
        this.setState({
          loading: true
        });
        Promise.all([
          calculateCurrentBill(
            location.id,
            {
              tariffId: billing.simple || !tariff.masterTariffId
                ? 'simple'
                : tariff.masterTariffId
            }
          ),
          fetchAggregate(location.id, 'billing', resolution)
        ])
          .then(
            ([cost, breakdown]) => {
              const getProductionSavings = () => {
                try {
                  const savings = cost.productionSavings || cost.kwhs.production * cost.kwhPrice;
                  if(!savings || isNaN(savings)){
                    throw new Error('Could not determine production savings');
                  }
                  return savings;
                } catch(err){
                  return 0;
                }
              };
              const circuitsData = sortCircuits(breakdown.filter(register => !registerIsMain(register) && !registerIsBattery(register) && register.label));
              const productionSavings = getProductionSavings();
              const consumptionCost = getTotalCost(cost) - productionSavings;
              this.setState({
                cost,
                circuitsData,
                consumptionKwhs: getConsumptionForSnapshot(breakdown, { metric: 'kwhr' }),
                productionKwhs: getProductionForSnapshot(breakdown, { metric: 'kwhr' }),
                productionSavings,
                consumptionCost
              });
            }
          )
          .catch(
            err => {
              console.error(err);
              this.setState({
                hasErrors: true
              });
              addAlert(APIErrorToMessage(err));
            }
          )
          .then(
            () => this.setState({
              loading: false
            })
          );
      }

    }
  }

  componentDidMount(){
    this.initGraph();
  }

  UNSAFE_componentWillMount(){
    const { clearAllAlerts } = this.props;
    clearAllAlerts();
    this.initForLocation();
  }

  renderMain(){
    if(this.state.loading) {
      return (
        <Loader className="fill large" />
      );
    }
    return null;
  }

  renderSummary (){
    const { circuitsData, billingCycleStart, consumptionKwhs, productionKwhs, cost, productionSavings, consumptionCost } = this.state;
    const { billing } = this.props;
    if(circuitsData && circuitsData.length){
      const { currency } = billing;
      const currencyFormatter = getCurrencyFormatter(currency);
      return (
        <div className="chart-header">
        {
          billingCycleStart ?
          (
            <h1>Since {monthDateFormat(billingCycleStart._d)}</h1>
          ) :
          null
        }
          <div className="summary">
            {
              productionSavings
                ? (
                  <ul className="kwhs net">
                    <li className="item production">Production: -{ currencyFormatter(Math.abs(productionSavings)) } ({ formatkWhs(productionKwhs) } kWh)</li>
                    <li className="item consumption">Consumption: { currencyFormatter(consumptionCost) } ({ formatkWhs(consumptionKwhs) } kWh)</li>
                    <li className="item total">Net: { currencyFormatter(getTotalCost(cost)) } ({ formatkWhs(consumptionKwhs + productionKwhs) } kWh)</li>
                  </ul>
                )
                : (
                  <ul className="kwhs">
                    <li className="item total">Total: { currencyFormatter(getTotalCost(cost)) } ({ formatkWhs(consumptionKwhs) } kWh)</li>
                  </ul>
                )
            }
          </div>
        </div>
      );
    }
    return null;
  }

  render(){
    const { currentLocation } = this.props;
    return (
      <div className="cost-breakdown-page full-screen full-screen-page">
        <Header linkTo={`/dash/${currentLocation.id}`} title="Cost Breakdown" />
        <Alerts />
        <main>
          {this.renderSummary()}
          <div
            className="chart-container"
            ref={c => (this.chartContainer = c)}>
            {this.renderMain()}
          </div>
        </main>
      </div>
    );
  }
}

export default connect(
  // mapStateToProps
  state => {
    const { billing={}, tariff={}, registers=[] } = state;
    return {
      billing,
      tariff,
      registers
    };
  },
  // mapDispatchToProps
  {
    addAlert,
    clearAllAlerts
  }
)(CostBreakdownPage);
