import React from 'react';
import { Link } from 'react-router-dom';

export default function Loader(props) {
  const { to, children, className } = props;
  return (
    <Link to={to} className={`more-info ${className ? className : ''}`}>
      <span className="content">{children}</span>
    </Link>
  );
}
