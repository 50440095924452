import React, { Component } from 'react';
import { connect } from 'react-redux';
import countries from 'shared/dist/lib/countries';
import Header from '../../atoms/Header';
import EnergyProviderForm from './EnergyProviderForm';
import actions from '../../actions';
import Loader from '../../atoms/Loader';
import { fetchUtilities, fetchLocationBilling, fetchTariffForLocation } from '../../lib/api';
import { validPostcode } from '../../lib/validators';

const { saveBilling, updateLocation, updateTariff } = actions;

class EnergyProviderMenu extends Component{
  static defaultProps = {
    location: {},
    billing: {},
    tariff: {}
  }

  constructor(props){
    super(props);
    const { billing, tariff } = props;
    this.state = {
      APIError: null,
      lses: [],
      tariffs: [],
      billing,
      tariff
    };    
  }

  save(){
    return values => {
      const { location, saveBilling, updateLocation, updateTariff, tariff } = this.props;
      const newTariff = this.state.tariffs.find(t => t.masterTariffId === values.tariffId);

      if(values.postcode !== location.postcode){
        updateLocation( location.id, { postcode: values.postcode });
      }

      if(newTariff && values.tariffId !== tariff.masterTariffId){
        updateTariff(location.id, newTariff);
      }

      return saveBilling(values, location);
    };
  }

  checkForNewData(zipCode){
    if(zipCode){
      this.setState({
        lses: [],
        tariffs: []
      });
      fetchUtilities(zipCode)
        .then(
          tariffs => {
            this.setState({
              APIError: null,
              tariffs,
              lses: tariffs
                // map out relevant keys
                .map(
                  tariff => {
                    return {
                      lseId: tariff.lseId,
                      lseName: tariff.lseName
                    };
                  }
                )
                // filter out duplicate tariffs by id
                .reduce(
                  (acc, value) => {
                    if(!acc.find((v) => v.lseId === value.lseId)){
                      acc.push(value);
                    }
                    return acc;
                  }, []
                )
            });
          }
        )
        .catch(
          (err) => {
            console.error(err);
            this.setState({
              APIError: `Could not fetch utility data for ${zipCode}`
            });
          }
        );
    }
  }

  UNSAFE_componentWillMount(){
    const { location, billing={} } = this.props;
    const country = countries.find(({iso2, iso3}) => { return iso3 === location.country || iso2 === location.country; });

    this.validatePostcode = value => {
      return validPostcode(value, country.iso2);
    };

    if(billing.location !== location.id){
      this.setState({
        loading: true
      });
      Promise.all([
        fetchLocationBilling(location.id),
        fetchTariffForLocation(location.id)
      ])
        .then(
          ([billing, tariff]) => this.setState({
            billing,
            tariff
          })
        )
        .catch(console.error)
        .then(
          () => this.setState({
            loading: false
          })
        );
    }

  }

  componentDidMount(){
    this.checkForNewData(this.props.location.postcode);
  }

  render(){
    try {
      const { location, history } = this.props;
      const { billing, tariff, loading, APIError, lses, tariffs } = this.state;
      const { masterTariffId, lseId } = tariff;
  
      return (
        <div className="full-height energy-provider-menu menu-section-dark">
          <Header clickTo={history.goBack} title="Energy Provider" />
          <div className="menu-content">
            <h3 className="title">{location.label}</h3>
            {
              loading
                ? (
                  <Loader className="fill large" />
                )
                : (
                  <EnergyProviderForm
                    handleSubmit={this.save()}
                    onZipCodeChange={zipCode => this.checkForNewData(zipCode)}
                    lses={lses}
                    tariffs={tariffs}
                    APIError={APIError}
                    location={location}
                    validatePostcode={this.validatePostcode}
                    enableReinitialize={true}
                    keepDirtyOnReinitialize={true}
                    initialValues={{
                      tariffId: masterTariffId,
                      lseId,
                      ...billing,
                      postcode: location.postcode,
                      submit: 'Submit'
                    }}
                  />
                )
            }
          </div>
        </div>
      );
    }
    catch(err){
      return <div className="error status">{err.message}</div>
    }
  }
}

export default connect(
  (state, ownProps) => {
    const { billing, tariff } = state;
    const { location } = ownProps;

    if(billing.location === location.id){
      return {
        billing,
        tariff
      };
    }
    return {};
  },
  {
    saveBilling,
    updateLocation,
    updateTariff
  }
)(EnergyProviderMenu);
