export const RECEIVE_BILLING = 'RECEIVE_BILLING';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const SAVE_BILLING = 'SAVE_BILLING';

export function receiveBilling(billing) {
  return {
    type: RECEIVE_BILLING,
    billing
  };
}

export function updateBilling(locationId, billing){
  return {
    type: UPDATE_BILLING,
    locationId,
    billing
  };
}

export function saveBilling(billing, location, done){
  return {
    type: SAVE_BILLING,
    billing,
    location,
    done
  };
}
